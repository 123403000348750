import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";
import withRouter from "../../../components/Common/withRouter";
import { formate } from "date-fns"
import {
    Container,
    Row,
    Col,
    CardText,
    Label,
    Form,
    Input,
    FormFeedback,
    FormGroup,
    Card,
    CardBody,
    CardTitle,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledTooltip,
    Badge

} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    fetchConsumptionsAsync,
    createConsumptionAsync,
    updateConsumptionAsync
} from '../../../toolkitStore/cloudConsumptionSlice'; // Update the path accordingly

import {
    fetchCustomers
} from "../../../toolkitStore/manageCustomerSlice";

import { fetchCustomerSubscriptions, resetCustomerConsumptions } from "toolkitStore/manageSubscriptionSlice"


import {
    fetchVendors
} from '../../../toolkitStore/manageVendorSlice';

import SubscriptionForm from './forms/addSubscriptions';
import CRUDSubscription from './CRUDSubscriptions';
import SubscriptionProducts from './SubscriptionProducts';
import TableContainer from './Components/TableContainer';
import moment from 'moment';

const Subscriptions = (props) => {

    const dispatch = useDispatch();
    const { consumptions } = useSelector(state => state.cloudConsumptionReducer);
    const { customerList, isSuccessfullyAdded } = useSelector(state => state.manageCustomerReducer);
    const { vendorList, isSuccessfullyAdded: isVendorAPISuccessfull } = useSelector(state => state.manageVendorReducer);
    const { customerSubscriptionList } = useSelector(state => state.manageSubscriptionReducer)


    const [userInformation, setInfo] = useState("");
    const [cmpConfig, setCmpConfig] = useState("");
    const [cstmrList, setCstmrList] = useState([]);

    const [isRight, setIsRight] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState(500)
    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [formMode, setFormMode] = useState("new")
    const [pageIs, setPageIs] = useState(null)

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setInfo(obj);
        const cConfig = JSON.parse(localStorage.getItem("company-config"));
        setCmpConfig(cConfig);
    }, []);


    useEffect(() => {
        if (!customerSubscriptionList && userInformation) {
            dispatch(fetchCustomerSubscriptions(userInformation.m_company_id));
        }
    }, [customerSubscriptionList, userInformation])

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const toggleRightCanvas = () => {
        isRight && setSelectedSubscription(null)
        isRight && setFormMode("new")
        setIsRight(!isRight);
    };

    const closeOffCanvas = () => {
        toggleRightCanvas();
        setCanvasWidth(500)
        dispatch(fetchCustomerSubscriptions(userInformation.m_company_id))
        setSelectedSubscription(null)
        setFormMode("new")
    }


    const columns = useMemo(
        () => [
            {
                Header: 'Subscription Name',
                accessor: 'subscription_ref_name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <Col>
                            <div style={{ fontSize: 14, fontWeight: "bold" }}>{cellProps.row.original.subscription_ref_name}</div>
                            <div style={{ fontSize: 12 }}>{cellProps.row.original.subscription_ref_id}</div>
                        </Col>
                    )
                }
            },
            {
                Header: 'Customer',
                accessor: 'customer_name',
                disableFilters: true,
            },
            {
                Header: 'Service',
                accessor: 'service_name',
                disableFilters: true,
            },
            {
                Header: 'Subscription Name',
                accessor: 'subscription_plan_name',
                disableFilters: true,
            },
            {
                Header: 'Vendor',
                accessor: 'vendor',
                disableFilters: true,
            },
            {
                Header: 'Provider',
                accessor: 'provider',
                disableFilters: true,
            },
            {
                Header: 'Start Date',
                accessor: 'start_date',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div>{moment(cellProps.row.original.subscription_customer_start_date).format("DD/MM/YYYY")}</div>
                    )
                }
            },
            {
                Header: 'End Date',
                accessor: 'end_date',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div>{moment(cellProps.row.original.subscription_customer_end_date).format("DD/MM/YYYY")}</div>
                    )
                }
            },
            {
                Header: 'State',
                accessor: 'state',
                disableFilters: true,
                Cell: (cellProps) => {
                    let date1 = moment(new Date());
                    let date2 = moment(cellProps.row.original.subscription_customer_end_date);
                    var isBefore = date2.isBefore(date1)
                    return (
                        <Col>
                            <div className="text-primary"><Badge className={ cellProps.row.original.state === "draft" ? "bg-secondary" : isBefore ? "bg-danger" : "bg-success"}>{cellProps.row.original.state === "draft" ? "Draft" : isBefore ? "expired" : "Published"}</Badge></div>
                        </Col>
                    )
                }
            },


            // {
            //     Header: 'Commitment Term',
            //     accessor: 'subscription_customer_commitment_term',
            //     disableFilters: true,
            // },
            // {
            //     Header: 'Billing Term',
            //     accessor: 'subscription_customer_billing_term',
            //     disableFilters: true,
            // },
            // {
            //     Header: 'Billing Type',
            //     accessor: 'subscription_customer_billing_type',
            //     disableFilters: true,
            // },

            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    let date1 = moment(new Date());
                    let date2 = moment(cellProps.row.original.subscription_customer_end_date);
                    var isBefore = date2.isBefore(date1)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                             { (cellProps.row.original.state !== "draft" && isBefore) &&
                                <li>
                                    <Link
                                        to="#!"
                                        className="btn btn-sm btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const data = cellProps.row.original;
                                            setSelectedSubscription(data)
                                            setPageIs("CRUDSubscription")
                                            setFormMode("clone")
                                            setIsRight(true)
                                        }}
                                    ><i className='mdi mdi-content-copy' />
                                    </Link>
                                </li>
                            }
                            { cellProps.row.original.state === "publish" &&
                                <li>
                                    <Link
                                        to="#!"
                                        className="btn btn-sm btn-outline-primary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const data = cellProps.row.original;
                                            //setSelectedSubscription(data)
                                            //setPageIs("SubscriptionProducts")
                                           //setIsRight(true)
                                            // showLocations(data);
                                            dispatch(resetCustomerConsumptions())
                                            localStorage.removeItem("selectedProduct")
                                            localStorage.setItem("selectedProduct", JSON.stringify(data))
                                            props.router.navigate("/customer-consumptions")
                                            //
                                        }}
                                    > Consumptions
                                    </Link>
                                </li>
                            }

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-info"
                                    onClick={(e) => {

                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        setSelectedSubscription(data)
                                        setPageIs("CRUDSubscription")
                                        setFormMode("edit")
                                        setIsRight(true)
                                        // editData(data);
                                    }}
                                >
                                   { cellProps.row.original.state === "publish" ? "View" : <i className="mdi mdi-pencil-outline" id="edittooltip" /> }
                                   { cellProps.row.original.state !== "publish" && <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>}
                                </Link>
                            </li>
                            { cellProps.row.original.state !== "publish" &&
                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        // deleteData(data);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                }


                        </ul>
                    );
                }
            },

        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row
                        className="justify-content-center"
                    >
                        <Card>
                            <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Subscriptions</h5>
                                    <div className="flex-shrink-0">
                                        {/* <Link to="/ne_subscriptions" className="btn btn-primary me-1">Add New Subscriptions</Link> */}
                                        <Link onClick={() => {
                                            setPageIs("CRUDSubscription")
                                            setIsRight(true)
                                        }} className="btn btn-primary me-1">Add New Subscriptions</Link>
                                        <Link to="#!" onClick={() => {
                                            dispatch(fetchCustomers(userInformation.m_company_id))
                                        }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                {
                                    customerSubscriptionList && customerSubscriptionList.length > 0 &&
                                    <TableContainer
                                        columns={columns}
                                        data={customerSubscriptionList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        customPageSize={10}
                                        customPageSizeOptions={false}
                                        isJobListGlobalFilter={false}
                                        className="custom-header-css"
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Row>
                    <Offcanvas
                        style={{ width: 600, transition: "width .35s ease-in-out", }}
                        isOpen={isRight}
                        backdrop={true}
                        direction="end"
                        toggle={() => { toggleRightCanvas() }}>
                        <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>
                            {
                               pageIs === "CRUDSubscription" ?  "Add / Edit Subscription" : "Add / Edit Products"
                            }
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            {
                                pageIs === "CRUDSubscription" &&
                                <CRUDSubscription
                                closeOffCanvas={() => { closeOffCanvas() }}
                                cmpConfig={cmpConfig}
                                userInformation={userInformation}
                                selectedSubscription={selectedSubscription}
                                formMode={formMode}
                            />
                            }

                            {
                                pageIs === "SubscriptionProducts" &&
                                <SubscriptionProducts
                                closeOffCanvas={() => { closeOffCanvas() }}
                                cmpConfig={cmpConfig}
                                userInformation={userInformation}
                                selectedSubscription={selectedSubscription}
                                formMode={formMode}
                            />
                            }
                            

                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default withRouter(Subscriptions);

Subscriptions.propTypes = {
    history: PropTypes.object,
};

