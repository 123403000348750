import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip,
  Modal,
  FormFeedback, Table
} from "reactstrap"

import TableContainer from "../components/TableContainer"
import { useSelector, useDispatch } from "react-redux"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import {
  fetchSLA,
  createSLA,
  updateSLA,
  deleteMasterSLA,
} from "../../../toolkitStore/manageSLASlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"
import { fetchDeliverables, createDeliverable, updateMasterDeliverable, deleteMasterDeliverable, getDeliverAutogenId, resetDeliverableAutogenID, resetDeliverableList } from "../../../toolkitStore/manageDeliverableSlice"


const Deliverables = (props) => {
  const { userInfo, companyConfig } = useSelector((state) => state.authReducer)
  const { deliverableList, deliverableAutoGenId, isDeliverableSuccessfullyAdded, } = useSelector(
    (state) => state.manageDeliverableReducer
  )
  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")

  const [selectedData, setSelectedData] = useState("")
  const [how_to, setHow_to] = useState("")
  const [deliverableData, setDeliverableData] = useState([])
  const [deliverableType, setDeliverableType] = useState("0")
  const [frequency, setFrequency] = useState("0")
  const [autId, setAutoID] = useState("")
  const [autogenid, setAutogenId] = useState(deliverableAutoGenId)
  const [mode, setMode] = useState("new")
  const [selectedID, setSelectedID] = useState("")
  
  const [deliverableError, setDelError] = useState("")
  const [freqError, setFreqError] = useState("")



  const dispatch = useDispatch()

  const [modal_backdrop, setmodal_backdrop] = useState(false)

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [open, setOpen] = useState(false)
  const [isRight, setIsRight] = useState(false)

  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
    }

  }, [companyConfig])


  useEffect(() => {
    if (!userInformation) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      dispatch(getDeliverAutogenId())
      dispatch(getConfig())
    }
  }, [userInformation])

  useState(() => {
    dispatch(getDeliverAutogenId())
  }, [dispatch])

  useEffect(() => {
    console.log("deliverableAutoGenId", deliverableAutoGenId)
    if (deliverableAutoGenId === "") {
      dispatch(getDeliverAutogenId())
    }
  }, [deliverableAutoGenId])

  useEffect(() => {
    console.log("isDeliverableSuccessfullyAdded", isDeliverableSuccessfullyAdded)
    if (isDeliverableSuccessfullyAdded) {
      dispatch(fetchDeliverables(userInformation.m_company_id))
    }
  }, [isDeliverableSuccessfullyAdded])

  useEffect(() => {
    if (userInformation && deliverableList === null) {
      dispatch(fetchDeliverables(userInformation.m_company_id))
    }
    else if (userInformation) {
      setDeliverableData([...deliverableList])
    }
  }, [userInformation, deliverableList])


  const createAutoGenId = () => {
    console.log("contractAutoGenId", deliverableAutoGenId)
    var prefix_1 = "DID"
    var todaydate = new Date()
    var year = todaydate.getFullYear()
    var id = prefix_1 + "-" + deliverableAutoGenId
    setAutoID(id)
    setAutogenId(deliverableAutoGenId)
  }

  const toggleRightCanvas = () => {
    setMode("new")
    setHow_to("")
    setSelectedData("")
    setDeliverableType("0")
    setFrequency("0")
    dispatch(getDeliverAutogenId())
    createAutoGenId()
    setIsRight(!isRight)
  }

  const editDeliverable = (data) => {
    setMode("edit")
    setSelectedData(data)
    setHow_to(data.action)
    setDeliverableType(data.type_id)
    setFrequency(data.frequency_id)
    setIsRight(!isRight)
  }

  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    setHow_to(data)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // deliverable_id: selectedData.deliverable_id || autId || "",
      name: selectedData.name || "",
      action: selectedData.action || "",
      remarks: selectedData.remarks || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter deliverable name"),
    }),
    onSubmit: (values, { resetForm }) => {

      if(deliverableType === "0")
      {
        setDelError("Select Deliverable Type")
        return
      }

      if(frequency === "0")
      {
        setFreqError("Select Frequency")
        return
      }

      var getDeliverableType = _.filter(companyConfig.deliverable_type, { id: deliverableType })[0].name
      var getFrequency = _.filter(companyConfig.frequencies, { id: frequency })[0].name

      

      values["type"] = getDeliverableType
      values["type_id"] = deliverableType
      values["action"] = how_to

      values["frequency"] = getFrequency
      values["frequency_id"] = frequency

      values["m_company_id"] = userInformation.m_company_id
      values["m_b_unit_id"] = userInformation.m_b_unit_id
      values["created_by"] = userInformation._id

      console.log("values", values)

      if (mode === "edit") {
        values["_id"] = selectedData._id
        dispatch(updateMasterDeliverable(values))
      }
      else {
        dispatch(createDeliverable(values))
      }
      resetForm({ values: "" })
      dispatch(resetDeliverableAutogenID())
      dispatch(fetchDeliverables(userInformation.m_company_id))
      toggleRightCanvas()

    },
  })

  const deleteData = (data) => {

    setSelectedID(data._id)
    tog_backdrop()
  }
  const onConfirmDelete = () => {
    console.log("selectedID", selectedID)
    dispatch(deleteMasterDeliverable(selectedID))
    setmodal_backdrop(false);
  }



  const columns = useMemo(
    () => [
      {
        Header: "Deliverable id",
        accessor: "deliverable_id",
        disableFilters: true,

      },
      {
        Header: "Deliverable type",
        accessor: "type",
        disableFilters: true,
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        disableFilters: true,
      },
      {
        Header: "Deliverable Name",
        accessor: "name",
        disableFilters: true,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    editDeliverable(data)
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-danger"
                  onClick={(e) => {
                    e.preventDefault()
                    const data = cellProps.row.original
                    deleteData(data)
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

          <Row className="justify-content-center">
            <Card>
              <CardBody className="border-bottom" style={{padding:"0.7rem"}}>
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Deliverable</h5>
                  <div className="flex-shrink-0">
                    <Link
                      to="#!"
                      onClick={() => toggleRightCanvas()}
                      className="btn btn-primary me-1"
                    >
                      Add New Deliverable
                    </Link>
                    <Link
                      to="#!"
                      onClick={() => {
                        dispatch(fetchDeliverables(userInformation.m_company_id))
                      }}
                      className="btn btn-light me-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
                <Offcanvas
                  isOpen={isRight}
                  backdrop={"static"}
                  direction="end"
                  toggle={toggleRightCanvas}
                >
                  <OffcanvasHeader
                    toggle={toggleRightCanvas}
                    style={{ borderBottom: "1px solid #dedede" }}
                  >
                    Add / Edit Deliverable
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        {/* <div className="mb-3">
                          <Label htmlFor="deliverable_id">Deliverable ID</Label>
                          <Input
                            name="deliverable_id"
                            className="form-control"
                            placeholder="Enter Deliverable ID"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.deliverable_id || ""}
                            disabled
                            invalid={
                              validation.touched.deliverable_id &&
                                validation.errors.deliverable_id
                                ? true
                                : false
                            }
                          />
                          {validation.touched.deliverable_id &&
                            validation.errors.deliverable_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.deliverable_id}
                            </FormFeedback>
                          ) : null}
                        </div> */}

                        {
                          companyConfig && <div className="mb-4">
                            <label className="" htmlFor="autoSizingSelect">Deliverable Type</label>
                            <select className="form-select" onChange={(e) => {
                              setDelError("")
                              setDeliverableType(e.target.value)
                            }}
                              defaultValue={deliverableType}
                            >
                              <option value="0" disabled>Choose...</option>
                              {
                                companyConfig.deliverable_type.map((item, idx) => {
                                  return (
                                    <option key={"cnst" + idx} value={item.id}>{item.name}</option>
                                  )
                                })
                              }
                            </select>
                            {
                              deliverableError !== "" && <label className="font-size-11 text-danger py-1 px-1">{deliverableError}</label>
                            }
                          </div>
                        }

{
                          companyConfig && <div className="mb-4">
                            <label className="" htmlFor="autoSizingSelect">Frequency</label>
                            <select className="form-select" onChange={(e) => {
                              setFreqError("")
                              setFrequency(e.target.value)
                            }}
                              defaultValue={frequency}
                            >
                              <option value="0" disabled>Choose...</option>
                              {
                                companyConfig.frequencies.map((item, idx) => {
                                  return (
                                    <option key={"freqq" + idx} value={item.id}>{item.name}</option>
                                  )
                                })
                              }
                            </select>
                            {
                              freqError !== "" && <label className="font-size-11 text-danger py-1 px-1">{freqError}</label>
                            }
                          </div>
                        }

                        <div className="mb-3">
                          <Label htmlFor="name"> Name</Label>
                          <Input
                            name="name"
                            className="form-control"
                            placeholder="Enter Deliverable Name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name &&
                                validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 ">
                          <Label htmlFor="how_to">Action</Label>
                          <CKEditor
                            config={{
                              placeholder: "Type the How to  Here..!",
                            }}
                            editor={ClassicEditor}
                            data={how_to}
                            onChange={handleCkeditorIn}
                            value={how_to}
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="remarks"> Remarks </Label>
                          <Input
                            name="remarks"
                            className="form-control"
                            placeholder="Enter Description "
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.remarks || ""}
                            invalid={
                              validation.touched.remarks &&
                                validation.errors.remarks
                                ? true
                                : false
                            }
                          />
                          {validation.touched.remarks &&
                            validation.errors.remarks ? (
                            <FormFeedback type="invalid">
                              {validation.errors.remarks}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div>
                          <button type="submit" className="btn btn-primary w-md">
                            {mode === "edit" ? "Update" : "Submit"}
                          </button>
                        </div>
                      </Row>





                    </Form>
                  </OffcanvasBody>
                </Offcanvas>
              </CardBody>
              <CardBody>
                {deliverableData.length > 0 && (
                  // <TableContainer
                  //   columns={columns}
                  //   data={deliverableData}
                  //   isGlobalFilter={true}
                  //   isAddOptions={false}
                  //   customPageSize={10}
                  //   customPageSizeOptions={false}
                  //   isJobListGlobalFilter={false}
                  //   className="custom-header-css"
                  // />

                  <div className="table-responsive">
                  <Table className="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        deliverableData.map((item, idx) => {

                          return (
                            <tr key={"delvl" + idx}>
                              <th>{idx + 1}</th>
                              <td>{item.type}</td>
                              {
                                item.deliverables.length > 0 &&
                                <table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="text-info">Frequency</th>
                                      <th scope="col" className="text-info">Deliverable Name</th>
                                      <th scope="col" className="text-info">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      item.deliverables.map((elem, indx) => {
                                        return (
                                          <tr key={"cstrlevl" + indx}>
                                            <td width={"20%"}>{elem.frequency}</td>
                                            <td width={"70%"}>{elem.name}</td>
                                            <td width={"10%"}>
                                              <ul className="list-unstyled hstack gap-1 mb-0">
                                                <li>
                                                  <Link
                                                    to="#"
                                                    className="btn btn-sm btn-info"
                                                    onClick={() => {
                                                      const data = elem
                                                      editDeliverable(data)
                                                    }}
                                                  >
                                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                                      Edit
                                                    </UncontrolledTooltip>
                                                  </Link>
                                                </li>

                                                <li>
                                                  <Link
                                                    to="#"
                                                    className="btn btn-sm btn-danger"
                                                    onClick={(e) => {
                                                      e.preventDefault()
                                                      const data = elem
                                                      deleteData(data)
                                                    }}
                                                  >
                                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                      Delete
                                                    </UncontrolledTooltip>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>
                              }
                            </tr>
                          )

                        })
                      }
                    </tbody>
                  </Table>
                </div>


                )}
              </CardBody>
            </Card>
          </Row>

          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete Business unit
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure, you want to delete this business unit?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setmodal_backdrop(false)
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  onConfirmDelete()
                }}
              >
                Yes, delete it
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Deliverables
