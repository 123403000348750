import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialState = {
    consumptions: [],
    exchangeRate: null,
    status: 'idle',
    error: null,
};

const cloudConsumptionSlice = createSlice({
    name: 'cloudConsumption',
    initialState,
    reducers: {
        fetchConsumptionsStart: (state) => {
            state.status = 'loading';
        },
        fetchConsumptionsSuccess: (state, action) => {
            state.status = 'succeeded';
            console.log("action.payload", action.payload);
            state.consumptions = action.payload;
        },
        fetchConsumptionsFailure: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        createConsumptionSuccess: (state, action) => {
            state.consumptions.push(action.payload);
        },
        updateConsumptionSuccess: (state, action) => {
            const updatedConsumption = action.payload;
            const index = state.consumptions.findIndex((c) => c._id === updatedConsumption._id);
            if (index !== -1) {
                state.consumptions[index] = updatedConsumption;
            }
        },
    },
})

export const fetchConsumptionsAsync = () => async (dispatch) => {
    dispatch(fetchConsumptionsStart());
    try {
        const response = await get('converter/fetch-consumption');
        dispatch(fetchConsumptionsSuccess(response.data));
    } catch (error) {
        dispatch(fetchConsumptionsFailure(error.message));
    }
};

export const createConsumptionAsync = (consumptionData) => async (dispatch) => {
    try {
        const response = await post('converter/create-consumption', consumptionData);
        dispatch(createConsumptionSuccess(response.data));
    } catch (error) {
        console.error('Error creating consumption:', error);
    }
};

export const updateConsumptionAsync = (consumptionData) => async (dispatch) => {
    try {
        const response = await post(`converter/update-consumption/${consumptionData._id}`, consumptionData);
        dispatch(updateConsumptionSuccess(response.data));
    } catch (error) {
        console.error('Error updating consumption:', error);
    }
};


export const {
    fetchConsumptionsStart,
    fetchConsumptionsSuccess,
    fetchConsumptionsFailure,
    createConsumptionSuccess,
    updateConsumptionSuccess,
} = cloudConsumptionSlice.actions;

export default cloudConsumptionSlice.reducer;
