import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { useNavigate } from 'react-router-dom';

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";


import { getConfig } from "../../toolkitStore/authenticateSlice"
import { searchCustomer, resetCustomerData } from "../../toolkitStore/dashboardSlice"

const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);

  const dispatch = useDispatch()

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { customerInfo, isSuccessfullyAdded } = useSelector(state => state.dashboardReducer)
  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [searchInfo, setSearchInfo] = useState("")
  const [searchedCustomers, setSearchedCustomers] = useState([])

  const [showListpanel, setShowListpanel] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
    }

  }, [companyConfig])


  useEffect(() => {
    if (!userInformation) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      dispatch(getConfig())
    }
  }, [userInformation])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

  }

  const navigateTo = (data) => {
    dispatch(resetCustomerData())
    setShowListpanel(false)
    navigate("/support-dashboard", {
      state:{
        "info":data
      }
    })
   
  }




  // useEffect(() => {
  //   console.log("customerInfo", customerInfo)
  //   if(customerInfo === "")
  //   {
      
  //   }
  //   else
  //   {
  //     setSearchInfo(customerInfo)
  //   }

  // },[customerInfo])

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Orders",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ]

  //meta title
  document.title = "Support360 HQ | Pentabrains"

  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if(searchTerm.length > 0)
    {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm)
      var values = {}
      values["customer_name"] = searchTerm
      values["m_company_id"] = userInformation.m_company_id
      dispatch(searchCustomer(values))
      // Send Axios request here
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }
  }, [searchTerm])

  useEffect(()=>{
    console.log("customerInfo", customerInfo)
    if(customerInfo !== null && customerInfo !== undefined)
    {
      setSearchedCustomers([...customerInfo])
    }

  },[customerInfo])

  const searchFieldValidation = (text) => {
    if(text.length > 0)
    {
      setShowListpanel(true)
    }
    else
    {
      setShowListpanel(false)
    }
  }



  const searchValidate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      search_customer:""
    },
    validationSchema: Yup.object({
      //customer_name: Yup.string().required("Please Enter Your impact name"),
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, { resetForm }) => {

      values["customer_name"] = values.search_customer
      values["m_company_id"] = userInformation.m_company_id
      dispatch(searchCustomer(values))
      console.log("Values", values)

    }
  });

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <form className="app-search d-none d-lg-block"
             onSubmit={(e) => {
              e.preventDefault();
              //searchValidate.handleSubmit();
              return false;
            }}
            >
              <div className="position-relative">
                <input
                  type="text"
                  name="search_customer"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                  //onChange={searchValidate.handleChange}
                  onChange={(e)=> {
                    searchFieldValidation(e.target.value)
                    setSearchTerm(e.target.value)
                  }}
                  // onBlur={(e) => searchFieldValidation(e.target.value)}
                  value={searchTerm}
                  style={{width:400}}
                  // required
                  // invalid={
                  //   searchValidate.touched.search_customer && searchValidate.errors.search_customer ? true : false
                  // }

                />
                <span className="bx bx-search-alt" />
                {
                  showListpanel && <div style={{ position:"absolute", top:45, height:300, width:"100%", overflow:"auto", background:"#fff", border:"1px solid #dedede"}}>
                  <ul className='col' style={{position:"relative", padding:"0px 10px", width:"100%"}}>
                    {
                      searchedCustomers && searchedCustomers.map((item, idx) => {
                        return (
                          <li key={"srch" + idx} style={{ padding: "7px 10px", listStyle:"none", width:"100%", borderBottom: "1px solid #dedede", cursor:"pointer" }} 
                          onClick={() => {
                            navigateTo(item)
                          }}
                          >
                            <div style={{ width:"100%"}}>{item.customer_name}</div>
                          </li>
                        )
                      })
                    }

                  </ul>
                </div>
                }
                
              </div>
            </form>

          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                        onChange={(e)=>{}}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />

            {/* <div
               onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
