import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    customerInfo: null,
    customerData: null,
    masterServices:null,
    serviceContractList:null,
    countInfo:null,
    isSuccessfullyAdded:false,
};


const dashboardSlice = createSlice({
    name: "manageCustomers",
    initialState: initialRegState,
    reducers: {

        setCustomerInfo:(state, action) => {
            state.customerInfo = action.payload
        },
        setCustomerData:(state, action) => {
            state.customerData = action.payload
        },
        resetCustomerData:(state, action) => {
            state.customerData = null
        },
        setMasterServices:(state, action) => {
            state.masterServices = action.payload
        },
        resetMasterServices:(state, action) => {
            state.masterServices = null
        },
        setServiceContractList:(state, action) => {
            state.serviceContractList = action.payload
        },
        resetServiceContractList:(state, action) => {
            state.serviceContractList = null
        },
        setCountInfo:(state, action) => {
            state.countInfo = action.payload
        }
    }

})

export const searchCustomer = (values) => {
    return async(dispatch) =>{
        try {
            
            var responseData = await post("/manage_dashboard/search-customer", {
                "searchInfo": values
            })
            console.log("search customer", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setCustomerInfo(responseData.data))
            }

        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const getCustomerInfo = (values) => {

    return async(dispatch) =>{
        try {
            dispatch(resetCustomerData())
            var responseData = await post("/manage_dashboard/get-customer-info", {
                "customer_id": values._id
            })
            console.log("search customer", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setCustomerData(responseData.data[0]))
            }

        } catch (error) {
            console.log("catch error", error)
        }
    }

}

export const getMasterServices = (company_id) => {

    return async(dispatch) =>{
        try {
            dispatch(resetCustomerData())
            dispatch(resetMasterServices())
            var responseData = await post("/manage_dashboard/get-master-service-info", {
                "company_id": company_id
            })
            console.log("dashboard services", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setMasterServices(responseData.data))
            }

        } catch (error) {
            console.log("catch error", error)
        }
    }

}

export const getServiceContracts = (serviceInfo, cstrInfo) => {

    return async(dispatch) =>{
        try {
            dispatch(resetServiceContractList())
            var responseData = await post("/manage_dashboard/get-service-contracts", {
                "serviceInfo": serviceInfo,
                customerInfo:cstrInfo
            })
            console.log("service data", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setServiceContractList(responseData.data))
            }

        } catch (error) {
            console.log("catch error", error)
        }
    }

}

export const getContractAssets = (contract_id) => {
    return async(dispatch) => {
        try {
            var responseData = await post("/manage_dashboard/get-service-contract-asset-info", {
                contract_id:contract_id
            })
            if(responseData.response_code === 500){
                
            }
        } catch (error) {
            
        }
    }
}

export const getDInfo = (company_id) => {
    return async(dispatch) => {
        try {
            var responseData = await post("/dashboard/d-info", {
                company_id:company_id
            })
            if(responseData.response_code === 500){
                console.log("count", responseData.data)
                dispatch(setCountInfo(responseData.data))
            }
        } catch (error) {
            
        }
    }
}



export const { 
    setCustomerInfo, 
    setCustomerData, 
    resetCustomerData, 
    setMasterServices,
    resetMasterServices,
    setServiceContractList,
    resetServiceContractList,
    setCountInfo
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
