import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { getConfig } from "../../../../toolkitStore/authenticateSlice"
import { resetVendorSuccessfullyAdded, fetchVendor, createVendor, updateMasterVendor, resetVenResponseError } from "../../../../toolkitStore/manageVendorSlice"



const AddVendorForm = (props) => {


    const dispatch = useDispatch()

    const [selectedData, setSelectedData] = useState(props.selectedData)
    const { vendorList, isSuccessfullyAdded, responseVendorError } = useSelector(state => state.manageVendorReducer)
    const [vendorType, setVendorType] = useState(props.vendorType)
    const [cusTypeError, setCusTypeError] = useState("")
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/




    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            vendor_name: selectedData.vendor_name || "",
            vendor_type: selectedData.vendor_type || "",
            email: selectedData.email || "",
            website: selectedData.website || "",
            phone: selectedData.phone || "",
            address1: selectedData.address1 || "",
            address2: selectedData.address2 || "",
            city: selectedData.city || "",
            state: selectedData.state || "",
            country: selectedData.country || "",
            pincode: selectedData.pincode || "",
            remarks: selectedData.remarks || "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        }),
        onSubmit: (values, { resetForm }) => {

            // if (vendorType === "0") {
            //     setCusTypeError("Please select Customer type")
            //     return
            // }

            // dispatch(resetVenResponseError())
            // var getIndex = _.findIndex(props.cmpConfig.vendor_type, { id: vendorType })
            // if (getIndex !== -1) {
            //     var id = props.cmpConfig.vendor_type[getIndex].id
            //     var name = props.cmpConfig.vendor_type[getIndex].name
            // }

            // values["vendor_type_id"] = id
            // values["vendor_type"] = name
            // values["m_company_id"] = props.userInformation.m_company_id
            // values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            // values["created_by"] = props.userInformation._id

            // if (props.mode === "edit") {
            //     values["_id"] = selectedData._id
            //     dispatch(updateMasterVendor(values))
            // }
            // else {
                dispatch(createVendor(values))
            // }s
        }
    });

    useEffect(() => {
        if (isSuccessfullyAdded && !responseVendorError) {
            validation.resetForm({ values: "" });
            dispatch(resetVendorSuccessfullyAdded());
        }
    }, [isSuccessfullyAdded, responseVendorError, dispatch, props.closeOffCanvas, validation]);

    return (
        <div>
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >

                <Row>
                    {
                        props.cmpConfig &&
                        <Col md={12}>
                            <div className="col-sm-auto mb-3">
                                <label className="" htmlFor="autoSizingSelect">Vendor type</label>
                                <select className="form-select" onChange={(e) => {
                                    setCusTypeError("")
                                    setVendorType(e.target.value)
                                }}
                                    defaultValue={customerType}
                                >
                                    <option value="0" disabled>Choose...</option>
                                    {
                                        props.cmpConfig.customer_type.map((item, idx) => {
                                            return (
                                                <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }

                                </select>
                                {cusTypeError ? (
                                    <label className="font-size-10 text-danger mt-2">{cusTypeError}</label>
                                ) : null}
                            </div>
                        </Col>
                    }

                </Row>

                <div className="mb-3">
                    <Label htmlFor="vendor_name">Vendor Name</Label>
                    <Input
                        name="vendor_name"
                        className="form-control"
                        placeholder="Enter Vendor name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.vendor_name || ""}
                        required
                        invalid={
                            validation.touched.vendor_name && validation.errors.vendor_name ? true : false
                        }
                    />
                    {validation.touched.vendor_name && validation.errors.vendor_name ? (
                        <FormFeedback type="invalid">{validation.errors.vendor_name}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email id"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        required
                        invalid={
                            validation.touched.email && validation.errors.email ? true : false
                        }
                    />
                    {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                </div>

                <Row>
                    <Col lg={6} >
                        <div className="mb-3">
                            <Label htmlFor="phone">Phone</Label>
                            <Input
                                name="phone"
                                className="form-control"
                                placeholder="Enter Phone number"
                                type="text"
                                maxLength={"10"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                required
                                invalid={
                                    validation.touched.phone && validation.errors.phone ? true : false
                                }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} >
                        <div className="mb-3">
                            <Label htmlFor="website">Website</Label>
                            <Input
                                name="website"
                                className="form-control"
                                placeholder="Enter website url"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.website || ""}
                                invalid={
                                    validation.touched.website && validation.errors.website ? true : false
                                }
                            />
                            {validation.touched.website && validation.errors.website ? (
                                <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="mb-3">
                        <Label htmlFor="address1">Address 1</Label>
                        <Input
                            name="address1"
                            className="form-control"
                            placeholder="Enter Address 1"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address1 || ""}
                            invalid={
                                validation.touched.address1 && validation.errors.address1 ? true : false
                            }
                        />
                        {validation.touched.address1 && validation.errors.address1 ? (
                            <FormFeedback type="invalid">{validation.errors.address1}</FormFeedback>
                        ) : null}
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="address2">Address 2</Label>
                        <Input
                            name="address2"
                            className="form-control"
                            placeholder="Enter Address 2"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address2 || ""}
                            invalid={
                                validation.touched.address2 && validation.errors.address2 ? true : false
                            }
                        />
                        {validation.touched.address2 && validation.errors.address2 ? (
                            <FormFeedback type="invalid">{validation.errors.address2}</FormFeedback>
                        ) : null}
                    </div>
                </Row>

                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="city">City</Label>
                            <Input
                                name="city"
                                className="form-control"
                                placeholder="Enter City"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                required
                                invalid={
                                    validation.touched.city && validation.errors.city ? true : false
                                }
                            />
                            {validation.touched.city && validation.errors.city ? (
                                <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="state">State</Label>
                            <Input
                                name="state"
                                className="form-control"
                                placeholder="Enter State"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.state || ""}
                                required
                                invalid={
                                    validation.touched.state && validation.errors.state ? true : false
                                }
                            />
                            {validation.touched.state && validation.errors.state ? (
                                <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="country">Country</Label>
                            <Input
                                name="country"
                                className="form-control"
                                placeholder="Enter Country"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country || ""}
                                required
                                invalid={
                                    validation.touched.country && validation.errors.country ? true : false
                                }
                            />
                            {validation.touched.country && validation.errors.country ? (
                                <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="pincode">Pincode</Label>
                            <Input
                                name="pincode"
                                className="form-control"
                                placeholder="Enter Pincode"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincode || ""}
                                invalid={
                                    validation.touched.pincode && validation.errors.pincode ? true : false
                                }
                            />
                            {validation.touched.pincode && validation.errors.pincode ? (
                                <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>


                <div className="mb-3">
                    <Label htmlFor="remarks">Remarks</Label>
                    <Input
                        name="remarks"
                        className="form-control"
                        placeholder="Enter Remarks"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.remarks || ""}
                        invalid={
                            validation.touched.remarks && validation.errors.remarks ? true : false
                        }
                    />
                    {validation.touched.remarks && validation.errors.remarks ? (
                        <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                    ) : null}
                </div>

                <div className="d-flex flex-column">
                    {/* {responseCustomerError && <label className="font-size-10 text-danger">{responseCustomerError}</label>} */}
                    <button type="submit" className="btn btn-primary w-md" >
                        {props.mode === "edit" ? "Update" : "Submit"}
                    </button>
                </div>
            </Form>
        </div>

    );
};

export default AddVendorForm;
