import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    CardSubtitle,
    Container,
    Progress,
    Table,
    Collapse,
    CardHeader,
    CardText
} from "reactstrap";
import Dropzone from "react-dropzone";

import axios from 'axios';
import { map } from "lodash"


import { Link } from "react-router-dom";

import { del, get, post, put, apiURL } from "helpers/QP_API_ACCESS/apiAccess";


const FileUpload = (props) => {

    //meta title
    //document.title = "Form File Upload | Support HQ Manager";

    const [selectedFiles, setselectedFiles] = useState(props.filesAttached.length > 0 ? props.filesAttached : []);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isInvalidFile, setIsInvalid] = useState(false)
    const [ColToggle, setColToggle] = useState(false)

    async function handleAcceptedFiles(files) {
        setIsInvalid(false)
        const formData = new FormData();

        // [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".pdf", ".jpg", ".jpeg"]'
        const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/doc', 'application/xls', 'application/xlsx',];
        const invalidFiles = files.filter(file => !acceptedFileTypes.includes(file.type));

        if (invalidFiles.length > 0) {
            console.log('Invalid files:', invalidFiles);
            setIsInvalid(true)
            return
            // Handle invalid files here
        } else {
            console.log('Accepted files:', files);
            setIsInvalid(false)
            // Handle accepted files here
        }


        files.map(file => {
            Object.assign(file, {
                "preview": URL.createObjectURL(file),
                "formattedSize": formatBytes(file.size),
                "uploading": false,
                "filetype": file.type,
                "uploadingStatus": 'Not uploaded',
                "originalName": file.name,
            })


        }
        )

        // files.forEach((file, index) => {
        //     formData.append(`file${index}`, file);
        //   });

        formData.append(`file`, files[0]);
        formData.append("fileInfo", props.fileToBeAttach)


        try {
            // if (files) {
            //     const response = await post('/file-upload/upload', { formData }, {
            //         headers: {
            //             'Content-type': 'multipart/form-data',
            //         },
            //     });

            //     console.log(response.data);
            // }

            console.log("apiURL", apiURL())

            var baseURL = apiURL()

            let imageFilePath = "";
            if (files) {
                imageFilePath = await fetch(baseURL + '/file-upload/upload', {
                    method: 'POST',
                    body: formData,
                })
                const res = await imageFilePath.json()
                if (res.success) {
                    console.log(res)
                    props.uploadedFiles(res.fileDetails)
                    var newArr = []
                    newArr = [...selectedFiles]
                    newArr.push(res.fileDetails)
                   // setselectedFiles([...newArr])
                }
            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }

    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    useEffect(() => {
        setselectedFiles([...props.filesAttached])
    }, [props.filesAttached])

    function getFileIcon(data) {
      //  console.log("file type", data)
        if (data === "application/pdf") {
            return "bx bxs-file-pdf text-danger"
        }

        if (data === "image/jpeg" || data === "image/jpg") {
            return "bx bxs-file-jpg text-success"
        }

        if (data === "image/gif") {
            return "bx bxs-file-gif text-secondary"
        }

    }

    return (
        <div>
            <Dropzone
                onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone border border-1 border-warning" style={{ minHeight: 70 }}>
                        <div
                            className="dz-message needsclick d-flex flex-row align-items-center justify-content-left"
                            style={{ maxHeight: 70 }}
                            {...getRootProps()}
                        >
                            <div className="d-flex flex-row align-items-center">
                                <input {...getInputProps()} />
                                <div className="me-3">
                                    <i className="fas fa-file-invoice text-primary" style={{ fontSize: '1.5em' }} />
                                </div>
                                <div style={{ fontSize: 14, lineHeight: 1.2, textAlign: "left" }} ><span>Attach {props.fileToBeAttach} </span><br /> <span className="text-secondary" style={{ fontSize: 13 }}>Drop files here or click to upload.</span><br /> <span className="text-secondary" style={{ fontSize: 13 }}>File types are .jpg, .pdf, .doc, .docx, .xls, .xlsx</span></div>
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
            {
                isInvalidFile && <label className="font-size-13 my-2 text-danger">This type of files are not accepted</label>
            }
            <div className="table-responsive">
                <Table className="table-wrap align-middle table-hover mb-0">
                    <tbody>
                        {map(selectedFiles, (file, i) => {
                            var fileIcon = getFileIcon(file.mimetype)
                            return (
                                <tr key={"_file_" + i}>
                                    <td  >
                                        <div className="d-flex flex-column w-100 ">
                                            <div className="d-flex flex-row  align-items-center">
                                                <div className="col-10">
                                                    <div className="d-flex flex-row align-items-center">
                                                    <div className="avatar-sm me-3">
                                                        <span className="avatar-title rounded-circle bg-white border border-secondary border-1  text-primary font-size-24">
                                                            <i className={fileIcon} />
                                                        </span>
                                                    </div>
                                                    <h5 className="font-size-14 mb-1">
                                                        <Link to="#" className="text-dark">
                                                            {file.originalname}
                                                        </Link>
                                                    </h5>
                                                    </div>
                                                </div>

                                                <div className="text-center col-2">
                                                    <div className="text-dark" style={{cursor:"pointer"}} onClick={() => {
                                                        setColToggle(i)
                                                    }} >
                                                        <i className="mdi mdi-delete h3 m-0 text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex " >
                                                {
                                                    <Collapse isOpen={ColToggle === i} style={{width:"100%"}} className="mt-3">
                                                        <div className="row mb-3">
                                                            <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                                        </div>

                                                        <div >
                                                            <div className="py-2 ps-3" >
                                                                <CardHeader className="bg-transparent mb-3">
                                                                    <h7 className="my-0 text-danger">
                                                                        <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                                    </h7>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <CardText>
                                                                        <div>
                                                                            <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                                                            <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); props.removeFile(file) }}>Delete</button>
                                                                        </div>
                                                                    </CardText>
                                                                </CardBody>
                                                            </div>

                                                        </div>
                                                    </Collapse>
                                                }
                                            </div>
                                        </div>

                                    </td>
                                
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>
            </div>
            {/* <div className="dropzone-previews mt-1 d-flex flex-row g-3 flex-wrap" id="file-previews">
                {selectedFiles.map((f, i) => {
                    return (
                        <Card
                            className="mt-1 mb-0 me-1 col-auto shadow-none border border-warning px-3 py-2  dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"} 
                        >
                            <div className='d-flex flex-row justify-content-between align-items-center' >
                                <div style={{ fontSize: 15, width:200, paddingRight:10 }}>{f.originalname}</div>
                                <div onClick={() => {
                                     props.removeFile(f)
                                }}><i className='mdi mdi-delete font-size-20 text-danger' /></div>
                            </div>
                        </Card>
                    )
                })}
            </div> */}
        </div>
    )
}

export default FileUpload
