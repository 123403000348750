import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    isUnitAdded: false,
    businessUnits: null,
    isBusinessSuccessfullyAdded:false,
    responseBUError:""
};


const companySlice = createSlice({
    name: "companymaster",
    initialState: initialRegState,
    reducers: {

        setBusinessUnit: (state, action) => {
            state.businessUnits = action.payload
        },
        setBusinessListSuccessfullyAdded:(state, action) => {
            state.responseBUError = null
            state.isBusinessSuccessfullyAdded = true
        },
        resetBusinessListSuccessfullyAdded:(state, action) => {
            state.isBusinessSuccessfullyAdded = false
        },
        resetBusinessUnit: (state, action) => {
            state.businessUnits = null
        },
        setBUResponseError:(state, action) => {
            state.responseBUError = action.payload
        },
        resetBUResponseError:(state, action) => {
            state.responseBUError = null
        }

        
    }

})

export const createBusinessUnits = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetBusinessListSuccessfullyAdded())
            const responseData = await post("/business_unit/add-business-unit", {
                businessUnitInfo: values
            })
            if(responseData.response_code === 500)
            {
                dispatch(setBusinessListSuccessfullyAdded())
            }
            else{
                dispatch(setBUResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateBusinessUnits = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetBusinessListSuccessfullyAdded())
            const responseData = await post("/business_unit/update-business-unit", {
                businessUnitInfo: values 
            })
            if(responseData.response_code === 500)
            {
                dispatch(setBusinessListSuccessfullyAdded())
            }
            else{
                dispatch(setBUResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const getBusinessUnits = (company_id) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/business_unit/get-business-unit", {
                company_id:company_id
            })
            if(responseData.response_code === 500)
            {
                dispatch(setBusinessUnit(responseData.data))
            }
            else{
                dispatch(setBUResponseError(responseData.message))
            }
            console.log("get business unit", responseData)
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteBusinessUnits = (buid) => {
    return async (dispatch) => {
        dispatch(resetBusinessUnit())
        try {
            const responseData = await post("/business_unit/delete-business-unit", {
                "BUID":buid
            })
            console.log("delete units", responseData)
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setBusinessUnit, 
    resetBusinessUnit, 
    setBusinessListSuccessfullyAdded, 
    resetBusinessListSuccessfullyAdded,
    setBUResponseError,
    resetBUResponseError
} = companySlice.actions;

export default companySlice.reducer;
