import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    contactList: null,
    contactLocationList: null,
    isContactSuccessfullyAdded:false,
};


const manageCCustomerSlice = createSlice({
    name: "manageCCustomer",
    initialState: initialRegState,
    reducers: {

        setContactList: (state, action) => {
            state.contactList = _.each(action.payload, item => {
                item["edit"] = false
                item["locationEditor"] = false
            })
        },
        setContactSuccessfullyAdded:(state, action) => {
            state.isContactSuccessfullyAdded = true
        },
        resetContactSuccessfullyAdded:(state, action) => {
            state.isContactSuccessfullyAdded = false
        },
        resetContactList:(state, action) => {
            state.contactList = null
        },
        setContactLocationList: (state, action) => {
            state.contactLocationList = action.payload
        },
        
    }

})

export const createContact = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetContactSuccessfullyAdded())
            const responseData = await post("/manage_contact/add-contact", {
                "contactInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContactSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterContact = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetContactSuccessfullyAdded())
            const responseData = await post("/manage_contact/update-contact", {
                "contactInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContactSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchContacts = (company_id, customer_id, location_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_contact/get-contacts", {
                "company_id":company_id,
                "customer_id":customer_id,
            })
            console.log("get contacts", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setContactList(responseData.data))
                }
                else
                {
                    dispatch(resetContactList())
                }
                
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterContact = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetContactSuccessfullyAdded())
            const responseData = await post("/manage_contact/delete-contact", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContactSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const mapLocationtoContact = (data) => {
    return async (dispatch) => {
        try {
           // dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/map-location-contact", {
                "mapInfo":data
            })
            console.log("map response", responseData)
            if(responseData.response_code === 500)
            {
                //dispatch(setAssetSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const removeMapLocationtoContact = (data) => {
    return async (dispatch) => {
        try {
           // dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/removemap-location-contact", {
                "mapInfo":data
            })
            console.log("map response", responseData)
            if(responseData.response_code === 500)
            {
               // dispatch(setAssetSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const getMapLocationtoContact = (contactId) => {

    return async (dispatch) => {
        try {
            const responseData = await post("/manage_asset/get-contact-locations", {
                "contactId":contactId
            })
            console.log("asset locations", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContactLocationList(responseData.data))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { setContactList, resetContactList, setContactSuccessfullyAdded, resetContactSuccessfullyAdded, setContactLocationList } = manageCCustomerSlice.actions;

export default manageCCustomerSlice.reducer;
