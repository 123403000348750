import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    CardText,
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback, Collapse
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import TimezoneSelect from 'react-timezone-select'

import { getConfig } from "../../../../toolkitStore/authenticateSlice"
import { resetLocationSuccessfullyAdded, fetchLocations, createLocation, updateMasterLocation, deleteMasterLocation } from "../../../../toolkitStore/manageLocationSlice"
import { resetContactSuccessfullyAdded, fetchContacts, createContact, updateMasterContact, deleteMasterContact, mapLocationtoContact, removeMapLocationtoContact, getMapLocationtoContact } from "../../../../toolkitStore/manageCContactSlice"
import { resetAssetSuccessfullyAdded, fetchAssets, createAsset, updateMasterAsset, deleteMasterAsset, mapLocationtoAsset, removeMapLocationtoAsset, getMapLocationtoAsset } from "../../../../toolkitStore/manageCAssetSlice"

import CountryCode from "../../components/CountryCodes.json"
const AddContacts = (props) => {

    const { locationList, isSuccessfullyAdded } = useSelector(state => state.manageLocationReducer)
    const { contactList, contactLocationList, isContactSuccessfullyAdded } = useSelector(state => state.manageCContactReducer)

    const dispatch = useDispatch()

    const [cntList, setCtList] = useState([])
    const [selectedData, setSelectedData] = useState(props.selectedData)
    const [showEditor, setEditor] = useState(false)

    const [locList, setLocList] = useState([])
    const [modalAsset_backdrop, setmodalContact_backdrop] = useState(false);
    const [selectedContactData, setSelectedContactData] = useState("")
    const [selectedContactLocationData, setSelectedContactLocationData] = useState("")
    const [contactType, setContactType] = useState("0")
    const [tglLcn, setToggleLocation] = useState(false)
    const [selectedCntForLocation, setSelectCntForLocation] = useState("")

    const [ColToggle, setColToggle] = useState(false)

    const [mobile_ccode, setMobileCcode] = useState("+91")
    const [wp_ccode, setWPCcode] = useState("+91")
    const [an_ccode, setANCcode] = useState("+91")



    useEffect(() => {

        if (contactList === null) {
            dispatch(fetchContacts(props.userInformation.m_company_id, props.selectedData._id))
        }
        else {
            console.log("contactList", contactList)
            setCtList([...contactList])
        }

    }, [contactList])

    useEffect(() => {
        if (locationList === null) {
            dispatch(fetchLocations(props.userInformation.m_company_id, selectedData._id))
        }
        else {
            setLocList([...locationList])
        }

    }, [locationList])

    useEffect(() => {
        if (isContactSuccessfullyAdded) {
            dispatch(fetchContacts(props.userInformation.m_company_id, props.selectedData._id))
            setSelectedContactData("")
        }
    }, [isContactSuccessfullyAdded])




    const toggleShowEditor = () => {
        showEditor && setSelectedContactData("")
        setMobileCcode("+91")
        setWPCcode("+91")
        var tempLoc = []
        _.each(cntList, item => {
            var loc = { ...item }
            loc["edit"] = false
            tempLoc.push(loc)
        })
        setContactType("0")
        setSelectedContactData("")
        setCtList([...tempLoc])
        setEditor(!showEditor)
    }



    const closeEditor = () => {
        setSelectedContactData("")
        setEditor(!showEditor)
    }

    const closeContactEditor = () => {
        var tempLoc = []
        _.each(cntList, item => {
            var loc = { ...item }
            loc["edit"] = false
            tempLoc.push(loc)
        })
        setContactType("0")
        setCtList([...tempLoc])

    }

    const toggleEditor = (data) => {
        console.log("data", data)
        var tempLoc = []
        _.each(cntList, item => {
            var loc = { ...item }
            if (loc._id === data._id) {
                loc["edit"] = !loc.edit
                setSelectedContactData(loc)
                setMobileCcode(loc.mobile_ccode)
                setWPCcode(loc.wp_ccode)
            }
            else {
                loc["edit"] = false
            }
            loc["locationEditor"] = false
            tempLoc.push(loc)
        })
        setContactType(data.contact_type_id)
        setCtList([...tempLoc])
        setEditor(false)

    }



    const toggleLocation = (data) => {

        setSelectCntForLocation(data)
        dispatch(getMapLocationtoContact(data._id))
    }

    const closeEditors = () => {
        var tempLoc = []
        _.each(cntList, item => {
            var loc = { ...item }
            loc["edit"] = false
            loc["locationEditor"] = false
            tempLoc.push(loc)
        })
        setCtList([...tempLoc])
        setEditor(false)
    }

    useEffect(() => {

        function fetchData() {

            if (contactLocationList === null) {
                dispatch(getMapLocationtoContact(selectedCntForLocation._id))
            }
            else {
                var tempLoc = []
                var getLocations = []

                _.each(cntList, item => {
                    var loc = { ...item }

                    if (loc._id === selectedCntForLocation._id) {
                        loc["locationEditor"] = !loc.locationEditor
                        setSelectedContactData(loc)
                    }
                    else {
                        loc["locationEditor"] = false
                    }
                    loc["edit"] = false
                    tempLoc.push(loc)
                })


                if (!selectedCntForLocation.locationEditor) {

                    var getLocations = []
                    _.each(locList, item => {
                        var getItem = _.filter(contactLocationList, { location_id: item._id, contact_id: selectedCntForLocation._id })[0]
                        var loc = { ...item }
                        if (getItem !== undefined && getItem.location_id === loc._id) {

                            loc["editLocation"] = true
                        }
                        else {
                            loc["editLocation"] = false

                        }
                        getLocations.push(loc)
                    })
                    setLocList([...getLocations])
                }
                else {
                    setLocList([...locationList])
                }

                setCtList([...tempLoc])
            }
        }


        fetchData()


    }, [contactLocationList])




    const handleDeleteContact = (data) => {
        dispatch(deleteMasterContact(data._id))
    }





    const contactValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            first_name: selectedContactData.first_name || "",
            last_name: selectedContactData.last_name || "",
            work_phone: selectedContactData.work_phone || "",
            designation: selectedContactData.designation || "",
            mobile: selectedContactData.mobile || "",
            email: selectedContactData.email || "",
            department: selectedContactData.department || "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values, { resetForm }) => {

            console.log("values", values)

            var getContact = _.filter(props.cmpConfig.contact_type, { id: contactType })[0]

            values["mobile_ccode"] = mobile_ccode
            values["wp_ccode"] = wp_ccode
      
            values["contact_type_id"] = getContact.id
            values["contact_type"] = getContact.name
            values["customer_id"] = props.selectedData._id
            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            values["created_by"] = props.userInformation._id

            if (selectedContactData !== "") {
                values["_id"] = selectedContactData._id
                dispatch(updateMasterContact(values))
                setSelectedContactData("")
                if (selectedContactData.edit) {
                    closeContactEditor()
                }
                else {
                    closeEditor()
                }

                resetForm({ values: "" })
            }
            else {
                dispatch(createContact(values))
                setSelectedContactData("")
                if (selectedContactData.edit) {
                    closeContactEditor()
                }
                else {
                    closeEditor()
                }
                resetForm({ values: "" })
            }

        }
    });

    const contactMapLocation = (location, contact) => {

        var tempLoc = []
        _.each(locList, item => {
            var loc = { ...item }
            console.log("lc", loc._id, location._id)
            if (loc._id === location._id) {
                loc["editLocation"] = !loc.editLocation

                var data = {
                    "contact_name": contact.first_name,
                    "contact_id": contact._id,
                    "location_id": location._id,
                    "customer_id": props.selectedData._id,
                    "m_company_id": props.userInformation.m_company_id,
                    "m_b_unit_id": props.userInformation.m_b_unit_id,
                    "created_by": props.userInformation._id,
                }
                if (loc.editLocation === true) {
                    dispatch(mapLocationtoContact(data))
                }
                else {
                    var data = {
                        "contact_id": contact._id,
                        "location_id": location._id,
                        "customer_id": props.selectedData._id,
                        "m_company_id": props.userInformation.m_company_id,
                    }
                    dispatch(removeMapLocationtoContact(data))
                }
                // 
            }

            tempLoc.push(loc)
        })

        setLocList([...tempLoc])



    }



    // ============================




    return (
        <div className="p-3">
            <Row style={{ borderBottom: "1px solid #ededed", paddingBottom: 10 }}>
                <Col lg={9} >
                    <div className="row">
                        <div className="font-size-10 text-secondary text-opacity-70">Customer</div>
                        <div className="text-primary font-size-15">{selectedData.customer_name}</div>
                    </div>
                </Col>
                <Col lg={3} className="d-flex flex-row justify-content-end">
                    <button type="submit" className="btn btn-warning w-md" onClick={() => {
                        toggleShowEditor()
                    }}>
                        Add Contact <i className={showEditor ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                    </button>
                </Col>

            </Row>
            <Row>
                <Col lg={12} >
                    {
                        showEditor &&
                        <div style={{ borderBottom: "1px solid #ededed", paddingBottom: 10, paddingTop: 10 }}>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    contactValidation.handleSubmit();
                                    return false;
                                }}
                            >

                                <Row>
                                    {
                                        props.cmpConfig &&
                                        <Col md={12}>
                                            <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="autoSizingSelect">Contact type</label>
                                                <select className="form-select" onChange={(e) => {
                                                    console.log("event.taget.value", e.target.value)
                                                    setContactType(e.target.value)
                                                }}
                                                    defaultValue={contactType}
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        props.cmpConfig.contact_type.map((item, idx) => {
                                                            return (
                                                                <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        </Col>
                                    }

                                </Row>

                                <div className="mb-3">
                                    <Label htmlFor="first_name">First Name</Label>
                                    <Input
                                        name="first_name"
                                        className="form-control"
                                        placeholder="Enter first name"
                                        type="text"
                                        onChange={contactValidation.handleChange}
                                        onBlur={contactValidation.handleBlur}
                                        value={contactValidation.values.first_name}
                                        required
                                        invalid={
                                            contactValidation.touched.first_name && contactValidation.errors.first_name ? true : false
                                        }
                                    />
                                    {contactValidation.touched.first_name && contactValidation.errors.first_name ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.first_name}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="last_name">Last Name</Label>
                                    <Input
                                        name="last_name"
                                        className="form-control"
                                        placeholder="Enter last name"
                                        type="text"
                                        onChange={contactValidation.handleChange}
                                        onBlur={contactValidation.handleBlur}
                                        value={contactValidation.values.last_name}
                                        required
                                        invalid={
                                            contactValidation.touched.last_name && contactValidation.errors.last_name ? true : false
                                        }
                                    />
                                    {contactValidation.touched.last_name && contactValidation.errors.last_name ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.last_name}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="work_phone">Work Phone</Label>
                                    <InputGroup >
                                        <div className="">
                                            <select className="form-select" onChange={(e) => {
                                                setWPCcode(e.target.value)
                                            }}
                                                required
                                                defaultValue={wp_ccode}
                                            >
                                                <option value="0" disabled >Choose...</option>
                                                {
                                                    CountryCode.map((item, idx) => {
                                                        return (
                                                            <option key={"cc" + idx} value={item.dial_code} >{item.dial_code} {item.code}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </div>
                                        <Input
                                            name="work_phone"
                                            className="form-control"
                                            placeholder="Enter work phone number"
                                            type="text"
                                            onChange={contactValidation.handleChange}
                                            onBlur={contactValidation.handleBlur}
                                            value={contactValidation.values.work_phone}
                                            required
                                            invalid={
                                                contactValidation.touched.work_phone && contactValidation.errors.work_phone ? true : false
                                            }
                                        />
                                    </InputGroup>
                                    {contactValidation.touched.work_phone && contactValidation.errors.work_phone ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.work_phone}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="designation">Designation</Label>
                                    <Input
                                        name="designation"
                                        className="form-control"
                                        placeholder="Enter designation"
                                        type="text"
                                        onChange={contactValidation.handleChange}
                                        onBlur={contactValidation.handleBlur}
                                        value={contactValidation.values.designation}
                                        required
                                        invalid={
                                            contactValidation.touched.designation && contactValidation.errors.designation ? true : false
                                        }
                                    />
                                    {contactValidation.touched.designation && contactValidation.errors.designation ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.designation}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="mobile">Mobile</Label>
                                    <InputGroup >
                                        <div className="">
                                            <select className="form-select" onChange={(e) => {
                                                setMobileCcode(e.target.value)
                                            }}
                                                required
                                                defaultValue={mobile_ccode}
                                            >
                                                <option value="0" disabled >Choose...</option>
                                                {
                                                    CountryCode.map((item, idx) => {
                                                        return (
                                                            <option key={"cc" + idx} value={item.dial_code}>{item.dial_code} {item.code}</option>
                                                        )
                                                    })
                                                }

                                            </select>
                                        </div>
                                        <Input
                                            name="mobile"
                                            className="form-control"
                                            placeholder="Enter mobile number"
                                            type="text"
                                            onChange={contactValidation.handleChange}
                                            onBlur={contactValidation.handleBlur}
                                            value={contactValidation.values.mobile}
                                            required
                                            invalid={
                                                contactValidation.touched.mobile && contactValidation.errors.mobile ? true : false
                                            }
                                        />
                                    </InputGroup>
                                    {contactValidation.touched.mobile && contactValidation.errors.mobile ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.mobile}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        type="text"
                                        onChange={contactValidation.handleChange}
                                        onBlur={contactValidation.handleBlur}
                                        value={contactValidation.values.email}
                                        required
                                        invalid={
                                            contactValidation.touched.email && contactValidation.errors.email ? true : false
                                        }
                                    />
                                    {contactValidation.touched.email && contactValidation.errors.email ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="department">Department</Label>
                                    <Input
                                        name="department"
                                        className="form-control"
                                        placeholder="Enter Department"
                                        type="text"
                                        onChange={contactValidation.handleChange}
                                        onBlur={contactValidation.handleBlur}
                                        value={contactValidation.values.department}
                                        required
                                        invalid={
                                            contactValidation.touched.department && contactValidation.errors.department ? true : false
                                        }
                                    />
                                    {contactValidation.touched.department && contactValidation.errors.department ? (
                                        <FormFeedback type="invalid">{contactValidation.errors.department}</FormFeedback>
                                    ) : null}
                                </div>



                                <div>
                                    <button type="submit" className="btn btn-primary w-md" >
                                        {selectedContactData !== "" ? "update" : "Submit"}
                                    </button>
                                </div>
                            </Form>

                        </div>
                    }


                    <div className="d-flex flex-column mt-2 mb-2">
                        <div className="d-flex flex-column  mt-2">
                            <div className="text-secondary">Contacts</div>

                            {
                                cntList.length > 0 && cntList.map((elem, idx) => {
                                    return (
                                        <div key={"ast" + idx} className="row py-2 w-100" style={{ borderBottom: "1px solid #e0e0e0" }}>
                                            <div className="col-9 d-flex flex-row align-items-center my-2" >
                                                <div className="">
                                                    <div className="">{elem.first_name} {elem.last_name} ({elem.designation})</div>
                                                    <div className="font-size-12 text-secondary" >
                                                        <i className="bx bx-phone text-warning" style={{ position: "relative", top: 1.5 }}></i> {elem.mobile_ccode} {elem.mobile} &nbsp;&nbsp;&nbsp;&nbsp; <i className="bx bx-mail-send text-warning" style={{ position: "relative", top: 1.5 }}></i> {elem.email}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2">
                                                <div>
                                                    <Link
                                                        to="#!"
                                                        className="btn btn-sm btn-soft-success me-1"
                                                        onClick={(e) => {

                                                            e.preventDefault()
                                                            setColToggle(-1)
                                                            const data = elem;
                                                            toggleLocation(elem)

                                                        }}
                                                    >
                                                        <i className={elem.locationEditor ? "mdi mdi-close font-size-15" : "mdi mdi-google-maps font-size-15"} id="edittooltip" />
                                                    </Link>
                                                    <Link
                                                        to="#!"
                                                        className="btn btn-sm btn-soft-warning me-1"
                                                        onClick={(e) => {

                                                            e.preventDefault()
                                                            setColToggle(-1)
                                                            const data = elem;
                                                            toggleEditor(elem)
                                                        }}
                                                    >
                                                        <i className={elem.edit ? "mdi mdi-close font-size-15" : "mdi mdi-pencil-outline font-size-15"} id="edittooltip" />
                                                    </Link>
                                                    <Link
                                                        to="#!"
                                                        className="btn btn-sm btn-soft-danger"
                                                        onClick={(e) => {

                                                            e.preventDefault()
                                                            const data = elem;
                                                            closeEditors()
                                                            ColToggle !== idx ? setColToggle(idx) : setColToggle(-1)
                                                        }}
                                                    >
                                                        <i className={ColToggle === idx ? "mdi mdi-close font-size-15" : "mdi mdi-delete-outline font-size-15"} />
                                                    </Link>
                                                </div>
                                            </div>
                                            {
                                                <Collapse isOpen={elem.edit} >
                                                    <div className="row mb-3">
                                                        <div className="font-size-13 text-warning bg-warning bg-opacity-10 py-2 ps-2">Edit Contact</div>
                                                    </div>
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            contactValidation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >

                                                        <Row >
                                                            {
                                                                props.cmpConfig &&
                                                                <Col md={12}>
                                                                    <div className="col-sm-auto mb-3">
                                                                        <label className="" htmlFor="autoSizingSelect">Contact type</label>
                                                                        <select className="form-select" onChange={(e) => {
                                                                            console.log("event.taget.value", e.target.value)
                                                                            setContactType(e.target.value)
                                                                        }}
                                                                            defaultValue={contactType}
                                                                        >
                                                                            <option value="0" disabled>Choose...</option>
                                                                            {
                                                                                props.cmpConfig.contact_type.map((item, idx) => {
                                                                                    return (
                                                                                        <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            }

                                                        </Row>

                                                        <div className="mb-3">
                                                            <Label htmlFor="first_name">First Name</Label>
                                                            <Input
                                                                name="first_name"
                                                                className="form-control"
                                                                placeholder="Enter first name"
                                                                type="text"
                                                                onChange={contactValidation.handleChange}
                                                                onBlur={contactValidation.handleBlur}
                                                                value={contactValidation.values.first_name}
                                                                required
                                                                invalid={
                                                                    contactValidation.touched.first_name && contactValidation.errors.first_name ? true : false
                                                                }
                                                            />
                                                            {contactValidation.touched.first_name && contactValidation.errors.first_name ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.first_name}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="last_name">Last Name</Label>
                                                            <Input
                                                                name="last_name"
                                                                className="form-control"
                                                                placeholder="Enter last name"
                                                                type="text"
                                                                onChange={contactValidation.handleChange}
                                                                onBlur={contactValidation.handleBlur}
                                                                value={contactValidation.values.last_name}
                                                                required
                                                                invalid={
                                                                    contactValidation.touched.last_name && contactValidation.errors.last_name ? true : false
                                                                }
                                                            />
                                                            {contactValidation.touched.last_name && contactValidation.errors.last_name ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.last_name}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="work_phone">Work Phone</Label>
                                                            <InputGroup >
                                                                <div className="">
                                                                    <select className="form-select" onChange={(e) => {
                                                                        setWPCcode(e.target.value)
                                                                    }}
                                                                        required
                                                                        defaultValue={wp_ccode}
                                                                    >
                                                                        <option value="0" disabled >Choose...</option>
                                                                        {
                                                                            CountryCode.map((item, idx) => {
                                                                                return (
                                                                                    <option key={"cc" + idx} value={item.dial_code} >{item.dial_code} {item.code}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                </div>
                                                                <Input
                                                                    name="work_phone"
                                                                    className="form-control"
                                                                    placeholder="Enter work phone number"
                                                                    type="text"
                                                                    onChange={contactValidation.handleChange}
                                                                    onBlur={contactValidation.handleBlur}
                                                                    value={contactValidation.values.work_phone}
                                                                    required
                                                                    invalid={
                                                                        contactValidation.touched.work_phone && contactValidation.errors.work_phone ? true : false
                                                                    }
                                                                />
                                                            </InputGroup>
                                                            {contactValidation.touched.work_phone && contactValidation.errors.work_phone ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.work_phone}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="designation">Designation</Label>
                                                            <Input
                                                                name="designation"
                                                                className="form-control"
                                                                placeholder="Enter designation"
                                                                type="text"
                                                                onChange={contactValidation.handleChange}
                                                                onBlur={contactValidation.handleBlur}
                                                                value={contactValidation.values.designation}
                                                                required
                                                                invalid={
                                                                    contactValidation.touched.designation && contactValidation.errors.designation ? true : false
                                                                }
                                                            />
                                                            {contactValidation.touched.designation && contactValidation.errors.designation ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.designation}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="mobile">Mobile</Label>
                                                            <InputGroup >
                                                                <div className="">
                                                                    <select className="form-select" onChange={(e) => {
                                                                        setMobileCcode(e.target.value)
                                                                    }}
                                                                        required
                                                                        defaultValue={mobile_ccode}
                                                                    >
                                                                        <option value="0" disabled >Choose...</option>
                                                                        {
                                                                            CountryCode.map((item, idx) => {
                                                                                return (
                                                                                    <option key={"cc" + idx} value={item.dial_code}>{item.dial_code} {item.code}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                </div>
                                                                <Input
                                                                    name="mobile"
                                                                    className="form-control"
                                                                    placeholder="Enter mobile number"
                                                                    type="text"
                                                                    onChange={contactValidation.handleChange}
                                                                    onBlur={contactValidation.handleBlur}
                                                                    value={contactValidation.values.mobile}
                                                                    required
                                                                    invalid={
                                                                        contactValidation.touched.mobile && contactValidation.errors.mobile ? true : false
                                                                    }
                                                                />
                                                            </InputGroup>
                                                            {contactValidation.touched.mobile && contactValidation.errors.mobile ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.mobile}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="email">Email</Label>
                                                            <Input
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter Email"
                                                                type="text"
                                                                onChange={contactValidation.handleChange}
                                                                onBlur={contactValidation.handleBlur}
                                                                value={contactValidation.values.email}
                                                                required
                                                                invalid={
                                                                    contactValidation.touched.email && contactValidation.errors.email ? true : false
                                                                }
                                                            />
                                                            {contactValidation.touched.email && contactValidation.errors.email ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="department">Department</Label>
                                                            <Input
                                                                name="department"
                                                                className="form-control"
                                                                placeholder="Enter Department"
                                                                type="text"
                                                                onChange={contactValidation.handleChange}
                                                                onBlur={contactValidation.handleBlur}
                                                                value={contactValidation.values.department}
                                                                required
                                                                invalid={
                                                                    contactValidation.touched.department && contactValidation.errors.department ? true : false
                                                                }
                                                            />
                                                            {contactValidation.touched.department && contactValidation.errors.department ? (
                                                                <FormFeedback type="invalid">{contactValidation.errors.department}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div>
                                                            <button type="submit" className="btn btn-primary w-md me-2" >
                                                                {selectedContactData !== "" ? "Update" : "Submit"}
                                                            </button>

                                                            <button type="submit" className="btn btn-danger w-md" onClick={() => {
                                                                closeContactEditor()
                                                            }}>
                                                                {"cancel"}
                                                            </button>
                                                        </div>
                                                    </Form>

                                                </Collapse>
                                            }
                                            {
                                                <Collapse isOpen={elem.locationEditor} >
                                                    <div className="row mb-3">
                                                        <div className="font-size-13 text-success bg-success bg-opacity-10 py-2 ps-2">Locations mapped</div>
                                                    </div>
                                                    {
                                                        locList && locList.map((item, idx) => {
                                                            return (
                                                                <div className="row " key={"ln" + idx} style={{ borderBottom: idx !== locList.length - 1 && "1px solid #ededed", paddingBottom: 5, paddingTop: 5 }}>
                                                                    <div className="col-12">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <div style={{ width: "5%", textAlign: "center" }}>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        value={item.editLocation}
                                                                                        id={"pmsn" + idx}
                                                                                        onChange={(e) => { contactMapLocation(item, selectedCntForLocation) }}
                                                                                        checked={item.editLocation}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: "95%" }}>
                                                                                <div className="text-success font-size-14">{item.location_name}</div>
                                                                                <div className="font-size-11">{item.location_type}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            }
                                            {
                                                <Collapse isOpen={ColToggle === idx}>
                                                    <div className="row mb-3">
                                                        <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                                    </div>

                                                    <div >
                                                        <div className="py-2 ps-3" >
                                                            <CardHeader className="bg-transparent mb-3">
                                                                <h7 className="my-0 text-danger">
                                                                    <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                                </h7>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <CardText>
                                                                    <div>
                                                                        <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                                                        <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); handleDeleteContact(elem) }}>Delete</button>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </div>

                                                    </div>
                                                </Collapse>
                                            }

                                        </div>
                                        // <div key={"ast" + idx} className="btn btn-outline-secondary p-2 me-2" onClick={() => {
                                        //     handleEditContact(elem, item)
                                        // }}>
                                        //     <div className="col-auto" >
                                        //         <div className="d-flex flex-column justify-content-start ">
                                        //             <div className="me-2"><i className={elem.asset_icon} style={{ fontSize: 25 }} id="edittooltip" /> </div>
                                        //             <div style={{ lineHeight: 1 }}>{elem.asset_name}</div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                    )
                                })

                            }
                        </div>
                    </div>
                </Col>

            </Row>
        </div>
    );
};

export default AddContacts;
