import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const MiniWidget = (props) => {
  const widgetStyle = {minHeight:140, marginBottom:4}
  return (
    <React.Fragment>
      <Col sm="3"  
        onClick={() => {
          props.showList("locations")
        }}
        style={{cursor:"pointer"}}
        >
        <Card style={widgetStyle} className="bg-success text-white border border-success" 
        >
          <CardBody>
            <div className="d-flex align-items-center mb-3">
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-white text-success font-size-18">
                <i className="mdi mdi-google-maps"/>
                </span>
              </div>
              <h5 className="font-size-14 mb-0">Locations</h5>
            </div>
            <div className="text-white mt-4"  >
              <h4>
                {props.cstrInfo.locations.length }{" "}
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>

       <Col sm="3"
        onClick={() => {
          props.showList("contacts")
        }}
        style={{cursor:"pointer"}}
       >
        <Card 
        style={widgetStyle} className="bg-warning text-white border border-warning"
        >
          <CardBody >
            <div className="d-flex align-items-center mb-3">
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-white text-warning font-size-18">
                  <i className="mdi mdi-account"/>
                </span>
              </div>
              <h5 className="font-size-14 mb-0">Contacts</h5>
            </div>
            <div className="text-white mt-4"  >
              <h4>
              {props.cstrInfo.contacts.length }{" "}
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col sm="3"
       onClick={() => {
        props.showList("assets")
      }}
      style={{cursor:"pointer"}}
      >
        <Card style={widgetStyle} className="bg-secondary text-white border border-secondary">
          <CardBody>
            <div className="d-flex align-items-center mb-3">
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-white  text-secondary font-size-18">
                <i className="mdi mdi-desktop-tower"/>
                </span>
              </div>
              <h5 className="font-size-14 mb-0 ">Assets</h5>
            </div>
            <div className="mt-4 text-white"  >
              <h4>
              {props.cstrInfo.assets.length }{" "}
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>

     

      <Col sm="3" 
      onClick={() => {
        props.showList("contracts")
      }}
      style={{cursor:"pointer"}}
      >
        <Card style={widgetStyle} className="bg-primary text-white border border-primary">
          <CardBody >
            <div className="d-flex align-items-center mb-3">
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-white text-primary font-size-18">
                <i className="mdi mdi-note-text-outline"/>
                </span>
              </div>
              <h5 className="font-size-14 mb-0">Contracts</h5>
            </div>
            <div className="text-white mt-4"  >
              <h4>
              {props.cstrInfo.contracts.length }{" "}
              </h4>
            </div>
          </CardBody>
        </Card>
      </Col>

    </React.Fragment>
  )
}



export default MiniWidget;
