import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Container, Row, Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip,
  Modal, FormFeedback
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch } from "react-redux";

import { sendEmails, fetchUsers, createUser, updateUserInfo, deleteUsers, resetUserResponseError, resetUserList, resetPassword, resetUserSuccessfullyAdded } from "../../../toolkitStore/manageUserSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"
import { getBusinessUnits } from "../../../toolkitStore/businessUnitSlice"

import CountryCode from "../components/CountryCodes.json"



const ManageUsers = (props) => {

  //meta title
  document.title = "Pentabrains | Customer Support Dashboard";

  const dispatch = useDispatch()

  const handleSendEmails = (userId) => {
    dispatch(sendEmails(userId));
  };

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { userList, isSuccessfullyAdded, responseUserError } = useSelector(state => state.manageUserReducer)
  const { businessUnits } = useSelector(state => state.businessUnitReducer)

  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [bUnit, setBUnit] = useState([])
  const [userDesignation, setUserDesignation] = useState("0")
  const [userBusinessUnit, setUserBusinessUnit] = useState("0")
  const [firstName, setFirstName] = useState("")
  const [selectedUser, setSelectedUser] = useState("")
  const [userID, setUserID] = useState("")
  const [mode, setMode] = useState("new")
  const [cmpUsers, setCmpUsers] = useState([])

  const [newUser, setNewUser] = useState([])
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_pwdreset, setmodal_pwdreset] = useState(false);
  const [open, setOpen] = useState(false);
  const [isRight, setIsRight] = useState(false);

  const [buError, setBuError] = useState(null)
  const [desgError, setDesgError] = useState(null)
  const [resetError, setResetError] = useState("")

  const [mobile_ccode, setMobileCcode] = useState("+91")
  const [wp_ccode, setWPCcode] = useState("+91")
  const [an_ccode, setANCcode] = useState("+91")


  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setInfo(obj)
    const cConfig = JSON.parse(localStorage.getItem("company-config"));
    setCmpConfig(cConfig)
    setNewUser([...cConfig.screens])

  }, [])


  useEffect(() => {

    if (!userList && !businessUnits && userInformation) {
      dispatch(fetchUsers(userInformation.m_company_id))
      dispatch(getBusinessUnits(userInformation.m_company_id))
    }
  }, [userInformation, userList, businessUnits])





  useEffect(() => {
    if (isSuccessfullyAdded) {
      dispatch(fetchUsers(userInformation.m_company_id))
      dispatch(resetUserSuccessfullyAdded())
    }
  }, [isSuccessfullyAdded])


  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    setSelectedUser("")
    removeBodyCss();
  }

  function tog_pwdreset() {
    setmodal_pwdreset(!modal_pwdreset);
    removeBodyCss();
  }


  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const toggleRightCanvas = () => {
    validation.resetForm({ values: "" })
    setMode("new")
    setNewUser([...cmpConfig.screens])
    setSelectedUser("")
    setMobileCcode("+91")
    setWPCcode("+91")
    setANCcode("+91")
    setUserDesignation("0")
    setUserBusinessUnit("0")
    setUserID("")
    setIsRight(!isRight);
  };


  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: selectedUser.first_name || '',
      last_name: selectedUser.last_name || "",
      work_phone: selectedUser.work_phone || "",
      mobile: selectedUser.mobile || "",
      mobile_altnumber: selectedUser.mobile_altnumber || "",
      email_id: selectedUser.email_id || "",
      designation: selectedUser.designation || "",
      emp_code: selectedUser.emp_code || "",
      username: selectedUser.username || "",
      password: selectedUser.password || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First name"),
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
      mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      work_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      mobile_altnumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    }),
    onSubmit: (values, { resetForm }) => {

      if (userDesignation === "0") {
        setDesgError("Please select Designation")
        return
      }

      if (userInformation.role === "SuperAdmin" && userBusinessUnit === "0") {
        setBuError("Please select business unit")
        return
      }


      setBuError(null)
      setDesgError(null)
      var getBunitIndex = _.findIndex(bUnit, { b_unit_code: userBusinessUnit })
      if (getBunitIndex !== -1) {
        var bUnitID = bUnit[getBunitIndex]._id
        var business_unit_name = bUnit[getBunitIndex].business_unit_name
        var b_unit_code = bUnit[getBunitIndex].b_unit_code
      }

      var getDesignationIndex = _.findIndex(cmpConfig.company_designation, { id: userDesignation })
      if (getDesignationIndex !== -1) {
        var selectedDesignation = cmpConfig.company_designation[getDesignationIndex].name
      }

      dispatch(resetUserResponseError())
      //  console.log("values", values)
      values["mobile_ccode"] = mobile_ccode
      values["wp_ccode"] = wp_ccode
      values["mobile_alt_ccode"] = an_ccode

      values["facilities"] = newUser
      values["designation"] = selectedDesignation
      values["business_unit_name"] = business_unit_name
      values["b_unit_code"] = b_unit_code
      values["m_b_unit_id"] = bUnitID
      values["username"] = values.email_id
      values["m_company_id"] = userInformation.m_company_id
      values["created_by"] = userInformation._id
      if (mode === "edit") {
        dispatch(updateUserInfo(values, selectedUser._id))
      }
      else {
        dispatch(createUser(values))
      }

    }
  });

  useEffect(() => {
    if (isSuccessfullyAdded) {
      if (!responseUserError) {
        validation.resetForm({ values: "" })
        setIsRight(!isRight);
      }
    }
  }, [isSuccessfullyAdded])


  const editUser = (UData) => {
    setMode("edit")
    var getDesignationIndex = _.findIndex(cmpConfig.company_designation, { name: UData.designation })
    if (getDesignationIndex !== -1) {
      var selectedDesignation = cmpConfig.company_designation[getDesignationIndex].id
    }
    setUserDesignation(selectedDesignation)
    setUserBusinessUnit(UData.b_unit_code)
    setNewUser([...UData.facilities])
    setSelectedUser(UData)
    setMobileCcode(UData.mobile_ccode)
    setWPCcode(UData.wp_ccode)
    setANCcode(UData.mobile_alt_ccode)

    setIsRight(!isRight);
  }

  const deleteUser = (UserData) => {
    setUserID(UserData._id)
    tog_backdrop()
  }
  const onConfirmDelete = () => {
    dispatch(deleteUsers(userID))
    dispatch(resetUserList())
    setmodal_backdrop(false);
  }

  const addPermissions = (item) => {
    var getIndex = _.findIndex(newUser, { id: item.id })
    var editable = { ...newUser[getIndex] }
    editable.enabled = !editable.enabled
    newUser[getIndex] = editable
    setNewUser([...newUser])
  }

  const resetPWD = (UserData) => {
    setUserID(UserData._id)
    tog_pwdreset()
  }
  const onConfirmPasswordReset = () => {
    dispatch(resetPassword(userID))
    dispatch(resetUserList())
    setmodal_backdrop(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'User ID',
        accessor: 'company_user_id',
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'first_name',
        disableFilters: true,
      },
      {
        Header: 'Designation',
        accessor: 'designation',
        disableFilters: true,
      },
      {
        Header: 'Emp code',
        accessor: 'emp_code',
        disableFilters: true,
      },
      {
        Header: 'Email',
        accessor: 'email_id',
        disableFilters: true,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>{cellProps.row.original.mobile_ccode} {cellProps.row.original.mobile}</div>
          )
        }
      },
      {
        Header: 'Business unit',
        accessor: 'business_unit_name',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">

              {
                cellProps.row.original.role !== "SuperAdmin" &&
                <li>
                  <Link
                    to="#!"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault()
                      const data = cellProps.row.original;
                      resetPWD(data);
                    }}
                  > Reset Password
                  </Link>
                </li>
              }

              {
                cellProps.row.original.role !== "SuperAdmin" &&
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-info"
                    onClick={() => {
                      const UData = cellProps.row.original;
                      editUser(UData);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }



              {
                cellProps.row.original._id !== userInformation._id && cellProps.row.original.role !== "SuperAdmin" &&
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      const UserData = cellProps.row.original;
                      deleteUser(UserData);
                    }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }
            </ul>
          );
        }
      },
      {
        Header: 'Vcard',
        accessor: 'vcard',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleSendEmails(cellProps.row.original._id)}
            >
              vCard
            </button>
          );
        }
      }

    ],
    []
  );

  const validationPWD = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirm_password: Yup.string().required("Please Enter Your Confirm Password"),
    }),
    onSubmit: (values, { resetForm }) => {

      if (values.password === values.confirm_password) {
        setResetError("")
        dispatch(resetPassword(userID, values.password))
        setmodal_pwdreset(false)
      }
      else {
        setResetError("Password and Confirm password not matched")
        return
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

          <Row
            className="justify-content-center"
          >
            <Card>
              <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Manage Users</h5>
                  <div className="flex-shrink-0">
                    <Link to="#!" onClick={() => toggleRightCanvas()} className="btn btn-primary me-1">Add New Users</Link>
                    <Link to="#!" onClick={() => {
                      dispatch(fetchUsers(userInformation.m_company_id))
                    }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                  </div>
                </div>
                <Offcanvas
                  isOpen={isRight}
                  style={{ width: 500 }}
                  direction="end"
                  backdrop="static"
                  toggle={toggleRightCanvas}>
                  <OffcanvasHeader toggle={toggleRightCanvas} style={{ borderBottom: "1px solid #dedede" }}>
                    Add / Edit Users
                  </OffcanvasHeader>
                  <OffcanvasBody>

                    <Form
                      className="form-horizontal"
                      autoComplete="new-password"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="first_name">First Name</Label>
                            <Input
                              name="first_name"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              required
                              invalid={
                                validation.touched.first_name && validation.errors.first_name ? true : false
                              }
                            />
                            {validation.touched.first_name && validation.errors.first_name ? (
                              <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="last_name">Last Name</Label>
                            <Input
                              name="last_name"
                              className="form-control"
                              placeholder="Enter Last Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name && validation.errors.last_name ? true : false
                              }
                            />
                            {validation.touched.last_name && validation.errors.last_name ? (
                              <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <Label htmlFor="email_id">Email</Label>
                        <Input
                          name="email_id"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email_id || ""}
                          required
                          invalid={
                            validation.touched.email_id && validation.errors.email_id ? true : false
                          }
                        />
                        {validation.touched.email_id && validation.errors.email_id ? (
                          <FormFeedback type="invalid">{validation.errors.email_id}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="emp_code">Employee code</Label>
                        <Input
                          name="emp_code"
                          className="form-control"
                          placeholder="Enter Emp code"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.emp_code || ""}
                          required
                          invalid={
                            validation.touched.emp_code && validation.errors.emp_code ? true : false
                          }
                        />
                        {validation.touched.emp_code && validation.errors.emp_code ? (
                          <FormFeedback type="invalid">{validation.errors.emp_code}</FormFeedback>
                        ) : null}
                      </div>

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="mobile">Mobile</Label>
                            <InputGroup >
                              <div className="">
                                <select className="form-select" onChange={(e) => {
                                  setMobileCcode(e.target.value)
                                }}
                                  required
                                  defaultValue={mobile_ccode}
                                >
                                  <option value="0" disabled >Choose...</option>
                                  {
                                    CountryCode.map((item, idx) => {
                                      return (
                                        <option key={"cc" + idx} value={item.dial_code}>{item.dial_code} {item.code}</option>
                                      )
                                    })
                                  }

                                </select>
                              </div>
                              <Input
                                name="mobile"
                                className="form-control"
                                placeholder="Enter Mobile Number"
                                type="text"
                                maxLength={"10"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile || ""}
                                required
                                invalid={
                                  validation.touched.mobile && validation.errors.mobile ? true : false
                                }
                              />
                            </InputGroup>
                            {validation.touched.mobile && validation.errors.mobile ? (
                              <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="work_phone">Work Phone</Label>
                            <InputGroup >
                              <div className="">
                                <select className="form-select" onChange={(e) => {
                                  setDesgError(null)
                                  setWPCcode(e.target.value)
                                }}
                                  required
                                  defaultValue={wp_ccode}
                                >
                                  <option value="0" disabled >Choose...</option>
                                  {
                                    CountryCode.map((item, idx) => {
                                      return (
                                        <option key={"cc" + idx} value={item.dial_code} >{item.dial_code} {item.code}</option>
                                      )
                                    })
                                  }

                                </select>
                              </div>
                              <Input
                                name="work_phone"
                                className="form-control"
                                placeholder="Enter Work Phone"
                                type="text"
                                maxLength={"10"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.work_phone || ""}
                                invalid={
                                  validation.touched.work_phone && validation.errors.work_phone ? true : false
                                }
                              />
                            </InputGroup>
                            {validation.touched.work_phone && validation.errors.work_phone ? (
                              <FormFeedback type="invalid">{validation.errors.work_phone}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <Label htmlFor="mobile_altnumber">Alternate Number</Label>
                        <InputGroup >
                          <div className="">
                            <select className="form-select" onChange={(e) => {
                              setDesgError(null)
                              setANCcode(e.target.value)
                            }}
                              required
                              defaultValue={an_ccode}
                            >
                              <option value="0" disabled >Choose...</option>
                              {
                                CountryCode.map((item, idx) => {
                                  return (
                                    <option key={"cc" + idx} value={item.dial_code} >{item.dial_code} {item.code}</option>
                                  )
                                })
                              }

                            </select>
                          </div>
                          <Input
                            name="mobile_altnumber"
                            className="form-control"
                            placeholder="Enter Alternate Number"
                            type="text"
                            maxLength={"10"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobile_altnumber || ""}
                            invalid={
                              validation.touched.mobile_altnumber && validation.errors.mobile_altnumber ? true : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.mobile_altnumber && validation.errors.mobile_altnumber ? (
                          <FormFeedback type="invalid">{validation.errors.mobile_altnumber}</FormFeedback>
                        ) : null}
                      </div>

                      <Row>
                        <Col md={userInformation.m_b_unit_id === undefined ? 6 : 12}>
                          {
                            cmpConfig &&
                            <div className="col-sm-auto mb-3">
                              <label className="" htmlFor="autoSizingSelect">Designation</label>
                              <select className="form-select" onChange={(e) => {
                                setDesgError(null)
                                setUserDesignation(e.target.value)
                              }}
                                required
                                defaultValue={userDesignation}
                              >
                                <option value="0" disabled>Choose...</option>
                                {
                                  cmpConfig.company_designation.map((item, idx) => {
                                    return (
                                      <option key={"doptn" + idx} value={item.id}>{item.name}</option>
                                    )
                                  })
                                }

                              </select>
                              {desgError ? (
                                <label className="font-size-10 text-danger mt-2">{desgError}</label>
                              ) : null}
                            </div>
                          }
                        </Col>
                        {
                          console.log("userInformation", userInformation)
                        }
                        {
                          userInformation !== undefined  &&
                          <Col md={6}>
                            {
                             businessUnits && businessUnits.length > 0 &&
                              <div className="col-sm-auto mb-3">
                                <label className="" htmlFor="autoSizingSelect">Business Unit</label>
                                <select className="form-select" onChange={(e) => {
                                  setBuError(null)
                                  setUserBusinessUnit(e.target.value)
                                }}
                                  defaultValue={userBusinessUnit}
                                >
                                  <option value="0" disabled>Choose...</option>
                                  {
                                    businessUnits.map((item, idx) => {
                                      return (
                                        <option key={"boptn" + idx} value={item.b_unit_code}>{item.business_unit_name}</option>
                                      )
                                    })
                                  }

                                </select>
                                {buError ? (
                                  <label className="font-size-10 text-danger mt-2">{buError}</label>
                                ) : null}
                              </div>
                            }

                          </Col>
                        }

                      </Row>
                      <Row>
                        <div className="col-sm-auto mb-3">
                          <label className="" htmlFor="autoSizingSelect">Select Permission</label>
                          <div className="row">
                            {
                              newUser.length !== 0 && newUser.map((item, idx) => {
                                if (item.type === "menu") {

                                  return <Col className="col-auto" key={"scrn" + idx}>
                                    <div className="form-check mb-3">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={"pmsn" + idx}
                                        onChange={(e) => { addPermissions(item) }}
                                        checked={item.enabled}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={"pmsn" + idx}
                                      >
                                        {item.interfacename}
                                      </label>
                                    </div>
                                  </Col>
                                }

                              })
                            }
                          </div>
                        </div>
                      </Row>
                      {/* <Row>
                        
                      </Row> */}

                      {mode === "new" && <><div className="mb-3">
                        <Label htmlFor="username">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email_id || ""}
                          required
                          disabled
                        // invalid={
                        //   validation.touched.username && validation.errors.username ? true : false
                        // }
                        />
                        {/* {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null} */}
                      </div>

                        <div className="mb-3">
                          <Label htmlFor="password">Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            autoComplete="new-password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            required
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div></>
                      }



                      <div className="d-flex flex-column">
                        {responseUserError && <label className="font-size-10 text-danger">{responseUserError}</label>}
                        <button type="submit" className="btn btn-primary w-md" >
                          {mode === "edit" ? "Update" : "Submit"}
                        </button>
                      </div>
                    </Form>



                  </OffcanvasBody>
                </Offcanvas>
              </CardBody>
              <CardBody>
                {
                 userList && userList.length > 0 &&
                  <TableContainer
                    columns={columns}
                    data={userList}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    customPageSizeOptions={false}
                    isJobListGlobalFilter={false}
                    className="custom-header-css"
                  />
                }

              </CardBody>
            </Card>
          </Row>

          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop();
            }}
            backdrop={'static'}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Delete user</h5>
              <button type="button" className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false);
                }} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Are you sure, you want to delete this user?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => {
                setmodal_backdrop(false);
              }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={() => {
                onConfirmDelete()
              }}>Yes, delete it</button>
            </div>
          </Modal>

          <Modal
            isOpen={modal_pwdreset}
            toggle={() => {
              tog_pwdreset();
            }}
            backdrop={'static'}
            id="staticBackdrop1"
          >
            <Form
              className="form-horizontal"
              autoComplete="new-password"
              onSubmit={(e) => {
                e.preventDefault();
                validationPWD.handleSubmit();
                return false;
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Password Reset</h5>
                <button type="button" className="btn-close"
                  onClick={() => {
                    setmodal_pwdreset(false);
                  }} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={validationPWD.handleChange}
                        onBlur={validationPWD.handleBlur}
                        value={validationPWD.values.password || ""}
                        required
                        invalid={
                          validationPWD.touched.password && validationPWD.errors.password ? true : false
                        }
                      />
                      {validationPWD.touched.password && validationPWD.errors.password ? (
                        <FormFeedback type="invalid">{validationPWD.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="confirm_password">Confirm Password</Label>
                      <Input
                        name="confirm_password"
                        className="form-control"
                        placeholder="Enter confirm password"
                        type="password"
                        autoComplete="new-password"
                        onChange={validationPWD.handleChange}
                        onBlur={validationPWD.handleBlur}
                        value={validationPWD.values.confirm_password || ""}
                        required
                        invalid={
                          validationPWD.touched.confirm_password && validationPWD.errors.confirm_password ? true : false
                        }
                      />
                      {validationPWD.touched.confirm_password && validationPWD.errors.confirm_password ? (
                        <FormFeedback type="invalid">{validationPWD.errors.confirm_password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {resetError !== "" && <label className="text-danger font-size-12">{resetError}</label>}
                </Row>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={() => {
                  setmodal_pwdreset(false);
                }}>Close</button>
                <button type="submit" className="btn btn-danger" onClick={() => {
                  onConfirmPasswordReset()
                }}>Reset Password</button>
              </div>
            </Form>
          </Modal>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageUsers;
