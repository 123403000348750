import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardImg,
    CardText,
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback, Collapse
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import TimezoneSelect from 'react-timezone-select'

import { createService, updateService, fetchSubServices, deleteMasterService, resetServiceSuccessfullyAdded } from "../../../toolkitStore/manageServiceSlice"

const SubServices = (props) => {

    const dispatch = useDispatch()

    const { subServiceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)
    const [selectedData, setSelectedData] = useState(props.selectedData)
    const [selectedSubService, setSelectedSService] = useState("")
    const [subServicemode, setSubServiceMode] = useState("new")
    const [showEditor, setEditor] = useState(false)
    const [ssList, setSSList] = useState([])
    const [ColToggle, setColToggle] = useState(false)

    useEffect(() => {
        var serviceInfo = {
            company_id: props.userInformation.m_company_id,
            relative_id: selectedData._id
        }
        dispatch(fetchSubServices(serviceInfo))
    },[])

    useEffect(() => {
        if (subServiceList) setSSList([...subServiceList])

    }, [subServiceList])


    useEffect(() => {
        if (isSuccessfullyAdded) {
            //props.closeOffCanvas()
            dispatch(resetServiceSuccessfullyAdded())
            var serviceInfo = {
                company_id: props.userInformation.m_company_id,
                relative_id: selectedData._id
            }
            dispatch(fetchSubServices(serviceInfo))
        }
    }, [isSuccessfullyAdded])

    const toggleShowEditor = () => {
        // var tempSubService = []
        // _.each(ssList, item => {
        //     var sService = { ...item }
        //     sService["edit"] = false
        //     tempSubService.push(sService)
        // })
        // setSelectedSService([...tempSubService])
        setSelectedSService("")
        setSubServiceMode("new")
        setEditor(!showEditor)
    }

    const subServiceValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: selectedSubService.name || '',
            short_name: selectedSubService.short_name || '',
            description: selectedSubService.description || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter service name"),
            short_name: Yup.string().required("Please Enter service short name"),
        }),
        onSubmit: (values, { resetForm }) => {


            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id

            values["service_level"] = selectedData.name
            values["service_level_id"] = "3" // getServiceLevel.id

            values["created_by"] = props.userInformation._id

            if (subServicemode === "new") {
                values["relative_id"] = selectedData._id
                dispatch(createService(values))
            }
            else {
                values["_id"] = selectedSubService._id
                dispatch(updateService(values))
            }
            resetForm({ values: "" })
            setEditor(false)
        }
    });

    const toggleEditor = (data) => {
        var tempSubService = []
        _.each(ssList, item => {
            var sService = { ...item }
            if (sService._id === data._id) {
                sService["edit"] = !sService.edit
                setSelectedSService(sService)
            }
            else {
                sService["edit"] = false
            }
            tempSubService.push(sService)
        })
        setSubServiceMode("edit")
        setSSList([...tempSubService])
        setEditor(false)

    }

    const closeEditors = () => {
        var tempSubService = []
        _.each(ssList, item => {
            var sService = { ...item }
            sService["edit"] = false
            tempSubService.push(sService)
        })
        setSSList([...tempSubService])
        setEditor(false)
    }

    const handleDeleteService = (data) => {
        dispatch(deleteMasterService(data._id))
    }

    return (
        <div className="p-3">

            <Row style={{ borderBottom: "1px solid #ededed", paddingBottom: 10 }}>
                <Col lg={7} >
                    <div className="row">
                        <div className="font-size-10 text-secondary text-opacity-70">Secondary Service</div>
                        <div className="text-primary font-size-15">{selectedData.name}</div>
                    </div>
                </Col>
                <Col lg={5} className="d-flex flex-row justify-content-end">
                    <button type="submit" className="btn btn-primary w-md" onClick={() => {
                        toggleShowEditor()
                    }}>
                        Add Sub Services <i className={showEditor ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                    </button>
                </Col>

            </Row>

            {
                showEditor &&
                <div style={{ borderBottom: "1px solid #ededed", paddingBottom: 10, paddingTop: 10 }}>
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            subServiceValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="mb-3">
                            <Label htmlFor="name">Name</Label>
                            <Input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Enter Your Service Name"
                                onChange={subServiceValidation.handleChange}
                                onBlur={subServiceValidation.handleBlur}
                                value={subServiceValidation.values.name || ""}
                                required
                                invalid={
                                    subServiceValidation.touched.name && subServiceValidation.errors.name ? true : false
                                }
                            />
                            {subServiceValidation.touched.name && subServiceValidation.errors.name ? (
                                <FormFeedback type="invalid">{subServiceValidation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="short_name">Short Name</Label>
                            <Input
                                type="text"
                                className="form-control"
                                name="short_name"
                                placeholder="Enter Your Short Name"
                                onChange={subServiceValidation.handleChange}
                                onBlur={subServiceValidation.handleBlur}
                                value={subServiceValidation.values.short_name || ""}
                                required
                                invalid={
                                    subServiceValidation.touched.short_name && subServiceValidation.errors.short_name ? true : false
                                }
                            />
                            {subServiceValidation.touched.short_name && subServiceValidation.errors.short_name ? (
                                <FormFeedback type="invalid">{subServiceValidation.errors.short_name}</FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="description">Description</Label>
                            <Input
                                type="textarea"
                                className="form-control"
                                name="description"
                                placeholder="Enter description"
                                onChange={subServiceValidation.handleChange}
                                onBlur={subServiceValidation.handleBlur}
                                value={subServiceValidation.values.description || ""}
                                invalid={
                                    subServiceValidation.touched.description && subServiceValidation.errors.description ? true : false
                                }
                            />
                            {subServiceValidation.touched.description && subServiceValidation.errors.description ? (
                                <FormFeedback type="invalid">{subServiceValidation.errors.description}</FormFeedback>
                            ) : null}
                        </div>


                        <div className="mb-5">
                            <button type="submit" className="btn btn-primary w-md">
                                {/* {bu_data !== "" ? "Update" : "Submit"} */}Submit
                            </button>
                        </div>
                    </Form>
                </div>
            }

            <div className="d-flex flex-column mt-2 mb-2">
                <div className="d-flex flex-column  mt-2">
                    <div className="text-secondary">Sub Services</div>
                    {
                        ssList && ssList.map((item, idx) => {
                            return (
                                <div key={"lcn" + idx} className="py-2 " style={{ borderBottom: "1px solid #ededed" }}>

                                    <div className="row">

                                        <div className="col-9 d-flex flex-row align-items-center my-2" >
                                            <div className="">
                                                <div className="text-primary">{item.name}</div>
                                                <div className="font-size-11">{item.short_name}</div>
                                            </div>
                                        </div>


                                        <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2">
                                            {
                                                <Link
                                                    to="#!"
                                                    className="btn btn-sm btn-soft-primary me-1"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setColToggle(-1)
                                                        const data = item;
                                                        toggleEditor(data)
                                                    }}
                                                >
                                                    <i className={item.edit ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                                                </Link>
                                            }

                                            {
                                                <Link
                                                    to="#!"
                                                    className="btn btn-sm btn-soft-danger"
                                                    onClick={(e) => {

                                                        e.preventDefault()
                                                        const data = item;
                                                        closeEditors()
                                                        ColToggle !== idx ? setColToggle(idx) : setColToggle(-1)
                                                    }}
                                                >
                                                    <i className={ColToggle === idx ? "mdi mdi-close" : "mdi mdi-delete-outline"} />
                                                </Link>
                                            }

                                        </div>

                                        {
                                            <Collapse isOpen={ColToggle === idx}>
                                                <div className="row mb-3">
                                                    <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                                </div>

                                                <div >
                                                    <div className="py-2 ps-3" >
                                                        <CardHeader className="bg-transparent mb-3">
                                                            <h7 className="my-0 text-danger">
                                                                <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                            </h7>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <CardText>
                                                                <div>
                                                                    <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                                                    <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); handleDeleteService(item) }}>Delete</button>
                                                                </div>
                                                            </CardText>
                                                        </CardBody>
                                                    </div>

                                                </div>
                                            </Collapse>
                                        }
                                        {
                                            <Collapse isOpen={item.edit}>
                                                <div className="row mb-3">
                                                    <div className="font-size-13 text-primary bg-primary bg-opacity-10 py-2 ps-2">Edit Sub Service</div>
                                                </div>

                                                <Col lg={"12"} >
                                                    <Form
                                                        className="form-horizontal"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            subServiceValidation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <div className="mb-3">
                                                            <Label htmlFor="name">Name</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Enter Your Service Name"
                                                                onChange={subServiceValidation.handleChange}
                                                                onBlur={subServiceValidation.handleBlur}
                                                                value={subServiceValidation.values.name || ""}
                                                                required
                                                                invalid={
                                                                    subServiceValidation.touched.name && subServiceValidation.errors.name ? true : false
                                                                }
                                                            />
                                                            {subServiceValidation.touched.name && subServiceValidation.errors.name ? (
                                                                <FormFeedback type="invalid">{subServiceValidation.errors.name}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="short_name">Short Name</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                name="short_name"
                                                                placeholder="Enter Your Short Name"
                                                                onChange={subServiceValidation.handleChange}
                                                                onBlur={subServiceValidation.handleBlur}
                                                                value={subServiceValidation.values.short_name || ""}
                                                                required
                                                                invalid={
                                                                    subServiceValidation.touched.short_name && subServiceValidation.errors.short_name ? true : false
                                                                }
                                                            />
                                                            {subServiceValidation.touched.short_name && subServiceValidation.errors.short_name ? (
                                                                <FormFeedback type="invalid">{subServiceValidation.errors.short_name}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="description">Description</Label>
                                                            <Input
                                                                type="textarea"
                                                                className="form-control"
                                                                name="description"
                                                                placeholder="Enter description"
                                                                onChange={subServiceValidation.handleChange}
                                                                onBlur={subServiceValidation.handleBlur}
                                                                value={subServiceValidation.values.description || ""}
                                                                invalid={
                                                                    subServiceValidation.touched.description && subServiceValidation.errors.description ? true : false
                                                                }
                                                            />
                                                            {subServiceValidation.touched.description && subServiceValidation.errors.description ? (
                                                                <FormFeedback type="invalid">{subServiceValidation.errors.description}</FormFeedback>
                                                            ) : null}
                                                        </div>


                                                        <div className="mb-5">
                                                            <button type="submit" className="btn btn-primary w-md me-2">
                                                                Update
                                                            </button>
                                                            <button type="submit" className="btn btn-danger w-md" onClick={() => {
                                                                closeEditors()
                                                            }}>
                                                               Cancel
                                                            </button>
                                                        </div>
                                                    </Form>

                                                </Col>
                                            </Collapse>
                                        }

                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default SubServices;