import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"
import { searchCustomer, getDInfo } from "../../../toolkitStore/dashboardSlice"
import { useSelector, useDispatch } from "react-redux";


function CardUser(props) {
  const dispatch = useDispatch()
  const { customerInfo, isSuccessfullyAdded, countInfo } = useSelector(state => state.dashboardReducer)
  const [dCountInfo, setDCountInfo] = useState("")

  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu
  useEffect(() => {
    if (props.userInformation) {

    }
  }, [props.userInformation])

  useEffect(() => {
    console.log("countInfo", countInfo)
    if (!countInfo) {
      dispatch(getDInfo(props.userInformation.m_company_id))
    }
    else {
      setDCountInfo(countInfo)
    }
  }, [countInfo])


  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                {/* <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={avatar1}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome to SHQM</p>
                        <h5 className="mb-1">{props.userInformation.first_name}</h5>
                        <p className="mb-0">{props.userInformation.designation}</p>
                      </div>
                    </div>
                  </div>
                </Col> */}
                {
                  dCountInfo !== "" &&
                  <Col lg="12" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      {
                        dCountInfo.map((item, idx) => {
                         return <Col xs="4" key={"cnt"+idx}>
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              {item.name}
                            </p>
                            <h5 className="mb-0">{item.count}</h5>
                          </div>
                        </Col>
                        })
                      }
                      </Row>
                    </div>
                  </Col>
                }


              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
