import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback
} from "reactstrap";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { getBusinessUnits, resetBusinessUnit, createBusinessUnits, updateBusinessUnits, deleteBusinessUnits, resetBUResponseError } from "../../../toolkitStore/businessUnitSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"



const BusinessUnits = (props) => {

    //meta title
    document.title = "Pentabrains | Customer Support Dashboard";
    const dispatch = useDispatch()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { businessUnits, isBusinessSuccessfullyAdded, responseBUError } = useSelector(state => state.businessUnitReducer)

    const [userInformation, setInfo] = useState("")
    const [cmpConfig, setCmpConfig] = useState("")
    const [bName, setBName] = useState("")
    const [bCode, setBCode] = useState("")
    const [bu_data, setBuData] = useState([])
    const [selectedBdata, setSelectedBData] = useState("")
    const [selectedBdataID, setSelectedBDataID] = useState("")
    const [mode, setMode] = useState("new")

    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [showForm, setShowForm] = useState(true)



    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setInfo(obj)
        const cConfig = JSON.parse(localStorage.getItem("company-config"));
        setCmpConfig(cConfig)
    }, [])


    useEffect(() => {
        if (!businessUnits) {
            dispatch(getBusinessUnits(userInformation.m_company_id))
        }
       

    }, [businessUnits, userInformation])


    useEffect(() => {
        if (isBusinessSuccessfullyAdded) {
            dispatch(getBusinessUnits(userInformation.m_company_id))
        }
    }, [isBusinessSuccessfullyAdded])


    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        setSelectedBData("")
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    const [open, setOpen] = useState(false);
    const [isRight, setIsRight] = useState(false);

    const toggleRightCanvas = () => {
        dispatch(resetBUResponseError())
        validation.resetForm({ values: "" })
        setSelectedBData("")
        setSelectedBDataID("")
        setMode("new")
        setIsRight(!isRight);
    };


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            business_unit_name: selectedBdata.business_unit_name || "",
            b_unit_code: selectedBdata.b_unit_code || "",
            b_owner: selectedBdata.b_owner || "",
            bu_description: selectedBdata.bu_description || "",
        },
        validationSchema: Yup.object({
            business_unit_name: Yup.string().required("Please Enter Business unit name"),
            b_unit_code: Yup.string().required("Please Enter Unit code"),
            b_owner: Yup.string().required("Please Enter Business owner"),
        }),
        onSubmit: (values, { resetForm }) => {
            dispatch(resetBUResponseError())
            values["m_company_id"] = userInformation.m_company_id
            values["create_by"] = userInformation._id

            if (mode === "edit") {
                values["_id"] = selectedBdata._id
                dispatch(updateBusinessUnits(values))
            }
            else {
                dispatch(createBusinessUnits(values))
            }

        }
    });

    useEffect(() => {
        if (isBusinessSuccessfullyAdded) {
            if (!responseBUError) {
                setMode("new")
                setSelectedBData("")
                validation.resetForm({ values: "" })
                setIsRight(!isRight);
            }
        }
    }, [isBusinessSuccessfullyAdded])




    const editBusinessUnit = (BData) => {
        setMode("edit")
        setSelectedBData(BData)
        setIsRight(!isRight);
    }

    const deleteBusinessUnit = (BData) => {
        setSelectedBDataID(BData._id)
        tog_backdrop()
    }
    const onConfirmDelete = () => {
        dispatch(deleteBusinessUnits(selectedBdataID))
        dispatch(getBusinessUnits(userInformation.m_company_id))
        setmodal_backdrop(false);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'BU ID',
                accessor: 'b_unit_id',
                disableFilters: true,
            },
            {
                Header: 'Unit Name',
                accessor: 'business_unit_name',
                disableFilters: true,
            },
            {
                Header: 'Unit code',
                accessor: 'b_unit_code',
                disableFilters: true,
            },
            {
                Header: 'Business Owner',
                accessor: 'b_owner',
                disableFilters: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">


                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                        const BData = cellProps.row.original;
                                        editBusinessUnit(BData);
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        const BData = cellProps.row.original;
                                        deleteBusinessUnit(BData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },

        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

                    <Row
                        className="justify-content-center"
                    >
                        <Card>
                            <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Business Units</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="#!" onClick={() => toggleRightCanvas()} className="btn btn-primary me-1">Add New Business Unit</Link>
                                        <Link to="#!" onClick={() => {
                                            dispatch(getBusinessUnits(userInformation.m_company_id))
                                        }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                    </div>
                                </div>
                                <Offcanvas
                                    isOpen={isRight}
                                    direction="end"
                                    backdrop={'static'}
                                    toggle={toggleRightCanvas}
                                    onOpened={() => {
                                        setShowForm(true)
                                    }}
                                    onClosed={() => {
                                        setShowForm(false)
                                    }}
                                >
                                    <OffcanvasHeader toggle={toggleRightCanvas} style={{ borderBottom: "1px solid #dedede" }}>
                                        Add / Edit Business Unit
                                    </OffcanvasHeader>
                                    <OffcanvasBody>

                                        {
                                            showForm &&
                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >

                                                <div className="mb-3">
                                                    <Label htmlFor="business_unit_name">Unit name</Label>
                                                    <Input
                                                        name="business_unit_name"
                                                        className="form-control"
                                                        placeholder="Enter Business unit name"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.business_unit_name || ""}
                                                        required
                                                        invalid={
                                                            validation.touched.business_unit_name && validation.errors.business_unit_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.business_unit_name && validation.errors.business_unit_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.business_unit_name}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="b_unit_code">Unit Code</Label>
                                                    <Input
                                                        name="b_unit_code"
                                                        className="form-control"
                                                        placeholder="Enter Business code"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.b_unit_code || ""}
                                                        required
                                                        invalid={
                                                            validation.touched.b_unit_code && validation.errors.b_unit_code ? true : false
                                                        }
                                                    />
                                                    {validation.touched.b_unit_code && validation.errors.b_unit_code ? (
                                                        <FormFeedback type="invalid">{validation.errors.b_unit_code}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="b_owner">Business Owner</Label>
                                                    <Input
                                                        name="b_owner"
                                                        className="form-control"
                                                        placeholder="Enter Business owner"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.b_owner || ""}
                                                        required
                                                        invalid={
                                                            validation.touched.b_owner && validation.errors.b_owner ? true : false
                                                        }
                                                    />
                                                    {validation.touched.b_owner && validation.errors.b_owner ? (
                                                        <FormFeedback type="invalid">{validation.errors.b_owner}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="bu_description">Description</Label>
                                                    <Input
                                                        name="bu_description"
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        type="textarea"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.bu_description || ""}
                                                        required
                                                        invalid={
                                                            validation.touched.bu_description && validation.errors.bu_description ? true : false
                                                        }
                                                    />
                                                    {validation.touched.bu_description && validation.errors.bu_description ? (
                                                        <FormFeedback type="invalid">{validation.errors.bu_description}</FormFeedback>
                                                    ) : null}
                                                </div>



                                                <div className="d-flex flex-column">
                                                    {responseBUError && <label className="font-size-10 text-danger">{responseBUError}</label>}
                                                    <button type="submit" className="btn btn-primary w-md" >
                                                        {mode === "edit" ? "Update" : "Submit"}
                                                    </button>
                                                </div>
                                            </Form>

                                        }

                                    </OffcanvasBody>
                                </Offcanvas>
                            </CardBody>
                            <CardBody>
                                {
                                    businessUnits && businessUnits.length > 0 &&
                                    <TableContainer
                                        columns={columns}
                                        data={businessUnits}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        customPageSize={10}
                                        customPageSizeOptions={false}
                                        isJobListGlobalFilter={false}
                                        className="custom-header-css"
                                    />
                                }

                            </CardBody>
                        </Card>
                    </Row>

                    <Modal
                        isOpen={modal_backdrop}
                        toggle={() => {
                            tog_backdrop();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Delete Business unit</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setmodal_backdrop(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure, you want to delete this business unit?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => {
                                setmodal_backdrop(false);
                            }}>Close</button>
                            <button type="button" className="btn btn-danger" onClick={() => {
                                onConfirmDelete()
                            }}>Yes, delete it</button>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default BusinessUnits;
