import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    slaList: null,
    isSuccessfullyAdded:false,
};


const manageSLASlice = createSlice({
    name: "manageSLA",
    initialState: initialRegState,
    reducers: {

        setSLAList: (state, action) => {
            state.slaList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        setSLASuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = true
        },
        resetSLASuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = false
        },
        resetSLAList: (state, action) => {
            state.slaList = null
        },
        
    }

})

export const createSLA = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetSLASuccessfullyAdded())
            const responseData = await post("/manage_sla/add-sla", {
                "slaInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSLASuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateSLA = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetSLASuccessfullyAdded())
            const responseData = await post("/manage_sla/update-sla", {
                "slaInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSLASuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchSLA = (company_id) => {
    return async (dispatch) => {
        try {
            console.log("company_id", company_id)
            const responseData = await post("/manage_sla/get-sla", {
                "company_id":company_id
            })
            console.log("get users", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setSLAList(responseData.data))
                }
                else{
                    dispatch(resetSLAList())
                }
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterSLA = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetSLASuccessfullyAdded())
            const responseData = await post("/manage_sla/delete-sla", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSLASuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { setSLAList, resetSLAList, setSLASuccessfullyAdded, resetSLASuccessfullyAdded } = manageSLASlice.actions;

export default manageSLASlice.reducer;
