import React, { useState, useEffect, useMemo } from "react"
import {
  Container, Row, Col,
  Card, CardBody, Form, FormFeedback, Input, UncontrolledTooltip
} from "reactstrap"

import { Link, useNavigate } from 'react-router-dom';
// import { io } from "socket.io-client";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { useSelector, useDispatch } from "react-redux";
import TableContainer from './Table/TableContainer';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Components
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"

import { getConfig } from "../../../toolkitStore/authenticateSlice"
import { searchCustomer, getDInfo } from "../../../toolkitStore/dashboardSlice"
import { fetchCustomers, createCustomer, updateMasterCustomer, deleteMasterCustomers } from "../../../toolkitStore/manageCustomerSlice"

import { use } from "i18next";

// let socket = null;




const DashboardSaas = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { customerInfo, countInfo } = useSelector(state => state.dashboardReducer)
  const { customerList, } = useSelector(state => state.manageCustomerReducer)

  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [searchInfo, setSearchInfo] = useState("")
  const [cstmrList, setCstmrList] = useState([])

  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
      dispatch(getDInfo(cmpConfig.m_company_id))
    }

  }, [companyConfig])


  useEffect(() => {
    if (!userInformation) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      dispatch(getConfig())
    }
  }, [userInformation])

  useEffect(() => {

    if (customerList === null && userInformation) {
      dispatch(fetchCustomers(userInformation.m_company_id))
    }
    else if (userInformation) {
      setCstmrList([...customerList])
    }
  }, [userInformation, customerList])



  const columns = useMemo(
    () => [
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
        Cell: (cellProps) => {
          return (
            <Col>
              <div
                onClick={(e) => {
                  e.preventDefault()
                  const SData = cellProps.row.original;
                  navigate("/support-dashboard", {
                    state: {
                      "info": SData
                    }
                  })
                  return false
                }}
                style={{ cursor: "pointer" }}
                className="text-primary">{cellProps.row.original.customer_name}</div>
            </Col>
          )
        }
      },



    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid >
          <Row >
            <CardUser userInformation={userInformation} />
            <Row>
              {/* total selling product */}
              <Col xl="5">
                <Card style={{ height: 450 }}>
                  <CardBody>
                    <h4 className="card-title mb-2">Customers</h4>
                    {
                      cstmrList.length > 0 &&
                      <TableContainer
                        columns={columns}
                        data={cstmrList}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={10}
                        customPageSizeOptions={false}
                        className="custom-header-css table-sm"
                      />
                    }
                  </CardBody>
                </Card>
              </Col>


              {/* chat box */}
              {/* <ChatBox /> */}

              {/* tasks */}
              <Tasks />

            </Row>
          </Row>




        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
