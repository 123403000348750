import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { getConfig } from "toolkitStore/authenticateSlice"


import {
    fetchCustomers
} from "toolkitStore/manageCustomerSlice";

import {
    createVendor, fetchVendors
} from "toolkitStore/manageVendorSlice"
import { createCustomerSubscription, fetchCompanySubscriptions, updateCustomerSubscription, resetSubscriptionSuccessfullyAdded, createSubscriptionAddon } from "toolkitStore/manageSubscriptionSlice";
import { fetchServices, createService, updateService, deleteMasterService } from "toolkitStore/manageServiceSlice";
import moment from "moment";



const SubscriptionProducts = (props) => {

    const dispatch = useDispatch()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { customerList } = useSelector(state => state.manageCustomerReducer);
    const { vendorList } = useSelector((state) => state.manageVendorReducer);
    const { serviceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)
    const { companySubscriptionList, isSubscriptionRequestSuccess } = useSelector(state => state.manageSubscriptionReducer)

    const [userInformation, setInfo] = useState(props.userInformation);
    const [cmpConfig, setCmpConfig] = useState(props.cmpConfig);

    const [actualCost, setActualCost] = useState(0)
    const [purchaseCost, setPurchaseCost] = useState(0)
    const [customerCost, setCustomerCost] = useState(0)
    const [expectedMargin, setExpectedMargin] = useState(0)
    const [rQuantity, setRQuantity] = useState(0)
    const [rDiscount, setRDiscount] = useState(0)
    const [rRebate, setRRebate] = useState(0)
    const [rUcost, setUCost] = useState(0)

    const [selectedProductData, setSelectedProductData] = useState(null)
    const [showEditor, setEditor] = useState(false)

    const [isApiRequested, setIsApiRequested] = useState(false)


    console.log("subscription -->", props.selectedSubscription)

    useEffect(() => {
        setIsApiRequested(isSubscriptionRequestSuccess)
        if (isSubscriptionRequestSuccess) {
            dispatch(resetSubscriptionSuccessfullyAdded())
            props.closeOffCanvas()
        }

    }, [isSubscriptionRequestSuccess])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            commitment_term: "",
            billing_term: "",
            billing_type: props.selectedSubscription && props.selectedSubscription.subscription_customer_billing_type || "",
            unit_cost: props.selectedSubscription && props.selectedSubscription.subscription_customer_unit_cost || "",
            rebate: "",
            discount: "",
            quantity: ""
        },
        validationSchema: Yup.object({
            // customer_name: Yup.string().required("Select Customer"),
        }),
        onSubmit: (values, { resetForm }) => {

            values["actual_cost"] = actualCost
            values["purchase_cost"] = purchaseCost
            values["customer_cost"] = customerCost
            values["expected_margin"] = expectedMargin

            values["customer_name"] = props.selectedSubscription.customer_name
            values["customer_master_id"] = props.selectedSubscription.customer_master_id
            values["subscription_plan_name"] = props.selectedSubscription.subscription_plan_name
            values["subscription_plan_master_id"] = props.selectedSubscription.subscription_plan_master_id
            values["subscription_ref_name"] = props.selectedSubscription.subscription_ref_name

            values["plan_ref_id"] = props.selectedSubscription._id

            values["m_company_id"] = userInformation.m_company_id
            values["m_b_unit_id"] = userInformation.m_b_unit_id
            values["created_by"] = userInformation._id

            setIsApiRequested(true)
            console.log("values", values)

            dispatch(createSubscriptionAddon(values))

        }
    });

    const toggleShowEditor = () => {
        // var tempLoc = []
        // _.each(astList, item => {
        //     var loc = { ...item }
        //     loc["edit"] = false
        //     tempLoc.push(loc)
        // })
        // setAssetType("0")
        // setSelectedAssetData("")
        // setAstList([...tempLoc])
        setEditor(!showEditor)
    }


    useEffect(() => {


        setActualCost(validation.values.unit_cost * validation.values.quantity)

        // (Unit cost - (Unit cost X Rebate %)) X Quantity
        var totalPurchaseCost = (validation.values.unit_cost - (validation.values.unit_cost * (validation.values.rebate / 100))) * validation.values.quantity
        setPurchaseCost(totalPurchaseCost)

        // (Unit Cost - (unit cost X discount %)) x Quantity
        var totalCustomerCost = (validation.values.unit_cost - (validation.values.unit_cost * (validation.values.discount / 100))) * validation.values.quantity
        setCustomerCost(totalCustomerCost)

        var expectedMargin = totalCustomerCost - totalPurchaseCost
        setExpectedMargin(expectedMargin)



    }, [validation.values.unit_cost, validation.values.rebate, validation.values.discount, validation.values.quantity])



    return (
        <div className="">

            <Row style={{ borderBottom: "1px solid #ededed", paddingBottom: 10, marginBottom: 10 }}>
                <Col lg={9} >
                    <div className="row">
                        <div className="font-size-10 text-secondary text-opacity-70">Customer</div>
                        <div className="text-primary font-size-15">{props.selectedSubscription && props.selectedSubscription.customer_name}</div>
                        <div className="text-primary font-size-12">{props.selectedSubscription && props.selectedSubscription.subscription_plan_name}</div>
                    </div>
                </Col>
                <Col lg={3} className="d-flex flex-row justify-content-end">
                    <button type="submit" className="btn btn-secondary w-md" onClick={() => {
                        toggleShowEditor()
                    }}>
                        Addon <i className={showEditor ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                    </button>
                </Col>

            </Row>



            {
                showEditor &&
                <Row className="border border-1 border-secondary p-4" style={{ borderRadius: 5 }}>
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >

                        {
                            cmpConfig &&
                            <Row>
                                <Col md={4}>
                                    <div className="col-sm-auto mb-3">
                                        <label className="" htmlFor="autoSizingSelect">Commitment Term</label>
                                        <Input className="form-select"
                                            name="commitment_term"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.commitment_term || "0"}
                                            required
                                        >
                                            <option value="0" disabled>Choose...</option>
                                            {
                                                cmpConfig.commitment_term.map((item, idx) => {
                                                    return (
                                                        <option key={"blt" + idx} value={item.name}>{item.name}</option>
                                                    )
                                                })
                                            }

                                        </Input>
                                        {validation.touched.commitment_term && validation.errors.commitment_term ? (
                                            <FormFeedback type="invalid">{validation.errors.commitment_term}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="col-sm-auto mb-3">
                                        <label className="" htmlFor="autoSizingSelect">Billing Term</label>
                                        <Input className="form-select"
                                            name="billing_term"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.billing_term || "0"}
                                            required
                                            disabled={validation.values.commitment_term === ""}
                                        >
                                            <option value="0" disabled>Choose...</option>
                                            {
                                                cmpConfig.billing_term.map((item, idx) => {
                                                    return (
                                                        <option key={"bty" + idx} value={item.name}>{item.name}</option>
                                                    )
                                                })
                                            }

                                        </Input>
                                        {validation.touched.billing_term && validation.errors.billing_term ? (
                                            <FormFeedback type="invalid">{validation.errors.billing_term}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="col-sm-auto mb-3">
                                        <label className="" htmlFor="autoSizingSelect">Billing Type</label>
                                        <Input className="form-select"
                                            name="billing_type"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.billing_type || "0"}
                                            required
                                        >
                                            <option value="0" disabled>Choose...</option>
                                            {
                                                cmpConfig.billing_type.map((item, idx) => {
                                                    return (
                                                        <option key={"bty" + idx} value={item.name}>{item.name}</option>
                                                    )
                                                })
                                            }

                                        </Input>
                                        {validation.touched.billing_type && validation.errors.billing_type ? (
                                            <FormFeedback type="invalid">{validation.errors.billing_type}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>

                            </Row>
                        }

                        <Row className="mb-4">
                            <Col className="col-3">
                                <div >
                                    <Label htmlFor="unit_cost">Unit Cost</Label>
                                    <Input
                                        name="unit_cost"
                                        className="form-control"
                                        placeholder="Enter unit cost"
                                        type="text"
                                        onChange={(event) => {
                                            validation.handleChange(event)
                                            setUCost(event.target.value)
                                        }
                                        } onBlur={validation.handleBlur}
                                        value={validation.values.unit_cost || ""}
                                        required
                                        invalid={
                                            validation.touched.unit_cost && validation.errors.unit_cost ? true : false
                                        }
                                    />
                                    {validation.touched.unit_cost && validation.errors.unit_cost ? (
                                        <FormFeedback type="invalid">{validation.errors.unit_cost}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div >
                                    <Label htmlFor="rebate">Rebate %</Label>
                                    <Input
                                        name="rebate"
                                        className="form-control"
                                        placeholder="Enter rebate %"
                                        type="text"
                                        onChange={(event) => {
                                            validation.handleChange(event)
                                            setRRebate(event.target.value)
                                        }
                                        }
                                        onBlur={validation.handleBlur}
                                        value={validation.values.rebate || ""}
                                        required
                                        invalid={
                                            validation.touched.rebate && validation.errors.rebate ? true : false
                                        }
                                    />
                                    {validation.touched.rebate && validation.errors.rebate ? (
                                        <FormFeedback type="invalid">{validation.errors.rebate}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div >
                                    <Label htmlFor="discount">Discount %</Label>
                                    <Input
                                        name="discount"
                                        className="form-control"
                                        placeholder="Enter discount %"
                                        type="text"
                                        onChange={(event) => {
                                            validation.handleChange(event)
                                            setRDiscount(event.target.value)
                                        }
                                        }
                                        onBlur={validation.handleBlur}
                                        value={validation.values.discount || ""}
                                        required
                                        invalid={
                                            validation.touched.discount && validation.errors.discount ? true : false
                                        }
                                    />
                                    {validation.touched.discount && validation.errors.discount ? (
                                        <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div >
                                    <Label htmlFor="quantity">Quantity</Label>
                                    <Input
                                        name="quantity"
                                        className="form-control"
                                        placeholder="Enter quantity"
                                        type="text"
                                        onChange={(event) => {
                                            validation.handleChange(event)
                                            setRQuantity(event.target.value)
                                        }
                                        }
                                        onBlur={validation.handleBlur}
                                        value={validation.values.quantity || ""}
                                        required
                                        invalid={
                                            validation.touched.quantity && validation.errors.quantity ? true : false
                                        }
                                    />
                                    {validation.touched.quantity && validation.errors.quantity ? (
                                        <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col className="col-auto me-2">
                                <div className="d-flex flex-column" >
                                    <Label className="text-secondary" htmlFor="vendor_name">Actual Cost</Label>
                                    <Label className="font-size-20" htmlFor="vendor_name">{actualCost}</Label>

                                </div>
                            </Col>
                            <Col className="col-auto me-2">
                                <div className="d-flex flex-column" >
                                    <Label className="text-secondary" htmlFor="vendor_name">Total Purchase Cost</Label>
                                    <Label className="font-size-20" htmlFor="vendor_name">{purchaseCost}</Label>

                                </div>
                            </Col>
                            <Col className="col-auto me-2">
                                <div className="d-flex flex-column" >
                                    <Label className="text-secondary" htmlFor="vendor_name">Total Customer Cost</Label>
                                    <Label className="font-size-20" htmlFor="vendor_name">{customerCost}</Label>

                                </div>
                            </Col>
                            <Col className="col-auto me-2">
                                <div className="d-flex flex-column" >
                                    <Label className="text-secondary" htmlFor="vendor_name">Expected Margin</Label>
                                    <Label className="font-size-20" htmlFor="vendor_name">{expectedMargin}</Label>

                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div>
                                <button type="submit" className="btn btn-primary w-md me-2" >
                                    {!selectedProductData ? "Submit" : "Update"}
                                </button>

                                <button type="submit" className="btn btn-danger w-md" onClick={() => {
                                    toggleShowEditor()
                                }}>
                                    {"cancel"}
                                </button>
                            </div>
                        </Row>

                    </Form>
                </Row>

            }



        </div>
    )

}

export default SubscriptionProducts