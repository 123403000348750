import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";

import authReducer from "../toolkitStore/authenticateSlice"
import businessUnitReducer from "../toolkitStore/businessUnitSlice"
import manageUserReducer from "toolkitStore/manageUserSlice";
import manageServiceReducer from "toolkitStore/manageServiceSlice";
import manageSLAReducer from "toolkitStore/manageSLASlice";
import manageCustomerReducer from "toolkitStore/manageCustomerSlice";
import manageLocationReducer from "toolkitStore/manageLocationSlice";
import manageCContactReducer from "toolkitStore/manageCContactSlice";
import manageCAssetReducer from "toolkitStore/manageCAssetSlice";
import manageContractReducer from "toolkitStore/manageContractSlice";
import manageDeliverableReducer from "toolkitStore/manageDeliverableSlice";
import manageEsculationReducer from "toolkitStore/manageEsculationSlice";
import dashboardReducer from "toolkitStore/dashboardSlice";

import cloudConsumptionReducer from "toolkitStore/cloudConsumptionSlice";
import manageVendorReducer from "toolkitStore/manageVendorSlice";
import manageSubscriptionReducer from "toolkitStore/manageSubscriptionSlice";
import socketReducer from "toolkitStore/socketSlice"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,


  authReducer,
  businessUnitReducer,
  manageUserReducer,
  manageServiceReducer,
  manageSLAReducer,
  manageCustomerReducer,
  manageLocationReducer,
  manageCContactReducer,
  manageCAssetReducer,
  manageContractReducer,
  manageDeliverableReducer,
  manageEsculationReducer,
  dashboardReducer,
  cloudConsumptionReducer,
  manageVendorReducer,
  manageSubscriptionReducer,
  socketReducer
});

export default rootReducer;
