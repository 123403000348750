import React, { useState, useEffect, useMemo } from "react"
import { formate } from "date-fns"
import {
  Container,
  Row,
  Col,
  CardText,
  Label,
  Form,
  Input,
  FormFeedback,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,

} from "reactstrap"


const KnowledgeBase = () => {

    return(<>
    </>)

}

export default KnowledgeBase

 