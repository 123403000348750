import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    assetList: null,
    assetLocationList: null,
    isAssetSuccessfullyAdded:false,
};


const manageCAssetSlice = createSlice({
    name: "manageCAsset",
    initialState: initialRegState,
    reducers: {

        setAssetList: (state, action) => {
            state.assetList = _.each(action.payload, item => {
                item["edit"] = false
                item["locationEditor"] = false
            })
        },
        setAssetSuccessfullyAdded:(state, action) => {
            state.isAssetSuccessfullyAdded = true
        },
        resetAssetSuccessfullyAdded:(state, action) => {
            state.isAssetSuccessfullyAdded = false
        },
        resetAssetList:(state, action) => {
            state.assetList = null
        },
        setAssetLocationList: (state, action) => {
            state.assetLocationList = action.payload
        },
        
    }

})

export const createAsset = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/add-asset", {
                "assetInfo": values
            })
            console.log("create asset", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setAssetSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterAsset = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/update-asset", {
                "assetInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setAssetSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchAssets = (company_id, customer_id, location_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_asset/get-assets", {
                "company_id":company_id,
                "customer_id":customer_id,
            })
            console.log("get contacts", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setAssetList(responseData.data))
                }
                else
                {
                    dispatch(resetAssetList())
                }
                
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterAsset = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/delete-asset", {
                "ID":id
            })
            console.log("delete asset", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setAssetSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const mapLocationtoAsset = (data) => {
    return async (dispatch) => {
        try {
           // dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/map-location-asset", {
                "mapInfo":data
            })
            console.log("map response", responseData)
            if(responseData.response_code === 500)
            {
                //dispatch(setAssetSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const removeMapLocationtoAsset = (data) => {
    return async (dispatch) => {
        try {
           // dispatch(resetAssetSuccessfullyAdded())
            const responseData = await post("/manage_asset/removemap-location-asset", {
                "mapInfo":data
            })
            console.log("map response", responseData)
            if(responseData.response_code === 500)
            {
               // dispatch(setAssetSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const getMapLocationtoAsset = (assetId) => {

    console.log("am in")
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_asset/get-asset-locations", {
                "assetId":assetId
            })
            console.log("asset locations", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setAssetLocationList(responseData.data))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}



export const { setAssetList, setAssetLocationList, resetAssetList, setAssetSuccessfullyAdded, resetAssetSuccessfullyAdded } = manageCAssetSlice.actions;

export default manageCAssetSlice.reducer;
