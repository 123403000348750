import PropTypes from "prop-types";
import React, { useState, useEffect } from "react"

import withRouter from "../../../components/Common/withRouter";

import { Container, Row, Col, Label, Form, Input, FormFeedback, FormGroup } from "reactstrap"

import { useSelector, useDispatch } from "react-redux";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";

import makeAnimated from "react-select/animated";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import { fetchServices, createService, updateService, deleteService, resetServiceSuccessfullyAdded } from "../../../toolkitStore/manageServiceSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"

import Select from "react-select";

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" }
    ]
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" }
    ]
  }
];

const animatedComponents = makeAnimated();

 const serviceForm = (props) => {

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { serviceList,isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)



  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [selectedService, setSelectedService] = useState("")
  const [inScope, setInscope] = useState("")
  const [outScope, setOutscope] = useState("")
  const [prerequisite, setPrerequisite] = useState("")
  const [mode, setMode] = useState("new")
  const [showScope, setscope] = useState(false)
  const [showSubmenu, setSubmenu] = useState(false)
  const [serviceTree, setServices] = useState([])
  const [serviceLevel, setServiceLevel] = useState("0")

  const [selectedGroup, setselectedGroup] = useState(null)

  const dispatch = useDispatch()
  

  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
    }

  }, [companyConfig])


  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setInfo(obj)
    dispatch(getConfig())
    dispatch(resetServiceSuccessfullyAdded())
    dispatch(fetchServices(obj.m_company_id))
  }, [])

  useEffect(() => {
    console.log("isSuccessfullyAdded", isSuccessfullyAdded)
    if (isSuccessfullyAdded) {
       props.router.navigate("/services")
    }
  }, [isSuccessfullyAdded])




  useEffect(() => {
    if (serviceList.length > 0) {
      console.log("serviceList", serviceList)

      var createTree = []
      _.each(serviceList, item => {
        createTree.push({
          label: item.relational_path+item.name,
          value: item.name,
          _id: item._id,
          relational_path: item.relational_path
        })
      })

      setServices([...createTree])

    }

  },[serviceList])


  // const handleChange = (event) => {
  //   const { name, value } = event.target
  //   setUser({ ...user, [name]: value })
  // }
  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    setInscope(data)
  }
  const handleCkeditorOut = (event, editor) => {
    const data = editor.getData()
    setOutscope(data)
  }
  const handleCkeditorPre = (event, editor) => {
    const data = editor.getData()
    setPrerequisite(data)
  }

  function handleSelectGroup(event) {
    console.log("selectedGroup", event)
    setselectedGroup(event);
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedService.name || '',
      short_name: selectedService.short_name || '',
      description: selectedService.description || '',
      remarks: selectedService.remarks || '',
      keywords: selectedService.keywords || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter service name"),
      short_name: Yup.string().required("Please Enter service short name"),
    }),
    onSubmit: (values) => {
      values["in_scope"] = inScope
      values["out_scope"] = outScope
      values["prerequisite"] = prerequisite
      values["m_company_id"] = userInformation.m_company_id
      values["m_b_unit_id"] = userInformation.m_b_unit_id
      if(showSubmenu && selectedGroup)
      {
        values["relative_id"] = selectedGroup._id
        values["relational_path"] = selectedGroup.relational_path+"-"
      }
      values["created_by"] = userInformation._id
      console.log("values", values)
      if(mode === "new")
      {
        dispatch(createService(values))
      }
      else
      {
        values["_id"] = selectedService._id
        dispatch(updateService(values))
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}
          <div direction="end">
            <Row className="pb-2 mb-3" style={{ borderBottom: "1px solid #dedede" }}>
              <Col lg={10}>
                <div>
            <h5  >
              Add New Service
            </h5>
            </div>
            </Col>
            <Col lg={2} className="d-flex justify-content-end">
              <button className="btn btn-dark" onClick={()=>{
                props.router.navigate("/services")
              }}>Back</button>
            </Col>
            </Row>
            <div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >

                <Row>

               

                  {
                    companyConfig && <div className="mb-4">
                      <label className="" htmlFor="autoSizingSelect">Select Service level</label>
                      <select className="form-select" onChange={(e) => {
                        setServiceLevel(e.target.value)

                      }}
                        defaultValue={serviceLevel}
                      >
                        <option value="0" disabled>Choose...</option>
                        {
                          companyConfig.service_level.map((item, idx) => {
                            return (
                              <option key={"cnst" + idx} value={item.id}>{item.name}</option>
                            )
                          })
                        }


                      </select>

                    </div>
                  }

                  {
                    showSubmenu && 
                    <Col lg={12}>
                    <div className="mb-3">
                            <Label>Single Primary Service</Label>
                            <Select
                              value={selectedGroup}
                              onChange={(e) => {
                                handleSelectGroup(e);
                              }}
                              options={serviceTree}
                              className="select2-selection" 
                            />
                          </div>
                    </Col>
                  }
                
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="name">Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Your Service Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        required
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="short_name">Short Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="short_name"
                        placeholder="Enter Your Short Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.short_name || ""}
                        required
                        invalid={
                          validation.touched.short_name && validation.errors.short_name ? true : false
                        }
                      />
                      {validation.touched.short_name && validation.errors.short_name ? (
                        <FormFeedback type="invalid">{validation.errors.short_name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <div className="mb-3">
                    <Label htmlFor="description">Description</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Enter description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                      invalid={
                        validation.touched.description && validation.errors.description ? true : false
                      }
                    />
                    {validation.touched.description && validation.errors.description ? (
                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>
                  <Col lg={12}>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={"scpe"}
                        onChange={(e) => { setscope(!showScope) }}
                        // checked={showScope}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"scpe"}
                      >
                        Add scope
                      </label>
                    </div>
                  </Col>

                  
                 
                  {
                    showScope && 
                    <>
                                      <Col md={6}>
                    <div className="mb-3 ">
                      <Label htmlFor="inl">In scope</Label>
                      <CKEditor
                        config={{ placeholder: "Type the In_scope Here..!" }}
                        editor={ClassicEditor}
                        data={inScope}
                        onChange={handleCkeditorIn}
                        value={inScope}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="out">Out scope</Label>
                      <CKEditor
                        config={{ placeholder: "Type the Out_scope Here..!" }}
                        editor={ClassicEditor}
                        data={outScope}
                        onChange={handleCkeditorOut}
                        value={outScope}
                      />
                    </div>
                  </Col>

                  <div className="mb-3">
                    <Label htmlFor="pre">Prerequisite</Label>
                    <CKEditor
                      config={{ placeholder: "Type the Prerequisite Here..!" }}
                      editor={ClassicEditor}
                      data={prerequisite}
                      onChange={handleCkeditorPre}
                      value={prerequisite}
                    />
                  </div>
                    </>
                  }


                  <div className="mb-3">
                    <Label htmlFor="remarks">Remakrs</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name="remarks"
                      placeholder="Enter remarks"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.remarks || ""}
                      invalid={
                        validation.touched.remarks && validation.errors.remarks ? true : false
                      }
                    />
                    {validation.touched.remarks && validation.errors.remarks ? (
                      <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="keywords">Keywords</Label>
                    <Input
                      type="text"
                      className="form-control"
                      name="keywords"
                      placeholder="Enter keywords"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.keywords || ""}
                      invalid={
                        validation.touched.remarks && validation.errors.keywords ? true : false
                      }
                    />
                    {validation.touched.keywords && validation.errors.keywords ? (
                      <FormFeedback type="invalid">{validation.errors.keywords}</FormFeedback>
                    ) : null}
                  </div>

                  


                  <div className="mb-5">
                    <button type="submit" className="btn btn-primary w-md">
                      {/* {bu_data !== "" ? "Update" : "Submit"} */}Submit
                    </button>

                  </div>
                </Row>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(serviceForm);

serviceForm.propTypes = {
  history: PropTypes.object,
};

