import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    customerList: null,
    isSuccessfullyAdded:false,
    responseCustomerError:""
};


const manageCustomerSlice = createSlice({
    name: "manageCustomers",
    initialState: initialRegState,
    reducers: {

        setCustomerList: (state, action) => {
            state.customerList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        setCustomerSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = true
        },
        resetCustomerSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = false
        },
        resetCustomerList: (state, action) => {
            state.customerList = null
        },
        setCusResponseError:(state, action) => {
            state.responseCustomerError = action.payload
        },
        resetCusResponseError:(state, action) => {
            state.responseCustomerError = null
        }
        
    }

})

export const createCustomer = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetCustomerSuccessfullyAdded())
            const responseData = await post("/manage_customer/add-customer", {
                "customerInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setCustomerSuccessfullyAdded())
            }
            else
            {
                dispatch(setCusResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterCustomer = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetCustomerSuccessfullyAdded())
            const responseData = await post("/manage_customer/update-customer", {
                "customerInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setCustomerSuccessfullyAdded())
            }
            else
            {
                dispatch(setCusResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchCustomers = (company_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_customer/get-customers", {
                "company_id":company_id
            })
            console.log("get customers", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setCustomerList(responseData.data))
                }
                else
                {
                    dispatch(resetCustomerList())
                }
            }
            else
            {
                dispatch(setCusResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterCustomers = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetCustomerSuccessfullyAdded())
            const responseData = await post("/manage_customer/delete-customer", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setCustomerSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setCustomerList, 
    setCustomerSuccessfullyAdded, 
    resetCustomerSuccessfullyAdded,
    resetCustomerList,
    setCusResponseError,
    resetCusResponseError
} = manageCustomerSlice.actions;

export default manageCustomerSlice.reducer;
