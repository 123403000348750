import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Container, Row, Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip,
  Modal, FormFeedback, Table
} from "reactstrap";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { fetchSLA, createSLA, updateSLA, deleteMasterSLA } from "../../../toolkitStore/manageSLASlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"


const SLA = (props) => {

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { slaList, isSuccessfullyAdded } = useSelector(state => state.manageSLAReducer)


  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [slas, setSlas] = useState([])
  const [selectedData, setSelectedData] = useState("")
  const [impactType, setImpactType] = useState("0")
  const [selectedID, setSelectedID] = useState("")
  const [mode, setMode] = useState("new")


  const dispatch = useDispatch()

  const [modal_backdrop, setmodal_backdrop] = useState(false);

  function tog_backdrop() {
    setSelectedData("")
    setImpactType("0")
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const [open, setOpen] = useState(false);
  const [isRight, setIsRight] = useState(false);

  const toggleRightCanvas = () => {
    setSelectedData("")
    setMode("new")
    setImpactType("0")
    setIsRight(!isRight);
  };


  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
    }

  }, [companyConfig])

  useEffect(() => {
    if (!userInformation) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      dispatch(fetchSLA(obj.m_company_id))
      dispatch(getConfig())
    }
  }, [userInformation])




  useEffect(() => {
    if (isSuccessfullyAdded) {
      dispatch(fetchSLA(userInformation.m_company_id))
    }
  }, [isSuccessfullyAdded])

  useEffect(() => {
    if (slaList === null) {
      dispatch(fetchSLA(userInformation.m_company_id))
    }
    else if (slaList !== undefined) {
      console.log("customerList", slaList)
      setSlas([...slaList])
    }
  }, [slaList])


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      impact_name: selectedData.impact_name || "",
      response_time: selectedData.response_time || "",
      resolution_time: selectedData.resolution_time || "",
      definition: selectedData.definition || "",
    },
    validationSchema: Yup.object({
      impact_name: Yup.string().required("Please Enter Your impact name"),
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {

      console.log("values", values)

      var getImpactIndex = _.findIndex(cmpConfig.impact_type, { id: impactType })
      if (getImpactIndex !== -1) {
        var impact_id = cmpConfig.impact_type[getImpactIndex].id
        var impact_type = cmpConfig.impact_type[getImpactIndex].name
      }

      values["impact_id"] = impact_id
      values["impact_type"] = impact_type
      values["m_company_id"] = userInformation.m_company_id
      values["m_b_unit_id"] = userInformation.m_b_unit_id
      values["created_by"] = userInformation._id
      if (mode === "edit") {
        values["_id"] = selectedData._id
        dispatch(updateSLA(values))
      }
      else {
        dispatch(createSLA(values))
      }
      dispatch(fetchSLA(userInformation.m_company_id))
      setMode("new")
      setSelectedData("")

      setIsRight(!isRight);

    }
  });




  const editData = (data) => {
    setMode("edit")

    var getImpactIndex = _.findIndex(cmpConfig.impact_type, { id: data.impact_id })
    if (getImpactIndex !== -1) {
      var impact_id = cmpConfig.impact_type[getImpactIndex].id
    }
    setImpactType(impact_id)

    setSelectedData(data)
    setIsRight(!isRight);
  }

  const deleteData = (data) => {

    setSelectedID(data._id)
    tog_backdrop()
  }
  const onConfirmDelete = () => {
    console.log("selectedID", selectedID)
    dispatch(deleteMasterSLA(selectedID))
    setmodal_backdrop(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'SLA ID',
        accessor: 'sla_id',
        disableFilters: true,
      },
      {
        Header: 'Impact type',
        accessor: 'impact_type',
        disableFilters: true,
      },
      {
        Header: 'Impact Name',
        accessor: 'impact_name',
        disableFilters: true,
      },
      {
        Header: 'Definition',
        accessor: 'definition',
        disableFilters: true,
      },
      {
        Header: 'Response time',
        accessor: 'response_time',
        disableFilters: true,
      },
      {
        Header: 'Resolution time',
        accessor: 'resolution_time',
        disableFilters: true,
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">


              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    const data = cellProps.row.original;
                    editData(data);
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    const data = cellProps.row.original;
                    deleteData(data);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          );
        }
      },

    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

          <Row
            className="justify-content-center"
          >
            <Card>
              <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Service Level</h5>
                  <div className="flex-shrink-0">
                    <Link to="#!" onClick={() => toggleRightCanvas()} className="btn btn-primary me-1">Add New SLA</Link>
                    <Link to="#!" onClick={() => {
                      dispatch(fetchSLA(userInformation.m_company_id))
                    }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                  </div>
                </div>
                <Offcanvas
                  isOpen={isRight}
                  backdrop={"static"}
                  direction="end"
                  toggle={toggleRightCanvas}>
                  <OffcanvasHeader toggle={toggleRightCanvas} style={{ borderBottom: "1px solid #dedede" }}>
                    Add / Edit SLA
                  </OffcanvasHeader>
                  <OffcanvasBody>

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <Row>
                        {
                          cmpConfig &&
                          <Col md={6}>
                            <div className="col-sm-auto mb-3">
                              <label className="" htmlFor="autoSizingSelect">Impact type</label>
                              <select className="form-select" onChange={(e) => {
                                console.log("event.taget.value", e.target.value)
                                setImpactType(e.target.value)
                              }}
                                defaultValue={impactType}
                              >
                                <option value="0" disabled>Choose...</option>
                                {
                                  cmpConfig.impact_type.map((item, idx) => {
                                    return (
                                      <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                    )
                                  })
                                }

                              </select>
                            </div>
                          </Col>
                        }

                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="impact_name">Impact name</Label>
                            <Input
                              name="impact_name"
                              className="form-control"
                              placeholder="Enter Impact name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.impact_name || ""}
                              required
                              invalid={
                                validation.touched.impact_name && validation.errors.impact_name ? true : false
                              }
                            />
                            {validation.touched.impact_name && validation.errors.impact_name ? (
                              <FormFeedback type="invalid">{validation.errors.impact_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label htmlFor="definition">Definition</Label>
                            <Input
                              name="definition"
                              className="form-control"
                              placeholder="Enter Definition"
                              type="textarea"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.definition || ""}
                              required
                              invalid={
                                validation.touched.definition && validation.errors.definition ? true : false
                              }
                            />
                            {validation.touched.definition && validation.errors.definition ? (
                              <FormFeedback type="invalid">{validation.errors.definition}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-3">
                        <Label htmlFor="response_time">Response time</Label>
                        <Input
                          name="response_time"
                          className="form-control"
                          placeholder="Enter Response time"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.response_time || ""}
                          required
                          invalid={
                            validation.touched.response_time && validation.errors.response_time ? true : false
                          }
                        />
                        {validation.touched.response_time && validation.errors.response_time ? (
                          <FormFeedback type="invalid">{validation.errors.response_time}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="resolution_time">Resolution time</Label>
                        <Input
                          name="resolution_time"
                          className="form-control"
                          placeholder="Enter Resolution time"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.resolution_time || ""}
                          required
                          invalid={
                            validation.touched.resolution_time && validation.errors.resolution_time ? true : false
                          }
                        />
                        {validation.touched.resolution_time && validation.errors.resolution_time ? (
                          <FormFeedback type="invalid">{validation.errors.resolution_time}</FormFeedback>
                        ) : null}
                      </div>





                      <div>
                        <button type="submit" className="btn btn-primary w-md" >
                          {mode === "edit" ? "Update" : "Submit"}
                        </button>
                      </div>
                    </Form>

                  </OffcanvasBody>
                </Offcanvas>

              </CardBody>
              <CardBody>
                {
                  slas.length > 0 &&
                  // <TableContainer
                  //   columns={columns}
                  //   data={slas}
                  //   isGlobalFilter={true}
                  //   isAddOptions={false}
                  //   customPageSize={10}
                  //   customPageSizeOptions={false}
                  //   isJobListGlobalFilter={false}
                  //   className="custom-header-css"
                  // />
                  <div className="table-responsive">
                    <Table className="table table-sm mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          slas.map((item, idx) => {

                            return (
                              <tr key={"cstrlevl" + idx}>
                                <th>{idx + 1}</th>
                                <td>{item.impact_type}</td>
                                {
                                  item.sla.length > 0 &&
                                  <table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="text-info">Impact Name</th>
                                        <th scope="col" className="text-info">Definition</th>
                                        <th scope="col" className="text-info">Response Time</th>
                                        <th scope="col" className="text-info">Resolution Time</th>
                                        <th scope="col" className="text-info">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        item.sla.map((elem, indx) => {
                                          return (
                                            <tr key={"cstrlevl" + indx}>
                                              <td  width={"10%"}>{elem.impact_name}</td>
                                              <td  width={"60%"}>{elem.definition}</td>
                                              <td  width={"10%"}>{elem.response_time}</td>
                                              <td  width={"10%"}>{elem.resolution_time}</td>
                                              <td  width={"10%"}>
                                                <ul className="list-unstyled hstack gap-1 mb-0">
                                                  <li>
                                                    <Link
                                                      to="#"
                                                      className="btn btn-sm btn-info"
                                                      onClick={() => {
                                                        const data = elem;
                                                        editData(data);
                                                      }}
                                                    >
                                                      <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                                      <UncontrolledTooltip placement="top" target="edittooltip">
                                                        Edit
                                                      </UncontrolledTooltip>
                                                    </Link>
                                                  </li>

                                                  <li>
                                                    <Link
                                                      to="#"
                                                      className="btn btn-sm btn-danger"
                                                      onClick={() => {
                                                        const data = elem;
                                                        deleteData(data);
                                                      }}
                                                    >
                                                      <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                      <UncontrolledTooltip placement="top" target="deletetooltip">
                                                        Delete
                                                      </UncontrolledTooltip>
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </table>
                                }
                              </tr>
                            )

                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                }

              </CardBody>
            </Card>
          </Row>

          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop();
            }}
            backdrop={'static'}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Delete Business unit</h5>
              <button type="button" className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false);
                }} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Are you sure, you want to delete this business unit?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => {
                setmodal_backdrop(false);
              }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={() => {
                onConfirmDelete()
              }}>Yes, delete it</button>
            </div>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default SLA;
