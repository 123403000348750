import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardImg,
    CardText,  
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback, Collapse
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import TimezoneSelect from 'react-timezone-select'

import { getConfig } from "../../../../toolkitStore/authenticateSlice"
import { resetLocationSuccessfullyAdded, fetchLocations, createLocation, updateMasterLocation, deleteMasterLocation } from "../../../../toolkitStore/manageLocationSlice"
import { resetContactSuccessfullyAdded, fetchContacts, createContact, updateMasterContact, deleteMasterContact } from "../../../../toolkitStore/manageCContactSlice"
import { resetAssetSuccessfullyAdded, fetchAssets, createAsset, updateMasterAsset, deleteMasterAsset } from "../../../../toolkitStore/manageCAssetSlice"


const ListOfLocations = (props) => {

    const { locationList, isSuccessfullyAdded } = useSelector(state => state.manageLocationReducer)
    const { contactList, isContactSuccessfullyAdded } = useSelector(state => state.manageCContactReducer)
    const { assetList, isAssetSuccessfullyAdded } = useSelector(state => state.manageCAssetReducer)

    const dispatch = useDispatch()

    const [locList, setLocList] = useState([])
    const [selectedData, setSelectedData] = useState(props.selectedData)
    const [selectedLocationData, setSelectedLocationData] = useState("")
    const [showEditor, setEditor] = useState(false)
    const [type, setType] = useState(props.type)
    const [selectedTimezone, setSelectedTimezone] = useState({})
    const [ColToggle, setColToggle] = useState(false)


    useEffect(() => {
        if (locationList === null) {
            dispatch(fetchLocations(props.userInformation.m_company_id, selectedData._id))
        }
        else {
            setLocList([...locationList])
        }

    }, [locationList])


    useEffect(() => {
        if (isSuccessfullyAdded) {
            //props.closeOffCanvas()
            dispatch(resetLocationSuccessfullyAdded())
            dispatch(fetchLocations(props.userInformation.m_company_id, selectedData._id))
        }
    }, [isSuccessfullyAdded])

    const toggleShowEditor = () => {
        setSelectedLocationData("")
        var tempLoc = []
        _.each(locList, item => {
            var loc = { ...item }
            loc["editLocation"] = false
            tempLoc.push(loc)
        })
        setType("0")
        setLocList([...tempLoc])
        setSelectedTimezone({})
        setEditor(!showEditor)
    }

    const closeEditor = () => {
        setSelectedLocationData("")
        setEditor(!showEditor)
    }

    const toggleEditor = (data) => {
        var tempLoc = []
        _.each(locList, item => {
            var loc = { ...item }
            if (loc._id === data._id) {
                loc["editLocation"] = !loc.editLocation
                setSelectedLocationData(loc)
            }
            else {
                loc["editLocation"] = false
            }
            tempLoc.push(loc)
        })
        setType(data.location_type_id)
        setLocList([...tempLoc])
        setSelectedTimezone(data.timezone ? data.timezone : {})
        setEditor(false)

    }

    const closeEditors = () => {
        var tempLoc = []
        _.each(locList, item => {
            var loc = { ...item }
            loc["editLocation"] = false
            tempLoc.push(loc)
        })
        setLocList([...tempLoc])
        setEditor(false)
    }

    const handleDeleteLocation = (data) => {
        dispatch(deleteMasterLocation(data._id))
    }


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            location_name: selectedLocationData.location_name || "",
            address1: selectedLocationData.address1 || "",
            address2: selectedLocationData.address2 || "",
            city: selectedLocationData.city || "",
            state: selectedLocationData.state || "",
            country: selectedLocationData.country || "",
            pincode: selectedLocationData.pincode || "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {

            console.log("values", values)

            var getIndex = _.findIndex(props.cmpConfig.location_type, { id: type })
            if (getIndex !== -1) {
                var id = props.cmpConfig.location_type[getIndex].id
                var name = props.cmpConfig.location_type[getIndex].name
            }

            values["location_type_id"] = id
            values["location_type"] = name
            values["timezone"] = selectedTimezone
            values["customer_id"] = selectedData._id
            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            values["created_by"] = props.userInformation._id

            if (selectedLocationData.editLocation) {
                values["_id"] = selectedLocationData._id
                dispatch(updateMasterLocation(values))
            }
            else {
                dispatch(createLocation(values))
                closeEditor()
            }

        }
    });

   
    // ============================






    return (
        <div className="p-3">
            <Row style={{ borderBottom: "1px solid #ededed", paddingBottom: 10 }}>
                <Col lg={9} >
                    <div className="row">
                        <div className="font-size-10 text-secondary text-opacity-70">Customer</div>
                        <div className="text-primary font-size-15">{selectedData.customer_name}</div>
                    </div>
                </Col>
                <Col lg={3} className="d-flex flex-row justify-content-end">
                    <button type="submit" className="btn btn-success w-md" onClick={() => {
                        toggleShowEditor()
                    }}>
                        Add location <i className={showEditor ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                    </button>
                </Col>

            </Row>
            {
                showEditor &&
                <div style={{ borderBottom: "1px solid #ededed", paddingBottom: 10, paddingTop: 10 }}>
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >

                        <Row>
                            {
                                props.cmpConfig &&
                                <Col md={12}>
                                    <div className="col-sm-auto mb-3">
                                        <label className="" htmlFor="autoSizingSelect">Location type</label>
                                        <select className="form-select" onChange={(e) => {
                                            console.log("event.taget.value", e.target.value)
                                            setType(e.target.value)
                                        }}
                                            defaultValue={type}
                                        >
                                            <option value="0" disabled>Choose...</option>
                                            {
                                                props.cmpConfig.location_type.map((item, idx) => {
                                                    return (
                                                        <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </Col>
                            }

                        </Row>

                        <div className="mb-3">
                            <Label htmlFor="location_name">Location Name</Label>
                            <Input
                                name="location_name"
                                className="form-control"
                                placeholder="Enter Location name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.location_name || ""}
                                required
                                invalid={
                                    validation.touched.location_name && validation.errors.location_name ? true : false
                                }
                            />
                            {validation.touched.location_name && validation.errors.location_name ? (
                                <FormFeedback type="invalid">{validation.errors.location_name}</FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="address1">Address 1</Label>
                            <Input
                                name="address1"
                                className="form-control"
                                placeholder="Enter Address 1"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address1 || ""}
                                invalid={
                                    validation.touched.address1 && validation.errors.address1 ? true : false
                                }
                            />
                            {validation.touched.address1 && validation.errors.address1 ? (
                                <FormFeedback type="invalid">{validation.errors.address1}</FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label htmlFor="address2">Address 2</Label>
                            <Input
                                name="address2"
                                className="form-control"
                                placeholder="Enter Address 2"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address2 || ""}
                                invalid={
                                    validation.touched.address2 && validation.errors.address2 ? true : false
                                }
                            />
                            {validation.touched.address2 && validation.errors.address2 ? (
                                <FormFeedback type="invalid">{validation.errors.address2}</FormFeedback>
                            ) : null}
                        </div>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="city">City</Label>
                                    <Input
                                        name="city"
                                        className="form-control"
                                        placeholder="Enter City"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.city || ""}
                                        invalid={
                                            validation.touched.city && validation.errors.city ? true : false
                                        }
                                    />
                                    {validation.touched.city && validation.errors.city ? (
                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="state">State</Label>
                                    <Input
                                        name="state"
                                        className="form-control"
                                        placeholder="Enter State"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.state || ""}
                                        invalid={
                                            validation.touched.state && validation.errors.state ? true : false
                                        }
                                    />
                                    {validation.touched.state && validation.errors.state ? (
                                        <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="country">Country</Label>
                                    <Input
                                        name="country"
                                        className="form-control"
                                        placeholder="Enter State"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.country || ""}
                                        invalid={
                                            validation.touched.country && validation.errors.country ? true : false
                                        }
                                    />
                                    {validation.touched.country && validation.errors.country ? (
                                        <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="pincode">Pincode</Label>
                                    <Input
                                        name="pincode"
                                        className="form-control"
                                        placeholder="Enter Pincode"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.pincode || ""}
                                        invalid={
                                            validation.touched.pincode && validation.errors.pincode ? true : false
                                        }
                                    />
                                    {validation.touched.pincode && validation.errors.pincode ? (
                                        <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>



                        <div className="mb-3 select-wrapper">
                            <Label htmlFor="email">Timezone</Label>
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                            />
                        </div>



                        <div>
                            <button type="submit" className="btn btn-primary w-md" >
                                {"Submit"}
                            </button>
                        </div>
                    </Form>
                </div>

            }
            <div className="d-flex flex-column mt-2 mb-2">
                <div className="d-flex flex-column  mt-2">
                    <div className="text-secondary">Locations</div>
                    {
                        locList && locList.map((item, idx) => {
                            return (
                                <div key={"lcn" + idx} className="py-2 " style={{ borderBottom: "1px solid #ededed" }}>

                                    <div className="row">

                                        <div className="col-9 d-flex flex-row align-items-center my-2" >
                                            <div className="">
                                                <div className="text-success">{item.location_name}</div>
                                                <div className="font-size-11">{item.location_type}</div>                                      
                                                <div className="font-size-11 text-secondary">{item.address1}</div>                           
                                                { item.address2.lenght !== 0 && <div className="font-size-11 text-secondary">{item.address2}</div> }
                                                <div className="font-size-11 text-secondary">{item.city}, {item.state}, {item.country}, {item.pincode}</div>                         
                                                <div className="font-size-11 text-secondary">{item.timezone.label}</div>                         
                                            </div>
                                        </div>


                                        <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2">
                                            {
                                                <Link
                                                    to="#!"
                                                    className="btn btn-sm btn-soft-success me-1"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setColToggle(-1)
                                                        const data = item;
                                                        toggleEditor(data)
                                                    }}
                                                >
                                                    <i className={item.editLocation ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                                                </Link>
                                            }

                                            {
                                                <Link
                                                    to="#!"
                                                    className="btn btn-sm btn-soft-danger"
                                                    onClick={(e) => {

                                                        e.preventDefault()
                                                        const data = item;
                                                        closeEditors()
                                                        ColToggle !== idx ? setColToggle(idx) : setColToggle(-1)
                                                    }}
                                                >
                                                    <i className={ColToggle === idx ? "mdi mdi-close" : "mdi mdi-delete-outline"} />
                                                </Link>
                                            }

                                        </div>
                                    </div>
                                    {
                                        <Collapse isOpen={ColToggle === idx}>
                                            <div className="row mb-3">
                                                <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                            </div>

                                            <div >
                                                <div className="py-2 ps-3" >
                                                    <CardHeader className="bg-transparent mb-3">
                                                        <h7 className="my-0 text-danger">
                                                            <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                        </h7>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <CardText>
                                                            <div>
                                                                <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                                                <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); handleDeleteLocation(item) }}>Delete</button>
                                                            </div>
                                                        </CardText>
                                                    </CardBody>
                                                </div>

                                            </div>
                                        </Collapse>
                                    }
                                    {
                                        <Collapse isOpen={item.editLocation}>
                                            <div className="row mb-3">
                                                <div className="font-size-13 text-success bg-success bg-opacity-10 py-2 ps-2">Edit Location</div>
                                            </div>

                                            <Col lg={"12"} >
                                                <Form
                                                    className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >

                                                    <Row>
                                                        {
                                                            props.cmpConfig &&
                                                            <Col md={12}>
                                                                <div className="col-sm-auto mb-3">
                                                                    <label className="" htmlFor="autoSizingSelect">Location type</label>
                                                                    <select className="form-select" onChange={(e) => {
                                                                        console.log("event.taget.value", e.target.value)
                                                                        setType(e.target.value)
                                                                    }}
                                                                        defaultValue={type}
                                                                    >
                                                                        <option value="0" disabled>Choose...</option>
                                                                        {
                                                                            props.cmpConfig.location_type.map((item, idx) => {
                                                                                return (
                                                                                    <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        }

                                                    </Row>

                                                    <div className="mb-3">
                                                        <Label htmlFor="location_name">Location Name</Label>
                                                        <Input
                                                            name="location_name"
                                                            className="form-control"
                                                            placeholder="Enter Location name"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.location_name || ""}
                                                            required
                                                            invalid={
                                                                validation.touched.location_name && validation.errors.location_name ? true : false
                                                            }
                                                        />
                                                        {validation.touched.location_name && validation.errors.location_name ? (
                                                            <FormFeedback type="invalid">{validation.errors.location_name}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="address1">Address 1</Label>
                                                        <Input
                                                            name="address1"
                                                            className="form-control"
                                                            placeholder="Enter Address 1"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.address1 || ""}
                                                            invalid={
                                                                validation.touched.address1 && validation.errors.address1 ? true : false
                                                            }
                                                        />
                                                        {validation.touched.address1 && validation.errors.address1 ? (
                                                            <FormFeedback type="invalid">{validation.errors.address1}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="address2">Address 2</Label>
                                                        <Input
                                                            name="address2"
                                                            className="form-control"
                                                            placeholder="Enter Address 2"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.address2 || ""}
                                                            invalid={
                                                                validation.touched.address2 && validation.errors.address2 ? true : false
                                                            }
                                                        />
                                                        {validation.touched.address2 && validation.errors.address2 ? (
                                                            <FormFeedback type="invalid">{validation.errors.address2}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="city">City</Label>
                                                                <Input
                                                                    name="city"
                                                                    className="form-control"
                                                                    placeholder="Enter City"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.city || ""}
                                                                    invalid={
                                                                        validation.touched.city && validation.errors.city ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.city && validation.errors.city ? (
                                                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="state">State</Label>
                                                                <Input
                                                                    name="state"
                                                                    className="form-control"
                                                                    placeholder="Enter State"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.state || ""}
                                                                    invalid={
                                                                        validation.touched.state && validation.errors.state ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.state && validation.errors.state ? (
                                                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="country">Country</Label>
                                                                <Input
                                                                    name="country"
                                                                    className="form-control"
                                                                    placeholder="Enter State"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.country || ""}
                                                                    invalid={
                                                                        validation.touched.country && validation.errors.country ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.country && validation.errors.country ? (
                                                                    <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="pincode">Pincode</Label>
                                                                <Input
                                                                    name="pincode"
                                                                    className="form-control"
                                                                    placeholder="Enter Pincode"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.pincode || ""}
                                                                    invalid={
                                                                        validation.touched.pincode && validation.errors.pincode ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.pincode && validation.errors.pincode ? (
                                                                    <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>



                                                    <div className="mb-3 select-wrapper">
                                                        <Label htmlFor="email">Timezone</Label>
                                                        <TimezoneSelect
                                                            value={selectedTimezone}
                                                            onChange={setSelectedTimezone}
                                                        />
                                                    </div>



                                                    <div>
                                                        <button type="submit" className="btn btn-primary w-md me-2" >
                                                            {"Update"}
                                                        </button>
                                                        <button type="submit" className="btn btn-danger w-md" onClick={() => {
                                                                closeEditors()
                                                            }}>
                                                                {"Cancel"}
                                                            </button>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Collapse>
                                    }

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ListOfLocations;
