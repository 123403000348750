import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form, Modal, CardTitle, InputGroup
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";

import avatar from "../../../assets/images/users/avatar-1.jpg";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../../assets/images/users/avatar-2.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../../store/actions";
import { changePassword, resetUserResponseError, updateUserProfile } from "../../../toolkitStore/manageUserSlice"

import CountryCode from "../components/CountryCodes.json"


const ManageUserProfile = () => {

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { userList, isSuccessfullyAdded, responseUserError } = useSelector(state => state.manageUserReducer)

  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [modal_pwdreset, setmodal_pwdreset] = useState(false);
  const [resetError, setResetError] = useState("")

  const [editUser, setEditUser] = useState(false)

  const [buError, setBuError] = useState(null)
  const [desgError, setDesgError] = useState(null)

  const [mobile_ccode, setMobileCcode] = useState("+91")
  const [wp_ccode, setWPCcode] = useState("+91")
  const [an_ccode, setANCcode] = useState("+91")


  const dispatch = useDispatch();

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setInfo(obj)
    setMobileCcode(obj.mobile_ccode)
    setWPCcode(obj.wp_ccode)
    setANCcode(obj.mobile_alt_ccode)
    const cConfig = JSON.parse(localStorage.getItem("company-config"));
    setCmpConfig(cConfig)
  }, [])

  useEffect(() => {
    if (responseUserError || resetError !== "") {
      setResetError("")
    }
    else {
      setmodal_pwdreset(false);
      validationPWD.resetForm({ values: "" })
    }

  }, [responseUserError])


  function tog_pwdreset() {
    responseUserError && !modal_pwdreset && dispatch(resetUserResponseError())
    !modal_pwdreset && validationPWD.resetForm({ values: "" })
    setmodal_pwdreset(!modal_pwdreset);
    removeBodyCss();
  }

  useEffect(() => {
    validationPWD.resetForm({ values: "" })
  }, [])


  useEffect(() => {
    if(isSuccessfullyAdded)
    {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setInfo(obj)
    setMobileCcode(obj.mobile_ccode)
    setWPCcode(obj.wp_ccode)
    setANCcode(obj.mobile_alt_ccode)

    }

  }, [isSuccessfullyAdded])

  



  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const validationPWD = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please Enter Your Old Password"),
      password: Yup.string().required("Please Enter Your New Password"),
      confirm_password: Yup.string().required("Please Enter Your Confirm Password"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values.password, values.confirm_password)
      if (values.password === values.confirm_password) {
        setResetError("")
        dispatch(changePassword(userInformation._id, values))
      }
      else {
        // dispatch(resetUserResponseError())
        setResetError("Password and Confirm password not matched")
        return
      }
    }
  });



  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: userInformation.first_name || '',
      last_name: userInformation.last_name || "",
      work_phone: userInformation.work_phone || "",
      mobile: userInformation.mobile || "",
      mobile_altnumber: userInformation.mobile_altnumber || "",
      email_id: userInformation.email_id || "",
      designation: userInformation.designation || "",
      emp_code: userInformation.emp_code || "",
      username: userInformation.username || "",
      password: userInformation.password || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First name"),
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
      mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      work_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      mobile_altnumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    }),
    onSubmit: (values, { resetForm }) => {

      // var getmcc = _.filter(CountryCode, {dial_code:mobile_ccode})[0]
      // var getwpcc = _.filter(CountryCode, {dial_code:wp_ccode})[0]
      // var getancc = _.filter(CountryCode, {dial_code:an_ccode})[0]

      values["mobile_ccode"] = mobile_ccode
      values["wp_ccode"] = wp_ccode
      values["mobile_alt_ccode"] = an_ccode

      dispatch(updateUserProfile(values, userInformation._id))

    }
  });





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="Skote" breadcrumbItem="Profile" /> */}

          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div style={{ width: 150, height: 150, backgroundColor: "gray" }}>
                      {/* <img
                        src={avatar5}
                        alt=""
                        width="150"
                        className="rounded img-thumbnail"
                      /> */}
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3 ">
                      <div className="text-muted">
                        <h1 className="display-6 text-primary" style={{ lineHeight: 0.8, fontSize: 25 }}>{userInformation.first_name} {userInformation.last_name} | <span className="font-size-20">{userInformation.emp_code}</span> </h1>
                        <h1 className="display-6 font-size-20" style={{ lineHeight: 0.7, marginBottom: 10 }}>{userInformation.designation}</h1>
                        <div className="mb-1 " style={{ lineHeight: 0.7 }}>{userInformation.business_unit_name} {"Bussiness unit 1"}</div>
                        <div>
                          <button className="btn btn-sm btn-primary mt-2" onClick={() => {
                            tog_pwdreset()
                          }}>Change Password</button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <div className="mb-1">Work : <span className="fw-bold">{userInformation.mobile_ccode} {userInformation.work_phone}</span></div>
                        <div className="mb-1">Mobile : <span className="fw-bold">{userInformation.wp_ccode} {userInformation.mobile}</span></div>
                        <div className="mb-1">Alternate Number : <span className="fw-bold">{userInformation.mobile_alt_ccode} {userInformation.mobile_altnumber}</span></div>
                        <div className="mb-1">Email id : <span className="fw-bold">{userInformation.email_id}</span></div>
                      </div>
                    </div>
                    <div className="flex-grow-1 align-self-center ms-1">
                      <div className="text-muted">
                        <h1 className="display-6 text-primary" style={{ lineHeight: 0.8 }}><span><i className="mdi mdi-account-edit ms-2  font-size-23 " onClick={() => {
                          setEditUser(!editUser)
                        }} /></span></h1>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {
            editUser &&
            <Card>
              <CardBody>
                <CardTitle>Edit Profile</CardTitle>
                <Form
                  className="form-horizontal"
                  autoComplete="new-password"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >

                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="first_name">First Name</Label>
                        <Input
                          name="first_name"
                          className="form-control"
                          placeholder="Enter First Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          required
                          invalid={
                            validation.touched.first_name && validation.errors.first_name ? true : false
                          }
                        />
                        {validation.touched.first_name && validation.errors.first_name ? (
                          <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="last_name">Last Name</Label>
                        <Input
                          name="last_name"
                          className="form-control"
                          placeholder="Enter Last Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name && validation.errors.last_name ? true : false
                          }
                        />
                        {validation.touched.last_name && validation.errors.last_name ? (
                          <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="emp_code">Employee code</Label>
                        <Input
                          name="emp_code"
                          className="form-control"
                          placeholder="Enter Emp code"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.emp_code || ""}
                          required
                          invalid={
                            validation.touched.emp_code && validation.errors.emp_code ? true : false
                          }
                        />
                        {validation.touched.emp_code && validation.errors.emp_code ? (
                          <FormFeedback type="invalid">{validation.errors.emp_code}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>



                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="mobile">Mobile</Label>
                        <InputGroup >
                          <div className="">
                            <select className="form-select" onChange={(e) => {
                              setDesgError(null)
                              setMobileCcode(e.target.value)
                            }}
                              required
                              defaultValue={mobile_ccode}
                            >
                              <option value="0" disabled >Choose...</option>
                              {
                                CountryCode.map((item, idx) => {
                                  return (
                                    <option key={"cc" + idx} value={item.dial_code}>{item.dial_code} {item.code}</option>
                                  )
                                })
                              }

                            </select>
                          </div>
                          <Input
                            name="mobile"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            type="text"
                            maxLength={"10"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobile || ""}
                            required
                            invalid={
                              validation.touched.mobile && validation.errors.mobile ? true : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.mobile && validation.errors.mobile ? (
                          <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="work_phone">Work Phone</Label>
                        <InputGroup >
                          <div className="">
                            <select className="form-select" onChange={(e) => {
                              setDesgError(null)
                              setWPCcode(e.target.value)
                            }}
                              required
                              defaultValue={wp_ccode}
                            >
                              <option value="0" disabled >Choose...</option>
                              {
                                CountryCode.map((item, idx) => {
                                  return (
                                    <option key={"cc" + idx} value={item.dial_code} >{item.dial_code} {item.code}</option>
                                  )
                                })
                              }

                            </select>
                          </div>
                          <Input
                            name="work_phone"
                            className="form-control"
                            placeholder="Enter Work Phone"
                            type="text"
                            maxLength={"10"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.work_phone || ""}
                            invalid={
                              validation.touched.work_phone && validation.errors.work_phone ? true : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.work_phone && validation.errors.work_phone ? (
                          <FormFeedback type="invalid">{validation.errors.work_phone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="mobile_altnumber">Alternate Number</Label>
                        <InputGroup >
                          <div className="">
                            <select className="form-select" onChange={(e) => {
                              setDesgError(null)
                              setANCcode(e.target.value)
                            }}
                              required
                              defaultValue={an_ccode}
                            >
                              <option value="0" disabled >Choose...</option>
                              {
                                CountryCode.map((item, idx) => {
                                  return (
                                    <option key={"cc" + idx} value={item.dial_code} >{item.dial_code} {item.code}</option>
                                  )
                                })
                              }

                            </select>
                          </div>
                          <Input
                            name="mobile_altnumber"
                            className="form-control"
                            placeholder="Enter Alternate Number"
                            type="text"
                            maxLength={"10"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobile_altnumber || ""}
                            invalid={
                              validation.touched.mobile_altnumber && validation.errors.mobile_altnumber ? true : false
                            }
                          />
                        </InputGroup>
                        {validation.touched.mobile_altnumber && validation.errors.mobile_altnumber ? (
                          <FormFeedback type="invalid">{validation.errors.mobile_altnumber}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>




                  <div className="d-flex flex-column align-items-end">
                    {responseUserError && <label className="font-size-10 text-danger">{responseUserError}</label>}
                    <div>
                      <button type="submit" className="btn btn-danger w-md me-2" onClick={() => {
                        setEditUser(!editUser)
                      }}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary w-md" >
                        Update
                      </button>
                    </div>
                  </div>
                </Form>

              </CardBody>
            </Card>
          }



          <Modal
            isOpen={modal_pwdreset}
            toggle={() => {
              tog_pwdreset();
            }}
            backdrop={'static'}
            id="staticBackdrop1"
          >
            <Form
              className="form-horizontal"
              autoComplete="new-password"
              onSubmit={(e) => {
                e.preventDefault();
                validationPWD.handleSubmit();
                return false;
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Change Password</h5>
                <button type="button" className="btn-close"
                  onClick={() => {
                    setmodal_pwdreset(false);
                  }} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Row>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="old_password">Old Password</Label>
                      <Input
                        name="old_password"
                        className="form-control"
                        placeholder="Enter Old Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={validationPWD.handleChange}
                        onBlur={validationPWD.handleBlur}
                        value={validationPWD.values.old_password || ""}
                        required
                        invalid={
                          validationPWD.touched.old_password && validationPWD.errors.old_password ? true : false
                        }
                      />
                      {validationPWD.touched.old_password && validationPWD.errors.old_password ? (
                        <FormFeedback type="invalid">{validationPWD.errors.old_password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="password">New Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter New Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={validationPWD.handleChange}
                        onBlur={validationPWD.handleBlur}
                        value={validationPWD.values.password || ""}
                        required
                        invalid={
                          validationPWD.touched.password && validationPWD.errors.password ? true : false
                        }
                      />
                      {validationPWD.touched.password && validationPWD.errors.password ? (
                        <FormFeedback type="invalid">{validationPWD.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="confirm_password">Confirm Password</Label>
                      <Input
                        name="confirm_password"
                        className="form-control"
                        placeholder="Enter confirm password"
                        type="password"
                        autoComplete="new-password"
                        onChange={validationPWD.handleChange}
                        onBlur={validationPWD.handleBlur}
                        value={validationPWD.values.confirm_password || ""}
                        required
                        invalid={
                          validationPWD.touched.confirm_password && validationPWD.errors.confirm_password ? true : false
                        }
                      />
                      {validationPWD.touched.confirm_password && validationPWD.errors.confirm_password ? (
                        <FormFeedback type="invalid">{validationPWD.errors.confirm_password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {resetError && <label className="text-danger font-size-12">{resetError}</label>}
                  {responseUserError && <label className="text-danger font-size-12">{responseUserError}</label>}
                </Row>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={() => {
                  setmodal_pwdreset(false);
                }}>Close</button>
                <button type="submit" className="btn btn-danger" >Change Password</button>
              </div>
            </Form>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ManageUserProfile);
