import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    deliverableList: null,
    deliverableAutoGenId:"",
    isDeliverableSuccessfullyAdded:false,
};

const manageDeliverableSlice = createSlice({
    name: "manageDeliverable",
    initialState: initialRegState,
    reducers: {

        setDeliverableList: (state, action) => {
            state.deliverableList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        setDeliverableSuccessfullyAdded:(state, action) => {
            state.isDeliverableSuccessfullyAdded = true
        },
        resetDeliverableSuccessfullyAdded:(state, action) => {
            state.isDeliverableSuccessfullyAdded = false
        },
        resetDeliverableList:(state, action) => {
            state.deliverableList = null
        },
        setDeliverableAutoGenID: (state, action) => {
            state.deliverableAutoGenId = action.payload[0].deliverable_generated_id
        },
        resetDeliverableAutogenID:(state, action) => {
            state.deliverableAutoGenId = ""
        }
        
    }

})

export const createDeliverable = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetDeliverableSuccessfullyAdded())
            const responseData = await post("/manage_deliverable/add-deliverable", {
                "deliverableInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setDeliverableSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterDeliverable = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetDeliverableSuccessfullyAdded())
            const responseData = await post("/manage_contact/update-deliverable", {
                "deliverableInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setDeliverableSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchDeliverables = (company_id, customer_id, location_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_deliverable/get-deliverables", {
                "company_id":company_id,
                "customer_id":customer_id,
            })
            console.log("get contacts", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setDeliverableList(responseData.data))
                }
                else
                {
                    dispatch(resetDeliverableList())
                }
                
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterDeliverable = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetDeliverableSuccessfullyAdded())
            const responseData = await post("/manage_deliverable/delete-deliverable", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setDeliverableSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const getDeliverAutogenId = (id) => {
    return async (dispatch) => {
        try {
            const responseData = await get("/manage_deliverable/get-deliverableautogenid", {
                "ID":id
            })
            console.log("auto gen ids", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setDeliverableAutoGenID(responseData.data))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { setDeliverableList, resetDeliverableList, setDeliverableSuccessfullyAdded, resetDeliverableSuccessfullyAdded, setDeliverableAutoGenID, resetDeliverableAutogenID} = manageDeliverableSlice.actions;

export default manageDeliverableSlice.reducer;
