import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import PBlogosvgWhite from "../../assets/images/PBLogo_white.png"

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import { withTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { getConfig } from "../../toolkitStore/authenticateSlice"
import { searchCustomer, resetCustomerData, resetServiceContractList } from "../../toolkitStore/dashboardSlice"

const Header = props => {
  const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);

  const dispatch = useDispatch()

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { customerInfo, isSuccessfullyAdded } = useSelector(state => state.dashboardReducer)
  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [searchInfo, setSearchInfo] = useState("")
  const [searchedCustomers, setSearchedCustomers] = useState([])

  const [showListpanel, setShowListpanel] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
    }

  }, [companyConfig])


  useEffect(() => {
    if (!userInformation) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      dispatch(getConfig())
    }
  }, [userInformation])


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const navigateTo = (data) => {
    dispatch(resetServiceContractList())
    dispatch(resetCustomerData())
    setShowListpanel(false)
    setSearchTerm('')
    navigate("/support-dashboard", {
      state:{
        "info":data
      }
    })
   
  }

  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if(searchTerm.length > 0)
    {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm)
      var values = {}
      values["customer_name"] = searchTerm
      values["m_company_id"] = userInformation.m_company_id
      dispatch(searchCustomer(values))
      // Send Axios request here
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }
  }, [searchTerm])

  useEffect(()=>{
    console.log("customerInfo", customerInfo)
    if(customerInfo !== null && customerInfo !== undefined)
    {
      setSearchedCustomers([...customerInfo])
    }

  },[customerInfo])

  const searchFieldValidation = (text) => {
    if(text.length > 0)
    {
      setShowListpanel(true)
    }
    else
    {
      setShowListpanel(false)
    }
  }



  const searchValidate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      search_customer:""
    },
    validationSchema: Yup.object({
      //customer_name: Yup.string().required("Please Enter Your impact name"),
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, { resetForm }) => {

      values["customer_name"] = values.search_customer
      values["m_company_id"] = userInformation.m_company_id
      dispatch(searchCustomer(values))
      console.log("Values", values)

    }
  });
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={PBlogosvgWhite} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={PBlogosvgWhite} alt="" height="25" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={PBlogosvgWhite} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={PBlogosvgWhite} alt="" height="19" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <form className="app-search d-none d-lg-block">
            <div className="position-relative">
                <input
                  type="text"
                  name="search_customer"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                  //onChange={searchValidate.handleChange}
                  onChange={(e)=> {
                    searchFieldValidation(e.target.value)
                    setSearchTerm(e.target.value)
                  }}
                  // onBlur={(e) => searchFieldValidation(e.target.value)}
                  value={searchTerm}
                  style={{width:400, marginTop:5}}
                  // required
                  // invalid={
                  //   searchValidate.touched.search_customer && searchValidate.errors.search_customer ? true : false
                  // }

                />
                <span className="bx bx-search-alt" />
                {/* {
                  showListpanel && <div style={{ position:"absolute", top:45, height:300, width:"100%", overflow:"auto", background:"#fff", border:"1px solid #dedede"}}>
                  <ul className='col' style={{position:"relative", padding:"0px 10px", width:"100%"}}>
                    {
                      searchedCustomers && searchedCustomers.map((item, idx) => {
                        return (
                          <li key={"srch" + idx} style={{ padding: "7px 10px", listStyle:"none", width:"100%", borderBottom: "1px solid #dedede", cursor:"pointer" }} 
                          onClick={() => {
                            navigateTo(item)
                          }}
                          >
                            <div style={{ width:"100%"}}>{item.customer_name}</div>
                          </li>
                        )
                      })
                    }

                  </ul>
                </div>
                } */}

                {
                  showListpanel && <div style={{ position:"absolute", top:45, height:300, width:"100%", overflow:"auto", background:"#fff", border:"1px solid #dedede"}}>
                  <SimpleBar style={{ height: "230px" }}>
                  {
                      searchedCustomers && searchedCustomers.map((item, idx) => {
                        return(
                          <div  className="text-reset notification-item" key={"srch" + idx} style={{cursor:"pointer"}} onClick={() => {
                            navigateTo(item)
                          }}>
                            <div className="d-flex">
                             
                              <div className="flex-grow-1">
                                <h6 className="mt-0 mb-1">
                                {item.customer_name}
                                </h6>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </SimpleBar>
                  </div>
                }
                
              </div>
            </form>

          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>



            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/* <div className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  navigate("/chat")
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-chat" />
              </button>
            </div> */}

            <NotificationDropdown />

            <ProfileMenu />

            {/* <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  props.showRightSidebarAction(!props.showRightSidebar);
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
