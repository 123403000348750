import React from "react"
import { Row, Col, Card } from "reactstrap"

//Import Images
import profileImg from "../../assets/images/profile-img.png"

function CardWelcome(props) {
  return (
    <React.Fragment>
      <Col lg="4">
      <Card className="bg-info text-white  border border-info" style={{height:140, marginBottom:4}}>
        <div>
          <Row>
            <Col lg="12">
              <div className=" p-3">
                  <div className="col" style={{ fontSize: 13 }}>
                    <div style={{marginTop:5}}>{props.cstrInfo.address1}, </div>
                    <div>{props.cstrInfo.address2}, {props.cstrInfo.city},</div>
                    <div>{props.cstrInfo.state}, {props.cstrInfo.country}, {props.cstrInfo.pincode}</div>
                    <div style={{marginTop:5}}>{props.cstrInfo.phone}, <a href={"http://"+props.cstrInfo.website} style={{color:"#fff"}} rel="noreferrer" target="_blank">{props.cstrInfo.website}</a></div>
                  </div>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      </Col>
  </React.Fragment>
  );
}

export default CardWelcome;