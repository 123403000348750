import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { formate } from "date-fns"
import {
    Container,
    Row,
    Col,
    CardText,
    Label,
    Form,
    Input,
    FormFeedback,
    FormGroup,
    Card,
    CardBody,
    CardTitle,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledTooltip,
    Badge,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    ButtonDropdown,
    Button,
    Modal


} from "reactstrap"

// import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    fetchConsumptionsAsync,
    createConsumptionAsync,
    updateConsumptionAsync
} from 'toolkitStore/cloudConsumptionSlice'; // Update the path accordingly

import {
    fetchCustomers
} from "toolkitStore/manageCustomerSlice";

import {
    fetchCustomerSubscriptions,
    fetchCustomerConsumptions,
    updateCustomerConsumptions,
    fetchCustomerMonthlyConsumption,
    dataExpandCollapse
} from "toolkitStore/manageSubscriptionSlice"


import {
    fetchVendors
} from 'toolkitStore/manageVendorSlice';

import CRUDConsumptions from './Component/CRUDCustomerConsumption'
import CRUDVendorReconsolation from "./Component/CRUDVendorReconsolation"
import TableContainer from './Component/TableContainer';
import moment from 'moment';
import _ from "lodash"

import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


const SubscriptionUpdate = () => {

    const { customerConsumptions, customerConsumptionMonthlyList } = useSelector(state => state.manageSubscriptionReducer)


    const dispatch = useDispatch();

    const [userInformation, setInfo] = useState("");
    const [cmpConfig, setCmpConfig] = useState("");
    const [selectedSubscription, setSelectedSubscription] = useState()
    const [selectedConsumption, setSelectedConsumption] = useState()
    const [isRight, setIsRight] = useState(false);
    const [formMode, setFormMode] = useState("new")
    const [pageIs, setPageIs] = useState(null)
    const [isComponent, setComponent] = useState(null)
    const [btnprimary1, setBtnprimary1] = useState(false)

    const [defaultDM, setDefaultDM] = useState(null)

    const [isCustomerDetailExpand, setIsCustomerDetailExpand] = useState(false)
    const [isVendorDetailExpand, setIsVendorDetailExpand] = useState(false)
    const [modal_fullscreen, setmodal_fullscreen] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileContent, setFileContent] = useState(null)
    const [scale, setScale] = useState(1.0);




    function tog_fullscreen() {
        console.log("modal_fullscreen", modal_fullscreen)
        setmodal_fullscreen(!modal_fullscreen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }



    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const nextPage = () => {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
    };

    const prevPage = () => {
        setPageNumber(prevPageNumber => prevPageNumber - 1);
    };

    const zoomIn = () => {
        setScale(prevScale => prevScale + 0.25);
    };

    const zoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.25, 0.25));
    };




    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setInfo(obj);
        const cConfig = JSON.parse(localStorage.getItem("company-config"));
        setCmpConfig(cConfig);

        var check = moment(new Date(), 'YYYY/MM/DD');

        var month = check.format('MM');
        var day = check.format('D');
        var year = check.format('YYYY');

        var defaultValue = year + "-" + month

        setDefaultDM(defaultValue)


        // const info = JSON.parse(localStorage.getItem("selectedProduct"));
        // setSelectedSubscription(info)


    }, []);


    useEffect(() => {
        if (userInformation) {
            var check = moment(new Date(), 'YYYY/MM/DD');

            var month = check.format('MMMM');
            var day = check.format('D');
            var year = check.format('YYYY');
            var subscriptionInfo = {
                m_company_id: userInformation.m_company_id,
                month,
                year,
            }
            dispatch(fetchCustomerMonthlyConsumption(subscriptionInfo));
        }
    }, [userInformation])

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
        reloadPage()
    };

    const closeOffCanvas = () => {
        toggleRightCanvas();
    }

    const reloadPage = () => {
        var check = moment(defaultDM, 'YYYY/MM');
        var month = check.format('MMMM');
        var year = check.format('YYYY');

        console.log(year + " : " + month);
        var subscriptionInfo = {
            m_company_id: userInformation.m_company_id,
            month,
            year,
        }
        dispatch(fetchCustomerMonthlyConsumption(subscriptionInfo));
        setIsRight(false)
    }

    useEffect(() => {
        var check = moment(defaultDM, 'YYYY/MM');
        var month = check.format('MMMM');
        var year = check.format('YYYY');

        console.log(year + " : " + month);
        var subscriptionInfo = {
            m_company_id: userInformation.m_company_id,
            month,
            year,
        }
        dispatch(fetchCustomerMonthlyConsumption(subscriptionInfo));
        setIsRight(false)
    }, [defaultDM])

    const loadMonthData = (event) => {


        setDefaultDM(event.target.value)

        var check = moment(event.target.value, "YYYY/MM")

        var month = check.format('MMMM');
        var year = check.format('YYYY');

        var subscriptionInfo = {
            m_company_id: userInformation.m_company_id,
            month,
            year,
        }

        dispatch(fetchCustomerMonthlyConsumption(subscriptionInfo));
        setIsRight(false)


    }



    const removeCustomerFile = (selectedConsumption, file) => {
        var findIndex = _.findIndex(selectedConsumption.customer_attachments, { "fileName": file.fileName })
        if (findIndex !== -1) {
            let consumption = { ...selectedConsumption }
            let newArr = [...selectedConsumption.customer_attachments]
            newArr.splice(findIndex, 1)
            consumption.customer_attachments = [...newArr]
            dispatch(updateCustomerConsumptions(consumption))

            setTimeout(() => {
                reloadPage()
            }, 50);
        }
    }

    const removeVendorFile = (selectedConsumption, file) => {
        var findIndex = _.findIndex(selectedConsumption.vendor_attachments, { "fileName": file.fileName })
        if (findIndex !== -1) {
            let consumption = { ...selectedConsumption }
            let newArr = [...selectedConsumption.vendor_attachments]
            newArr.splice(findIndex, 1)
            consumption.vendor_attachments = [...newArr]
            dispatch(updateCustomerConsumptions(consumption))

            setTimeout(() => {
                reloadPage()
            }, 50);
        }
    }

    const incDecMonth = (action) => {

        var check = action === "inc" ? moment(defaultDM, 'YYYY/MM').add(1, "M") : moment(defaultDM, 'YYYY/MM').add(-1, "M");

        var month = check.format('MM');
        var year = check.format('YYYY');

        console.log(year + "-" + month)

        var defaultValue = year + "-" + month
        setDefaultDM(defaultValue)


        // 
    }




    const columns = useMemo(
        () => [
            {
                Header: 'Customer',
                accessor: 'customer_name',
                disableFilters: true,
                showColumn:true,
                Cell: (cellProps) => {
                    return (
                        <div className='d-flex flex-column'>
                            <div className='mb-2'>
                                <div style={{ fontSize: 13, fontWeight: "600" }} className='text-dark'>{cellProps.row.original.customer_name}</div>
                            </div>
                            <div className='mb-2 d-flex flex-row'>
                                <div className='me-3'>
                                    <div style={{ fontSize: 13, }} className='text-primary'>Consumption Month</div>
                                    <div style={{ fontSize: 13, }} className='text-dark mb-2'>{cellProps.row.original.consumption_month} {cellProps.row.original.consumption_year}</div>
                                </div>
                                <div>
                                    <div style={{ fontSize: 13, }} className='text-primary'>Billing Month</div>
                                    <div style={{ fontSize: 13, }} className='text-dark'>{cellProps.row.original.billing_month} {cellProps.row.original.billing_year}</div>
                                </div>
                            </div>
                            {
                                cellProps.row.original.is_expand && <>

                                    <div className='mb-2'>
                                        <div style={{ fontSize: 13, }} className='text-primary'>Service</div>
                                        <div style={{ fontSize: 13, }} className='text-dark mb-2'>{cellProps.row.original.service_name}</div>
                                        <div style={{ fontSize: 13, }} className='text-primary'>Subscription</div>
                                        <div style={{ fontSize: 13, }} className='text-dark'>{cellProps.row.original.subscription_plan_name}</div>
                                    </div>
                                </>
                            }

                        </div>
                    )
                }
            },
            {
                Header: 'Vendor / Provider',
                accessor: 'provider',
                disableFilters: true,
                showColumn:true,
                Cell: (cellProps) => {
                    return (
                        <div className='d-flex flex-column'>
                            <div className='mb-2 d-flex flex-row'>
                                <div className='me-3'>
                                    <div style={{ fontSize: 13, }} className='text-primary'>Vendor</div>
                                    <div style={{ fontSize: 13, }} className='text-dark mb-2'>{cellProps.row.original.vendor}</div>
                                </div>
                                <div>
                                    <div style={{ fontSize: 13, }} className='text-primary'>Provider</div>
                                    <div style={{ fontSize: 13, }} className='text-dark'>{cellProps.row.original.provider}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'Consumption details',
                accessor: 'consumption_value',
                disableFilters: true,
                showColumn:true,
                Cell: (cellProps) => {
                    var consumptionAttachments = _.filter(cellProps.row.original.customer_attachments, { "related_to": "Consumption Report" })
                    return (
                        <div>
                            {
                                cellProps.row.original.consumption_value ?
                                    <>
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <div className='d-flex flex-row'>
                                                <div className='d-flex flex-column me-3'>
                                                    <div className='font-size-13 text-primary'>Consumption Value</div>
                                                    <div className="" style={{ fontSize: 18 }}>{cellProps.row.original.consumption_value ? "Rs. " + cellProps.row.original.consumption_value : "N/A"}</div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-row me-3'>
                                                {cellProps.row.original.customer_attachments && consumptionAttachments.length > 0 && <div className='me-2'>
                                                    <UncontrolledDropdown className="mb-0">
                                                        <DropdownToggle
                                                            className="btn btn-link text-muted mt-n2"
                                                            tag="a"
                                                        >
                                                            <i className='mdi mdi-paperclip font-size-20' />
                                                        </DropdownToggle>

                                                        <DropdownMenu className="dropdown-menu-end p-2" style={{ maxHeight: 250, overflowY: "auto" }} >
                                                            <div className="p-3" style={{ borderBottom: "1px solid #dedede" }}>
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <h6 className="m-0 text-primary"> {"Attachments"} </h6>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {
                                                                consumptionAttachments.map((item, index) => {
                                                                    return <DropdownItem href="#" key={index} style={{ whiteSpace: "pre-wrap", borderBottom: "1px solid #dedede" }} onClick={() => {
                                                                        setFileContent(item);
                                                                        tog_fullscreen()
                                                                    }}>
                                                                        <div className='d-flex flex-row justify-content-between align-items-center py-2' style={{ width: 250 }}>
                                                                            <div style={{ width: 200, fontSize: 13 }}>{item.originalname}</div>
                                                                            {/* <div onClick={() => {
                                                                                removeCustomerFile(cellProps.row.original, item)
                                                                            }}><i className='mdi mdi-delete font-size-20 text-danger' /></div> */}
                                                                        </div>
                                                                        <div className='text-secondary font-size-13'>{item.related_to}</div>

                                                                    </DropdownItem>
                                                                })
                                                            }

                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                                }
                                                <Link
                                                    to="#!"
                                                    className="text-warning"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        const data = cellProps.row.original;
                                                        setSelectedConsumption(data)
                                                        setFormMode("edit")
                                                        setComponent("Consumption")
                                                        setIsRight(true)
                                                    }}
                                                >
                                                    <i className='mdi mdi-pencil-outline font-size-20' />
                                                </Link>
                                            </div>
                                        </div>

                                        {
                                            cellProps.row.original.is_expand && <>
                                                <div className='mb-2'>
                                                    <div style={{ fontSize: 13, }} className='text-secondary'>Consumption </div>
                                                    <div style={{ fontSize: 13, fontWeight: "600" }}>From: {moment(cellProps.row.original.consumption_start_date).format("DD/MM/YYYY")} To: {moment(cellProps.row.original.consumption_end_date).format("DD/MM/YYYY")}</div>
                                                </div>

                                                <div className="" style={{ fontSize: 14, }}>Derived Margin: {cellProps.row.original.consumption_margin ? cellProps.row.original.consumption_margin : "N/A"}</div>
                                                <div className="mb-1">Discount: {cellProps.row.original.derived_discount !== null ? cellProps.row.original.derived_discount : "N/A"}</div>



                                                <div className="mb-1">Payment: {cellProps.row.original.customer_payment ? cellProps.row.original.customer_payment : "N/A"}</div>
                                            </>
                                        }



                                    </> : <Link
                                        to="#!"
                                        className="btn btn-sm btn-warning"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const data = cellProps.row.original;
                                            setSelectedConsumption(data)
                                            setFormMode("edit")
                                            setComponent("Consumption")
                                            setIsRight(true)
                                        }}
                                    >
                                        Edit
                                    </Link>
                            }
                        </div>
                    )
                }
            },
            {
                Header: 'Billing details',
                accessor: 'vendor_cost',
                disableFilters: true,
                showColumn:userInformation.role === "admin" || userInformation.role === "SuperAdmin"  ? true : false ,
                Cell: (cellProps) => {
                    var vndAttachments = cellProps.row.original.vendor_attachments
                    var csrAttachments = _.filter(cellProps.row.original.customer_attachments, { "related_to": "Customer Invoice" })
                    var newArray = [...vndAttachments, ...csrAttachments]

                    var VM = cellProps.row.original.vendor_margin ? cellProps.row.original.vendor_margin : "N/A"


                    return (
                        <div>
                            {
                                cellProps.row.original.vendor_cost ?
                                    <>
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <div className='d-flex flex-row'>
                                                <div className='d-flex flex-column me-3'>
                                                    <div className='font-size-13 text-primary'>Customer Value</div>
                                                    <div className="" style={{ fontSize: 18 }}>{cellProps.row.original.customer_invoice_value ? "Rs. " + cellProps.row.original.customer_invoice_value : "N/A"}</div>
                                                </div>
                                                <div className='d-flex flex-column me-3'>
                                                    <div className='font-size-13 text-primary'>Vendor Value</div>
                                                    <div className="" style={{ fontSize: 18 }}>{cellProps.row.original.vendor_cost ? "Rs. " + cellProps.row.original.vendor_cost : "N/A"}</div>
                                                </div>

                                            </div>
                                            <div className='d-flex flex-row'>
                                                {newArray && newArray.length > 0 && <div className='me-2'>
                                                    <UncontrolledDropdown className="mb-0">
                                                        <DropdownToggle
                                                            className="btn btn-link text-muted mt-n2"
                                                            tag="a"
                                                        >
                                                            <i className='mdi mdi-paperclip font-size-20' />
                                                        </DropdownToggle>

                                                        <DropdownMenu className="dropdown-menu-end p-2" style={{ maxHeight: 250, overflowY: "auto" }} >
                                                            <div className="p-3" style={{ borderBottom: "1px solid #dedede" }}>
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <h6 className="m-0 text-primary"> {"Attachments"} </h6>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {
                                                                newArray.map((item, index) => {
                                                                    return <DropdownItem href="#" key={index} style={{ whiteSpace: "pre-wrap", borderBottom: "1px solid #dedede" }} onClick={() => {
                                                                        setFileContent(item);
                                                                        tog_fullscreen()
                                                                    }}>
                                                                        <div className='d-flex flex-row justify-content-between align-items-center py-2' style={{ width: 250 }}>
                                                                            <div style={{ width: 200, fontSize: 13 }} >{item.originalname}</div>
                                                                            {/* <div onClick={() => {
                                                                                removeVendorFile(cellProps.row.original, item)
                                                                            }}><i className='mdi mdi-delete font-size-20 text-danger' /></div> */}
                                                                        </div>
                                                                        <div className='text-secondary font-size-13'>{item.related_to}</div>

                                                                    </DropdownItem>
                                                                })
                                                            }

                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                                }
                                                <Link
                                                    to="#!"
                                                    className=" me-2 text-secondary"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        const data = cellProps.row.original;
                                                        setSelectedSubscription(selectedSubscription)
                                                        setSelectedConsumption(data)
                                                        setFormMode("edit")
                                                        setComponent("Resonsolation")
                                                        setIsRight(true)
                                                    }}
                                                >
                                                    <i className='mdi mdi-pencil-outline font-size-20' />
                                                </Link>
                                            </div>
                                        </div>


                                        {
                                            cellProps.row.original.is_expand && <>
                                                <div className='d-flex flex-column mb-2'>
                                                    <div className='text-primary'>Customer</div>
                                                    <div className='d-flex flex-row'>
                                                        <div className='d-flex flex-column me-3'>
                                                            <div className='font-size-13 text-secondary'>Invoice No.</div>
                                                            <div>{cellProps.row.original.customer_invoice_no}</div>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-size-13 text-secondary'>Invoice Date</div>
                                                            <div>{moment(cellProps.row.original.customer_invoice_date).format("DD/MM/YYYY")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column mb-2'>
                                                    <div className='text-primary'>Vendor</div>
                                                    <div className='d-flex flex-row'>
                                                        <div className='d-flex flex-column me-3'>
                                                            <div className='font-size-13 text-secondary'>Invoice No.</div>
                                                            <div>{cellProps.row.original.vendor_invoice_no}</div>
                                                        </div>
                                                        <div className='d-flex flex-column'>
                                                            <div className='font-size-13 text-secondary'>Invoice Date</div>
                                                            <div>{moment(cellProps.row.original.vendor_invoice_date).format("DD/MM/YYYY")}</div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="" style={{ fontSize: 14, }}>Expected Margin: {cellProps.row.original.derived_margin !== null ? (Number(cellProps.row.original.consumption_margin) - Number(cellProps.row.original.derived_discount)) : "N/A"}</div>
                                                <div className="mb-2" style={{}}>Actual Margin: {cellProps.row.original.vendor_margin ? cellProps.row.original.vendor_margin : "N/A"}</div>
                                                <div className="mb-1">Payment: {cellProps.row.original.payment_to_vendor ? cellProps.row.original.payment_to_vendor : "N/A"}</div>
                                            </>
                                        }

                                    </> : <Link
                                        to="#!"
                                        className="btn btn-sm btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const data = cellProps.row.original;
                                            setSelectedSubscription(selectedSubscription)
                                            setSelectedConsumption(data)
                                            setFormMode("edit")
                                            setComponent("Resonsolation")
                                            setIsRight(true)
                                        }}
                                    >
                                        Edit
                                    </Link>
                            }
                        </div>

                    )
                }
            },

            {
                Header: 'Margin',
                accessor: 'vendor_margin',
                disableFilters: true,
                showColumn:userInformation.role === "admin" || userInformation.role === "SuperAdmin"  ? true : false ,
                Cell: (cellProps) => {
                    var VM = cellProps.row.original.vendor_margin ? cellProps.row.original.vendor_margin.toFixed(2) : "N/A"
                    return (
                        <div >
                            <div className='d-flex flex-column'>
                                <div className='font-size-13 text-primary'>Actual Margin</div>
                                <div className={VM < 0 ? "text-danger" : VM === 0 ? "text-dark" : VM > 0 ? "text-success" : VM === "N/A" && "text-dark"} style={{ fontSize: 18 }}>{cellProps.row.original.vendor_margin ? cellProps.row.original.vendor_margin : "N/A"}</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                showColumn:true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div>
                            <div className='' style={{ cursor: "pointer" }} onClick={() => {
                                var data = cellProps.row.original;
                                dispatch(dataExpandCollapse(data))
                            }} ><i className={cellProps.row.original.is_expand ? "mdi mdi-arrow-up-drop-circle-outline font-size-20" : "mdi mdi-arrow-down-drop-circle-outline font-size-20"}></i></div>
                        </div>
                    )
                }
            },

            // {
            //     Header: 'Profit',
            //     accessor: 'profit',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return (
            //             <div>
            //                 {
            //                     cellProps.row.original.vendor_margin ?
            //                         <>
            //                             <div style={{ fontSize: 14, fontWeight: "bold" }}>{cellProps.row.original.vendor_margin}</div>
            //                         </> : "N/A"
            //                 }
            //             </div>
            //         )
            //     }
            // },
            // {
            //     Header: 'Difference',
            //     accessor: 'difference',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         return (
            //             <div>
            //                 {
            //                     cellProps.row.original.vendor_margin ?
            //                         <>
            //                             <div style={{ fontSize: 14, fontWeight: "bold" }}>{cellProps.row.original.vendor_margin - (cellProps.row.original.consumption_margin - cellProps.row.original.derived_discount)}</div>
            //                         </> : "N/A"
            //                 }
            //             </div>
            //         )
            //     }
            // },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //        // console.log("index", cellProps)
            //         return (
            //             <div>
            //                     <div className=''>
            //                     <UncontrolledDropdown className="mb-0">
            //                         <DropdownToggle
            //                             className="btn btn-link text-muted mt-n2"
            //                             tag="a"
            //                         >
            //                             <button className='btn btn-sm btn-secondary' >Draft</button>
            //                         </DropdownToggle>

            //                         <DropdownMenu className="dropdown-menu-end" >
            //                             <DropdownItem href="#" style={{ whiteSpace: "pre-wrap", borderBottom: "1px solid #dedede" }} >Close</DropdownItem>
            //                         </DropdownMenu>
            //                     </UncontrolledDropdown>
            //                 </div> 
            //             </div>
            //         )
            //     }
            // },



        ],
        [selectedSubscription, isVendorDetailExpand, isCustomerDetailExpand, userInformation]
    );






    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row
                        className="justify-content-center"
                    >
                        <Card>
                            <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Consumptions</h5>
                                    <div className='col-auto me-3'>
                                        <div className='d-flex flex-row'>
                                            <label
                                                htmlFor="example-month-input"
                                                className="col-form-label me-2"
                                            >
                                                Billing Month
                                            </label>
                                            <div className='btn btn-outline-secondary me-2 ' onClick={() => incDecMonth("dec")}><i className='mdi mdi-arrow-left' /></div>
                                            <div className="me-2">
                                                <input
                                                    onChange={loadMonthData}
                                                    className="form-control"
                                                    type="month"
                                                    value={defaultDM}
                                                    id="example-month-input"
                                                />
                                            </div>
                                            <div className='btn btn-outline-secondary me-2' onClick={() => incDecMonth("inc")}><i className='mdi mdi-arrow-right' /></div>
                                            <div className="">
                                                <Link to="#!" onClick={() => {
                                                    dispatch(fetchCustomers(userInformation.m_company_id))
                                                }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </CardBody>

                            <CardBody >
                                {
                                    customerConsumptionMonthlyList && customerConsumptionMonthlyList.length > 0 ?
                                        <TableContainer
                                            columns={columns}
                                            data={customerConsumptionMonthlyList}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            customPageSize={20}
                                            customPageSizeOptions={false}
                                            isJobListGlobalFilter={false}
                                            className="custom-header-css"
                                        /> :
                                        <div className='d-flex flex-1 align-items-center justify-content-center' style={{ minHeight: 350 }}>
                                            <div className='font-size-15 text-secondary'>Data not available</div>
                                        </div>
                                }
                            </CardBody>
                        </Card>
                    </Row>
                    <Offcanvas
                        style={{ width: 600, transition: "width .35s ease-in-out", }}
                        isOpen={isRight}
                        backdrop={"static"}
                        direction="end"
                        toggle={() => { toggleRightCanvas() }}>
                        <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>
                            {
                                isComponent === "Consumption" ? "Consumption Details" : "Billing Details"
                            }
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <div className='px-3'>
                                {
                                    isComponent === "Consumption" ?
                                        <CRUDConsumptions
                                            closeOffCanvas={() => { closeOffCanvas() }}
                                            cmpConfig={cmpConfig}
                                            userInformation={userInformation}
                                            selectedConsumption={selectedConsumption}
                                            removeCustomerFile={(row, item) => {
                                                removeCustomerFile(row, item)
                                            }}
                                            formMode={formMode}
                                        /> :
                                        <CRUDVendorReconsolation
                                            closeOffCanvas={() => { closeOffCanvas() }}
                                            cmpConfig={cmpConfig}
                                            userInformation={userInformation}
                                            selectedConsumption={selectedConsumption}
                                            removeCustomerFile={(row, item) => {
                                                removeCustomerFile(row, item)
                                            }}
                                            removeVendorFile={(row, item) => {
                                                removeVendorFile(row, item)
                                            }}
                                            formMode={formMode}
                                        />

                                }
                            </div>
                        </OffcanvasBody>
                    </Offcanvas>

                    <Modal
                        size="xl"
                        isOpen={modal_fullscreen}
                        toggle={() => {
                            tog_fullscreen();
                        }}
                        // className="modal-fullscreen"
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <div>
                                <h5
                                    className="modal-title mt-0"
                                    id="exampleModalFullscreenLabel"
                                >
                                    {fileContent ? fileContent.originalname : ""}
                                </h5>
                                <div className='text-secondary font-size-13'>{fileContent ? fileContent.related_to : ""}</div>

                            </div>
                            <button
                                onClick={() => {
                                    setmodal_fullscreen(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='d-flex justify-content-center bg-secondary p-2'>

                                <Document file={{ url: fileContent ? "https://api.supportHQManager.com/uploads/" + fileContent.fileName : "" }} onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <>
                                            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
                                            <p className='my-3 text-white' style={{ textAlign: "center" }}>
                                                Page {index + 1} of {numPages}
                                            </p>
                                        </>

                                    ))}
                                </Document>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button className='btn btn-primary' onClick={prevPage} disabled={pageNumber <= 1}>Previous</button>
                            <button className='btn btn-primary' onClick={nextPage} disabled={pageNumber >= numPages}>Next</button> */}
                            <button className='btn btn-primary' onClick={zoomIn}>Zoom In</button>
                            <button className='btn btn-primary' onClick={zoomOut}>Zoom Out</button>
                            <button
                                type="button"
                                onClick={() => {
                                    tog_fullscreen();
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>

    )
}

export default SubscriptionUpdate