import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    esculationList: null,
    isEsculationSuccessfullyAdded:false,
};


const manageEsculationSlice = createSlice({
    name: "manageEsculation",
    initialState: initialRegState,
    reducers: {

        setEsculationList: (state, action) => {
            state.esculationList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        setEsculationSuccessfullyAdded:(state, action) => {
            state.isEsculationSuccessfullyAdded = true
        },
        resetEsculationSuccessfullyAdded:(state, action) => {
            state.isEsculationSuccessfullyAdded = false
        },
        resetEsculationList:(state, action) => {
            state.esculationList = null
        },
        
    }

})

export const createEsculation = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetEsculationSuccessfullyAdded())
            const responseData = await post("/manage_esculation/add-esculation", {
                "esculationInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setEsculationSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterEsculation = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetEsculationSuccessfullyAdded())
            const responseData = await post("/manage_esculation/update-esculation", {
                "esculationInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setEsculationSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchEsculations = (company_id, customer_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_esculation/get-esculations", {
                "company_id":company_id,
                "customer_id":customer_id,
            })
            console.log("get esculations", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setEsculationList(responseData.data))
                }
                else
                {
                    dispatch(resetEsculationList())
                }
                
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterEscalation = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetEsculationSuccessfullyAdded())
            const responseData = await post("/manage_esculation/delete-esculation", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setEsculationSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}




export const { setEsculationList, resetEsculationList, setEsculationSuccessfullyAdded, resetEsculationSuccessfullyAdded } = manageEsculationSlice.actions;

export default manageEsculationSlice.reducer;
