import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Container, Row, Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip,
  Modal, FormFeedback
} from "reactstrap";

import Select from "react-select";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import SubServices from "./subServices";
import SubscriptionForm from "./subscriptionForm";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch } from "react-redux";

import { fetchServices, createService, updateService, deleteMasterService } from "../../../toolkitStore/manageServiceSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"


const Services = (props) => {

  const dispatch = useDispatch()

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { serviceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)


  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [selectedService, setSelectedService] = useState("")
  const [selectedServiceID, setSelectedServiceID] = useState("")
  const [mode, setMode] = useState("new")

  const [inScope, setInscope] = useState("")
  const [outScope, setOutscope] = useState("")
  const [prerequisite, setPrerequisite] = useState("")
  const [showSubmenu, setSubmenu] = useState(false)
  const [serviceTree, setServices] = useState([])
  const [serviceType, setServiceType] = useState("0")
  const [serviceLevel, setServiceLevel] = useState("0")

  const [filteredServices, setFilteredServices] = useState(null)

  const [selectedGroup, setselectedGroup] = useState(null)
  const [primaryService, setService] = useState("choose")

  const [subServicemode, setSubServiceMode] = useState("new")
  const [panel, setPanel] = useState("")
  const [thirdlevel, setThirdLevel] = useState([])

  const [open, setOpen] = useState(false);
  const [isRight, setIsRight] = useState(false);

  const [modal_backdrop, setmodal_backdrop] = useState(false);


  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setInfo(obj)
    const cConfig = JSON.parse(localStorage.getItem("company-config"));
    setCmpConfig(cConfig)
    dispatch(fetchServices(obj.m_company_id))

  }, [])


  useEffect(() => {
    if (isSuccessfullyAdded) {
      dispatch(fetchServices(userInformation.m_company_id))
    }
  }, [isSuccessfullyAdded])



  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    setSelectedService("")
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }



  const editService = (UData) => {
    console.log("UData", UData)
    setPanel("mainservice")
    setMode("edit")
    setSelectedService(UData)
    setSelectedServiceID(UData._id)
    setInscope(UData.in_scope)
    setOutscope(UData.out_scope)
    setPrerequisite(UData.prerequisite)
    setServiceType(UData.service_type_id)
    setServiceLevel(UData.service_level_id)
    setService(UData.relative_id ? UData.relative_id : "0")
    setIsRight(!isRight);
  }


  const deleteService = (service) => {

    setSelectedServiceID(service._id)
    tog_backdrop()
  }
  const onConfirmDelete = () => {
    console.log("service", selectedServiceID)
    dispatch(deleteMasterService(selectedServiceID))
    setmodal_backdrop(false);
  }


  const toggleRightCanvas = () => {
    isRight && setSelectedService("")
    isRight && setSelectedServiceID("")
    isRight && setMode("new")
    isRight && setInscope("")
    isRight && setOutscope("")
    isRight && setPrerequisite("")
    isRight && setServiceLevel("0")
    isRight && setServiceType("0")
    setIsRight(!isRight);
    
  };


  // 


  useEffect(() => {

    if (serviceList.length > 0) {
      console.log("service list", serviceList)
      var groupData = _.sortBy(serviceList, ["_id", "relative_id"])
      var sortedData = []
      var thirdLvl = []
      _.map(groupData, function (element) {
        if (element.relative_id === null) {
          sortedData.push(element)
          var getData = _.filter(groupData, { relative_id: element._id })
          _.each(getData, item => {
            sortedData.push(item)
            var getData1 = _.filter(groupData, { relative_id: item._id })
            _.each(getData1, (item2, idx) => {
              // sortedData.push(item2)
              thirdLvl.push(item2)
            })
          })
        }
      });
      setThirdLevel([...thirdLvl])
      sortedData.reverse()
      setServices([...sortedData])


    }

  }, [serviceList])

  useEffect(() => {

    if(serviceType !== "0" && serviceList.length > 0){
    var getPrimaryData = _.filter(serviceList, {"service_type_id":serviceType})
    console.log("getPrimaryData", getPrimaryData, serviceList, serviceType)
    setService("choose")
    setFilteredServices(getPrimaryData)
    }

  },[serviceType, serviceList])


  // const handleChange = (event) => {
  //   const { name, value } = event.target
  //   setUser({ ...user, [name]: value })
  // }
  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    setInscope(data)
  }
  const handleCkeditorOut = (event, editor) => {
    const data = editor.getData()
    setOutscope(data)
  }
  const handleCkeditorPre = (event, editor) => {
    const data = editor.getData()
    setPrerequisite(data)
  }



  const addSubService = (UData) => {
    // setPanel("subservice")
    setSubServiceMode("new")
    setSelectedService(UData)
    setSelectedServiceID(UData._id)
    setIsRight(!isRight);
  }



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedService.name || '',
      short_name: selectedService.short_name || '',
      description: selectedService.description || '',
      remarks: selectedService.remarks || '',
      keywords: selectedService.keywords || '',
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Please Enter service name"),
      // short_name: Yup.string().required("Please Enter service short name"),
    }),
    onSubmit: (values, { resetForm }) => {

      values["in_scope"] = inScope
      values["out_scope"] = outScope
      values["prerequisite"] = prerequisite

      values["m_company_id"] = userInformation.m_company_id
      values["m_b_unit_id"] = userInformation.m_b_unit_id

      var getServiceType = _.filter(cmpConfig.service_type, { id: serviceType })[0]
      values["service_type"] = getServiceType.name
      values["service_type_id"] = getServiceType.id

      var getServiceLevel = _.filter(cmpConfig.service_level, { id: serviceLevel })[0]
      values["service_level"] = getServiceLevel.name
      values["service_level_id"] = getServiceLevel.id

      if (serviceLevel === "2") {
        console.log("primaryService", primaryService)
        values["relative_id"] = primaryService
      }
      values["created_by"] = userInformation._id

      if (mode === "new") {
        dispatch(createService(values))
      }
      else {
        values["_id"] = selectedService._id
        dispatch(updateService(values))
      }
      resetForm({ values: "" })
      toggleRightCanvas()
    }
  });

 

  const columns = useMemo(
    () => [
      {
        Header: 'Service Id',
        accessor: 'company_service_id',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div >{cellProps.row.original.company_service_id}</div>
          )
        }
      },
      {
        Header: 'Type',
        accessor: 'service_type',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div >{cellProps.row.original.service_type}</div>
          )
        }
      },
      {
        Header: 'Service Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex flex-row">
              <div className={
                cellProps.row.original.service_level_id === "1" ? "fw-bold me-2 text-uppercase" :
                  cellProps.row.original.service_level_id === "2" ? "fw-bold ps-3" : "ps-5"}>
                {cellProps.row.original.name}
              </div>
              {
                cellProps.row.original.service_level_id === "1" &&
                <ul className="list-unstyled hstack gap-1 mb-0">
                  <li>
                    <Link
                      to="#"
                      className="btn btn-sm btn-soft-info"
                      onClick={(e) => {
                        e.preventDefault()
                        const SData = cellProps.row.original;
                        editService(SData);
                      }}
                    >
                      <i className="mdi mdi-pencil-outline" id="edittooltip" />
                      <UncontrolledTooltip placement="top" target="edittooltip">
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                </ul>
              }
            </div>
          )
        }
      },
      {
        Header: 'Short Name',
        accessor: 'short_name',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div >{cellProps.row.original.short_name}</div>
          )
        }
      },
      {
        Header: 'Type',
        accessor: 'service_level',
        disableFilters: true,
      },
      // {
      //   Header: 'id',
      //   accessor: '_id',
      //   disableFilters: true,
      // },
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          const SData = cellProps.row.original;
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">

              {
                cellProps.row.original.service_level_id === "2" &&
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-outline-primary"
                    onClick={(e) => {
                      e.preventDefault()
                      setServiceType(SData.service_type_id)
                      setPanel("subservice")
                      addSubService(SData);
                    }}
                  >
                    {SData.service_type_id === "1" ? "Sub Services" : "Subscriptions"}
                  </Link>
                </li>
              }
              {
                cellProps.row.original.service_level_id !== "1" &&
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-info"
                    onClick={(e) => {
                      e.preventDefault()
                      const SData = cellProps.row.original;
                      editService(SData)
                      // cellProps.row.original.service_level_id === "2" ? editService(SData) : editSubService(SData);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }

              {
                cellProps.row.original.service_level_id !== "1" &&
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      const SData = cellProps.row.original;
                      deleteService(SData);
                    }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }

            </ul>
          );
        }
      },

    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

          <Row
            className="justify-content-center"
          >
            <Card>
              <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Services</h5>
                  <div className="flex-shrink-0">
                    <Link to="#!" onClick={() => {
                      setPanel("mainservice")
                      toggleRightCanvas()
                    }} className="btn btn-primary me-1">Add New Service</Link>
                    <Link to="#!" onClick={() => {
                      dispatch(fetchServices(userInformation.m_company_id))
                    }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                  </div>
                </div>

                <Offcanvas
                  style={{ width: 600, transition: "width .35s ease-in-out", }}
                  isOpen={isRight}
                  backdrop={"static"}
                  direction="end"
                  toggle={() => { toggleRightCanvas() }}>
                  <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>
                    {
                      panel === "mainservice" ? "Add / Edit Service" : "Sub Services"
                    }
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    {
                      panel === "mainservice" ?
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

                          <Row>

                          {
                              companyConfig && <div className="mb-4">
                                <label className="" htmlFor="autoSizingSelect">Select Service type</label>
                                <select className="form-select" onChange={(e) => {
                                  setService("choose")
                                  setServiceType(e.target.value)
                                }}
                                  defaultValue={serviceType}
                                >
                                  <option value="0" disabled>Choose...</option>
                                  {
                                    companyConfig.service_type.map((item, idx) => {
                                      return (
                                        <option key={"cnst" + idx} value={item.id}>{item.name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            }

                            {
                              serviceType !== "0" && companyConfig && <div className="mb-4">
                                <label className="" htmlFor="autoSizingSelect">Select Service level</label>
                                <select className="form-select" onChange={(e) => {
                                  setService("choose")
                                  setServiceLevel(e.target.value)
                            
                                }}
                                  defaultValue={serviceLevel}
                                >
                                  <option value="0" disabled>Choose...</option>
                                  {
                                    companyConfig.service_level.map((item, idx) => {
                                      return (
                                        <option key={"cnst" + idx} value={item.id}>{item.name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            }

                            {
                              serviceLevel === "2" && filteredServices && <div className="mb-4">
                                <label className="" htmlFor="autoSizingSelect">Select Primary Service</label>
                                <select className="form-select" onChange={(e) => {
                                  setService(e.target.value)
                                }}
                                  value={primaryService}
                                >
                                  <option value="choose" disabled>Choose...</option>
                                  {
                                    filteredServices.map((item, idx) => {
                                      return (
                                        item.service_level_id === "1" && <option key={"svc" + idx} value={item._id}>{item.name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            }

                            {
                              serviceLevel === "1"  ?
                                <>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="name">Name</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder="Enter Service Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        required
                                        invalid={
                                          validation.touched.name && validation.errors.name ? true : false
                                        }
                                      />
                                      {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                      ) : null
                                      }
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="short_name">Short Name</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="short_name"
                                        placeholder="Enter Short Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.short_name || ""}
                                        required
                                        invalid={
                                          validation.touched.short_name && validation.errors.short_name ? true : false
                                        }
                                      />
                                      {validation.touched.short_name && validation.errors.short_name ? (
                                        <FormFeedback type="invalid">{validation.errors.short_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>

                                  <div className="mb-3">
                                    <Label htmlFor="description">Description</Label>
                                    <Input
                                      type="textarea"
                                      className="form-control"
                                      name="description"
                                      placeholder="Enter description"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.description || ""}
                                      invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                      }
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                  </div>
                                </> : serviceLevel === "2" && primaryService !== "choose" ?
                                  <>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="name">Name</Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          placeholder="Enter Service Name"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.name || ""}
                                          required
                                          invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                          }
                                        />
                                        {validation.touched.name && validation.errors.name ? (
                                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                        ) : null
                                        }
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="short_name">Short Name</Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          name="short_name"
                                          placeholder="Enter Short Name"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.short_name || ""}
                                          required
                                          invalid={
                                            validation.touched.short_name && validation.errors.short_name ? true : false
                                          }
                                        />
                                        {validation.touched.short_name && validation.errors.short_name ? (
                                          <FormFeedback type="invalid">{validation.errors.short_name}</FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>

                                    <div className="mb-3">
                                      <Label htmlFor="description">Description</Label>
                                      <Input
                                        type="textarea"
                                        className="form-control"
                                        name="description"
                                        placeholder="Enter description"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        invalid={
                                          validation.touched.description && validation.errors.description ? true : false
                                        }
                                      />
                                      {validation.touched.description && validation.errors.description ? (
                                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </> : null
                            }


                            {
                              serviceLevel === "2" && primaryService !== "choose" && serviceType === "1" &&
                              <>
                                <Col md={12}>
                                  <div className="mb-3 ">
                                    <Label htmlFor="inl">In scope</Label>
                                    <CKEditor
                                      config={{ placeholder: "Type the In_scope Here..!" }}
                                      editor={ClassicEditor}
                                      data={inScope}
                                      onChange={handleCkeditorIn}
                                      value={inScope}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="out">Out scope</Label>
                                    <CKEditor
                                      config={{ placeholder: "Type the Out_scope Here..!" }}
                                      editor={ClassicEditor}
                                      data={outScope}
                                      onChange={handleCkeditorOut}
                                      value={outScope}
                                    />
                                  </div>
                                </Col>

                                <div className="mb-3">
                                  <Label htmlFor="pre">Prerequisite</Label>
                                  <CKEditor
                                    config={{ placeholder: "Type the Prerequisite Here..!" }}
                                    editor={ClassicEditor}
                                    data={prerequisite}
                                    onChange={handleCkeditorPre}
                                    value={prerequisite}
                                  />
                                </div>
                              </>
                            }

                            {
                              serviceLevel === "1" ?
                                <>
                                  <div className="mb-3">
                                    <Label htmlFor="remarks">Remakrs</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="remarks"
                                      placeholder="Enter remarks"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.remarks || ""}
                                      invalid={
                                        validation.touched.remarks && validation.errors.remarks ? true : false
                                      }
                                    />
                                    {validation.touched.remarks && validation.errors.remarks ? (
                                      <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                    ) : null}
                                  </div>

                                  <div className="mb-3">
                                    <Label htmlFor="keywords">Keywords</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      name="keywords"
                                      placeholder="Enter keywords"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.keywords || ""}
                                      invalid={
                                        validation.touched.remarks && validation.errors.keywords ? true : false
                                      }
                                    />
                                    {validation.touched.keywords && validation.errors.keywords ? (
                                      <FormFeedback type="invalid">{validation.errors.keywords}</FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-5">
                                    <button type="submit" className="btn btn-primary w-md">
                                      {/* {bu_data !== "" ? "Update" : "Submit"} */}Submit
                                    </button>

                                  </div>
                                </> : serviceLevel === "2" && primaryService !== "choose" && serviceType === "1" ?
                                  <>
                                    <div className="mb-3">
                                      <Label htmlFor="remarks">Remakrs</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="remarks"
                                        placeholder="Enter remarks"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.remarks || ""}
                                        invalid={
                                          validation.touched.remarks && validation.errors.remarks ? true : false
                                        }
                                      />
                                      {validation.touched.remarks && validation.errors.remarks ? (
                                        <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                      ) : null}
                                    </div>

                                    <div className="mb-3">
                                      <Label htmlFor="keywords">Keywords</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="keywords"
                                        placeholder="Enter keywords"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.keywords || ""}
                                        invalid={
                                          validation.touched.remarks && validation.errors.keywords ? true : false
                                        }
                                      />
                                      {validation.touched.keywords && validation.errors.keywords ? (
                                        <FormFeedback type="invalid">{validation.errors.keywords}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="mb-5">
                                      <button type="submit" className="btn btn-primary w-md">
                                        {/* {bu_data !== "" ? "Update" : "Submit"} */}Submit
                                      </button>
                                    </div>
                                  </> : null
                            }

                            {
                              serviceLevel === "2" && primaryService !== "choose" && serviceType === "2" &&
                              <div className="mb-5">
                              <button type="submit" className="btn btn-primary w-md">
                                {/* {bu_data !== "" ? "Update" : "Submit"} */}Submit
                              </button>
                            </div>
                            }
                          </Row>
                        </Form>
                        :
                        panel === "subservice" && serviceType === "1" ?
                        <SubServices
                          selectedData={selectedService}
                          type={""}
                          closeOffCanvas={() => { closeOffCanvas() }}
                          cmpConfig={cmpConfig}
                          userInformation={userInformation}
                          mode={mode}
                        /> :
                        panel === "subservice" && serviceType === "2" &&
                        <SubscriptionForm
                          selectedData={selectedService}
                          type={""}
                          closeOffCanvas={() => { closeOffCanvas() }}
                          cmpConfig={cmpConfig}
                          userInformation={userInformation}
                          mode={mode}
                        /> 
                    }
                  </OffcanvasBody>
                </Offcanvas>
              </CardBody>
              <CardBody>
                {
                  serviceTree.length > 0 &&
                  <TableContainer
                    columns={columns}
                    data={serviceTree}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    customPageSizeOptions={false}
                    isJobListGlobalFilter={false}
                    className="custom-header-css"
                  />
                }

              </CardBody>
            </Card>
          </Row>

          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop();
            }}
            backdrop={'static'}
            id="staticBackdrop"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Delete Business unit</h5>
              <button type="button" className="btn-close"
                onClick={() => {
                  setmodal_backdrop(false);
                }} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Are you sure, you want to delete this business unit?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => {
                setmodal_backdrop(false);
              }}>Close</button>
              <button type="button" className="btn btn-danger" onClick={() => {
                onConfirmDelete()
              }}>Yes, delete it</button>
            </div>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Services;
