import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    CardHeader,
    CardText,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback, Collapse
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import TimezoneSelect from 'react-timezone-select'

import { getConfig } from "../../../../toolkitStore/authenticateSlice"
import { resetContactSuccessfullyAdded, fetchContacts, createContact, updateMasterContact, deleteMasterContact, mapLocationtoContact, removeMapLocationtoContact, getMapLocationtoContact } from "../../../../toolkitStore/manageCContactSlice"
import { fetchUsers, createUser, updateUserInfo, deleteUsers } from "../../../../toolkitStore/manageUserSlice"
import { fetchEsculations, createEsculation, updateMasterEsculation, deleteMasterEscalation } from "../../../../toolkitStore/manageEsculationSlice"
import { set } from "lodash";



const AddEsculations = (props) => {

    const dispatch = useDispatch()
    const { contactList, contactLocationList, isContactSuccessfullyAdded } = useSelector(state => state.manageCContactReducer)
    const { userList, isSuccessfullyAdded } = useSelector(state => state.manageUserReducer)
    const { esculationList, isEsculationSuccessfullyAdded } = useSelector(state => state.manageEsculationReducer)

    const [selectedData, setSelectedData] = useState(props.selectedData)
    const [selectedContact, setContactData] = useState("0")
    const [selectedUser, setUserData] = useState("0")
    const [cntList, setCntList] = useState([])
    const [usrList, setUsrList] = useState([])
    const [escList, setEscList] = useState([])
    const [selectedCEscType, setCEsculationType] = useState("0")
    const [selectedCMPEscType, setCMPEsculationType] = useState("0")
    const [customerFilteredList, setCustomerFilteredList] = useState(props.cmpConfig.esculation_type)
    const [companyFilteredList, setCompanyFilteredList] = useState(props.cmpConfig.esculation_type)

    const [escalation, setEscalation] = useState(props.cmpConfig.esculation_type)
    const [ColToggle, setColToggle] = useState(false)
    const [ColToggleCmy, setColToggleCmy] = useState(false)

    useEffect(() => {
        if (contactList === null) {
            dispatch(fetchContacts(props.userInformation.m_company_id, selectedData._id))
            setContactData("0")
        }
        else {
            setCntList([...contactList])
            setContactData("0")
        }

    }, [contactList])

    useEffect(() => {
        if (isContactSuccessfullyAdded) {
            dispatch(fetchContacts(props.userInformation.m_company_id, selectedData._id))
            setContactData("0")
        }
    }, [isContactSuccessfullyAdded])





    useEffect(() => {
        console.log("userList", userList)
        if (userList === null) {
            dispatch(fetchUsers(props.userInformation.m_company_id))
            setUserData("0")
        }
        else {
            setUsrList([...userList])
            setUserData("0")
        }

    }, [userList])

    useEffect(() => {
        if (isSuccessfullyAdded) {
            dispatch(fetchUsers(props.userInformation.m_company_id))
        }
    }, [isSuccessfullyAdded])




    useEffect(() => {
        console.log("esculationList", esculationList)
        if (esculationList === null) {
            dispatch(fetchEsculations(props.userInformation.m_company_id, selectedData._id))
        }
        else if (esculationList !== undefined) {
            setEscList([...esculationList])
            updateEscalationType(esculationList)
        }

    }, [esculationList])

    const updateEscalationType = (esculationList) => {


        var customerFilteredList = []

        _.each(escalation, (item) => {
            var getIndex = _.findIndex(esculationList, { "from": "customer", "esculation_name": item.name })
            if (getIndex === -1) {
                customerFilteredList.push(item)
            }
        })

        customerFilteredList.length > 0 && setCustomerFilteredList([...customerFilteredList])

        var companyFilteredList = []

        _.each(escalation, (item) => {
            var getIndex = _.findIndex(esculationList, { "from": "company", "esculation_name": item.name })
            if (getIndex === -1) {
                companyFilteredList.push(item)
            }
        })


        companyFilteredList.length > 0 && setCompanyFilteredList([...companyFilteredList])

        // console.log("ouput", _.without(configEscalationType, customerEscalationType), configEscalationType, customerEscalationType, companyEscalationType)
    }

    useEffect(() => {
        if (isEsculationSuccessfullyAdded) {
            dispatch(fetchEsculations(props.userInformation.m_company_id, selectedData._id))
        }
    }, [isEsculationSuccessfullyAdded])


    const deleteCustomerEscalation = (item) => {
        dispatch(deleteMasterEscalation(item._id))
    }






    const customerEsculationValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            customer_esculation_name: "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values, { resetForm }) => {

            console.log("values", values)

            var getContact = _.filter(cntList, { _id: selectedContact })[0]
            var getEsculationLevel = _.filter(props.cmpConfig.esculation_type, { id: selectedCEscType })[0]

            values["esculation_name"] = getEsculationLevel.name
            values["esculation_level_id"] = getEsculationLevel.id
            values["from"] = "customer"

            values["user_name"] = getContact.first_name + " " + getContact.last_name
            values["user_mobile"] = getContact.mobile_ccode+ " " + getContact.mobile
            values["user_email"] = getContact.email
            values["user_id"] = getContact._id

            values["customer_id"] = props.selectedData._id
            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            values["created_by"] = props.userInformation._id

            if (getContact !== undefined && getEsculationLevel !== undefined) {
                dispatch(createEsculation(values))
                setContactData("0")
                setCEsculationType("0")
                resetForm({ values: "" })
            }
            else {
                setContactData("0")
                setCEsculationType("0")
                resetForm({ values: "" })
            }



        }
    });



    const companyEsculationValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values, { resetForm }) => {

            var getUser = _.filter(usrList, { _id: selectedUser })[0]
            var getEsculationLevel = _.filter(props.cmpConfig.esculation_type, { id: selectedCMPEscType })[0]

            values["esculation_name"] = getEsculationLevel.name
            values["esculation_level_id"] = getEsculationLevel.id
            values["from"] = "company"

            values["user_name"] = getUser.first_name + " " + getUser.last_name
            values["user_mobile"] = getUser.mobile_ccode+ " " + getUser.mobile
            values["user_email"] = getUser.email_id
            values["user_id"] = getUser._id


            values["customer_id"] = props.selectedData._id
            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            values["created_by"] = props.userInformation._id

            if (getUser !== undefined && getEsculationLevel !== undefined) {
                dispatch(createEsculation(values))
                setUserData("0")
                setCMPEsculationType("0")
                resetForm({ values: "" })
            }
            else {
                setUserData("0")
                setCMPEsculationType("0")
                resetForm({ values: "" })
            }

        }
    });



    return (
        <div className="p-3">
            <Row>
                <Col lg={12} >
                    <div className="d-flex flex-row" style={{ borderBottom: "1px solid #ededed", paddingBottom: 10 }}>
                        <div style={{ width: "70%" }} >
                            <div className="row">
                                <div className="font-size-10 text-secondary text-opacity-50">Customer</div>
                                <div className="text-primary font-size-15">{selectedData.customer_name}</div>
                            </div>
                        </div>
                        {/* <div style={{ width: "30%" }}>
                            <button type="submit" className="btn btn-primary w-md" onClick={() => {
                                // toggleShowEditor()
                            }}>
                                Create Esculation
                                <i className={"mdi mdi-pencil-outline"} id="edittooltip" />
                            </button>
                        </div> */}

                    </div>

                    <div style={{ borderBottom: escList.length === 0 ? "1px solid #ededed" : "none", paddingBottom: 30, paddingTop: 30 }}>

                        <div className="mb-1 ">
                            <label className="text-danger">Customer Escalation</label>
                        </div>

                        <div>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    customerEsculationValidation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="align-items-end mb-4">
                                    <Col lg={4}>
                                        {
                                            customerFilteredList.length > 0 &&
                                            <div className="d-flex flex-column mt-2 ">
                                                <Label className="font-size-11">Escalation Level</Label>
                                                <div className="d-flex flex-column ">
                                                    {
                                                        <select className="form-select" onChange={(e) => {
                                                            console.log("event.taget.value", e.target.value)
                                                            setCEsculationType(e.target.value)
                                                        }}
                                                            value={selectedCEscType}
                                                        >
                                                            <option value="0" disabled>Choose...</option>
                                                            {
                                                                customerFilteredList.map((item, idx) => {
                                                                    return (
                                                                        <option key={"esct" + idx} value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>


                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={5}>
                                        {

                                            <div className="d-flex flex-column mt-2 ">
                                                <Label className="font-size-11">Contacts</Label>
                                                <div className="d-flex flex-column ">
                                                    {
                                                        <select className="form-select" onChange={(e) => {
                                                            console.log("event.taget.value", e.target.value)
                                                            setContactData(e.target.value)
                                                        }}
                                                            value={selectedContact}
                                                        >
                                                            <option value="0" disabled>Choose...</option>
                                                            {
                                                                cntList.map((item, idx) => {
                                                                    return (
                                                                        <option key={"impt" + idx} value={item._id}>{item.first_name} {item.last_name} ({item.designation})</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>


                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <div>
                                            <button type="submit" className="btn btn-outline-primary w-md" >
                                                {"Add"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>

                            <div className="d-flex flex-column mt-2 mb-2">
                                <div className="d-flex flex-column  mt-2">

                                    {
                                        escList && escList.map((elem, idx) => {
                                            if (elem.from === "customer") {
                                                return (
                                                    <div key={"esc" + idx} className="row py-2 w-100" style={{ paddingLeft: 0, paddingRight: 0, borderBottom: "1px solid #ededed" }}>
                                                        <div className="col-9 d-flex flex-row align-items-center my-2" >
                                                            <div >
                                                                <div className="">{elem.user_name}</div>
                                                                <div className="font-size-12 text-secondary" >{elem.esculation_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2">
                                                            <div>


                                                                <Link
                                                                    to="#!"
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={(e) => {

                                                                        e.preventDefault()
                                                                        const data = elem;
                                                                        ColToggle !== idx ? setColToggle(idx) : setColToggle(-1)
                                                                    }}
                                                                >
                                                                    <i className={ColToggle === idx ? "mdi mdi-close font-size-15" : "mdi mdi-delete-outline font-size-15"} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        {
                                                            <Collapse isOpen={ColToggle === idx}>
                                                                <div className="row mb-3">
                                                                    <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                                                </div>

                                                                <div >
                                                                    <div className="py-2 ps-3" >
                                                                        <CardHeader className="bg-transparent mb-3">
                                                                            <h7 className="my-0 text-danger">
                                                                                <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                                            </h7>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <CardText>
                                                                                <div>
                                                                                    <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                                                                    <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); deleteCustomerEscalation(elem) }}>Delete</button>
                                                                                </div>
                                                                            </CardText>
                                                                        </CardBody>
                                                                    </div>

                                                                </div>
                                                            </Collapse>
                                                        }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>

                        </div>

                    </div>

                    <div style={{ borderBottom: escList.length === 0 ? "1px solid #ededed" : "none", paddingBottom: 30, paddingTop: 50 }}>

                        <div className="mb-1 ">
                            <label className="text-danger">Pentabrains Escalation</label>
                        </div>

                        <div>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    companyEsculationValidation.handleSubmit();
                                    //onCompanyEscSubmit()
                                    return false;
                                }}
                            >
                                <Row className="align-items-end mb-4">
                                    <Col lg={4}>
                                        {
                                            companyFilteredList.length > 0 &&
                                            <div className="d-flex flex-column mt-2 ">
                                                <Label className="font-size-11">Escalation Level</Label>
                                                <div className="d-flex flex-column ">
                                                    {
                                                        <select className="form-select" onChange={(e) => {
                                                            console.log("event.taget.value", e.target.value)
                                                            setCMPEsculationType(e.target.value)
                                                        }}
                                                            value={selectedCMPEscType}
                                                        >
                                                            <option value="0" disabled>Choose...</option>
                                                            {
                                                                companyFilteredList.map((item, idx) => {
                                                                    return (
                                                                        <option key={"esct" + idx} value={item.id}>{item.name}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={5}>
                                        {
                                            <div className="d-flex flex-column mt-2 ">
                                                <Label className="font-size-11">Users</Label>
                                                <div className="d-flex flex-column ">
                                                    {
                                                        <select className="form-select" onChange={(e) => {
                                                            console.log("event.taget.value", e.target.value)
                                                            setUserData(e.target.value)
                                                        }}
                                                            value={selectedUser}
                                                        >
                                                            <option value="0" disabled>Choose...</option>
                                                            {
                                                                usrList.map((item, idx) => {
                                                                    return (
                                                                        <option key={"impt" + idx} value={item._id}>{item.first_name} ({item.designation})</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>


                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <div>
                                            <button type="submit" className="btn btn-outline-primary w-md" >
                                                {"Add"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>

                            <div className="d-flex flex-column mt-2 mb-2">
                                <div className="d-flex flex-column  mt-2">

                                    {
                                        escList && escList.map((elem, idx) => {
                                            if (elem.from === "company") {
                                                return (
                                                    <div key={"esc" + idx} className="row py-2 w-100" style={{ paddingLeft: 0, paddingRight: 0, borderBottom: "1px solid #ededed" }}>
                                                        <div className="col-9 d-flex flex-row align-items-center my-2" >
                                                            <div >
                                                                <div className="">{elem.user_name}</div>
                                                                <div className="font-size-12 text-secondary" >{elem.esculation_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2">
                                                            <div>


                                                                <Link
                                                                    to="#!"
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={(e) => {

                                                                        e.preventDefault()
                                                                        const data = elem;
                                                                        ColToggleCmy !== idx ? setColToggleCmy(idx) : setColToggleCmy(-1)
                                                                    }}
                                                                >
                                                                    <i className={ColToggleCmy === idx ? "mdi mdi-close font-size-15" : "mdi mdi-delete-outline font-size-15"} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        {
                                                            <Collapse isOpen={ColToggleCmy === idx}>
                                                                <div className="row mb-3">
                                                                    <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                                                </div>

                                                                <div >
                                                                    <div className="py-2 ps-3" >
                                                                        <CardHeader className="bg-transparent mb-3">
                                                                            <h7 className="my-0 text-danger">
                                                                                <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                                            </h7>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <CardText>
                                                                                <div>
                                                                                    <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggleCmy(-1) }}>Cancel</button>
                                                                                    <button className="btn btn-sm btn-danger" onClick={() => { setColToggleCmy(-1); deleteCustomerEscalation(elem) }}>Delete</button>
                                                                                </div>
                                                                            </CardText>
                                                                        </CardBody>
                                                                    </div>

                                                                </div>
                                                            </Collapse>
                                                        }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>




                        </div>

                    </div>


                </Col>
            </Row>
        </div>
    )

}

export default AddEsculations;