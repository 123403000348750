

import React, { createContext, useState, useEffect, useContext } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export const useSocketContext = () => {
    return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {

    const [socket, setSocket] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [userInfo, setInfo] = useState(null); // Initialize userInfo as null

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setInfo(obj); // Set userInfo from localStorage

        // Cleanup function to close the socket connection when component unmounts
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, []); // This effect runs only once when the component mounts

    useEffect(() => {

        if (userInfo && userInfo._id) {
            const newSocket = io("http://127.0.0.1:8060/", {
              reconnection: true,
              reconnectionDelay: 1000,
              reconnectionDelayMax: 5000,
              reconnectionAttempts: Infinity,
                query: {
                    userId: userInfo._id,
                },
            });

            setSocket(newSocket);

            // Listen for online users
            newSocket.on("getOnlineUsers", (users) => {
                setOnlineUsers(users);
            });

            // Cleanup function to close the socket connection when userInfo changes
            return () => {
                newSocket.close();
            };
        } else {
            // If userInfo is not available or user is not authenticated, close the socket
            if (socket) {
                socket.close();
                setSocket(null);
            }
        }

    }, [userInfo]); // This effect runs whenever userInfo changes

    return (
        <SocketContext.Provider value={{ socket, onlineUsers }}>
            {children}
        </SocketContext.Provider>
    );
};