import React, { useState, useEffect, useMemo } from "react"
import { formate } from "date-fns"
import {
  Container,
  Row,
  Col,
  CardText,
  CardHeader,
  Label,
  Form,
  Input,
  FormFeedback,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane, Modal, Table, Badge,
  UncontrolledTooltip, Collapse

} from "reactstrap"
import Select from "react-select";
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumb from "components/Common/Breadcrumb"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux";

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import TableContainer from '../components/TableContainer';

import { fetchServices, createService, updateService, deleteMasterService } from "../../../toolkitStore/manageServiceSlice"
import { fetchCustomers, createCustomer, updateMasterCustomer, deleteMasterCustomers, } from "../../../toolkitStore/manageCustomerSlice"
import {
  fetchContracts,
  createContractServices,
  getContractServices,
  removeServiceFromContract,
  resetContractServices,

  getCustomerAssets,
  resetCustomerAssetList,
  addAssetsToContract,
  fetchContractServiceAssets,
  removeAssetFromSC,

  addSWToContractService,
  getContractServiceSW,
  removeSWFromSC,

  getCompanyDeliverables,
  addDeliverablesToContract,
  fetchContractServiceDeliverables,
  removeDeliverablesFromSC,

  resetContractServiceResourceList,
  resetContractServiceAssetList,

  getCompanySLAs,
  addSLAToContract,
  fetchContractServiceSLAs,
  removeSLAFromSC,

  removeResourcesFromSC,
  fetchCSResources,
  addResourceToCS,

  saveServiceInscope,
  saveServiceOutscope,
  saveServicePrerequisite,
  saveServiceInternalNotes,
  resetServiceContent,

  updateContractDeliverable


} from "../../../toolkitStore/manageContractSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"
import { Link } from "react-router-dom";
import classnames from "classnames";


import ReactHtmlParser from 'react-html-parser';


import ContractAssets from "./components/addAssets";
import AddResources from "./components/addResources";
import { sortBy } from "lodash";

import scopeImage from "../../../assets/images/scope.png"


const ConfigureContract = () => {

  const dispatch = useDispatch()

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { customerList, } = useSelector(state => state.manageCustomerReducer)
  const { assetList, assetLocationList, isAssetSuccessfullyAdded } = useSelector(state => state.manageCAssetReducer)
  const {
    customerAssetList,
    contractServiceList,
    contractServiceAssetList,
    contractServiceAssetSummary,
    contractServiceSWList,
    isContractServiceSuccessfullyAdded,
    companyDeliverableList,
    contractServiceDeliverableList,
    companySLAList,
    contractServiceSLAList,
    serviceMaster,
    contractServiceResourceList,
    isDataLoading
  } = useSelector(state => state.manageContractReducer)
  const { serviceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)


  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [selectedContract, setSelectedContract] = useState("")
  const [selectedService, setServiceSelected] = useState("")

  const [optionGroup, setOptionGroup] = useState([])
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [contractServices, setContractServices] = useState([])
  const [selectedData, setSelectedData] = useState("")
  const [contractServiceAssets, setContractServiceAssets] = useState([])



  const [astList, setAstList] = useState([])
  const [swList, setSWList] = useState([])
  const [cmpDeliverableList, setCmpDeliverableList] = useState([])
  const [cmpSLAList, setCmpSLAList] = useState([])
  const [resourceList, setResourceList] = useState([])


  const [formFor, setFormFor] = useState("")

  const [isRight, setIsRight] = useState(false);
  const [activeTab, setactiveTab] = useState("1");

  const [inScope, setInscope] = useState("")
  const [outScope, setOutscope] = useState("")
  const [prerequisite, setPrerequisite] = useState("")
  const [internalNotes, setInternalNotes] = useState("")

  const [editAsset, setEditAsset] = useState(false)
  const [editService, setEditService] = useState(false)
  const [editDeliverables, setEditDeliverables] = useState(false)
  const [editSLA, setEditSLA] = useState(false)
  const [editInscope, setEditScope] = useState(false)
  const [editOutscope, setEditOutScope] = useState(false)
  const [editPrerq, setEditPrerq] = useState(false)
  const [editResources, setEditResources] = useState(false)

  const [showAssetTable, setShowAssetTable] = useState(true)
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  const [ColToggle, setColToggle] = useState(false)
  const [ColToggleA, setColToggleA] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [filteredAssets, setFilteredAssets] = useState([])

  const [inscopeReset, setInscopeReset] = useState(false)
  const [outscopeReset, setOutscopeReset] = useState(false)
  const [prerqReset, setPrerqReset] = useState(false)

  const [resourceType, setResourceType] = useState("0")
  const [deliverableType, setDeliverableType] = useState("0")
  const [frequency, setFrequency] = useState("0")
  const [how_to, setHow_to] = useState("")
  const [mode, setMode] = useState("new")

  const [deliverableError, setDelError] = useState("")
  const [freqError, setFreqError] = useState("")


  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }





  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggleRightCanvas = () => {
    isRight && setFormFor("")
    setIsRight(!isRight);
  };

  useEffect(() => {
    if (selectedContract === "") {
      var getContract = JSON.parse(localStorage.getItem("@selectedContract"))
      console.log("getContract", getContract)
      setSelectedContract(getContract)
    }

  }, [selectedContract])

  useEffect(() => {
    if (cmpConfig === "") {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }

  }, [cmpConfig])


  useEffect(() => {
    if (userInformation === "") {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
    }
  }, [userInformation])

  useEffect(() => {
    if (isSuccessfullyAdded) {
      dispatch(fetchServices(userInformation.m_company_id))
    }
  }, [isSuccessfullyAdded])

  useEffect(() => {

    if (serviceList.length > 0) {
      var tempSer = []
      _.each(serviceList, item => {
        if (item.relative_id === null) {
          var getSecSer = _.filter(serviceList, { relative_id: item._id })

          var itemOptions = []
          _.each(getSecSer, elem => {
            itemOptions.push({
              "label": elem.name,
              "value": elem._id,
              "relational_path": item.name
            })
          })
          tempSer.push({
            "label": item.name,
            "options": itemOptions
          })
        }
      })
      setOptionGroup([...tempSer])
    }
    else {
      dispatch(fetchServices(userInformation.m_company_id))
    }

  }, [serviceList])

  useEffect(() => {
    console.log("contractServiceList", contractServiceList, selectedContract)
    if (contractServiceList === null && selectedContract !== "") {
      var info = {
        "cc_id": selectedContract._id,
        "customer_id": selectedContract.customer_id,
        "m_company_id": userInformation.m_company_id
      }
      dispatch(getContractServices(info))
    }
    else {

      updateContractServiceList(contractServiceList ? [...contractServiceList] : [])
    }

  }, [contractServiceList, selectedContract])


  const updateContractServiceList = (contractServiceList) => {


    var contractTempSerivceList = []

    _.each(serviceList, (item) => {
      var getIndex = _.findIndex(contractServiceList, { "m_service_id": item._id })
      if (getIndex === -1) {
        contractTempSerivceList.push(item)
      }
    })



    var tempSer = []
    _.each(contractTempSerivceList, item => {
      if (item.relative_id === null) {
        var getSecSer = _.filter(contractTempSerivceList, { "relative_id": item._id })

        var itemOptions = []
        _.each(getSecSer, elem => {
          itemOptions.push({
            "label": elem.name,
            "value": elem._id,
            "relational_path": item.name
          })
        })
        tempSer.push({
          "label": item.name,
          "options": itemOptions
        })
      }
    })

    setOptionGroup([...tempSer])
    setContractServices(contractServiceList ? [...contractServiceList] : [])
  }


  useEffect(() => {
    if (!isContractServiceSuccessfullyAdded && selectedContract) {
      var info = {
        "cc_id": selectedContract._id,
        "customer_id": selectedContract.customer_id,
        "m_company_id": userInformation.m_company_id
      }
      dispatch(getContractServices(info))
    }
  }, [isContractServiceSuccessfullyAdded, selectedContract])



  useEffect(() => {
    if (serviceMaster) {
      var tempSer = []
      _.each(contractServiceList, item => {
        var srvc = { ...item }
        if (item._id === selectedService._id) {
          if (activeTab === "2") {
            srvc["in_scope"] = serviceMaster[0].in_scope
          }

          if (activeTab === "3") {
            srvc["out_scope"] = serviceMaster[0].out_scope
          }

          if (activeTab === "4") {
            srvc["prerequisite"] = serviceMaster[0].prerequisite
          }

          srvc["edit"] = true
        }
        else {
          srvc["edit"] = false
        }
        tempSer.push(srvc)
      })
      setContractServices([...tempSer])

    }

  }, [serviceMaster])




  // ================= Contract Service Assets ===================

  useEffect(() => {
    if (userInformation !== "" && selectedContract !== "" && customerAssetList === null) {
      var info = {
        "m_company_id": userInformation.m_company_id,
        "customer_id": selectedContract.customer_id
      }
      dispatch(getCustomerAssets(info))
    }
    else if (userInformation !== "" && selectedContract !== "") {
      setAstList([...customerAssetList])
    }

  }, [userInformation, selectedContract, customerAssetList])

  useEffect(() => {
    if (isAssetSuccessfullyAdded) {
      var info = {
        "m_company_id": userInformation.m_company_id,
        "customer_id": selectedContract.customer_id
      }
      dispatch(getCustomerAssets(info))
    }
  }, [isAssetSuccessfullyAdded])

  useEffect(() => {
    if (contractServiceAssetList) {
      filterServiceAssets("All")
    }

  }, [contractServiceAssetList])

  const moveToContract = (serviceInfo, assetInfo) => {
    var tempAsset = { ...assetInfo }
    tempAsset["service_id"] = serviceInfo._id
    tempAsset["cc_id"] = selectedContract._id
    tempAsset["m_asset_id"] = assetInfo._id
    dispatch(addAssetsToContract(tempAsset))
    getAssets(serviceInfo)

  }

  const getAssets = (serviceInfo) => {
    setTimeout(() => {
      var GCInfo =
      {
        "m_company_id": userInformation.m_company_id,
        "customer_id": serviceInfo.customer_id,
        "service_id": serviceInfo._id,
        "cc_id": selectedContract._id,
      }
      dispatch(fetchContractServiceAssets(GCInfo))
      setShowAssetTable(true)
      filterServiceAssets("All")
      setSelectedIndex(0)
    }, 100);
  }

  const getContractServiceAssets = (info) => {
    var GCInfo =
    {
      "m_company_id": userInformation.m_company_id,
      "customer_id": info.customer_id,
      "service_id": info._id,
      "cc_id": selectedContract._id,
    }
    dispatch(fetchContractServiceAssets(GCInfo))
  }

  const removeAssetFromServiceContract = (serviceInfo, assetInfo) => {
    setShowAssetTable(false)
    dispatch(removeAssetFromSC(assetInfo))
    getAssets(serviceInfo)
    filterServiceAssets("All")
    setSelectedIndex(0)

  }

  const filterServiceAssets = (assetType) => {
    var filteredAssets = _.filter(contractServiceAssetList, { asset_type: assetType })
    setFilteredAssets(assetType === "All" ? [...contractServiceAssetList] : [...filteredAssets])
  }


  // ================= Contract Service - Service Window ===================


  useEffect(() => {
    if (userInformation !== "" && selectedContract !== "" && contractServiceSWList === null) {
      var info = {
        "m_company_id": userInformation.m_company_id,
        "customer_id": selectedContract.customer_id
      }
      dispatch(getContractServiceSW(info))
    }
    else if (userInformation !== "" && selectedContract !== "") {
      setSWList([...contractServiceSWList])
    }

  }, [userInformation, selectedContract, contractServiceSWList])


  const moveToSWContract = (serviceInfo, swInfo) => {
    var tempSW = { ...swInfo }
    tempSW["customer_id"] = serviceInfo.customer_id
    tempSW["m_company_id"] = userInformation.m_company_id
    tempSW["service_id"] = serviceInfo._id
    tempSW["cc_id"] = selectedContract._id
    tempSW["m_sw_id"] = swInfo.id
    tempSW["created_by"] = userInformation._id

    dispatch(addSWToContractService(tempSW))

    getSWs(serviceInfo)

  }

  const getSWs = (serviceInfo) => {
    setTimeout(() => {
      var GCInfo =
      {
        "m_company_id": userInformation.m_company_id,
        "customer_id": serviceInfo.customer_id,
        "service_id": serviceInfo._id,
        "cc_id": selectedContract._id,
      }
      dispatch(getContractServiceSW(GCInfo))
    }, 200);
  }

  const removeSWFromServiceContract = (serviceInfo, swInfo) => {
    dispatch(removeSWFromSC(swInfo))
    getSWs(serviceInfo)
  }


  // ================= Contract Service Deliverables ===================

  useEffect(() => {
    if (userInformation !== "" && selectedContract !== "" && companyDeliverableList === null) {
      var company_id = userInformation.m_company_id
      dispatch(getCompanyDeliverables(company_id))
    }
    else if (userInformation !== "" && selectedContract !== "") {
      setCmpDeliverableList([...companyDeliverableList])
    }

  }, [userInformation, selectedContract, companyDeliverableList])


  const moveToContractDeliverables = (serviceInfo, deliverableInfo) => {
    var tempDeliverable = { ...deliverableInfo }
    tempDeliverable["service_id"] = serviceInfo._id
    tempDeliverable["customer_id"] = serviceInfo.customer_id,
      tempDeliverable["cc_id"] = selectedContract._id
    tempDeliverable["m_deliverable_id"] = deliverableInfo._id
    dispatch(addDeliverablesToContract(tempDeliverable))

    getDeliverables(serviceInfo)

  }

  const getDeliverables = (serviceInfo) => {
    setTimeout(() => {
      var GCInfo =
      {
        "m_company_id": userInformation.m_company_id,
        "customer_id": serviceInfo.customer_id,
        "service_id": serviceInfo._id,
        "cc_id": selectedContract._id,
      }
      dispatch(fetchContractServiceDeliverables(GCInfo))
    }, 200);
  }

  const getContractServiceDeliverables = (info) => {
    var GCInfo =
    {
      "m_company_id": userInformation.m_company_id,
      "customer_id": info.customer_id,
      "service_id": info._id,
      "cc_id": selectedContract._id,
    }
    dispatch(fetchContractServiceDeliverables(GCInfo))
  }

  const removeDeliverableFromServiceContract = (serviceInfo, assetInfo) => {
    dispatch(removeDeliverablesFromSC(assetInfo))
    getDeliverables(serviceInfo)
  }

  // ================= Contract Resources ===================


  const resource_type = [
    {
      value: "1",
      name: "Dedicated"
    },
    {
      value: "2",
      name: "Shared"
    }
  ]

  const getCtrResources = (serviceInfo) => {
    setTimeout(() => {
      var GCInfo =
      {
        "m_company_id": userInformation.m_company_id,
        "customer_id": serviceInfo.customer_id,
        "service_id": serviceInfo._id,
        "cc_id": selectedContract._id,
      }
      dispatch(fetchCSResources(GCInfo))
    }, 200);
  }

  const getContractResources = (info) => {
    var GCInfo =
    {
      "m_company_id": userInformation.m_company_id,
      "customer_id": info.customer_id,
      "service_id": info._id,
      "cc_id": selectedContract._id,
    }
    dispatch(fetchCSResources(GCInfo))
  }

  const resourcesPlanningValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      resource_level: "",
      quantity: "",
      remarks: ""
    },
    validationSchema: Yup.object({
      //customer_name: Yup.string().required("Please Enter Your impact name"),
      // email: Yup.string().required("Please Enter Your Email"),
      // password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, { resetForm }) => {

      var getResourcetype = _.filter(resource_type, { value: resourceType })[0]
      values["resource_type"] = getResourcetype.name
      values["resource_type_id"] = getResourcetype.value
      values["customer_id"] = selectedContract.customer_id
      values["cc_id"] = selectedContract._id
      values["service_id"] = selectedData._id
      values["m_company_id"] = userInformation.m_company_id
      values["m_b_unit_id"] = userInformation.m_b_unit_id
      values["created_by"] = userInformation._id

      if (resourceType !== "0") {
        dispatch(addResourceToCS(values))
        getCtrResources(selectedService)
        resetForm({ values: "" })
      }
      else {
        resetForm({ values: "" })
      }

    }
  });




  const removeResources = (serviceInfo, item) => {
    dispatch(removeResourcesFromSC(item))
    getCtrResources(serviceInfo)
  }


  // ================= Contract Service SLAs ===================

  useEffect(() => {
    if (userInformation !== "" && selectedContract !== "" && companySLAList === null) {
      var company_id = userInformation.m_company_id
      dispatch(getCompanySLAs(company_id))
    }
    else if (userInformation !== "" && selectedContract !== "") {
      setCmpSLAList([...companySLAList])
    }

  }, [userInformation, selectedContract, companySLAList])


  const moveToContractSLAs = (serviceInfo, slaInfo) => {
    var tempSLA = { ...slaInfo }
    tempSLA["service_id"] = serviceInfo._id
    tempSLA["customer_id"] = serviceInfo.customer_id,
      tempSLA["cc_id"] = selectedContract._id
    tempSLA["m_sla_id"] = slaInfo._id
    dispatch(addSLAToContract(tempSLA))

    getSLAs(serviceInfo)

  }

  const getSLAs = (serviceInfo) => {
    setTimeout(() => {
      var GCInfo =
      {
        "m_company_id": userInformation.m_company_id,
        "customer_id": serviceInfo.customer_id,
        "service_id": serviceInfo._id,
        "cc_id": selectedContract._id,
      }
      dispatch(fetchContractServiceSLAs(GCInfo))
    }, 200);
  }

  const getContractServiceSLAs = (info) => {
    var GCInfo =
    {
      "m_company_id": userInformation.m_company_id,
      "customer_id": info.customer_id,
      "service_id": info._id,
      "cc_id": selectedContract._id,
    }
    dispatch(fetchContractServiceSLAs(GCInfo))
  }

  const removeSLAFromServiceContract = (serviceInfo, assetInfo) => {
    dispatch(removeSLAFromSC(assetInfo))
    getSLAs(serviceInfo)
  }



  // ============ save inscope ===========

  const handleCkeditorIn = (event, editor) => {
    const data = editor.getData()
    setInscope(data)
  }


  const saveInsocpe = (item) => {
    var info = {
      "_id": item._id,
      "in_scope": inScope,
    }
    dispatch(saveServiceInscope(info))
  }



  // ============ save out scope ===========

  const handleCkeditorOut = (event, editor) => {
    const data = editor.getData()
    setOutscope(data)
  }


  const saveOutscope = (item) => {
    var info = {
      "_id": item._id,
      "out_scope": outScope,
    }
    dispatch(saveServiceOutscope(info))
  }

  // ============ save Prerequisite ===========

  const handleCkeditorPre = (event, editor) => {
    const data = editor.getData()
    setPrerequisite(data)
  }


  const savePrerequisite = (item) => {
    var info = {
      "_id": item._id,
      "prerequisite": prerequisite,
    }
    dispatch(saveServicePrerequisite(info))
  }

  // ============ save Internal Notes ===========

  const handleCkeditorIN = (event, editor) => {
    const data = editor.getData()
    setInternalNotes(data)
  }


  const saveInternalNotes = (item) => {
    var info = {
      "_id": item._id,
      "internal_notes": internalNotes,
    }
    dispatch(saveServiceInternalNotes(info))
  }




  // ===========================================================================
  function handleMulti(selectedMulti) {
    console.log("selectedMulti", selectedMulti)
    setselectedMulti(selectedMulti);
  }

  const addSelectedServices = () => {
    if (selectedMulti.length > 0) {
      var getServiceIds = _.map(selectedMulti, "value")
      var info = {
        "cc_id": selectedContract._id,
        "customer_id": selectedContract.customer_id
      }
      dispatch(createContractServices(getServiceIds, info))
      setselectedMulti("")
      updateContractServiceList()
    }
  }

  const removeService = () => {
    console.log("selected data", selectedService)
    dispatch(removeServiceFromContract(selectedService))
    dispatch(resetContractServices())
  }



  const closeOffCanvas = () => {
    toggleRightCanvas();
  }

  useEffect(() => {
    formFor !== "" && !isRight && toggleRightCanvas();
  }, [formFor])


  const showService = (data) => {
    var tempLoc = []
    _.each(contractServices, item => {
      var loc = { ...item }
      if (loc._id === data._id) {
        loc["edit"] = !loc.edit
        setSelectedData(loc)
      }
      else {
        loc["edit"] = false
      }
      tempLoc.push(loc)
    })
    setContractServices([...tempLoc])
  }

  const closeService = () => {
    var tempLoc = []
    _.each(contractServices, item => {
      var loc = { ...item }
      loc["edit"] = false
      tempLoc.push(loc)
    })
    setContractServices([...tempLoc])
  }


  // ================== Assets Table ==============================

  const columnsAssets = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'dummy',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>{cellProps.row.index + 1}</div>
          )
        }

      },
      {
        Header: 'Asset Type',
        accessor: 'asset_type',
        disableFilters: true,

      },
      {
        Header: 'Asset Serial No',
        accessor: 'asset_serial_no',
        disableFilters: true,

      },
      {
        Header: 'Name',
        accessor: 'asset_name',
        disableFilters: true,

      },
      {
        Header: 'Configuration',
        accessor: 'asset_config',
        disableFilters: true,
      },
      {
        Header: 'Unique Id',
        accessor: 'auid',
        disableFilters: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      const SData = cellProps.row.original;
                      removeAssetFromServiceContract(selectedService, SData)
                      //deleteService(SData);
                    }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }
            </ul>
          );
        }
      },

    ],
    []
  );

  // ================== Service window Table ==============================

  const columnsSW = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'dummy',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>{cellProps.row.index + 1}</div>
          )
        }

      },
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,

      },
      {
        Header: 'Work Hours',
        accessor: 'hours_in_day',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>{cellProps.row.original.hours_in_day} / {cellProps.row.original.day_in_week}</div>
          )
        }

      },

      {
        Header: 'Description',
        accessor: 'description',
        disableFilters: true,
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      const SData = cellProps.row.original;
                      removeSWFromServiceContract(selectedService, SData)
                      //deleteService(SData);
                    }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }
            </ul>
          );
        }
      },

    ],
    []
  );

  // ================== Delivery Table ==============================

  const columnsDeliverable = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'dummy',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>{cellProps.row.index + 1}</div>
          )
        }

      },
      {
        Header: 'DID',
        accessor: 'deliverable_id',
        disableFilters: true,

      },
      {
        Header: 'Type',
        accessor: 'type',
        disableFilters: true,
      },

      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
      },

      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          console.log("cellProps.row", cellProps.row.index + 1)
          return (
            <div>{ReactHtmlParser(cellProps.row.original.action)}</div>
          )
        }

      },

      {
        Header: 'Action',
        accessor: 'act',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      const SData = cellProps.row.original;
                      removeDeliverableFromServiceContract(selectedService, SData)
                      //deleteService(SData);
                    }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }
            </ul>
          );
        }
      },

    ],
    []
  );

  // ================== SLA Table ==============================

  const columnsSLA = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'dummy',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>{cellProps.row.index + 1}</div>
          )
        }

      },
      {
        Header: 'Impact type',
        accessor: 'impact_type',
        disableFilters: true,

      },
      {
        Header: 'Name',
        accessor: 'impact_name',
        disableFilters: true,
      },

      {
        Header: 'Response time',
        accessor: 'response_time',
        disableFilters: true,
      },

      {
        Header: 'Resolution time',
        accessor: 'resolution_time',
        disableFilters: true,
      },

      {
        Header: 'Definition',
        accessor: 'definition',
        disableFilters: true,
      },


      {
        Header: 'Action',
        accessor: 'act',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              {
                <li>
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      const SData = cellProps.row.original;
                      removeSLAFromServiceContract(selectedService, SData)
                      //deleteService(SData);
                    }}
                  >
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              }
            </ul>
          );
        }
      },

    ],
    []
  );

  const editDeliverable = (data) => {
    setSelectedData(data)
    setHow_to(data.action)
    setDeliverableType(data.type_id)
    setFrequency(data.frequency_id)
    toggleRightCanvas()


  }

  const handleCkeditorDeliverables = (event, editor) => {
    const data = editor.getData()
    setHow_to(data)
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // deliverable_id: selectedData.deliverable_id || autId || "",
      name: selectedData.name || "",
      action: selectedData.action || "",
      remarks: selectedData.remarks || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter deliverable name"),
    }),
    onSubmit: (values, { resetForm }) => {

      if(deliverableType === "0")
      {
        setDelError("Select Deliverable Type")
        return
      }

      if(frequency === "0")
      {
        setFreqError("Select Frequency")
        return
      }

      var getDeliverableType = _.filter(companyConfig.deliverable_type, { id: deliverableType })[0].name
      var getFrequency = _.filter(companyConfig.frequencies, { id: frequency })[0].name

      

      values["type"] = getDeliverableType
      values["type_id"] = deliverableType
      values["action"] = how_to

      values["frequency"] = getFrequency
      values["frequency_id"] = frequency

      values["m_company_id"] = userInformation.m_company_id
      values["m_b_unit_id"] = userInformation.m_b_unit_id
      values["created_by"] = userInformation._id

      console.log("values", values)

        values["_id"] = selectedData._id
        dispatch(updateContractDeliverable(values, selectedService))
     
      resetForm({ values: "" })
      toggleRightCanvas()

    },
  })





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid >
          {/* <Breadcrumb title="Projects" breadcrumbItem="Create New" /> */}
          <Row >

            <Card >
              <CardBody style={{ padding: "0.7rem" }}>
                <div className="row align-items-center">

                  <Col lg={6} className="ps-3">
                    <div className="fw-bold">{selectedContract.contract_name}</div>
                    <div className="font-size-10 text-secondary ">{selectedContract.contract_id}</div>
                  </Col>

                  <Col lg={6} className="d-flex flex-row justify-content-end" >
                    {
                      serviceList &&
                      <div className="col-7 me-2" >
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={(e) => {
                            handleMulti(e);
                          }}
                          options={optionGroup}
                          className="select2-selection"
                        />
                      </div>
                    }
                    <button className="btn btn-info me-3" onClick={() => {
                      addSelectedServices()
                    }}>
                      Add Service
                    </button>
                    <div>
                      <Link to="/contracts" className="btn btn-secondary">Back</Link>
                    </div>
                  </Col>

                </div>
              </CardBody>
            </Card>

            {
              isDataLoading &&
              <Card body className="text-center">
                <CardBody>
                  <div>data loading</div>
                </CardBody>
              </Card>
            }

            {
              isDataLoading === false && contractServices && contractServices.length === 0 &&
              <Card body className="text-center">
                <CardTitle className="mt-0 text-primary">Scope of work</CardTitle>
                <CardBody>
                  <img src={scopeImage} style={{ width: "20%" }} />
                </CardBody>
                <CardText>
                  No Records found. Add your scope by selecting services.
                </CardText>
              </Card>
            }



            {
              isDataLoading === false && contractServices.length > 0 && <>
                <Row style={{ background: "#f7f7fa", marginTop: 30 }}>
                  <label className="ps-3">Scope of work</label>
                </Row>
                {
                  contractServices.map((item, idx) => {
                    return (
                      <div key={"ast" + idx} className="row py-2 w-100" style={{ borderBottom: "1px solid #e0e0e0", backgroundColor: "#fff" }}
                        onClick={() => {

                        }}
                      >
                        <div className="col-9 d-flex flex-row align-items-center my-2 px-3" >
                          <div className="">
                            <div className="text-primary">{idx + 1}. {item.name}</div>
                            {/* <div className="text-primary">{item.company_service_id}</div> */}
                          </div>
                        </div>
                        <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2 pe-3">
                          <div>

                            <Link
                              to="#!"
                              className="btn btn-sm btn-info me-1"
                              onClick={(e) => {
                                e.preventDefault()
                                setColToggle(-1)
                                dispatch(resetContractServiceAssetList())
                                dispatch(resetContractServiceResourceList())
                                dispatch(resetCustomerAssetList())
                                getContractServiceSLAs(item)
                                getContractServiceDeliverables(item)
                                getContractResources(item)
                                getSWs(item)
                                getContractServiceAssets(item)
                                showService(item)
                                setServiceSelected(item)
                                // toggleEditor(item)
                                setSelectedIndex(0)
                                setInscopeReset(false)
                              }}
                            >
                              <i className={item.edit ? "mdi mdi-close font-size-15" : "mdi mdi-pencil-outline font-size-15"} id="edittooltip" />
                            </Link>
                            <Link
                              to="#!"
                              className="btn btn-sm btn-danger"
                              onClick={(e) => {

                                e.preventDefault()
                                const data = item;
                                setSelectedIndex(0)
                                console.log("item", item)
                                setServiceSelected(item)
                                closeService()
                                ColToggle !== idx ? setColToggle(idx) : setColToggle(-1)
                              }}
                            >
                              <i className={ColToggle === idx ? "mdi mdi-close font-size-15" : "mdi mdi-delete-outline font-size-15"} />
                            </Link>
                          </div>
                        </div>

                        {
                          <Collapse isOpen={ColToggle === idx}>
                            <div className="row p-3 ">
                              <div className="row mb-3 ">
                                <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                              </div>

                              <div >
                                <div className="py-2 ps-3" >
                                  <CardHeader className="bg-transparent mb-3">
                                    <h7 className="my-0 text-danger">
                                      <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                    </h7>
                                  </CardHeader>
                                  <CardBody>
                                    <CardText>
                                      <div>
                                        <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); removeService() }}>Delete</button>
                                      </div>
                                    </CardText>
                                  </CardBody>
                                </div>

                              </div>
                            </div>
                          </Collapse>
                        }

                        {
                          <Collapse isOpen={item.edit} >
                            <div className="mt-2">
                              <Nav tabs>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      toggle("1");
                                    }}
                                  >
                                    Assets In scope
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                      toggle("2");
                                    }}
                                  >
                                    In scope
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "3",
                                    })}
                                    onClick={() => {
                                      toggle("3");
                                    }}
                                  >
                                    Out scope
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "4",
                                    })}
                                    onClick={() => {
                                      toggle("4");
                                    }}
                                  >
                                    Prerequisite
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "5",
                                    })}
                                    onClick={() => {
                                      toggle("5");
                                    }}
                                  >
                                    Service Window
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "6",
                                    })}
                                    onClick={() => {
                                      toggle("6");
                                    }}
                                  >
                                    Deliverables
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "7",
                                    })}
                                    onClick={() => {
                                      toggle("7");
                                    }}
                                  >
                                    Resources
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "8",
                                    })}
                                    onClick={() => {
                                      toggle("8");
                                    }}
                                  >
                                    SLA
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "9",
                                    })}
                                    onClick={() => {
                                      toggle("9");
                                    }}
                                  >
                                    Internal Notes
                                  </NavLink>
                                </NavItem>
                              </Nav>

                              <TabContent activeTab={activeTab} className="p-3 text-muted" >

                                <TabPane tabId="1">
                                  <Row style={{ padding: 0, margin: 0 }}>
                                    {
                                      !editAsset && contractServiceAssetSummary && contractServiceAssetSummary.length > 0 &&
                                      <Col lg="3">
                                        <div className="row mb-4 ">

                                          <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                            <label className="text-primary ps-2" style={{ marginBottom: 0 }}>Asset Summary</label>
                                          </div>

                                          <Row style={{ padding: 10 }}>
                                            {
                                              contractServiceAssetSummary && contractServiceAssetSummary.map((smry, idx) => {
                                                return <Col lg={"12"} key={"smryy" + idx} style={{ marginBottom: 7, cursor: "pointer" }} onClick={() => {
                                                  // setSelectedAssetType(smry.name)
                                                  filterServiceAssets(smry.name)
                                                  setSelectedIndex(idx)
                                                }}>
                                                  <Row >
                                                    <Col lg={"9"}>
                                                      <div className={selectedIndex === idx ? "bg-primary text-white" : smry.count > 0 ? "bg-success text-white" : "bg-secondary bg-opacity-75 text-white"} style={{ paddingTop: 2, paddingBottom: 2, borderRadius: 10, paddingLeft: 20 }}>{idx + 1} : {smry.name}</div>
                                                    </Col>
                                                    <Col lg={"2"} >
                                                      <div className={selectedIndex === idx ? "bg-primary text-white" : smry.count > 0 ? "bg-success text-white" : "bg-secondary bg-opacity-75 text-white"} style={{ backgroundColor: "#f0f0f0", textAlign: "center", paddingTop: 2, paddingBottom: 2, borderRadius: 10, }}>
                                                        {smry.count}
                                                      </div></Col>
                                                  </Row>
                                                </Col>
                                              })
                                            }
                                          </Row>
                                        </div>
                                      </Col>
                                    }

                                    <Col lg={editAsset ? "7" : contractServiceAssetSummary && contractServiceAssetSummary.length > 0 ? "9" : "12"}>
                                      <div className="row mb-4">

                                        <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                          <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Assets added for this service</label></Col>
                                          {!editAsset && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                            <button className={editAsset ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditAsset(editAsset ? false : true) }} > {editAsset ? "Close" : "Add"}</button>
                                          </Col>}
                                        </div>

                                        <div className="table-responsive">
                                          <Table className="table table-sm mb-0">
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>Asset Type</th>
                                                {!editAsset && <th>Asset Serial No</th>}
                                                <th>Name</th>
                                                {!editAsset && <th>Configuration</th>}
                                                {!editAsset && <th>Unique Id</th>}
                                                {!editAsset && <th>Environment</th>}
                                                {!editAsset && <th>Action</th>}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                showAssetTable && filteredAssets && filteredAssets.map((item, idx) => {
                                                  return (

                                                    <tr key={"ctrAstlvl" + idx}>
                                                      <th>{idx + 1}</th>
                                                      <td>{item.asset_type}</td>
                                                      {!editAsset && <td>{item.asset_serial_no}</td>}
                                                      <td>{item.asset_name}</td>
                                                      {!editAsset && <td>{item.asset_config}</td>}
                                                      {!editAsset && <td>{item.auid}</td>}
                                                      {!editAsset && <td>{item.asset_env}</td>}
                                                      {!editAsset && <td>
                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                          {
                                                            <li>
                                                              <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-danger"
                                                                onClick={(e) => {
                                                                  e.preventDefault()
                                                                  console.log("am in")
                                                                  // const SData = cellProps.row.original;
                                                                  removeAssetFromServiceContract(selectedService, item)
                                                                  //ColToggleA !== idx ? setColToggleA(idx) : setColToggleA(-1)
                                                                  //deleteService(SData);
                                                                }}
                                                              >
                                                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                  Delete
                                                                </UncontrolledTooltip>
                                                              </Link>
                                                            </li>
                                                          }
                                                        </ul>

                                                      </td>
                                                      }
                                                    </tr>


                                                  )
                                                })
                                              }
                                            </tbody>
                                          </Table>
                                        </div>

                                      </div>

                                    </Col>

                                    {
                                      editAsset &&
                                      <Col lg={editAsset ? "5" : "3"} >
                                        <div className="row mb-4 ">
                                          <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 ms-1" style={{ height: 50 }}>
                                            <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Assets Stock</label></Col>
                                            {editAsset && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                              <button className={editAsset ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditAsset(editAsset ? false : true) }} >Close</button>
                                            </Col>}
                                          </div>
                                          <div style={{ maxHeight: 400, overflow: "auto" }} >
                                            {
                                              astList.map((elem, idx) => {
                                                return (
                                                  <div key={"ast" + idx} className="py-2 w-100 mb-2 position-relative" style={{ borderBottom: "1px solid #e0e0e0", background: "#fff" }}>
                                                    <div className="d-flex flex-row align-items-center ms-2">
                                                      <div style={{ marginRight: 5 }}>
                                                        <Link to="#!" className="btn btn-sm btn-success me-2"
                                                          onClick={() => { moveToContract(item, elem) }}
                                                        ><i className="mdi mdi-plus-thick font-size-15" id="deletetooltip" /></Link>
                                                      </div>
                                                      <div style={{}} className="ms-2">
                                                        <div className="d-flex flex-column ">
                                                          {/* <div className="me-2"><i className={elem.asset_icon} style={{ fontSize: 14 }} id="edittooltip" />  </div> */}
                                                          <div className="fw-bold">{elem.asset_name}</div>
                                                          <div className="font-size-12 text-secondary" >{elem.asset_type}</div>
                                                          <div className="font-size-12 text-secondary" >AUID - {elem.auid}, Serial No - {elem.asset_serial_no}</div>
                                                        </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })

                                            }
                                          </div>
                                        </div>
                                      </Col>
                                    }
                                  </Row>

                                </TabPane>

                                <TabPane tabId="2">

                                  <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                    <Col lg={3}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Inscope</label></Col>
                                    <Col lg={9} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                      {
                                        inscopeReset && <div className="d-flex flex-row align-items-center bg-white  px-3 me-2" style={{ borderRadius: 25 }} >
                                          <>
                                            <label style={{ marginBottom: 0, marginRight: 10 }} className="text-danger">Are you sure you want to reset? </label>
                                            <Badge className="badge-soft-success me-2" style={{ cursor: "pointer" }} onClick={() => {
                                              dispatch(resetServiceContent(item))
                                              setInscopeReset(false)

                                            }}> Yes </Badge>
                                            <Badge className="badge-soft-danger me-2" style={{ cursor: "pointer" }} onClick={() => {
                                              setInscopeReset(false)
                                            }}> No </Badge>
                                          </>
                                        </div>
                                      }
                                      {editInscope && !inscopeReset && <button className="btn btn-sm btn-primary me-2"
                                        onClick={() => {
                                          setInscopeReset(!inscopeReset)
                                        }}
                                      >Restore</button>}
                                      {editInscope && <button className="btn btn-sm btn-success me-2"
                                        onClick={() => { setInscopeReset(false); saveInsocpe(item) }}
                                      >Save</button>}
                                      <button className={editInscope ? "btn btn-sm btn-danger me-2" : "btn btn-sm btn-primary me-2"}
                                        onClick={() => {
                                          setInscopeReset(false);
                                          setEditScope(editInscope ? false : true)
                                        }} > {editInscope ? "Close" : "Edit"}
                                      </button>
                                    </Col>
                                  </div>

                                  <Row>
                                    {
                                      editInscope ? <Col lg="12">
                                        <CKEditor
                                          config={{ placeholder: "Type the In_scope Here..!" }}
                                          editor={ClassicEditor}
                                          data={item.in_scope}
                                          onChange={handleCkeditorIn}
                                          value={inScope}

                                        />
                                      </Col> :
                                        <Col lg="12">
                                          <div>
                                            {ReactHtmlParser(item.in_scope)}
                                          </div>
                                        </Col>
                                    }

                                  </Row>
                                </TabPane>

                                <TabPane tabId="3">
                                  <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                    <Col lg={3}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Out scope</label></Col>
                                    <Col lg={9} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                      {
                                        outscopeReset && <div className="d-flex flex-row align-items-center bg-white  px-3 me-2" style={{ borderRadius: 25 }} >
                                          <>
                                            <label style={{ marginBottom: 0, marginRight: 10 }} className="text-danger">Are you sure you want to reset? </label>
                                            <Badge className="badge-soft-success me-2" style={{ cursor: "pointer" }} onClick={() => {
                                              dispatch(resetServiceContent(item))
                                              setOutscopeReset(false)

                                            }}> Yes </Badge>
                                            <Badge className="badge-soft-danger me-2" style={{ cursor: "pointer" }} onClick={() => {
                                              setOutscopeReset(false)
                                            }}> No </Badge>
                                          </>
                                        </div>
                                      }
                                      {editOutscope && !outscopeReset && <button className="btn btn-sm btn-primary me-2"
                                        onClick={() => {
                                          setOutscopeReset(!outscopeReset)
                                        }}
                                      >Restore</button>}
                                      {editOutscope && <button className="btn btn-sm btn-success me-2"
                                        onClick={() => { setOutscopeReset(false); saveOutscope(item) }}
                                      >Save</button>}
                                      <button className={editOutscope ? "btn btn-sm btn-danger me-2" : "btn btn-sm btn-primary me-2"}
                                        onClick={() => {
                                          setOutscopeReset(false);
                                          setEditOutScope(editOutscope ? false : true)
                                        }} > {editOutscope ? "Close" : "Edit"}
                                      </button>
                                    </Col>
                                  </div>

                                  <Row>
                                    {
                                      editOutscope ?
                                        <Col sm="12">
                                          <CKEditor
                                            config={{ placeholder: "Type the Out_scope Here..!" }}
                                            editor={ClassicEditor}
                                            data={item.out_scope}
                                            onChange={handleCkeditorOut}
                                            value={outScope}
                                          />
                                        </Col> :
                                        <Col lg="12">
                                          <div>
                                            {ReactHtmlParser(item.out_scope)}
                                          </div>
                                        </Col>
                                    }
                                  </Row>
                                </TabPane>

                                <TabPane tabId="4">
                                  <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                    <Col lg={3}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Prerequisite</label></Col>
                                    <Col lg={9} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                      {
                                        prerqReset && <div className="d-flex flex-row align-items-center bg-white  px-3 me-2" style={{ borderRadius: 25 }} >
                                          <>
                                            <label style={{ marginBottom: 0, marginRight: 10 }} className="text-danger">Are you sure you want to reset? </label>
                                            <Badge className="badge-soft-success me-2" style={{ cursor: "pointer" }} onClick={() => {
                                              dispatch(resetServiceContent(item))
                                              setPrerqReset(false)

                                            }}> Yes </Badge>
                                            <Badge className="badge-soft-danger me-2" style={{ cursor: "pointer" }} onClick={() => {
                                              setPrerqReset(false)
                                            }}> No </Badge>
                                          </>
                                        </div>
                                      }
                                      {editPrerq && !prerqReset && <button className="btn btn-sm btn-primary me-2"
                                        onClick={() => {
                                          setPrerqReset(!prerqReset)
                                        }}
                                      >Restore</button>}
                                      {editPrerq && <button className="btn btn-sm btn-success me-2"
                                        onClick={() => { setPrerqReset(false); savePrerequisite(item) }}
                                      >Save</button>}
                                      <button className={editPrerq ? "btn btn-sm btn-danger me-2" : "btn btn-sm btn-primary me-2"}
                                        onClick={() => {
                                          setPrerqReset(false);
                                          setEditPrerq(editPrerq ? false : true)
                                        }} > {editPrerq ? "Close" : "Edit"}
                                      </button>
                                    </Col>
                                  </div>
                                  <Row>
                                    {
                                      editPrerq ?
                                        <Col sm="12">

                                          <CKEditor
                                            config={{ placeholder: "Type the Prerequisite Here..!" }}
                                            editor={ClassicEditor}
                                            data={item.prerequisite}
                                            onChange={handleCkeditorPre}
                                            value={prerequisite}
                                          />
                                        </Col> :
                                        <Col lg="12">
                                          <div>
                                            {ReactHtmlParser(item.prerequisite)}
                                          </div>
                                        </Col>
                                    }
                                  </Row>
                                </TabPane>

                                <TabPane tabId="5">
                                  <Row style={{ padding: 0, margin: 0 }}>
                                    <Col lg={editService ? "7" : "12"}>
                                      <div className="row mb-4 ">

                                        <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                          <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Service Window added for this service</label></Col>
                                          {!editService && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                            <button className={editService ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditService(editService ? false : true) }} > {editService ? "Close" : "Add"}</button>
                                          </Col>}
                                        </div>

                                        <Row style={{ padding: 10 }}>
                                          <div className="table-responsive">
                                            <Table className="table table-sm mb-0">
                                              <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Name</th>
                                                  <th>Work Hours</th>
                                                  {!editService && <th>Description</th>}
                                                  {!editService && <th>Action</th>}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  contractServiceSWList && contractServiceSWList.map((item, idx) => {
                                                    return (
                                                      <>
                                                        <tr key={"ctrAstlvl" + idx}>
                                                          <th>{idx + 1}</th>
                                                          <td>{item.name}</td>
                                                          <td>{item.hours_in_day} / {item.day_in_week}</td>
                                                          {!editService && <td>{item.description}</td>}
                                                          {!editService && <td>
                                                            <ul className="list-unstyled hstack gap-1 mb-0">
                                                              {
                                                                <li>
                                                                  <Link
                                                                    to="#"
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={(e) => {
                                                                      e.preventDefault()
                                                                      console.log("am in")
                                                                      // const SData = cellProps.row.original;
                                                                      removeSWFromServiceContract(selectedService, item)
                                                                      //ColToggleA !== idx ? setColToggleA(idx) : setColToggleA(-1)
                                                                      //deleteService(SData);
                                                                    }}
                                                                  >
                                                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                      Delete
                                                                    </UncontrolledTooltip>
                                                                  </Link>
                                                                </li>
                                                              }
                                                            </ul>

                                                          </td>
                                                          }
                                                        </tr>

                                                      </>
                                                    )
                                                  })
                                                }
                                              </tbody>
                                            </Table>
                                          </div>

                                        </Row>
                                      </div>
                                    </Col>

                                    {
                                      editService &&
                                      <Col lg={editService ? "5" : "3"} >
                                        <div className="row mb-4 ">
                                          <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 ms-1" style={{ height: 50 }}>
                                            <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Service Window</label></Col>
                                            {editService && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                              <button className={editService ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditService(editService ? false : true) }} >Close</button>
                                            </Col>}
                                          </div>
                                          <div style={{ maxHeight: 400, overflow: "auto" }} >
                                            {
                                              cmpConfig.service_window.map((elem, idx) => {
                                                return (
                                                  <div key={"ast" + idx} className="py-2 w-100 mb-2 position-relative" style={{ borderBottom: "1px solid #e0e0e0", background: "#fff" }}>
                                                    <div className="d-flex flex-row align-items-center ms-2">
                                                      <div style={{ marginRight: 5 }}>
                                                        <Link to="#!" className="btn btn-sm btn-success me-2"
                                                          onClick={() => { moveToSWContract(item, elem) }}
                                                        ><i className="mdi mdi-plus-thick font-size-15" id="deletetooltip" /></Link>
                                                      </div>
                                                      <div style={{}} className="ms-2">
                                                        <div className="d-flex flex-column ">
                                                          <div className="fw-bold">{elem.name}</div>
                                                          <div className="font-size-12 text-secondary" >{elem.hours_in_day} Hours in day / {elem.day_in_week} Day in week</div>
                                                          <div className="font-size-12 text-secondary" >Description - {elem.description}</div>
                                                        </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })

                                            }
                                          </div>
                                        </div>
                                      </Col>
                                    }
                                  </Row>
                                </TabPane>

                                <TabPane tabId="6">

                                  <Row style={{ padding: 0, margin: 0 }}>
                                    <Col lg={editDeliverables ? "7" : "12"}>
                                      <div className="row mb-4 ">

                                        <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                          <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Deliverables added for this service</label></Col>
                                          {!editDeliverables && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                            <button className={editDeliverables ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditDeliverables(editDeliverables ? false : true) }} > {editDeliverables ? "Close" : "Add"}</button>
                                          </Col>}
                                        </div>

                                        <Row style={{ padding: 10 }}>
                                          <div className="table-responsive">
                                            <Table className="table table-sm mb-0">
                                              <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>DID</th>
                                                  <th>Type</th>
                                                  <th>Frequency</th>
                                                  <th>Name</th>
                                                  {!editDeliverables && <th>Deliverable Action</th>}
                                                  {!editDeliverables && <th>Action</th>}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  contractServiceDeliverableList && contractServiceDeliverableList.map((item, idx) => {
                                                    return (
                                                      <>
                                                        <tr key={"delvre" + idx}>
                                                          <th>{idx + 1}</th>
                                                          <td>{item.deliverable_id}</td>
                                                          <td>{item.type}</td>
                                                          <td>{item.frequency}</td>
                                                          <td>{item.name}</td>
                                                          {!editDeliverables && <td>{ReactHtmlParser(item.action)}</td>}
                                                          {!editDeliverables && <td>
                                                            <ul className="list-unstyled hstack gap-1 mb-0">
                                                              <li>
                                                                <Link
                                                                  to="#"
                                                                  className="btn btn-sm btn-info"
                                                                  onClick={() => {
                                                                    const data = item;
                                                                   // editData(data);
                                                                   editDeliverable(data)
                                                                  }}
                                                                >
                                                                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                                                  <UncontrolledTooltip placement="top" target="edittooltip">
                                                                    Edit
                                                                  </UncontrolledTooltip>
                                                                </Link>
                                                              </li>

                                                              {
                                                                <li>
                                                                  <Link
                                                                    to="#"
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={(e) => {
                                                                      e.preventDefault()
                                                                      console.log("am in")
                                                                      // const SData = cellProps.row.original;
                                                                      removeDeliverableFromServiceContract(selectedService, item)
                                                                      //ColToggleA !== idx ? setColToggleA(idx) : setColToggleA(-1)
                                                                      //deleteService(SData);
                                                                    }}
                                                                  >
                                                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                      Delete
                                                                    </UncontrolledTooltip>
                                                                  </Link>
                                                                </li>
                                                              }
                                                            </ul>

                                                          </td>
                                                          }
                                                        </tr>

                                                      </>
                                                    )
                                                  })
                                                }
                                              </tbody>
                                            </Table>
                                          </div>

                                        </Row>
                                      </div>
                                    </Col>

                                    {
                                      editDeliverables &&
                                      <Col lg={editDeliverables ? "5" : "3"} >
                                        <div className="row mb-4 ">
                                          <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 ms-1" style={{ height: 50 }}>
                                            <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Deliverables</label></Col>
                                            {editDeliverables && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                              <button className={editDeliverables ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditDeliverables(editDeliverables ? false : true) }} >Close</button>
                                            </Col>}
                                          </div>
                                          <div style={{ maxHeight: 400, overflow: "auto" }} >
                                            {
                                              companyDeliverableList && companyDeliverableList.map((elem, idx) => {
                                                return (
                                                  <div key={"ast" + idx} className="py-2 w-100 mb-2 position-relative" style={{ borderBottom: "1px solid #e0e0e0", background: "#fff" }}>
                                                    <div className="d-flex flex-row align-items-center ms-2">
                                                      <div style={{ marginRight: 5 }}>
                                                        <Link to="#!" className="btn btn-sm btn-success me-2"
                                                          onClick={() => { moveToContractDeliverables(item, elem) }}
                                                        ><i className="mdi mdi-plus-thick font-size-15" id="deletetooltip" /></Link>
                                                      </div>
                                                      <div style={{}} className="ms-2">
                                                        <div className="d-flex flex-column ">
                                                          <div className="fw-bold">{elem.type}</div>
                                                          <div className="font-size-12 text-secondary" >{elem.name}</div>
                                                          {/* <div className="font-size-12 text-secondary" >Description - {elem.description}</div> */}
                                                        </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })

                                            }
                                          </div>
                                        </div>
                                      </Col>
                                    }
                                  </Row>

                                </TabPane>

                                <TabPane tabId="7">

                                  <Row style={{ padding: 0, margin: 0 }}>
                                    <Col lg={editResources ? "7" : "12"}>
                                      <div className="row mb-4 ">

                                        <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                          <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Resources added for this service</label></Col>
                                          {!editResources && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                            <button className={editResources ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditResources(editResources ? false : true) }} >Add</button>
                                          </Col>}
                                        </div>

                                        <Row style={{ padding: 10 }}>
                                          <div className="table-responsive">
                                            <Table className="table table-sm mb-0">
                                              <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Resource Level</th>
                                                  <th>Type</th>
                                                  <th>Quantity</th>
                                                  {!editResources && <th>Remarks</th>}
                                                  {!editResources && <th>Action</th>}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  contractServiceResourceList && contractServiceResourceList.map((item, idx) => {
                                                    return (
                                                      <>
                                                        <tr key={"delvr" + idx}>
                                                          <th>{idx + 1}</th>
                                                          <td>{item.resource_level}</td>
                                                          <td>{item.resource_type}</td>
                                                          <td>{item.quantity}</td>
                                                          {!editResources && <td>{item.remarks}</td> }
                                                          {!editResources && <td>
                                                            <ul className="list-unstyled hstack gap-1 mb-0">
                                                              {
                                                                <li>
                                                                  <Link
                                                                    to="#"
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={(e) => {
                                                                      e.preventDefault()
                                                                      console.log("am in")
                                                                      // const SData = cellProps.row.original;
                                                                      removeResources(selectedService, item)
                                                                      //ColToggleA !== idx ? setColToggleA(idx) : setColToggleA(-1)
                                                                      //deleteService(SData);
                                                                    }}
                                                                  >
                                                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                      Delete
                                                                    </UncontrolledTooltip>
                                                                  </Link>
                                                                </li>
                                                              }
                                                            </ul>

                                                          </td>
                                                          }
                                                        </tr>

                                                      </>
                                                    )
                                                  })
                                                }
                                              </tbody>
                                            </Table>
                                          </div>

                                        </Row>
                                      </div>
                                    </Col>

                                    {
                                      editResources &&
                                      <Col lg={editResources ? "5" : "3"} >
                                        <div className="row mb-4 ">
                                          <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 ms-1" style={{ height: 50 }}>
                                            <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Add Resources</label></Col>
                                            {editResources && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                              <button className={editResources ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditResources(editResources ? false : true) }} >Close</button>
                                            </Col>}
                                          </div>
                                          <div style={{ maxHeight: 400, overflow: "auto", padding: 10 }} >
                                            <Form
                                              className="form-horizontal"
                                              onSubmit={(e) => {
                                                e.preventDefault();
                                                resourcesPlanningValidation.handleSubmit();
                                                //onCompanyEscSubmit()
                                                return false;
                                              }}
                                            >
                                              <Row className="align-items-end mb-4 pb-4" style={{ borderBottom: "1px solid #ededed" }}>

                                                <Col lg={"12"} className="mb-3">
                                                  <div className="">
                                                    <Label htmlFor="resource_level">Resource Level</Label>
                                                    <Input
                                                      name="resource_level"
                                                      className="form-control"
                                                      placeholder="Ex. 'Sr. Tech Support Associate'"
                                                      type="text"
                                                      onChange={resourcesPlanningValidation.handleChange}
                                                      onBlur={resourcesPlanningValidation.handleBlur}
                                                      value={resourcesPlanningValidation.values.resource_level}
                                                      required
                                                      invalid={
                                                        resourcesPlanningValidation.touched.resource_level && resourcesPlanningValidation.errors.resource_level ? true : false
                                                      }
                                                    />
                                                    {resourcesPlanningValidation.touched.resource_level && resourcesPlanningValidation.errors.resource_level ? (
                                                      <FormFeedback type="invalid">{resourcesPlanningValidation.errors.resource_level}</FormFeedback>
                                                    ) : null}
                                                  </div>
                                                </Col>

                                                <Row>

                                                  <Col lg={6} className="mb-3">
                                                    <div className="">
                                                      <Label className="">Resource type</Label>
                                                      <div className="d-flex flex-column ">
                                                        <select className="form-select" onChange={(e) => {
                                                          setResourceType(e.target.value)
                                                        }}
                                                          defaultValue={resourceType}
                                                        >
                                                          <option value="0" disabled>Choose...</option>
                                                          {
                                                            resource_type.map((item, idx) => {
                                                              return (
                                                                <option value={item.value} key={"tpe" + idx} >{item.name}</option>
                                                              )
                                                            })
                                                          }



                                                        </select>
                                                      </div>
                                                    </div>
                                                  </Col>

                                                  <Col lg={"6"} className="mb-3">
                                                    <div className="">
                                                      <Label htmlFor="quantity">Quantity</Label>
                                                      <Input
                                                        name="quantity"
                                                        className="form-control"
                                                        placeholder="Quantity"
                                                        type="number"
                                                        onChange={resourcesPlanningValidation.handleChange}
                                                        onBlur={resourcesPlanningValidation.handleBlur}
                                                        value={resourcesPlanningValidation.values.quantity}
                                                        required
                                                        invalid={
                                                          resourcesPlanningValidation.touched.quantity && resourcesPlanningValidation.errors.quantity ? true : false
                                                        }
                                                      />
                                                      {resourcesPlanningValidation.touched.quantity && resourcesPlanningValidation.errors.quantity ? (
                                                        <FormFeedback type="invalid">{resourcesPlanningValidation.errors.quantity}</FormFeedback>
                                                      ) : null}
                                                    </div>
                                                  </Col>


                                                </Row>




                                                <Col lg={"12"} className="mb-3">
                                                  <div className="">
                                                    <Label htmlFor="remarks">Remarks</Label>
                                                    <Input
                                                      name="remarks"
                                                      className="form-control"
                                                      placeholder="Remars"
                                                      type="textarea"
                                                      onChange={resourcesPlanningValidation.handleChange}
                                                      onBlur={resourcesPlanningValidation.handleBlur}
                                                      value={resourcesPlanningValidation.values.remarks}
                                                      required
                                                      invalid={
                                                        resourcesPlanningValidation.touched.remarks && resourcesPlanningValidation.errors.remarks ? true : false
                                                      }
                                                    />
                                                    {resourcesPlanningValidation.touched.remarks && resourcesPlanningValidation.errors.remarks ? (
                                                      <FormFeedback type="invalid">{resourcesPlanningValidation.errors.remarks}</FormFeedback>
                                                    ) : null}
                                                  </div>
                                                </Col>

                                                <Col lg={"12"}>
                                                  <div>
                                                    <button type="submit" className="btn btn-outline-danger w-md" >
                                                      {"Add"}
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>

                                            </Form>

                                          </div>
                                        </div>
                                      </Col>
                                    }
                                  </Row>



                                </TabPane>

                                <TabPane tabId="8">

                                  <Row style={{ padding: 0, margin: 0 }}>
                                    <Col lg={editSLA ? "7" : "12"}>
                                      <div className="row mb-4 ">

                                        <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                          <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Deliverables added for this service</label></Col>
                                          {!editSLA && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                            <button className={editSLA ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditSLA(editSLA ? false : true) }} > {editSLA ? "Close" : "Add"}</button>
                                          </Col>}
                                        </div>

                                        <Row style={{ padding: 10 }}>
                                          <div className="table-responsive">
                                            <Table className="table table-sm mb-0">
                                              <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Impact Type</th>
                                                  <th>Name</th>
                                                  <th>Response Time</th>
                                                  <th>Resolution Time</th>
                                                  {!editSLA && <th>Definition</th>}
                                                  {!editSLA && <th>Action</th>}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  contractServiceSLAList && contractServiceSLAList.map((item, idx) => {
                                                    return (
                                                      <>
                                                        <tr key={"delvr" + idx}>
                                                          <th>{idx + 1}</th>
                                                          <td>{item.impact_type}</td>
                                                          <td>{item.impact_name}</td>
                                                          <td>{item.response_time}</td>
                                                          <td>{item.resolution_time}</td>
                                                          {!editSLA && <td>{item.definition}</td>}
                                                          {!editSLA && <td>
                                                            <ul className="list-unstyled hstack gap-1 mb-0">
                                                              {
                                                                <li>
                                                                  <Link
                                                                    to="#"
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={(e) => {
                                                                      e.preventDefault()
                                                                      console.log("am in")
                                                                      removeSLAFromServiceContract(selectedService, item)
                                                                    }}
                                                                  >
                                                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                      Delete
                                                                    </UncontrolledTooltip>
                                                                  </Link>
                                                                </li>
                                                              }
                                                            </ul>

                                                          </td>
                                                          }
                                                        </tr>

                                                      </>
                                                    )
                                                  })
                                                }
                                              </tbody>
                                            </Table>
                                          </div>

                                        </Row>
                                      </div>
                                    </Col>

                                    {
                                      editSLA &&
                                      <Col lg={editSLA ? "5" : "3"} >
                                        <div className="row mb-4 ">
                                          <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 ms-1" style={{ height: 50 }}>
                                            <Col lg={10}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>SLA</label></Col>
                                            {editSLA && <Col lg={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>
                                              <button className={editSLA ? "btn btn-sm btn-danger" : "btn btn-sm btn-primary"} onClick={() => { setEditSLA(editSLA ? false : true) }} >Close</button>
                                            </Col>}
                                          </div>
                                          <div style={{ maxHeight: 400, overflow: "auto" }} >
                                            {
                                              companySLAList && companySLAList.map((elem, idx) => {
                                                return (
                                                  <div key={"ast" + idx} className="py-2 w-100 mb-2 position-relative" style={{ borderBottom: "1px solid #e0e0e0", background: "#fff" }}>
                                                    <div className="d-flex flex-row align-items-center ms-2">
                                                      <div style={{ marginRight: 5 }}>
                                                        <Link to="#!" className="btn btn-sm btn-success me-2"
                                                          onClick={() => { moveToContractSLAs(item, elem) }}
                                                        ><i className="mdi mdi-plus-thick font-size-15" id="deletetooltip" /></Link>
                                                      </div>
                                                      <div style={{}} className="ms-2">
                                                        <div className="d-flex flex-column ">
                                                          <div className="fw-bold">{elem.impact_type}</div>
                                                          <div className="font-size-12 text-secondary" >{elem.impact_name}</div>
                                                          <div className="font-size-12 text-secondary" >Response time - {elem.response_time}, Resolution time - {elem.resolution_time}</div>
                                                        </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })

                                            }
                                          </div>
                                        </div>
                                      </Col>
                                    }
                                  </Row>

                                </TabPane>

                                <TabPane tabId="9">
                                  <div className="bg-primary bg-opacity-10 d-flex flex-row align-items-center mb-3 " style={{ height: 50 }}>
                                    <Col lg={3}><label className="text-primary ps-2" style={{ marginBottom: 0 }}>Internal Notes</label></Col>
                                    <Col lg={9} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 10 }}>

                                      <button className="btn btn-sm btn-success me-2"
                                        onClick={() => { saveInternalNotes(item) }}
                                      >Save</button>
                                    </Col>
                                  </div>
                                  <Row>
                                    <Col sm="12">
                                      <CKEditor
                                        config={{ placeholder: "Type your note here..." }}
                                        editor={ClassicEditor}
                                        data={item.internal_notes}
                                        onChange={handleCkeditorIN}
                                        value={internalNotes}
                                      />
                                    </Col>
                                  </Row>
                                </TabPane>

                              </TabContent>

                            </div>

                          </Collapse>
                        }


                      </div>
                    )
                  })
                }
              </>
            }



            <Modal
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop();
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Delete Service</h5>
                <button type="button" className="btn-close"
                  onClick={() => {
                    setmodal_backdrop(false);
                  }} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Are you sure, you want to delete this service?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={() => {
                  setmodal_backdrop(false);
                }}>Close</button>
                <button type="button" className="btn btn-danger" onClick={() => {
                  removeService()
                }}>Yes, delete it</button>
              </div>
            </Modal>

            <Offcanvas
                  isOpen={isRight}
                  backdrop={"static"}
                  direction="end"
                  toggle={toggleRightCanvas}
                >
                  <OffcanvasHeader
                    toggle={toggleRightCanvas}
                    style={{ borderBottom: "1px solid #dedede" }}
                  >
                    Edit Deliverable
                  </OffcanvasHeader>
                  <OffcanvasBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        {/* <div className="mb-3">
                          <Label htmlFor="deliverable_id">Deliverable ID</Label>
                          <Input
                            name="deliverable_id"
                            className="form-control"
                            placeholder="Enter Deliverable ID"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.deliverable_id || ""}
                            disabled
                            invalid={
                              validation.touched.deliverable_id &&
                                validation.errors.deliverable_id
                                ? true
                                : false
                            }
                          />
                          {validation.touched.deliverable_id &&
                            validation.errors.deliverable_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.deliverable_id}
                            </FormFeedback>
                          ) : null}
                        </div> */}

                        {
                          companyConfig && <div className="mb-4">
                            <label className="" htmlFor="autoSizingSelect">Deliverable Type</label>
                            <select className="form-select" onChange={(e) => {
                              setDelError("")
                              setDeliverableType(e.target.value)
                            }}
                              defaultValue={deliverableType}
                            >
                              <option value="0" disabled>Choose...</option>
                              {
                                companyConfig.deliverable_type.map((item, idx) => {
                                  return (
                                    <option key={"cnst" + idx} value={item.id}>{item.name}</option>
                                  )
                                })
                              }
                            </select>
                            {
                              deliverableError !== "" && <label className="font-size-11 text-danger py-1 px-1">{deliverableError}</label>
                            }
                          </div>
                        }

{
                          companyConfig && <div className="mb-4">
                            <label className="" htmlFor="autoSizingSelect">Frequency</label>
                            <select className="form-select" onChange={(e) => {
                              setFreqError("")
                              setFrequency(e.target.value)
                            }}
                              defaultValue={frequency}
                            >
                              <option value="0" disabled>Choose...</option>
                              {
                                companyConfig.frequencies.map((item, idx) => {
                                  return (
                                    <option key={"freqq" + idx} value={item.id}>{item.name}</option>
                                  )
                                })
                              }
                            </select>
                            {
                              freqError !== "" && <label className="font-size-11 text-danger py-1 px-1">{freqError}</label>
                            }
                          </div>
                        }

                        <div className="mb-3">
                          <Label htmlFor="name"> Name</Label>
                          <Input
                            name="name"
                            className="form-control"
                            placeholder="Enter Deliverable Name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name &&
                                validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name &&
                            validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 ">
                          <Label htmlFor="how_to">Action</Label>
                          <CKEditor
                            config={{
                              placeholder: "Type the How to  Here..!",
                            }}
                            editor={ClassicEditor}
                            data={how_to}
                            onChange={handleCkeditorDeliverables}
                            value={how_to}
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="remarks"> Remarks </Label>
                          <Input
                            name="remarks"
                            className="form-control"
                            placeholder="Enter Description "
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.remarks || ""}
                            invalid={
                              validation.touched.remarks &&
                                validation.errors.remarks
                                ? true
                                : false
                            }
                          />
                          {validation.touched.remarks &&
                            validation.errors.remarks ? (
                            <FormFeedback type="invalid">
                              {validation.errors.remarks}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div>
                          <button type="submit" className="btn btn-primary w-md">
                            {mode === "edit" ? "Update" : "Submit"}
                          </button>
                        </div>
                      </Row>





                    </Form>
                  </OffcanvasBody>
                </Offcanvas>


          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ConfigureContract
