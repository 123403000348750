import React,{useMemo} from "react";
import { Container, Row, Col, Card } from "reactstrap";


const ServiceWindow = (props) => {

  //meta title
  document.title = "Pentabrains | Customer Support Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceWindow;
