import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    customerList: null,
    isSuccessfullyAdded:false,
    responseVendorError:"",
    vendorList: null
};


const manageVendorSlice = createSlice({
    name: "manageVendor",
    initialState: initialRegState,
    reducers: {

        setVendorList: (state, action) => {
            state.vendorList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        setVendorSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = true
        },
        resetVendorSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = false
        },
        resetVendorList: (state, action) => {
            state.vendorList = null
        },
        setVenResponseError:(state, action) => {
            state.responseVendorError = action.payload
        },
        resetVenResponseError:(state, action) => {
            state.responseVendorError = null
        }
        
    }

})

export const createVendor = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetVendorSuccessfullyAdded())
            const responseData = await post("/manage_vendor/add-vendor", {
                "vendorInfo": values
            })
            console.log("log --", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setVendorSuccessfullyAdded())
            }
            else
            {
                dispatch(setVenResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterVendor = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetVendorSuccessfullyAdded())
            const responseData = await post("/manage_vendor/update-vendor", {
                "vendorInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setVendorSuccessfullyAdded())
            }
            else
            {
                dispatch(setVenResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchVendors = (company_id) => {
    return async (dispatch) => {
        try {
            const responseData = await get("/manage_vendor/fetch-vendor")
            if(responseData.response_code === 500)
            {
                console.log("response", responseData)
                if(responseData.data.length > 0)
                {
                    dispatch(setVendorList(responseData.data))
                }
                else
                {
                    dispatch(resetVendorList())
                }
            }
            else
            {
                dispatch(setVenResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterVendors = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetVendorSuccessfullyAdded())
            const responseData = await post("/manage_vendor/delete-vendor", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setVendorSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setVendorList, 
    setVendorSuccessfullyAdded, 
    resetVendorSuccessfullyAdded,
    resetVendorList,
    setVenResponseError,
    resetVenResponseError
} = manageVendorSlice.actions;

export default manageVendorSlice.reducer;
