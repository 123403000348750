import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    userList: null,
    isSuccessfullyAdded:false,
    responseUserError:""
};


const manageUserSlice = createSlice({
    name: "manageUser",
    initialState: initialRegState,
    reducers: {

        setUserList: (state, action) => {
            state.userList = action.payload
        },
        setUserSuccessfullyAdded:(state, action) => {
            state.responseUserError = null
            state.isSuccessfullyAdded = true
        },
        resetUserSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = false
        },
        resetUserList: (state, action) => {
            state.userList = null
        },

        setUserResponseError:(state, action) => {
            state.responseUserError = action.payload
        },
        resetUserResponseError:(state, action) => {
            state.responseUserError = null
        }
        
    }

})

export const createUser = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetUserSuccessfullyAdded())
            const responseData = await post("/manage_users/add-user", {
                "userInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setUserSuccessfullyAdded())
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateUserInfo = (values, updateUserId) => {
    return async (dispatch) => {
        try {
            values["_id"] = updateUserId
            dispatch(resetUserSuccessfullyAdded())
            const responseData = await post("/manage_users/update-user", {
                "userInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setUserSuccessfullyAdded())
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateUserProfile = (values, updateUserId) => {
    return async (dispatch) => {
        try {
            values["_id"] = updateUserId
            dispatch(resetUserSuccessfullyAdded())
            const responseData = await post("/manage_users/update-user", {
                "userInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                localStorage.setItem("authUser", JSON.stringify(responseData.data));
                dispatch(setUserSuccessfullyAdded())
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const sendEmails = (userId) => {
    return async (dispatch) => {
        try {
            const responseData = await post(`/manage_users/send-emails/${userId}`);
            console.log("send emails response", responseData);
            if(responseData.response_code === 500)
            {
                dispatch(setUserList(responseData.data))
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }

        } catch (error) {
            console.log("Error sending emails", error);
        }
    };
};

export const fetchUsers = (company_id) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_users/get-users", {
                "company_id":company_id
            })
            console.log("get users", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setUserList(responseData.data))
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }

            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteUsers = (uid) => {
    return async (dispatch) => {
        try {
            dispatch(resetUserList())
            const responseData = await post("/manage_users/delete-user", {
                "UID":uid
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                //dispatch(setUserSuccessfullyAdded())
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }

            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const resetPassword = (userID, password) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_users/pwd-reset", {
                "UID":userID,
                "password":password
            })
            console.log("password reset", responseData)
            if(responseData.response_code === 500)
            {
                //dispatch(setUserSuccessfullyAdded())
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const changePassword = (userID, passwordInfo) => {
    return async (dispatch) => {
        try {
           // dispatch(resetUserResponseError())
            const responseData = await post("/manage_users/change-pwd", {
                "UID":userID,
                "passwordInfo":passwordInfo
            })
            console.log("password changed", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(resetUserResponseError())
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setUserList, 
    resetUserList, 
    setUserSuccessfullyAdded, 
    resetUserSuccessfullyAdded,
    setUserResponseError,
    resetUserResponseError
} = manageUserSlice.actions;

export default manageUserSlice.reducer;
