import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";
// import { useSocketContext } from "helpers/socket";


const initialRegState = {
    companyInfo: null,
    userChatHistory:null,
    appUsers:null
};




const socketSlice = createSlice({
    name: "socketData",
    initialState: initialRegState,
    reducers: {
        setUserChatHistory:(state,action) => {
            state.userChatHistory = action.payload
        },
        setAppUsers:(state,action) => {

            let data = _.groupBy(action.payload, item => item.first_name[0].toUpperCase());

            // let data = action.payload.reduce((r, e) => {
            //     // get first letter of name of current element
            //     let group = e.first_name[0];
            //     let groups = []
            //     // if there is no property in accumulator with this letter create it
            //     groups.push({group, children: [e]})

            //     // if(!r[group]) r[group] = {group, children: [e]}
            //     // // if there is push current element to children array for that letter
            //     // else r[group].children.push(e);
            //     // return accumulator
            //     return groups;
            //   }, {})
              console.log("data", data)
            state.appUsers = data
        }
    }
})

export const sendMessage = (sender, receiver, message, socket) => {
    return async dispatch => {
        
        try {
            
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const loadUserChatHistory = (userInfo, contactInfo) => {
    return async dispatch => {
        try {
            const responseData = await post("/message-io/get_chat", {
                senderId:userInfo._id,
                receiverId:contactInfo._id
            })

            console.log("message response", responseData)

            if(responseData.response_code === 500)
            {
                dispatch(setUserChatHistory(responseData.data))
            }
        } catch (error) {
            console.log("catch error", error)
        }
    }
}


export const fetchAppUsers = (company_id, user_id) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_users/fetch_app_users", {
                "company_id":company_id,
                user_id:user_id
            })
            console.log("get users", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setAppUsers(responseData.data))
            }
            else{
                dispatch(setUserResponseError(responseData.message))
            }

            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setUserChatHistory,
    setAppUsers
} = socketSlice.actions;

export default socketSlice.reducer;