import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";
// import socket from "helpers/socket";

import io from "socket.io-client";
  

const initialRegState = {
    userInfo: null,
    isAuthenticated: false,
    authError: "",
    companyConfig:null
};


const authenticationSlice = createSlice({
    name: "authenticate",
    initialState: initialRegState,
    reducers: {

        setUserInfo: (state, action) => {
            console.log("am in --", action.payload)
        },
        setCompanyConfig:(state,action) => {
            state.companyConfig = action.payload
            localStorage.setItem("company-config", JSON.stringify(action.payload));
        },
        authUser: (state, action) => {
            
            let responseData = action.payload
            if (responseData.response_code === 500) {
                state.isAuthenticated = true
                state.authError = ""
                state.userInfo = responseData.data.userInfo
                localStorage.setItem("authUser", JSON.stringify(responseData.data.userInfo));
                localStorage.setItem("company-config", JSON.stringify(responseData.data.companyInfo));

                var userInfo = responseData.data.userInfo

                const socket = io.connect('http://localhost:8060/', {
                    reconnection: true,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000,
                    reconnectionAttempts: Infinity,
                    query: {
                        userId: userInfo._id,
                    },
                });

                socket.connect();
                ///soket connected to server
                socket.on('connect', () => {
                    console.log('Socket connected to server');
                });
                socket.on("connect_error", (error) => {
                    console.log("Connection error:", error);
                });

                return
            }
            else {
                state.isAuthenticated = false
                state.authError = responseData.message
                console.log("action.payload", action.payload)
                return
            }


        },
        logout:(state,action) => {
            localStorage.removeItem("authUser");
            localStorage.removeItem("company-config");
            state.isAuthenticated = false
        }
    }

})

export const login = (values) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/auth/authenticate", {
                userInfo: {
                    "username": values.username,
                    "password": values.password
                }
            })
            dispatch(authUser(responseData))
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const getConfig = () => {
    return async (dispatch) => {
        try {
            var responseData = await get("/auth/get-config")
            console.log("config", responseData)
            if(responseData.response_code === 500){
                dispatch(setCompanyConfig(responseData.data[0]))
            }
        }
        catch (error) {
            console.log("catch error", error)
        }
    }
}

export const { setUserInfo, setCompanyConfig, authUser, logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
