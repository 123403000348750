import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback,
} from "reactstrap";

import Dropzone from "react-dropzone";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { getConfig } from "toolkitStore/authenticateSlice"


import {
    fetchCustomers
} from "toolkitStore/manageCustomerSlice";

import {
    createVendor, fetchVendors
} from "toolkitStore/manageVendorSlice"
import {
    createCustomerSubscription,
    fetchCompanySubscriptions,
    updateCustomerSubscription,
    resetSubscriptionSuccessfullyAdded,
    updateCustomerConsumptions,
    resetConsumptionUpdation,
    updateConsumptionFiles,
    resetConsumptionFileUpdated

} from "toolkitStore/manageSubscriptionSlice"; import { fetchServices, createService, updateService, deleteMasterService } from "toolkitStore/manageServiceSlice";
import moment from "moment";

import FileUpload from "./FileUpload";



const VendorReconsalation = (props) => {


    const dispatch = useDispatch()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { customerList } = useSelector(state => state.manageCustomerReducer);
    const { vendorList } = useSelector((state) => state.manageVendorReducer);
    const { serviceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)
    const { companySubscriptionList, isSubscriptionRequestSuccess, updateConsumptionSuccessfully, consumptionData, updateConsumptionFileSuccessfully } = useSelector(state => state.manageSubscriptionReducer)

    const [userInformation, setInfo] = useState(props.userInformation);
    const [cmpConfig, setCmpConfig] = useState(props.cmpConfig);
    const [consumptionRow, setConsumptionRow] = useState(props.selectedConsumption)


    const [actualCost, setActualCost] = useState(0)
    const [purchaseCost, setPurchaseCost] = useState(0)
    const [customerCost, setCustomerCost] = useState(0)
    const [expectedMargin, setExpectedMargin] = useState(0)

    const [UCost, setUCost] = useState(0)
    const [rRebate, setRRebate] = useState(0)
    const [rdiscount, setRDiscount] = useState(0)
    const [rQuantity, setRQuantity] = useState(0)

    const [filteredServices, setFilteredServices] = useState(null)

    const [selectedService, setSelectedService] = useState(props.selectedSubscription ? props.selectedSubscription.service_name : null)
    const [serviceInfo, setServiceInfo] = useState(null)
    const [ssList, setSSList] = useState([])
    const [selectedSPlan, setSPlan] = useState(null)
    const [sStartDate, setSStartDate] = useState("")
    const [sEndDate, setSEndDate] = useState("")
    const [isApiRequested, setIsApiRequested] = useState(false)
    const [saveMode, setSaveMode] = useState("draft")

    const [derivedMargin, setDerivedMargin] = useState(0)
    const [derivedDiscount, setDerivedDiscount] = useState(0)
    const [customerAttachments, setCustomerAttachments] = useState(props.selectedConsumption ? props.selectedConsumption.customer_attachments : [])
    const [vendorAttachments, setVendorAttachments] = useState(props.selectedConsumption ? props.selectedConsumption.vendor_attachments : [])
    const [actualMargin, setActualMargin] = useState(0)
    const [marginDifferences, setMarginDifferences] = useState(0)


    const [vendorName, setVendorName] = useState("")
    const [vendorNameError, setVendorNameError] = useState(null)
    const [showEntry, setShowEntry] = useState(false)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    console.log("selectedConsumption -===", props.selectedConsumption)


    useEffect(() => {
        dispatch(fetchVendors(userInformation.m_company_id))
        dispatch(fetchServices(userInformation.m_company_id))
        dispatch(fetchCustomers(userInformation.m_company_id))
    }, []);


    useEffect(() => {
        if (serviceList.length > 0) {
            var getPrimaryData = _.filter(serviceList, { "service_type_id": "2", "service_level_id": "2" })
            setFilteredServices(getPrimaryData)
        }

    }, [serviceList])

    useEffect(() => {
        if (updateConsumptionSuccessfully) {
            dispatch(resetConsumptionUpdation())
            props.closeOffCanvas()
        }
    }, [updateConsumptionSuccessfully])

    useEffect(() => {
        console.log("consumptionData", consumptionData)
        if (updateConsumptionFileSuccessfully) {
            let consumption = { ...consumptionRow }
            consumption.customer_attachments = consumptionData.customer_attachments
            consumption.vendor_attachments = consumptionData.vendor_attachments
            setConsumptionRow({...consumption})

            setVendorAttachments(consumptionData.vendor_attachments)
            setCustomerAttachments(consumptionData.customer_attachments)
            dispatch(resetConsumptionFileUpdated())
        }

    }, [updateConsumptionFileSuccessfully])


    useEffect(() => {
        if (selectedService && filteredServices) {
            var selectedData = _.filter(filteredServices, { "name": selectedService })
            var subscriptionInfo = {
                company_id: userInformation.m_company_id,
                sub_service_id: selectedData[0]._id
            }
            setServiceInfo(selectedData[0])
            dispatch(fetchCompanySubscriptions(subscriptionInfo))
        }

    }, [selectedService, filteredServices])

    useEffect(() => {
        if (companySubscriptionList) {
            setSSList([...companySubscriptionList])
        }
        else {
            setSSList([])
        }
    }, [companySubscriptionList])


    useEffect(() => {
        setIsApiRequested(isSubscriptionRequestSuccess)
        if (isSubscriptionRequestSuccess) {
            dispatch(resetSubscriptionSuccessfullyAdded())
            props.closeOffCanvas()

        }

    }, [isSubscriptionRequestSuccess])


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            vendor_cost: consumptionRow && consumptionRow.vendor_cost || "",

            vendor_margin: consumptionRow && consumptionRow.vendor_margin || "0",
            vendor_invoice_no: consumptionRow && consumptionRow.vendor_invoice_no || "",
            vendor_invoice_date: consumptionRow && moment(consumptionRow.vendor_invoice_date).format("YYYY-MM-DD") || "0",

            customer_invoice_no: consumptionRow && consumptionRow.customer_invoice_no || "",
            customer_invoice_date: consumptionRow && moment(consumptionRow.customer_invoice_date).format("YYYY-MM-DD") || "",
            customer_invoice_value: consumptionRow && consumptionRow.customer_invoice_value || "",


        },
        validationSchema: Yup.object()
        .shape({
            vendor_invoice_date: Yup.date()
                .typeError("please enter date")
                .required("please enter date"),
                vendor_invoice_no: Yup.string().required("Enter Invoice Number"),
                vendor_cost: Yup.string().required("Enter Vendor Invoice Value"),
        }),
        onSubmit: (values, { resetForm }) => {

            values["vendor_margin"] = actualMargin

            values["vendor_attachments"] = [...vendorAttachments]
            values["customer_attachments"] = [...customerAttachments]

            console.log("values", values)

            setIsApiRequested(true)

            values["_id"] = consumptionRow._id
            dispatch(updateCustomerConsumptions(values))

        }
    });

    // consumption_valuec

    useEffect(() => {

        var dMargin = validation.values.customer_invoice_value - validation.values.vendor_cost;
        setActualMargin(dMargin.toFixed(2))

        var differenceValue = (dMargin - (consumptionRow.consumption_margin - consumptionRow.derived_discount))

        setMarginDifferences(differenceValue.toFixed(2))


    }, [validation.values.vendor_cost, validation.values.customer_invoice_value,])


    const removeVendorFile = (selectedConsumption, file) => {
        var findIndex = _.findIndex(selectedConsumption.vendor_attachments, { "fileName": file.fileName })
        if (findIndex !== -1) {
            let consumption = { ...selectedConsumption }
            let newArr = [...selectedConsumption.vendor_attachments]
            newArr.splice(findIndex, 1)
            consumption.vendor_attachments = newArr
            dispatch(updateConsumptionFiles(consumption))
        }
    }

    const updateVendorAttachments = (selectedConsumption, newArr) => {
        let consumption = { ...selectedConsumption }
        consumption.vendor_attachments = newArr
        dispatch(updateConsumptionFiles(consumption))
    }

    const removeCustomerFile = (selectedConsumption, file) => {
        var findIndex = _.findIndex(selectedConsumption.customer_attachments, { "fileName": file.fileName })
        if (findIndex !== -1) {
            let consumption = { ...selectedConsumption }
            let newArr = [...selectedConsumption.customer_attachments]
            newArr.splice(findIndex, 1)
            consumption.customer_attachments = newArr
            dispatch(updateConsumptionFiles(consumption))
        }
    }

    const updateCustomerAttachments = (selectedConsumption, newArr) => {
        let consumption = { ...selectedConsumption }
        consumption.customer_attachments = newArr
        dispatch(updateConsumptionFiles(consumption))
    }



    return (
        <Form
            className="form-horizontal"
            onSubmit={(e) => {
                e.preventDefault();

                return false;
            }}
        >
            <Row className="mb-3 pb-4" style={{ borderBottom: "1px solid #dedede" }}>
                <Col className="col-4">
                    <div >
                        <Label htmlFor="customer_invoice_no">Customer Invoice No.</Label>
                        <Input
                            name="customer_invoice_no"
                            className="form-control"
                            placeholder="Enter invoice no."
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.customer_invoice_no || ""}
                            invalid={
                                validation.touched.customer_invoice_no && validation.errors.customer_invoice_no ? true : false
                            }
                        />
                        {validation.touched.customer_invoice_no && validation.errors.customer_invoice_no ? (
                            <FormFeedback type="invalid">{validation.errors.customer_invoice_no}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4}>
                    <Label>Customer Invoice Date</Label>
                    <InputGroup>
                        <Input
                            id="customer_invoice_date"
                            name="customer_invoice_date"
                            placeholder="date placeholder"
                            type="date"
                            value={validation.values.customer_invoice_date}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />

                    </InputGroup>
                    {validation.touched.customer_invoice_date && validation.errors.customer_invoice_date ? (
                        <FormFeedback type="invalid">{validation.errors.customer_invoice_date}</FormFeedback>
                    ) : null}
                </Col>
                <Col className="col-4">
                    <div >
                        <Label htmlFor="customer_invoice_value">Customer Invoice value</Label>
                        <Input
                            name="customer_invoice_value"
                            className="form-control"
                            placeholder="Enter invoice value"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.customer_invoice_value || ""}
                            invalid={
                                validation.touched.customer_invoice_value && validation.errors.customer_invoice_value ? true : false
                            }
                        />
                        {validation.touched.customer_invoice_value && validation.errors.customer_invoice_value ? (
                            <FormFeedback type="invalid">{validation.errors.customer_invoice_value}</FormFeedback>
                        ) : null}
                    </div>
                </Col>

            </Row>

            <Row className="mb-3">
                <FileUpload
                    fileToBeAttach={"Customer Invoice"}
                    filesAttached={_.filter(customerAttachments, {"related_to":"Customer Invoice"})}
                    removeFile={(data) => {
                        removeCustomerFile(consumptionRow, data)
                    }}
                    uploadedFiles={(data) => {
                        let newArr = [...customerAttachments]
                        newArr.push(data)
                        //setCustomerAttachments([...newArr])
                        updateCustomerAttachments(consumptionRow, newArr)

                    }}
                />
            </Row>

            <Row className="mb-3">
                <Col className="col-4">
                    <div >
                        <Label htmlFor="vendor_invoice_no">Vendor Invoice No.</Label>
                        <Input
                            name="vendor_invoice_no"
                            className="form-control"
                            placeholder="Enter invoice no."
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.vendor_invoice_no || ""}
                            invalid={
                                validation.touched.vendor_invoice_no && validation.errors.vendor_invoice_no ? true : false
                            }
                        />
                        {validation.touched.vendor_invoice_no && validation.errors.vendor_invoice_no ? (
                            <FormFeedback type="invalid">{validation.errors.vendor_invoice_no}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col className="col-4">
                    <Label>Invoice Date</Label>
                        <Input
                            id="vendor_invoice_date"
                            name="vendor_invoice_date"
                            placeholder="date placeholder"
                            type="date"
                            value={validation.values.vendor_invoice_date}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.vendor_invoice_date && validation.errors.vendor_invoice_date ? true : false
                            }
                        />

                    {validation.touched.vendor_invoice_date && validation.errors.vendor_invoice_date ? (
                        <FormFeedback type="invalid">{validation.errors.vendor_invoice_date}</FormFeedback>
                    ) : null}
                </Col>
                <Col className="col-4">
                    <div >
                        <Label htmlFor="vendor_cost">Vendor Invoice value</Label>
                        <Input
                            name="vendor_cost"
                            className="form-control"
                            placeholder="Enter vendor cost"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.vendor_cost || ""}
                            invalid={
                                validation.touched.vendor_cost && validation.errors.vendor_cost ? true : false
                            }
                        />
                        {validation.touched.vendor_cost && validation.errors.vendor_cost ? (
                            <FormFeedback type="invalid">{validation.errors.vendor_cost}</FormFeedback>
                        ) : null}
                    </div>
                </Col>

            </Row>

            <Row className="mb-3 pb-4" style={{ borderBottom: "1px solid #dedede" }}>
            <Col className="col-4">
                    <div >
                        <Label htmlFor="vendor_margin" className="mb-0 pb-0">Expected Margin</Label>
                        <div >
                            <div style={{ fontSize: 20 }}>{(consumptionRow.consumption_margin - consumptionRow.derived_discount).toFixed(2)}</div>
                        </div>
                    </div>
                </Col>

                <Col className="col-4">
                    <div >
                        <Label htmlFor="vendor_margin" className="mb-0 pb-0">Actual Margin</Label>
                        <div >
                            <div style={{ fontSize: 20 }}>{actualMargin }</div>
                        </div>
                    </div>
                </Col>

                <Col className="col-4">
                    <div >
                        <Label htmlFor="vendor_margin" className="mb-0 pb-0">Difference</Label>
                        <div >
                            <div style={{ fontSize: 20 }}>{marginDifferences < 0 ? marginDifferences : "+ "+marginDifferences}</div>
                        </div>
                    </div>
                </Col>
                

            </Row>

            <Row className="mb-3">
                <FileUpload
                    fileToBeAttach={"Vendor Invoice"}
                    filesAttached={vendorAttachments}
                    removeFile={(data) => {
                        removeVendorFile(consumptionRow, data)
                    }}
                    uploadedFiles={(data) => {
                        let newArr = [...vendorAttachments]
                        newArr.push(data)
                        updateVendorAttachments(consumptionRow, newArr)

                    }}
                />
            </Row>

            <div className="d-flex flex-row">
                <button type="submit"
                    onClick={(event) => {
                        setSaveMode("draft")
                        validation.handleSubmit(event)
                    }}
                    className="btn btn-primary w-md me-2" disabled={isApiRequested} >
                    {"Submit"}
                </button>
            </div>
        </Form>

    );
};

export default VendorReconsalation;

