

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import PBlogosvg from "../../assets/images/pb_logo.svg"
import PBlogo from "../../assets/images/pb.png"
import CarouselPage from "./CarouselPage";

import {authUser, login, } from "../../toolkitStore/authenticateSlice"


// import { loginUser, socialLogin } from "../../store/actions";

import io from "socket.io-client";




const Login2 = (props) => {

  const {isAuthenticated, authError} = useSelector(state => state.authReducer)

  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(()=>{

  },[])

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated)
    
    if(isAuthenticated){
      props.router.navigate("/dashboard")
    }
  }, [isAuthenticated])

  //meta title
  document.title = "SHQM | Customer Support Dashboard";

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(login(values))
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0" style={{maxWidth:"100%"}}>
          <Row className="g-0">
            <CarouselPage />

            <Col xl={9}>
              <div className="p-md-5 p-4 bg-white d-flex flex-row justify-content-center align-items-center h-100" >
                <div className="d-flex flex-row justify-content-center align-self-center">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div className="d-flex flex-row justify-content-center" style={{marginBottom:100}}>
                        <img
                          src={PBlogo}
                          alt=""
                          width="60%"
                          className="logo-dark-element"
                        />
                      </div>
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to dashboard.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div> */}

                          {
                            authError!=="" && 
                            <label className="text-danger font-size-12">{authError}</label>
                          }

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                        </Form>

                        {/* <div className="mt-5 text-center">
                          <p>
                            Don&apos;t have an account ?
                            <Link
                              to="pages-register-2"
                              className="fw-medium text-primary"
                            >
                              Signup now
                            </Link>
                          </p>
                        </div> */}
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()}. Crafted with{" "} by
                        QuasarPixels
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login2);

Login2.propTypes = {
  history: PropTypes.object,
};

