import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { getConfig } from "toolkitStore/authenticateSlice"


import {
    fetchCustomers
} from "toolkitStore/manageCustomerSlice";

import {
    createVendor, fetchVendors
} from "toolkitStore/manageVendorSlice"
import { createCustomerSubscription, fetchCompanySubscriptions, updateCustomerSubscription, resetSubscriptionSuccessfullyAdded } from "toolkitStore/manageSubscriptionSlice";
import { fetchServices, createService, updateService, deleteMasterService } from "toolkitStore/manageServiceSlice";
import moment from "moment";



const CRUDSubscription = (props) => {


    const dispatch = useDispatch()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { customerList } = useSelector(state => state.manageCustomerReducer);
    const { vendorList } = useSelector((state) => state.manageVendorReducer);
    const { serviceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)
    const { companySubscriptionList, isSubscriptionRequestSuccess } = useSelector(state => state.manageSubscriptionReducer)

    const [userInformation, setInfo] = useState(props.userInformation);
    const [cmpConfig, setCmpConfig] = useState(props.cmpConfig);


    const [actualCost, setActualCost] = useState(0)
    const [purchaseCost, setPurchaseCost] = useState(0)
    const [customerCost, setCustomerCost] = useState(0)
    const [expectedMargin, setExpectedMargin] = useState(0)

    const [UCost, setUCost] = useState(0)
    const [rRebate, setRRebate] = useState(0)
    const [rdiscount, setRDiscount] = useState(0)
    const [rQuantity, setRQuantity] = useState(0)

    const [filteredServices, setFilteredServices] = useState(null)

    const [selectedService, setSelectedService] = useState(props.selectedSubscription ? props.selectedSubscription.service_name : null)
    const [serviceInfo, setServiceInfo] = useState(null)
    const [ssList, setSSList] = useState([])
    const [selectedSPlan, setSPlan] = useState(null)
    const [sStartDate, setSStartDate] = useState("")
    const [sEndDate, setSEndDate] = useState("")
    const [isApiRequested, setIsApiRequested] = useState(false)
    const [saveMode, setSaveMode] = useState("draft")

    const [vendorName, setVendorName] = useState("")
    const [vendorNameError, setVendorNameError] = useState(null)
    const [showEntry, setShowEntry] = useState(false)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [dateAlertEnabled, setDateAlert] = useState(false)

    // console.log("props -===", props.selectedSubscription)


    useEffect(() => {
        dispatch(fetchVendors(userInformation.m_company_id))
        dispatch(fetchServices(userInformation.m_company_id))
        dispatch(fetchCustomers(userInformation.m_company_id))
    }, []);

    useEffect(() => {

        if(props.formMode === "clone"){
            console.log("-- close testing --")
            var currentDate = String(moment(new Date()).format("YYYY-MM-DD"))
            // var convertDate = moment(String(moment(new Date()).format("YYYY-MM-DD")), "YYYY-MM-DD")

            var convertedStartDate = moment(String(moment(props.selectedSubscription.subscription_customer_start_date).format("YYYY-MM-DD")), "YYYY-MM-DD")
            var convertedEndDate = moment(String(moment(props.selectedSubscription.subscription_customer_end_date).format("YYYY-MM-DD")), "YYYY-MM-DD")
    
             var startDate = convertedStartDate.add(1, 'y').format("YYYY-MM-DD");
             var endDate = convertedEndDate.add(1, 'y').format("YYYY-MM-DD");
    

            setSStartDate(startDate)
            setSEndDate(endDate)
    
        }

    }, [props.formMode])


    useEffect(() => {
        if (serviceList.length > 0) {
            var getPrimaryData = _.filter(serviceList, { "service_type_id": "2", "service_level_id": "2" })
            setFilteredServices(getPrimaryData)
        }

    }, [serviceList])


    useEffect(() => {
        if (selectedService && filteredServices) {
            var selectedData = _.filter(filteredServices, { "name": selectedService })
            var subscriptionInfo = {
                company_id: userInformation.m_company_id,
                sub_service_id: selectedData[0]._id
            }
            setServiceInfo(selectedData[0])
            dispatch(fetchCompanySubscriptions(subscriptionInfo))
        }

    }, [selectedService, filteredServices])

    useEffect(() => {
        if (companySubscriptionList) {
            setSSList([...companySubscriptionList])
        }
        else {
            setSSList([])
        }
    }, [companySubscriptionList])


    useEffect(() => {
        setIsApiRequested(isSubscriptionRequestSuccess)
        if (isSubscriptionRequestSuccess) {
            dispatch(resetSubscriptionSuccessfullyAdded())
            props.closeOffCanvas()

        }

    }, [isSubscriptionRequestSuccess])

    useEffect(() => {
        if (props.selectedSubscription && ssList.length > 0) {
            var selectedPlan = _.filter(ssList, { subscription_name: props.selectedSubscription.subscription_plan_name })[0]
            setSPlan(selectedPlan)

            // setSStartDate(moment(props.selectedSubscription.subscription_customer_start_date).format("yyyy-DD-MM"))
            // setSEndDate(moment(props.selectedSubscription.subscription_customer_end_date).format("yyyy-DD-MM"))
        }
    }, [props.selectedSubscription, ssList])

    useEffect(() => {
        if (props.selectedSubscription) {
            console.log("props.selectedSubscription", props.selectedSubscription)

            var unitCost = props.selectedSubscription.subscription_unit_cost

            setActualCost(unitCost * props.selectedSubscription.subscription_customer_quantity)

            // (Unit cost - (Unit cost X Rebate %)) X Quantity
            var totalPurchaseCost = (unitCost - (unitCost * (props.selectedSubscription.subscription_customer_rebate / 100))) * props.selectedSubscription.subscription_customer_quantity
            setPurchaseCost((totalPurchaseCost).toFixed(2))

            // (Unit Cost - (unit cost X discount %)) x Quantity
            var totalCustomerCost = (unitCost - (unitCost * (props.selectedSubscription.subscription_customer_discount / 100))) * props.selectedSubscription.subscription_customer_quantity
            setCustomerCost((totalCustomerCost).toFixed(2))

            var expectedMargin = (totalCustomerCost - totalPurchaseCost).toFixed(2)
            setExpectedMargin(expectedMargin)

            console.log("output --", totalPurchaseCost, totalCustomerCost, expectedMargin)
        }
    }, [props.selectedSubscription])




    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            customer_name: props.selectedSubscription && props.selectedSubscription.customer_name || "",
            customer_master_id: props.selectedSubscription && props.selectedSubscription.customer_master_id || "",
            customer_subscription_id: props.selectedSubscription && props.selectedSubscription.customer_subscription_id || "",
            customer_account_details: props.selectedSubscription && props.selectedSubscription.customer_account_details || "",

            service_name: props.selectedSubscription && props.selectedSubscription.service_name || "",
            service_type: props.selectedSubscription && props.selectedSubscription.service_type || "",
            service_master_id: props.selectedSubscription && props.selectedSubscription.service_master_id || "",

            subscription_plan_name: props.selectedSubscription && props.selectedSubscription.subscription_plan_name || "0",
            subscription_plan_master_id: props.selectedSubscription && props.selectedSubscription.subscription_plan_master_id || "",

            vendor: props.selectedSubscription && props.selectedSubscription.vendor || "0",
            vendor_id: props.selectedSubscription && props.selectedSubscription.vendor_id || "",
            vendor_commitment_term: props.selectedSubscription && props.selectedSubscription.vendor_commitment_term || "0",
            vendor_billing_term: props.selectedSubscription && props.selectedSubscription.vendor_billing_term || "",
            vendor_billing_type: props.selectedSubscription && props.selectedSubscription.vendor_billing_type || "",

            subscription_customer_start_date: props.formMode === "clone" ? sStartDate : props.selectedSubscription &&  moment(props.selectedSubscription.subscription_customer_start_date).format("YYYY-MM-DD") || "", //,
            subscription_customer_end_date: props.formMode === "clone" ? sEndDate : props.selectedSubscription &&  moment(props.selectedSubscription.subscription_customer_end_date).format("YYYY-MM-DD") || "",

            remarks: props.selectedSubscription && props.selectedSubscription.remarks || "",

            subscription_customer_commitment_term: props.selectedSubscription && props.selectedSubscription.subscription_customer_commitment_term || "",
            subscription_customer_billing_term: props.selectedSubscription && props.selectedSubscription.subscription_customer_billing_term || "",
            subscription_customer_billing_type: props.selectedSubscription && props.selectedSubscription.subscription_customer_billing_type || "",
            subscription_customer_rebate: props.selectedSubscription && props.selectedSubscription.subscription_customer_rebate || "0",
            subscription_customer_discount: props.selectedSubscription && props.selectedSubscription.subscription_customer_discount || "0",
            subscription_customer_quantity: props.selectedSubscription && props.selectedSubscription.subscription_customer_quantity || "1",

            subscription_unit_cost: props.selectedSubscription ? props.selectedSubscription.subscription_unit_cost : "",

        },
        validationSchema: Yup.object({
            customer_name: Yup.string().required("Select Customer"),
            service_name: Yup.string().required("Select Service"),
            subscription_plan_name: Yup.string().required("Select Subscription Plan"),
            subscription_customer_billing_term: Yup.string().required("Select customer billing term"),
            vendor: Yup.string().required("Select vendor"),
            vendor_commitment_term: Yup.string().required("Select commitmment term"),
            vendor_billing_term: Yup.string().required("Select billing term"),
        }),
        onSubmit: (values, { resetForm }) => {

            values["actual_cost"] = actualCost
            values["purchase_cost"] = purchaseCost
            values["customer_cost"] = customerCost
            values["expected_margin"] = expectedMargin


            values["subscription_plan_master_id"] = selectedSPlan._id
            values["subscription_customer_billing_type"] = selectedSPlan.billing_type
            if (values.subscription_unit_cost === "" && selectedSPlan) {
                values["subscription_unit_cost"] = selectedSPlan.unit_cost
            }
            if (values.subscription_customer_commitment_term === "" && selectedSPlan) {
                values["subscription_customer_commitment_term"] = selectedSPlan.commitment_term
            }
            values["service_master_id"] = serviceInfo._id

            values["provider"] = selectedSPlan.provider



            values["m_company_id"] = userInformation.m_company_id
            values["m_b_unit_id"] = userInformation.m_b_unit_id
            values["created_by"] = userInformation._id


            if ((values["subscription_customer_commitment_term"] === "Yearly" && values["subscription_customer_billing_term"] === "Yearly") ||
                (values["subscription_customer_commitment_term"] === "Monthly" && values["subscription_customer_billing_term"] === "Monthly")
            ) {
                values["no_of_consumptions"] = 1
            }
            if (values["subscription_customer_commitment_term"] === "Yearly" && values["subscription_customer_billing_term"] === "Monthly") {
                values["no_of_consumptions"] = 12

            }

            console.log("saveMode===", saveMode)

            values["state"] = saveMode

            console.log("values", values)

            setIsApiRequested(true)

            if (props.formMode === "new" || props.formMode === "clone") {
                dispatch(createCustomerSubscription(values))
            } else if (props.formMode === "edit") {
                values["_id"] = props.selectedSubscription._id
                dispatch(updateCustomerSubscription(values))
            }

        }
    });

    useEffect(() => {

        var unitCost = validation.values.subscription_unit_cost ? validation.values.subscription_unit_cost : selectedSPlan ? selectedSPlan.unit_cost : 0


       // console.log(" -- >", unitCost, validation.values.subscription_unit_cost, validation.values.subscription_customer_rebate, validation.values.subscription_customer_discount, validation.values.subscription_customer_quantity)


        setActualCost(unitCost * (validation.values.subscription_customer_quantity ? validation.values.subscription_customer_quantity : 1))

        // (Unit cost - (Unit cost X Rebate %)) X Quantity
        var totalPurchaseCost = (unitCost - (unitCost * (validation.values.subscription_customer_rebate / 100))) * (validation.values.subscription_customer_quantity ? validation.values.subscription_customer_quantity : 1)
        setPurchaseCost((totalPurchaseCost).toFixed(2))


        // (Unit Cost - (unit cost X discount %)) x Quantity
        var totalCustomerCost = (unitCost - (unitCost * (validation.values.subscription_customer_discount / 100))) * (validation.values.subscription_customer_quantity ? validation.values.subscription_customer_quantity : 1)
        setCustomerCost((totalCustomerCost).toFixed(2))

        var expectedMargin = (totalCustomerCost - totalPurchaseCost).toFixed(2)
        setExpectedMargin(expectedMargin)


    }, [validation.values.subscription_unit_cost, selectedSPlan, validation.values.subscription_customer_rebate, validation.values.subscription_customer_discount, validation.values.subscription_customer_quantity])


    // useEffect(() => {
    //     // console.log("Values -->", values)
    //     var purchaseCost = (Number(UCost) - (Number(UCost) * (Number(rRebate) / 100))) * Number(rQuantity)
    //     var customerCost = (Number(UCost) - (Number(UCost) * (Number(rdiscount) / 100))) * Number(rQuantity)
    //     var expectedMargin = Math.round(Number(customerCost) - Number(purchaseCost))

    //     setPurchaseCost(purchaseCost)
    //     setCustomerCost(customerCost)
    //     setExpectedMargin(expectedMargin)
    //     setActualCost(Number(UCost) * Number(rQuantity))
    // }, [UCost, rRebate, rdiscount, rQuantity])


    const getPlanInfo = (event) => {
        var selectedPlan = _.filter(ssList, { subscription_name: event.target.value })[0]
        setSPlan(selectedPlan)

        var currentDate = String(moment(new Date()).format("YYYY-MM-DD"))
        var convertDate = moment(String(moment(new Date()).format("YYYY-MM-DD")), "YYYY-MM-DD")

        var yearEndDate = convertDate.add(1, 'y').format("YYYY-MM-DD");
        var monthEndDate = convertDate.add(1, 'm').format("YYYY-MM-DD");

        validation.values["subscription_customer_start_date"] = currentDate
        validation.values["subscription_customer_end_date"] = selectedPlan.commitment_term === "Yearly" ? yearEndDate : selectedPlan.commitment_term === "Monthly" ? monthEndDate : ""

        setSStartDate(currentDate)
        setSEndDate(selectedPlan.commitment_term === "Yearly" ? yearEndDate : selectedPlan.commitment_term === "Monthly" ? monthEndDate : "")
    }


    return (

        <Row >
            <Col md="12" >
                <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();

                        return false;
                    }}
                >
                    {
                        cmpConfig &&
                        <Row className="mb-3">

                            <Col md={12}>
                                <div className="col-sm-auto mb-3">
                                    <label className="" htmlFor="autoSizingSelect">Customer</label>
                                    <Input className="form-control"
                                        name="customer_name"
                                        type="select"
                                        autoFocus
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.customer_name || "0"}
                                        disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                        required
                                        invalid={
                                            validation.touched.customer_name && validation.errors.customer_name ? true : false
                                        }
                                    >
                                        <option value="0" disabled>Choose...</option>
                                        {
                                            customerList && customerList.map((item, idx) => {
                                                return (
                                                    <option key={"impt" + idx} value={item.customer_name}>{item.customer_name}</option>
                                                )
                                            })
                                        }

                                    </Input>
                                    {validation.touched.customer_name && validation.errors.customer_name ? (
                                        <FormFeedback type="invalid">{validation.errors.customer_name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div >
                                    <Label htmlFor="customer_account_details">Account / Tenant details</Label>
                                    <Input
                                        name="customer_account_details"
                                        className="form-control"
                                        placeholder="Enter account / tentant informations"
                                        type="textarea"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.customer_account_details || ""}
                                        disabled={validation.values.customer_name === "" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                        required
                                        invalid={
                                            validation.touched.customer_account_details && validation.errors.customer_account_details ? true : false
                                        }
                                    />
                                    {validation.touched.customer_account_details && validation.errors.customer_account_details ? (
                                        <FormFeedback type="invalid">{validation.errors.customer_account_details}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>

                    }

                    {
                        cmpConfig &&
                        <Row className="mb-3 pt-1">
                            <Row>
                                <Col md={6}>
                                    <div className="col-sm-auto">
                                        <label className="" htmlFor="service_name">Select Service</label>
                                        <Input className="form-control"
                                            name="service_name"
                                            type="select"
                                            id="service_name"
                                            onChange={(event) => {
                                                validation.handleChange(event)
                                                setSelectedService(event.target.value)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.service_name || "0"}
                                            disabled={validation.values.customer_name === "" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                            required
                                            invalid={
                                                validation.touched.service_name && validation.errors.service_name ? true : false
                                            }
                                        >
                                            <option value="0" disabled>Choose...</option>
                                            {
                                                filteredServices && filteredServices.map((item, idx) => {
                                                    return (
                                                        <option key={"impt" + idx} value={item.name} id={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }

                                        </Input>
                                        {validation.touched.service_name && validation.errors.service_name ? (
                                            <FormFeedback type="invalid">{validation.errors.service_name}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="col-sm-auto">
                                        <label className="" htmlFor="subscription_plan_name">Subscription Plans</label>
                                        <Input className="form-control"
                                            name="subscription_plan_name"
                                            type="select"
                                            id="subscription_plan_name"
                                            onChange={(event) => {
                                                validation.handleChange(event)
                                                getPlanInfo(event)
                                            }
                                            }
                                            onBlur={validation.handleBlur}
                                            value={validation.values.subscription_plan_name || "0"}
                                            disabled={validation.values.customer_name === "" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                            required
                                            invalid={
                                                validation.touched.subscription_plan_name && validation.errors.subscription_plan_name ? true : false
                                            }
                                        >
                                            <option value="0" disabled>Choose...</option>
                                            {
                                                ssList && ssList.map((item, idx) => {
                                                    return (
                                                        <option key={"sp" + idx} value={item.subscription_name}>{item.subscription_name}</option>
                                                    )
                                                })
                                            }

                                        </Input>
                                        {validation.touched.subscription_plan_name && validation.errors.subscription_plan_name ? (
                                            <FormFeedback type="invalid">{validation.errors.subscription_plan_name}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>

                            {
                                validation.values.subscription_plan_name !== "0" && selectedSPlan &&
                                <div className="mt-3 bg-secondary bg-opacity-25 p-3 ">
                                    <Row className="justify-content-between">
                                        <Col className="col-auto me-3">
                                            <span className="font-size-11">Provider</span>
                                            <div><span>{selectedSPlan.provider}</span></div>
                                        </Col>
                                        <Col className="col-auto me-3">
                                            <span className="font-size-11">Commitment term</span>
                                            <div><span>{selectedSPlan.commitment_term}</span></div>
                                        </Col>
                                        <Col className="col-auto me-3">
                                            <span className="font-size-11">Billing term</span>
                                            <div><span>{selectedSPlan.billing_term}</span></div>
                                        </Col>
                                        <Col className="col-auto me-3">
                                            <span className="font-size-11">Billing type</span>
                                            <div><span>{selectedSPlan.billing_type}</span></div>
                                        </Col>
                                    </Row>
                                </div>
                            }

                            {
                                (validation.values.subscription_plan_name !== "0" && selectedSPlan && cmpConfig) &&
                                <div className="bg-success bg-opacity-25 p-3">
                                    <Row className="mt-3">
                                        <Col md={4}>
                                            <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="autoSizingSelect">Commitment Term</label>
                                                <Input className="form-select"
                                                    name="subscription_customer_commitment_term"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_customer_commitment_term || selectedSPlan.commitment_term || "0"}
                                                    required
                                                    disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        cmpConfig.commitment_term.map((item, idx) => {
                                                            return (
                                                                <option key={"blt" + idx} value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                {validation.touched.subscription_customer_commitment_term && validation.errors.subscription_customer_commitment_term ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_customer_commitment_term}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="subscription_customer_billing_term">Billing Term</label>
                                                <Input className="form-select"
                                                    name="subscription_customer_billing_term"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_customer_commitment_term === "Monthly" || selectedSPlan.commitment_term === "Monthly" ? "Monthly" : (validation.values.subscription_customer_billing_term || "0")}
                                                    required
                                                    disabled={selectedSPlan.commitment_term === "Monthly" || validation.values.subscription_customer_commitment_term === "Monthly" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    invalid={
                                                        validation.touched.subscription_customer_billing_term && validation.errors.subscription_customer_billing_term ? true : false
                                                    }

                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        cmpConfig.billing_term.map((item, idx) => {
                                                            return (
                                                                <option key={"bty" + idx} value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                {validation.touched.subscription_customer_billing_term && validation.errors.subscription_customer_billing_term ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_customer_billing_term}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="autoSizingSelect">Billing Type</label>
                                                <Input className="form-select"
                                                    name="subscription_customer_billing_type"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_customer_billing_type || selectedSPlan.billing_type || "0"}
                                                    disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    required
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        cmpConfig.billing_type.map((item, idx) => {
                                                            return (
                                                                <option key={"bty" + idx} value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                {validation.touched.subscription_customer_billing_type && validation.errors.subscription_customer_billing_type ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_customer_billing_type}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="mb-4">
                                        <Col className="col-3">
                                            <div >
                                                <Label htmlFor="subscription_unit_cost">Unit Cost</Label>
                                                <Input
                                                    name="subscription_unit_cost"
                                                    className="form-control"
                                                    placeholder="Enter unit cost"
                                                    type="number"
                                                    onChange={(event) => {
                                                        validation.handleChange(event)
                                                        setUCost(event.target.value)
                                                    }
                                                    } onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_unit_cost || selectedSPlan.unit_cost || "0"}
                                                    required
                                                    disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    invalid={
                                                        validation.touched.subscription_unit_cost && validation.errors.subscription_unit_cost ? true : false
                                                    }
                                                />
                                                {validation.touched.subscription_unit_cost && validation.errors.subscription_unit_cost ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_unit_cost}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col className="col-3">
                                            <div >
                                                <Label htmlFor="subscription_customer_rebate">Rebate %</Label>
                                                <Input
                                                    name="subscription_customer_rebate"
                                                    className="form-control"
                                                    placeholder="Enter rebate %"
                                                    type="number"
                                                    onChange={(event) => {
                                                        validation.handleChange(event)
                                                        setRRebate(event.target.value)
                                                    }
                                                    }
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_customer_rebate || "0"}
                                                    required
                                                    disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    invalid={
                                                        validation.touched.subscription_customer_rebate && validation.errors.subscription_customer_rebate ? true : false
                                                    }
                                                />
                                                {validation.touched.subscription_customer_rebate && validation.errors.subscription_customer_rebate ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_customer_rebate}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col className="col-3">
                                            <div >
                                                <Label htmlFor="subscription_customer_discount">Discount %</Label>
                                                <Input
                                                    name="subscription_customer_discount"
                                                    className="form-control"
                                                    placeholder="Enter discount %"
                                                    type="number"
                                                    onChange={(event) => {
                                                        validation.handleChange(event)
                                                        setRDiscount(event.target.value)
                                                    }
                                                    }
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_customer_discount || "0"}
                                                    disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    required
                                                    invalid={
                                                        validation.touched.subscription_customer_discount && validation.errors.subscription_customer_discount ? true : false
                                                    }
                                                />
                                                {validation.touched.subscription_customer_discount && validation.errors.subscription_customer_discount ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_customer_discount}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col className="col-3">
                                            <div >
                                                <Label htmlFor="subscription_customer_quantity">Quantity</Label>
                                                <Input
                                                    name="subscription_customer_quantity"
                                                    className="form-control"
                                                    placeholder="Enter quantity"
                                                    type="number"
                                                    onChange={(event) => {
                                                        validation.handleChange(event)
                                                        setRQuantity(event.target.value)
                                                    }
                                                    }
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.subscription_customer_quantity || "1"}
                                                    disabled={props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    required
                                                    invalid={
                                                        validation.touched.subscription_customer_quantity && validation.errors.subscription_customer_quantity ? true : false
                                                    }
                                                />
                                                {validation.touched.subscription_customer_quantity && validation.errors.subscription_customer_quantity ? (
                                                    <FormFeedback type="invalid">{validation.errors.subscription_customer_quantity}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="">
                                        <Col className="col-auto me-2">
                                            <div className="d-flex flex-column" >
                                                <Label className="text-secondary" htmlFor="vendor_name">Actual Cost</Label>
                                                <Label className="font-size-20" htmlFor="vendor_name">{actualCost}</Label>

                                            </div>
                                        </Col>
                                        <Col className="col-auto me-2">
                                            <div className="d-flex flex-column" >
                                                <Label className="text-secondary" htmlFor="vendor_name">Total Purchase Cost</Label>
                                                <Label className="font-size-20" htmlFor="vendor_name">{purchaseCost}</Label>

                                            </div>
                                        </Col>
                                        <Col className="col-auto me-2">
                                            <div className="d-flex flex-column" >
                                                <Label className="text-secondary" htmlFor="vendor_name">Total Customer Cost</Label>
                                                <Label className="font-size-20" htmlFor="vendor_name">{customerCost}</Label>

                                            </div>
                                        </Col>
                                        <Col className="col-auto me-2">
                                            <div className="d-flex flex-column" >
                                                <Label className="text-secondary" htmlFor="vendor_name">Expected Margin</Label>
                                                <Label className="font-size-20" htmlFor="vendor_name">{expectedMargin} <i className={Math.round(expectedMargin) === 0 ? "mdi text-dark" : Math.round(expectedMargin) > 0 ? "mdi mdi-arrow-up text-success" : "mdi mdi-arrow-down text-danger"} /></Label>

                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className=" mt-4">
                                        <Col lg={6}>
                                            <Label>Commitment Start Date</Label>
                                            <InputGroup>
                                                <Input
                                                    id="sc_start_date"
                                                    name="subscription_customer_start_date"
                                                    placeholder="date placeholder"
                                                    type="date"
                                                    value={validation.values.subscription_customer_start_date}
                                                    disabled={props.formMode === "clone" ? false : validation.values.subscription_plan_name === "0" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    required
                                                />
                                            </InputGroup>
                                            {validation.touched.subscription_customer_start_date && validation.errors.subscription_customer_start_date ? (
                                                <FormFeedback type="invalid">{validation.errors.subscription_customer_start_date}</FormFeedback>
                                            ) : null}

                                        </Col>
                                        <Col lg={6}>
                                            <Label>Commitment End Date</Label>
                                            <InputGroup>
                                                <Input
                                                    id="exampleDate"
                                                    name="subscription_customer_end_date"
                                                    placeholder="date placeholder"
                                                    type="date"
                                                    value={validation.values.subscription_customer_end_date}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    disabled={props.formMode === "clone" ? false : validation.values.subscription_plan_name === "0" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    required
                                                />

                                            </InputGroup>
                                            {validation.touched.subscription_customer_end_date && validation.errors.subscription_customer_end_date ? (
                                                <FormFeedback type="invalid">{validation.errors.subscription_customer_end_date}</FormFeedback>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                            }

                            {
                                (validation.values.subscription_plan_name !== "0" && selectedSPlan && cmpConfig) &&
                                <div className="bg-primary bg-opacity-25 p-3">
                                    <Row className="mb-3" style={{ borderBottom: "1px solid #dedede" }}>
                                        <Col md={12}>
                                            <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="vendor">Vendor</label>
                                                <Input className="form-control"
                                                    name="vendor"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.vendor || "0"}
                                                    disabled={validation.values.subscription_plan_name === "0" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    required
                                                    invalid={
                                                        validation.touched.vendor && validation.errors.vendor ? true : false
                                                    }
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        vendorList && vendorList.map((item, idx) => {
                                                            return (
                                                                <option key={"impt" + idx} value={item.vendor_name}>{item.vendor_name}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                {validation.touched.vendor && validation.errors.vendor ? (
                                                    <FormFeedback type="invalid">{validation.errors.vendor}</FormFeedback>
                                                ) : null}

                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="col-sm-auto ">
                                                <label className="" htmlFor="vendor_commitment_term">Commitment Term</label>
                                                <Input className="form-control"
                                                    name="vendor_commitment_term"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.vendor_commitment_term || "0"}
                                                    disabled={validation.values.vendor === "0" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    required
                                                    invalid={
                                                        validation.touched.vendor_commitment_term && validation.errors.vendor_commitment_term ? true : false
                                                    }
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        cmpConfig.commitment_term.map((item, idx) => {
                                                            return (
                                                                <option key={"blt" + idx} value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                {validation.touched.vendor_commitment_term && validation.errors.vendor_commitment_term ? (
                                                    <FormFeedback type="invalid">{validation.errors.vendor_commitment_term}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="col-sm-auto">
                                                <label className="" htmlFor="vendor_billing_term">Billing Term</label>
                                                <Input className="form-control"
                                                    name="vendor_billing_term"
                                                    type="select"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.vendor_commitment_term === "Monthly" ? "Monthly" : (validation.values.vendor_billing_term || "0")}
                                                    required
                                                    disabled={validation.values.vendor_commitment_term === "Monthly" || validation.values.vendor_commitment_term === "0" || props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                                    invalid={
                                                        validation.touched.vendor_billing_term && validation.errors.vendor_billing_term ? true : false
                                                    }
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        cmpConfig.billing_term.map((item, idx) => {
                                                            return (
                                                                <option key={"bty" + idx} value={item.name}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </Input>
                                                {validation.touched.vendor_billing_term && validation.errors.vendor_billing_term ? (
                                                    <FormFeedback type="invalid">{validation.errors.vendor_billing_term}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>



                                    </Row>
                                </div>

                            }

                        </Row>

                    }




                    <Row className="mb-3">
                        <Col className="col-12">
                            <div >
                                <Label htmlFor="remarks">Remarks</Label>
                                <Input
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    type="textarea"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.remarks || ""}
                                    disabled={props.formMode === "clone" ? false : props.selectedSubscription && props.selectedSubscription.state === "publish"}
                                />
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        {
                            props.formMode === "clone" && 
                            <div>
                                <label className="text-danger font-size-13">Check Commitment start date and end date before save</label>
                            </div>
                        }
                    </Row>


                    <div className="d-flex flex-row">
                        

                        {
                            (!props.selectedSubscription) &&
                            <button type="submit"
                                onClick={(event) => {
                                    setSaveMode("draft")
                                    validation.handleSubmit(event)
                                }}
                                className="btn btn-primary w-md me-2" disabled={isApiRequested} >
                                {props.formMode === "edit" ? "Update draft" : "Save as draft"}
                            </button>
                        }

                        {
                            (props.formMode === "clone" || props.selectedSubscription && (props.selectedSubscription.state === "draft" && props.selectedSubscription.state !== "undefined")) &&
                            <button type="submit"
                                onClick={(event) => {
                                    setSaveMode("draft")
                                    validation.handleSubmit(event)
                                }}
                                className="btn btn-primary w-md me-2" disabled={isApiRequested} >
                                {props.formMode === "edit" ? "Update draft" : "Save as draft"}
                            </button>
                        }

                        {
                            props.selectedSubscription && props.selectedSubscription.state === "draft" &&
                            <button type="submit"
                                onClick={(event) => {
                                    setSaveMode("publish")
                                    validation.handleSubmit(event)
                                }}
                                className="btn btn-success w-md" disabled={isApiRequested} >
                                {"Publish"}
                            </button>
                        }


                    </div>
                </Form>

            </Col>

        </Row>

    );
};

export default CRUDSubscription;

