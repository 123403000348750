import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    fetchVendors,
    createVendor,
    updateMasterVendor,
    deleteMasterVendors,
    resetVendorSuccessfullyAdded
} from 'toolkitStore/manageVendorSlice';


const VendorForm = (props) => {


    const dispatch = useDispatch()

    const [selectedData, setSelectedData] = useState(props.selectedData)
    const { vendorList, isSuccessfullyAdded, responseVendorError } = useSelector(state => state.manageVendorReducer)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/




    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            vendor_name: selectedData.vendor_name || "",
            display_name: selectedData.display_name || "",
            email: selectedData.email || "",
            website: selectedData.website || "",
            phone: selectedData.phone || "",
            address1: selectedData.address1 || "",
            address2: selectedData.address2 || "",
            city: selectedData.city || "",
            state: selectedData.state || "",
            country: selectedData.country || "",
            pincode: selectedData.pincode || "",
            remarks: selectedData.remarks || "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        }),
        onSubmit: (values, { resetForm }) => {



            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            values["created_by"] = props.userInformation._id

            console.log("values", values)

            if (props.mode === "edit") {
                values["_id"] = selectedData._id
                dispatch(updateMasterVendor(values))
            }
            else {
                dispatch(createVendor(values))
            }
        }
    });

    useEffect(() => {
        if (isSuccessfullyAdded) {
            if (!responseVendorError) {
                validation.resetForm({ values: "" })
                props.closeOffCanvas()
                dispatch(resetVendorSuccessfullyAdded())
            }
        }
    }, [isSuccessfullyAdded])



    return (
        <div>
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >

                <div className="mb-3">
                    <Label htmlFor="vendor_name">Vendor Name</Label>
                    <Input
                        name="vendor_name"
                        className="form-control"
                        placeholder="Enter Customer name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.vendor_name || ""}
                        required
                        invalid={
                            validation.touched.vendor_name && validation.errors.vendor_name ? true : false
                        }
                    />
                    {validation.touched.vendor_name && validation.errors.vendor_name ? (
                        <FormFeedback type="invalid">{validation.errors.vendor_name}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <Label htmlFor="display_name">Display Name</Label>
                    <Input
                        name="display_name"
                        className="form-control"
                        placeholder="Enter Display name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.display_name || ""}
                        invalid={
                            validation.touched.display_name && validation.errors.display_name ? true : false
                        }
                    />
                    {validation.touched.display_name && validation.errors.display_name ? (
                        <FormFeedback type="invalid">{validation.errors.display_name}</FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <Label htmlFor="email">Email</Label>
                    <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email id"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        required
                        invalid={
                            validation.touched.email && validation.errors.email ? true : false
                        }
                    />
                    {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                </div>

                <Row>
                    <Col lg={6} >
                        <div className="mb-3">
                            <Label htmlFor="phone">Phone</Label>
                            <Input
                                name="phone"
                                className="form-control"
                                placeholder="Enter Phone number"
                                type="text"
                                maxLength={"10"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                required
                                invalid={
                                    validation.touched.phone && validation.errors.phone ? true : false
                                }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} >
                        <div className="mb-3">
                            <Label htmlFor="website">Website</Label>
                            <Input
                                name="website"
                                className="form-control"
                                placeholder="Enter website url"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.website || ""}
                                invalid={
                                    validation.touched.website && validation.errors.website ? true : false
                                }
                            />
                            {validation.touched.website && validation.errors.website ? (
                                <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="mb-3">
                        <Label htmlFor="address1">Address 1</Label>
                        <Input
                            name="address1"
                            className="form-control"
                            placeholder="Enter Address 1"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address1 || ""}
                            invalid={
                                validation.touched.address1 && validation.errors.address1 ? true : false
                            }
                        />
                        {validation.touched.address1 && validation.errors.address1 ? (
                            <FormFeedback type="invalid">{validation.errors.address1}</FormFeedback>
                        ) : null}
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="address2">Address 2</Label>
                        <Input
                            name="address2"
                            className="form-control"
                            placeholder="Enter Address 2"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address2 || ""}
                            invalid={
                                validation.touched.address2 && validation.errors.address2 ? true : false
                            }
                        />
                        {validation.touched.address2 && validation.errors.address2 ? (
                            <FormFeedback type="invalid">{validation.errors.address2}</FormFeedback>
                        ) : null}
                    </div>
                </Row>

                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="city">City</Label>
                            <Input
                                name="city"
                                className="form-control"
                                placeholder="Enter City"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                invalid={
                                    validation.touched.city && validation.errors.city ? true : false
                                }
                            />
                            {validation.touched.city && validation.errors.city ? (
                                <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="state">State</Label>
                            <Input
                                name="state"
                                className="form-control"
                                placeholder="Enter State"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.state || ""}
                                invalid={
                                    validation.touched.state && validation.errors.state ? true : false
                                }
                            />
                            {validation.touched.state && validation.errors.state ? (
                                <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="country">Country</Label>
                            <Input
                                name="country"
                                className="form-control"
                                placeholder="Enter Country"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country || ""}
                                invalid={
                                    validation.touched.country && validation.errors.country ? true : false
                                }
                            />
                            {validation.touched.country && validation.errors.country ? (
                                <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="pincode">Pincode</Label>
                            <Input
                                name="pincode"
                                className="form-control"
                                placeholder="Enter Pincode"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.pincode || ""}
                                invalid={
                                    validation.touched.pincode && validation.errors.pincode ? true : false
                                }
                            />
                            {validation.touched.pincode && validation.errors.pincode ? (
                                <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>


                <div className="mb-3">
                    <Label htmlFor="remarks">Remarks</Label>
                    <Input
                        name="remarks"
                        className="form-control"
                        placeholder="Enter Remarks"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.remarks || ""}
                        invalid={
                            validation.touched.remarks && validation.errors.remarks ? true : false
                        }
                    />
                    {validation.touched.remarks && validation.errors.remarks ? (
                        <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                    ) : null}
                </div>

                <div className="d-flex flex-column">
                    {responseVendorError && <label className="font-size-10 text-danger">{responseVendorError}</label>}
                    <button type="submit" className="btn btn-primary w-md" >
                        {props.mode === "edit" ? "Update" : "Submit"}
                    </button>
                </div>
            </Form>
        </div>

    );
};

export default VendorForm;
