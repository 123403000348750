import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    contractList: null,
    contractAutoGenId:"",
    contractServiceList:null,
    customerAssetList:null,
    contractServiceAssetList:null,
    contractServiceAssetSummary:'',
    isContractSuccessfullyAdded:false,
    isContractServiceSuccessfullyAdded:false,
    contractServiceSWList:null,
    companyDeliverableList:null,
    contractServiceDeliverableList:null,
    contractServiceResourceList:null,
    companySLAList:null,
    contractServiceSLAList:null,
    serviceMaster:null,
    isDataLoading:null,

};


const manageContractSlice = createSlice({
    name: "manageContract",
    initialState: initialRegState,
    reducers: {

        setContractList: (state, action) => {
            if (action.payload.length > 0) {
                state.contractList = _.each(action.payload, item => {
                    item["edit"] = false
                })
            }
            else {
                state.contractList = null
            }
        },
        setContractSuccessfullyAdded:(state, action) => {
            state.isContractSuccessfullyAdded = true
        },
        resetContractSuccessfullyAdded:(state, action) => {
            state.isContractSuccessfullyAdded = false
        },
        resetContractList: (state, action) => {
            state.contractList = null
        },
        setContractAutoGenID: (state, action) => {
            console.log("action.payload", action.payload)
            state.contractAutoGenId = action.payload.data
        },
        resetAutogenID:(state, action) => {
            state.contractAutoGenId = ""
        },
        setContractServices:(state, action) => {
            state.contractServiceList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        updateContractServices:(state, action) => {
            
            console.log("update", action.payload)

            var getIndex = _.findIndex(state.contractServiceList, {"_id": action.payload.data._id})
            console.log("getIndex", getIndex)

            var cloneServiceList = [...state.contractServiceList]
            cloneServiceList[getIndex] = action.payload.data

            state.contractServiceList =  _.each(cloneServiceList, item => {
                if(item._id === action.payload.data._id)
                {
                    item["edit"] = true
                }
                else
                {
                    item["edit"] = false
                }
                
            })
            
            // state.contractServiceList = _.each(action.payload, item => {
            //     item["edit"] = false
            // })
        },
        resetContractServices:(state, action) => {
            state.contractServiceList = null
        },
        setContractServiceSuccessfullyAdded:(state, action) => {
            state.isContractServiceSuccessfullyAdded = true
        },
        resetContractServiceSuccessfullyAdded:(state, action) => {
            state.isContractServiceSuccessfullyAdded = false
        },
        setCustomerAssetList:(state, action) => {
            state.customerAssetList = _.each(action.payload, item => {
                item["editItem"] = false
            })
        },
        resetCustomerAssetList:(state, action) => {
            state.customerAssetList = null
        },
        setContractServiceAssetList:(state, action) => {
            console.log("action.payload.length", action.payload)
            state.contractServiceAssetList = action.payload !== null ? action.payload.data : []
            state.contractServiceAssetSummary = action.payload !== null ? action.payload.summary : []
        },
        resetContractServiceAssetList:(state, action) => {
            state.contractServiceAssetList = null
            state.contractServiceAssetSummary = null
        },
        setContractServiceSWList:(state, action) => {
            state.contractServiceSWList = action.payload
        },
        resetContractServiceSWList:(state, action) => {
            state.contractServiceSWList = null
        },
        setCompanyDeliverableList:(state, action) => {
            state.companyDeliverableList = _.each(action.payload, item => {
                item["editItem"] = false
            })
        },
        setContractServiceDeliverableList:(state, action) => {
            state.contractServiceDeliverableList = action.payload
        },
        resetContractServiceDeliverableList:(state, action) => {
            state.contractServiceDeliverableList = null
        },
        setContractServiceResourceList:(state, action) => {
            state.contractServiceResourceList = action.payload
        },
        resetContractServiceResourceList:(state, action) => {
            state.contractServiceResourceList = null
        },
        setCompanySLAList:(state, action) => {
            state.companySLAList = _.each(action.payload, item => {
                item["editItem"] = false
            })
        },
        setContractServiceSLAList:(state, action) => {
            state.contractServiceSLAList = action.payload
        },
        resetContractServiceSLAList:(state, action) => {
            state.contractServiceSLAList = null
        },
        setServiceMaster:(state, action) => {
            state.contractServiceSLAList = action.payload
        },
        resetServiceMaster:(state, action) => {
            state.serviceMaster = null
        },
        setServiceRestoreContent:(state, action) => {
            state.serviceMaster = action.payload
        },
        setDataLoading:(state, action) => {
                state.isDataLoading = action.payload            
        },
        resetDataLoading:(state, action) => {
            state.isDataLoading = null           
        }

    }

})

export const createContract = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetContractSuccessfullyAdded())
            const responseData = await post("/manage_contract/add-contract", {
                "contractInfo": values
            })
            console.log("create contract", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterContract = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetContractSuccessfullyAdded())
            const responseData = await post("/manage_contract/update-contract", {
                "contractInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const publishingContract = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetContractSuccessfullyAdded())
            const responseData = await post("/manage_contract/publish-contract", {
                "contractInfo": values
            })
            console.log("publish", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchContracts = (company_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_contract/get-contracts", {
                "company_id":company_id
            })
            console.log("get contracts", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractList(responseData.data))
                // if(responseData.data.length > 0)
                // {
                //     dispatch(setContractList(responseData.data))
                // }
                // else
                // {
                //     dispatch(resetContractList())
                // }
            }
                
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterContract = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetContractSuccessfullyAdded())
            const responseData = await post("/manage_contract/delete-contract", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const getAutogenId = (id) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_contract/get-autogenid", {
                "company_id":id,
                "type":"contract"
            })
            console.log("auto gen ids", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractAutoGenID(responseData))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

// ============ Contract Service =================================

export const createContractServices = (service_ids, info) => {
    return async (dispatch) => {
        dispatch(setContractServiceSuccessfullyAdded())
        const responseData = await post("config_contract/create-contract-service", {
            "contractServiceInfo":{
                "serviceIDs":service_ids,
                "info":info
            }
        })
        console.log("contract services", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(resetContractServiceSuccessfullyAdded())
            }
    }
}

export const getContractServices = (info) => {
    // console.log("getContractServices", info)
    return async (dispatch) => {
        try {
            dispatch(setDataLoading(true))
            var responseData = await post("config_contract/get-contract-services", {
                "contractInfo":info
            })

            console.log("contract services", responseData)
            if(responseData.response_code === 500)
            {
                setTimeout(() => {
                    if(responseData.data.length > 0)
                {
                    dispatch(setContractServices(responseData.data))
                }
                else
                {
                    dispatch(setContractServices([]))
                }
                dispatch(setDataLoading(false))
                }, 1000);

                
            }

        } catch (error) {
            console.log("catch error", error)
        }
    }

}

export const removeServiceFromContract = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/remove-contract-service",{
                service_id:info._id
            })
            if(responseData.response_code === 500)
            {
                dispatch(setContractServices([]))
            }
            console.log("delete service", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const resetServiceContent = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/get-service-scope-content",{
                m_service_id:info.m_service_id
            })
            if(responseData.response_code === 500)
            {
                dispatch(setServiceRestoreContent(responseData.data))
            }
            console.log("reset service", responseData.data)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}



// ============ Contract Service Assets=================================

export const getCustomerAssets = (info) => {
    return async (dispatch) => {
        dispatch(resetCustomerAssetList())
        var responseData = await post("config_contract/get-customer-assets", {
            "customerInfo":info
        })
       
        if(responseData.response_code === 500)
            {
                    dispatch(setCustomerAssetList(responseData.data))
            }
    }
}

export const addAssetsToContract = (assets) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/add-asset-contract",{
                "assetInfo":assets
            })
            console.log("contract assets", responseData)     
        } catch (error) {
            
        }
    }
}

export const fetchContractServiceAssets = (value) => {
    return async dispatch => {
        try {
            dispatch(resetContractServiceAssetList())
            var responseData = await post("config_contract/get-contract-service-assets", {
                contractServiceInfo: value
            })
            if (responseData.response_code === 500) {
                console.log("contract service assets", responseData.data)
                if(responseData.data.length > 0 )
                {
                    dispatch(setContractServiceAssetList(responseData.data[0]))
                }
                else
                {
                    dispatch(setContractServiceAssetList(null))
                }
                    

            }
        } catch (error) {
            console.log("catch error", error)
        }
    }
       
}

export const removeAssetFromSC = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/remove-contract-service-assets",{
                asset_id:info._id
            })
            console.log("delete asset", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}


// ============ Contract Service - Service Window =================================


export const addSWToContractService = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/add-service-window-contract",{
                swInfo:info
            })
            console.log("add SW", responseData)

           
        }
        catch (error) {
            console.log("catch error", error)
        }
    }
}

export const getContractServiceSW = (info) => {
    return async dispatch => {
        try {
            dispatch(resetContractServiceSWList())
            var responseData = await post("config_contract/get-contract-service-sw",{
                swInfo:info
            })
            console.log("fetch SW", responseData)
            if (responseData.response_code === 500) {
                if (responseData.data.length > 0) {
                    dispatch(setContractServiceSWList(responseData.data))
                }

            }
        }
        catch (error) {
            console.log("catch error", error)
        }
    }
}

export const removeSWFromSC = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/remove-contract-service-sw",{
                sw_id:info._id
            })
            console.log("delete sw", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}


// ============ Contract Service Deliverables =================================

export const getCompanyDeliverables = (company_id) => {
    return async (dispatch) => {
        var responseData = await post("config_contract/get-deliverables-for-contract", {
            "company_id":company_id
        })
       
        if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setCompanyDeliverableList(responseData.data))
                }
                
            }
    }
}

export const addDeliverablesToContract = (deliverables) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/add-deliverable-to-contract",{
                "deliverableInfo":deliverables
            })
            console.log("contract deliverables", responseData)     
        } catch (error) {
            
        }
    }
}

export const fetchContractServiceDeliverables = (value) => {
    return async dispatch => {
        try {
            dispatch(resetContractServiceDeliverableList())
            var responseData = await post("config_contract/get-contract-service-deliverables", {
                contractServiceInfo: value
            })
            console.log("fetch contract deliverables", responseData)
            if (responseData.response_code === 500) {
                if (responseData.data.length > 0) {
                    dispatch(setContractServiceDeliverableList(responseData.data))
                }

            }
        } catch (error) {
            console.log("catch error", error)
        }
    }
       
}

export const removeDeliverablesFromSC = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/remove-contract-service-deliverables",{
                deliverable_id:info._id
            })
            console.log("delete asset", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const updateContractDeliverable = (deliverables, contractServiceInfo) => {
    return async (dispatch) => {
        try {
            dispatch(resetContractServiceDeliverableList())
            const responseData = await post("config_contract/update-contract-service-deliverable", {
                "deliverableInfo": deliverables,
                "contractServiceInfo": contractServiceInfo
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setContractServiceDeliverableList(responseData.data))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

// ============ Contract Service Resources =================================

export const addResourceToCS = (resourceInfo) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/add-resource-to-cs",{
                "resourceInfo":resourceInfo
            })
            console.log("contract resources", responseData)     
        } catch (error) {
            
        }
    }
}

export const fetchCSResources = (value) => {
    return async dispatch => {
        try {
            dispatch(resetContractServiceResourceList())
            var responseData = await post("config_contract/get-cs-resources", {
                contractServiceInfo: value
            })
            console.log("fetch ressources", responseData)
            if (responseData.response_code === 500) {
                if (responseData.data.length > 0) {
                    dispatch(setContractServiceResourceList(responseData.data))
                }

            }
        } catch (error) {
            console.log("catch error", error)
        }
    }
       
}

export const removeResourcesFromSC = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/remove-cs-resources",{
                resource_id:info._id
            })
            console.log("delete resource", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

// ============ Contract Service SLA =================================

export const getCompanySLAs = (company_id) => {
    return async (dispatch) => {
        var responseData = await post("config_contract/get-sla-for-contract", {
            "company_id":company_id
        })
       
        if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setCompanySLAList(responseData.data))
                }
                
            }
    }
}

export const addSLAToContract = (slaInfo) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/add-sla-to-contract",{
                "slaInfo":slaInfo
            })
            console.log("contract slas", responseData)     
        } catch (error) {
            
        }
    }
}

export const fetchContractServiceSLAs = (value) => {
    return async dispatch => {
        try {
            dispatch(resetContractServiceSLAList())
            var responseData = await post("config_contract/get-contract-service-sla", {
                contractServiceInfo: value
            })
            console.log("fetch contract slas", responseData)
            if (responseData.response_code === 500) {
                if (responseData.data.length > 0) {
                    dispatch(setContractServiceSLAList(responseData.data))
                }

            }
        } catch (error) {
            console.log("catch error", error)
        }
    }
       
}

export const removeSLAFromSC = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/remove-contract-service-sla",{
                sla_id:info._id
            })
            console.log("delete sla", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}


export const saveServiceInscope = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/save-inscope",{
                inscopeInfo:info
            })
            console.log("update inscope", responseData)
            dispatch(updateContractServices({data:responseData.data, type:"inscope"}))
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const saveServiceOutscope = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/save-outscope",{
                inscopeInfo:info
            })
            console.log("update inscope", responseData)
            dispatch(updateContractServices({data:responseData.data, type:"outscope"}))
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const saveServicePrerequisite = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/save-prerequisite",{
                inscopeInfo:info
            })
            console.log("update inscope", responseData)
            dispatch(updateContractServices({data:responseData.data, type:"prerequisite"}))
        } catch (error) {
            console.log("catch error", error)
        }
    }
}

export const saveServiceInternalNotes = (info) => {
    return async dispatch => {
        try {
            var responseData = await post("config_contract/save-internalnotes",{
                inscopeInfo:info
            })
            console.log("update inscope", responseData)
        } catch (error) {
            console.log("catch error", error)
        }
    }
}



export const { 
    setContractList, 
    setContractSuccessfullyAdded, 
    resetContractSuccessfullyAdded,
    resetContractList,
    setContractAutoGenID,
    resetAutogenID, setContractServices,
    resetContractServices,
    setContractServiceSuccessfullyAdded,
    resetContractServiceSuccessfullyAdded,
    setCustomerAssetList,
    resetCustomerAssetList,
    setContractServiceAssetList,
    resetContractServiceAssetList,
    setContractServiceSWList,
    resetContractServiceSWList,
    setCompanyDeliverableList,
    setContractServiceDeliverableList,
    resetContractServiceDeliverableList,
    setContractServiceResourceList,
    resetContractServiceResourceList,
    setCompanySLAList,
    setContractServiceSLAList,
    resetContractServiceSLAList,
    updateContractServices,
    setServiceRestoreContent,
    setDataLoading,
    resetDataLoading

} = manageContractSlice.actions;

export default manageContractSlice.reducer;
