import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classname from "classnames";

import { useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)

  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {

    if(userInformation === "")
    {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      }

  }, [userInformation])

  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [job, setJob] = useState(false);
  const [candidate, setCandidate] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }


  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              
              <ul className="navbar-nav">

                {
                  userInformation && userInformation.facilities.map((item, idx) => {
                    let getSubmenu = _.filter(userInformation.facilities, { parent: item.id })
                    let isSubmenuAvailable = _.some(getSubmenu, { enabled: true })
                    
                    if( item.type === "menu")
                    {
                      return <li className="nav-item dropdown" key={"mnu" + idx}>
                        {
                        item.parent === null && item.enabled && item.type === "menu" &&
                        <Link to={item.url} className="nav-link dropdown-toggle arrow-none" style={{padding: "0.7rem 1.3rem", fontSize:13}}>
                          {/* <i className={item.icon_name+ " me-2"}></i> */}
                          <span>{props.t(item.interfacename)}</span>
                        </Link>
                      }
                      </li>
                    }
                    if(isSubmenuAvailable){
                    return <li className="nav-item dropdown" key={"mnu" + idx}>
                      {
                        item.parent === null && item.enabled && item.type === "menu" &&
                        <Link to={item.url} className="nav-link dropdown-toggle arrow-none" style={{padding: "0.7rem 1.3rem", fontSize:13}}>
                          {/* <i className={item.icon_name+ " me-2"}></i> */}
                          <span>{props.t(item.interfacename)}</span>
                        </Link>
                      }

                      {
                        getSubmenu.length > 0 &&
                        <>
                          <Link
                            className="nav-link dropdown-toggle arrow-none" style={{padding: "0.7rem 1.3rem", fontSize:13}}
                            // onClick={e => {
                            //   e.preventDefault();
                            //   setdashboard(!dashboard);
                            // }}
                            // to="/dashboard"
                          >
                            {/* <i className={item.icon_name + " me-2"} ></i> */}
                            {props.t(item.interfacename)}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", { show: dashboard })}
                          >
                            {
                              getSubmenu.map((element, id) => {
                                if (element.enabled) {
                                  return <Link className="dropdown-item" to={element.url} key={"smnu" + id}>{props.t(element.interfacename)}</Link>
                                }
                                else {
                                  return null
                                }
                              })
                            }
                          </div>
                        </>
                      }
                    </li>
                    }


                  })
                }

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
