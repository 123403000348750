import React, { useState } from "react"
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Table,
  Input,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"

const TotalSellongProduct = props => {
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <Col xl="3">
        <Card style={{ height: 450 }}>
          <CardBody>
            <h4 className="card-title mb-4">Quick links</h4>

            {/* <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  In Process
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  Upcoming
                </NavLink>
              </NavItem>
            </Nav> */}

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "250px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle table-hover mb-0">
                    <tbody>
                      <tr>

                        <td>
                          <Link to="/subscription-update">

                            <h5 className="text-truncate font-size-14 mb-1">
                              <Link to="/subscription-update" className="text-dark">
                                Subscription Update
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">Monthly Consumptions</p>
                          </Link>
                        </td>
                        <td style={{ width: "90px" }}>
                          <Link to="/subscription-update">
                            <div>
                              <ul className="list-inline mb-0 font-size-16">
                                <li className="list-inline-item">
                                  <Link to="/subscription-update" className="text-success p-1">
                                    <i className="mdi mdi-arrow-right" />
                                  </Link>
                                </li>

                              </ul>
                            </div>
                          </Link>
                        </td>
                      </tr>

                      <tr>

                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            <Link to="#" className="text-dark">
                              Tasks
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">Notifications and tasks</p>
                        </td>
                        <td style={{ width: "90px" }}>
                          <div>
                            <ul className="list-inline mb-0 font-size-16">
                              <li className="list-inline-item">
                                <Link to="#" className="text-success p-1">
                                  <i className="mdi mdi-arrow-right" />
                                </Link>
                              </li>

                            </ul>
                          </div>
                        </td>
                      </tr>

                      <tr>

                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            <Link to="#" className="text-dark">
                              Knowledge Base
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">Share</p>
                        </td>
                        <td style={{ width: "90px" }}>
                          <div>
                            <ul className="list-inline mb-0 font-size-16">
                              <li className="list-inline-item">
                                <Link to="#" className="text-success p-1">
                                  <i className="mdi mdi-arrow-right" />
                                </Link>
                              </li>

                            </ul>
                          </div>
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </SimpleBar>
            </div>
          </CardBody>


        </Card>
      </Col>
    </React.Fragment>
  )
}

export default TotalSellongProduct
