import React, { useEffect, useMemo, useState } from "react";
import { Link, Route } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    FormFeedback,
    Modal, FormGroup, Badge
} from "reactstrap";

import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import * as Yup from "yup"
import { useFormik } from "formik"

import PropTypes from "prop-types";
import withRouter from "../../../components/Common/withRouter";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch, } from "react-redux";
import moment from "moment";

import { fetchServices, createService, updateService, deleteMasterService } from "../../../toolkitStore/manageServiceSlice"
import { fetchCustomers, createCustomer, updateMasterCustomer, deleteMasterCustomers } from "../../../toolkitStore/manageCustomerSlice"
import { 
    fetchContracts, 
    createContract, 
    updateMasterContract, 
    deleteMasterContract, 
    getAutogenId, 
    resetAutogenID,
    resetContractServices,
    resetDataLoading,
    publishingContract
} from "../../../toolkitStore/manageContractSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"



const Contracts = (props) => {

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { customerList, isSuccessfullyAdded } = useSelector(state => state.manageCustomerReducer)
    const { contractList, contractAutoGenId, isContractSuccessfullyAdded } = useSelector(state => state.manageContractReducer)


    const [userInformation, setInfo] = useState("")
    const [cmpConfig, setCmpConfig] = useState("")
    const [mode, setMode] = useState("new")
    const [cntrList, setContractList] = useState([])
    const [customerInfo, setCustomerInfo] = useState([])
    const [selectedContract, setSelectedContract] = useState("")
    const [err, setErr] = useState("")
    const [autId, setAutoID] = useState("")
    const [autogenid, setAutogenId] = useState('')

    const [selectedCustomer, setSelectedCustomer] = useState("0")
    const [selectedCustomerID, setSelectedCustomerID] = useState("0")
    const [contractName, setContractName] = useState("")
    const [stateMode, setStateMode] = useState("1")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [customerError, setCustomerError] = useState("")
    const [startDateError, setStartDateError] = useState("")
    const [endDateError, setEndDateError] = useState("")

    const [cstmrList, setCstmrList] = useState([])


    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [isRight, setIsRight] = useState(false);

    const createAutoGenId = () => {
        setAutoID(contractAutoGenId.generatedId)
        setAutogenId(contractAutoGenId.id)
    }

    useEffect(() => {
        if (companyConfig) {
            const cConfig = JSON.parse(localStorage.getItem("company-config"));
            setCmpConfig(cConfig)
        }
        else {
            dispatch(getConfig())
        }

    }, [companyConfig])


    useEffect(() => {
        if (!userInformation) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setInfo(obj)
            dispatch(getConfig())
        }
    }, [userInformation])

    useState(() => {
        localStorage.removeItem("@selectedContract")
    }, [dispatch])


    useEffect(() => {
        console.log("-----", userInformation, contractList)
        if (userInformation && contractList === null) {

            dispatch(fetchContracts(userInformation.m_company_id))
        }
        else if (contractList) {
            setContractList([...contractList])
        }

    }, [userInformation, contractList])

    useEffect(() => {
        if (isContractSuccessfullyAdded) {
            dispatch(fetchContracts(userInformation.m_company_id))
        }
    }, [isContractSuccessfullyAdded])

    useEffect(() => {
        if (userInformation && customerList === null && cntrList.length === 0) {
            dispatch(fetchCustomers(userInformation.m_company_id))
        }
        else if (userInformation && customerList !== null) {
            setCustomerInfo([...customerList])
        }
    }, [userInformation, customerList, cntrList])



    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [publishModal, setPublishModal] = useState(false);

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    function tog_publishModal() {
        setPublishModal(!publishModal);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const toggleRightCanvas = () => {
        setMode("new")
        setSelectedCustomer("")
        setStartDate("")
        setEndDate("")
        setStateMode("1")
        setSelectedCustomerID("0")
        setIsRight(!isRight);
    };

    const handleContractForm = () => {
        setMode("new")
        setSelectedCustomer("")
        setStartDate("")
        setEndDate("")
        setStateMode("1")
        setSelectedCustomerID("0")
        setSelectedContract("")
        dispatch(getAutogenId(userInformation.m_company_id))
        createAutoGenId()
        setIsRight(!isRight);
    }

    const handleSelectCustomerID = (value) => {

        console.log("customer value", value)

        var getCustomer = _.filter(customerList, { _id: value })[0]

        var contractName = "SLA" + autogenid + "-" + getCustomer.customer_name
        setContractName(contractName)
        setSelectedCustomer(getCustomer)
        setSelectedCustomerID(getCustomer._id)
    }


    const updateContractInfo = (data) => {

        setMode("edit")
        setSelectedCustomerID(data.customer_id)
        setSelectedContract(data)
        setStartDate(new Date(data.start_date))
        setEndDate(new Date(data.end_date))
        setStateMode(data.state_id)
        setIsRight(!isRight);

    }




    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            //contract_id: selectedContract.contract_id || autId || "",
            // contract_name: selectedContract.contract_name || contractName || "",
            customer_name: selectedContract.customer_name || "",
            reason: selectedContract.reason || "",
            description: selectedContract.description || "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Enter Customer Name..."),
            //reason: Yup.string().required("Enter Reason..."),
            //description: Yup.string().required("Enter Description..."),
        }),
        onSubmit: (values, { resetForm }) => {

            if (selectedCustomerID === "0") {
                setCustomerError("Please select Customer")
                return
            }

            if (startDate === "") {
                setStartDateError("Enter Start Date")
                return
            }

            if (endDate === "") {
                setEndDateError("Enter End Date")
                return
            }

            console.log("values", values)

            var getContract = _.filter(companyConfig.contract_state, { id: stateMode })[0]

            values["start_date"] = startDate === "" ? selectedContract.start_date : startDate
            values["end_date"] = endDate === "" ? selectedContract.end_date : endDate


            values["m_company_id"] = userInformation.m_company_id
            values["m_b_unit_id"] = userInformation.m_b_unit_id
            values["created_by"] = userInformation._id

            if (mode === "edit") {
                values["_id"] = selectedContract._id
                values["state"] = getContract.name
                values["state_id"] = getContract.id
                values["customer_id"] = selectedContract.customer_id
                values["customer_name"] = selectedContract.customer_name
                dispatch(updateMasterContract(values))
            }
            else {

                values["state"] = getContract.name
                values["state_id"] = getContract.id
                values["customer_id"] = selectedCustomer._id
                values["customer_name"] = selectedCustomer.customer_name
                dispatch(createContract(values))
            }
            resetForm({ values: "" })
            dispatch(resetAutogenID())
            dispatch(fetchContracts(userInformation.m_company_id))
            toggleRightCanvas()

        },
    })

    const crudContract = (data) => {
        localStorage.removeItem("@selectedContract")
        localStorage.setItem("@selectedContract", JSON.stringify(data))

        dispatch(resetContractServices())
        dispatch(resetDataLoading())

        props.router.navigate("/contract-configuration")
    }

    const publishContract = () => {
        dispatch(publishingContract(selectedContract))
        tog_publishModal()
    }


    const columns = useMemo(
        () => [
            {
                Header: 'Contract Name',
                accessor: 'contract_name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="col">
                            <div style={{ fontSize: 14, fontWeight: "bold" }}>{cellProps.row.original.contract_name}</div>
                            <div style={{ fontSize: 12 }}>{cellProps.row.original.contract_id}</div>
                        </div>
                    )
                }
            },
            {
                Header: 'Start Date',
                accessor: 'start_date',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div>{moment(cellProps.row.original.start_date).format("DD/MM/YYYY")}</div>
                    )
                }
            },
            {
                Header: 'End Date',
                accessor: 'end_date',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div>{moment(cellProps.row.original.end_date).format("DD/MM/YYYY")}</div>
                    )
                }
            },
            {
                Header: 'State',
                accessor: 'state',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div>
                            <Badge className={cellProps.row.original.state_id === "1" ? "badge-soft-secondary me-1" : cellProps.row.original.state_id === "2" ? "badge-soft-warning me-1" : "badge-soft-success me-1"}>
                                {cellProps.row.original.state}
                            </Badge>
                        </div>
                    )
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {
                                cellProps.row.original.state_id === "1" &&
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-outline-primary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const SData = cellProps.row.original;
                                            crudContract(SData);
                                            //toggleRightCanvas()
                                        }}
                                    >
                                        Configure contract

                                    </Link>
                                </li>
                            }
                            {
                                cellProps.row.original.state_id === "2" &&
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-outline-primary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const SData = cellProps.row.original;
                                            setSelectedContract(SData)
                                            tog_publishModal()
                                            //publishContract(SData);
                                            //toggleRightCanvas()
                                        }}
                                    >
                                        Publish

                                    </Link>
                                </li>
                            }
                            {
                                cellProps.row.original.state_id === "1" && 
                                <li>
                                    <Link
                                        to="#!"
                                        className="btn btn-sm btn-info"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const SData = cellProps.row.original;
                                            updateContractInfo(SData, customerInfo);
                                            return false
                                        }}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    </Link>
                                </li>
                            }

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-success"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const SData = cellProps.row.original;
                                        return false
                                    }}
                                >
                                    <i className="mdi mdi-eye" id="edittooltip" />
                                </Link>
                            </li>


                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-danger"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const info = cellProps.row.original;
                                        setSelectedContract(info)
                                        tog_backdrop();
                                        // deleteService(SData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },

        ],
        []
    );


    const removeContract = () => {

        dispatch(deleteMasterContract(selectedContract._id))
        tog_backdrop();
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

                    <Row
                        className="justify-content-center"
                    >
                        <Card>
                            <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Contracts</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="#!" onClick={() => {
                                            handleContractForm()
                                        }} className="btn btn-primary me-1">Add New Contract</Link>
                                        <Link to="#!" onClick={() => {
                                            dispatch(fetchContracts(userInformation.m_company_id))
                                        }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                    </div>
                                </div>
                                <Offcanvas
                                    style={{ width: 500, transition: "width .35s ease-in-out", }}
                                    isOpen={isRight}
                                    backdrop={"static"}
                                    direction="end"
                                    toggle={() => { toggleRightCanvas() }}>
                                    <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>
                                        {
                                            "Add Contract"
                                        }
                                    </OffcanvasHeader>
                                    <OffcanvasBody>

                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <Row>
                                                <Col>

                                                    {
                                                        customerInfo.length > 0 && <div className="mb-4">
                                                            <label className="" htmlFor="autoSizingSelect">Customer</label>
                                                            <select className="form-select" onChange={(e) => {
                                                                setCustomerError("")
                                                                handleSelectCustomerID(e.target.value)

                                                            }}
                                                                value={selectedCustomerID}
                                                            >
                                                                <option value="0" disabled>Choose...</option>
                                                                {
                                                                    customerInfo.map((item, idx) => {
                                                                        return (
                                                                            <option key={"impt" + idx} value={item._id}>{item.customer_name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            {customerError ? (
                                                                <label className="font-size-10 text-danger mt-2">{customerError}</label>
                                                            ) : null}
                                                        </div>
                                                    }


                                                    <div className="mb-4 row">
                                                        <FormGroup>
                                                            <Row style={{ marginBottom: 0 }}>
                                                                <Col lg={6}>
                                                                    <Label>Start Date</Label>
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="dd M,yyyy"
                                                                            options={{
                                                                                altInput: true,
                                                                                altFormat: "F j, Y",
                                                                                dateFormat: "Y-m-d"
                                                                            }}
                                                                            value={startDate}
                                                                            onChange={(e) => {
                                                                                console.log("eve", e)
                                                                                setStartDateError("")
                                                                                setStartDate(e)
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    {startDateError ? (
                                                                        <label className="font-size-10 text-danger mt-2">{startDateError}</label>
                                                                    ) : null}

                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Label>End Date</Label>
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="dd M,yyyy"
                                                                            options={{
                                                                                altInput: true,
                                                                                altFormat: "F j, Y",
                                                                                dateFormat: "Y-m-d"
                                                                            }}
                                                                            value={endDate}
                                                                            onChange={(e) => {
                                                                                console.log("eve", e)
                                                                                setEndDateError("")
                                                                                setEndDate(e)
                                                                            }}
                                                                        />

                                                                    </InputGroup>
                                                                    {endDateError ? (
                                                                        <label className="font-size-10 text-danger mt-2">{endDateError}</label>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                    </div>




                                                    {
                                                        mode !== "new" && companyConfig && <div className="mb-4">
                                                            <label className="" htmlFor="autoSizingSelect">State</label>
                                                            <select className="form-select" onChange={(e) => {
                                                                setStateMode(e.target.value)

                                                            }}
                                                                defaultValue={stateMode}
                                                            >
                                                                <option value="0" disabled>Choose...</option>
                                                                {
                                                                    companyConfig.contract_state.map((item, idx) => {
                                                                        return (
                                                                            <option key={"cnst" + idx} value={item.id} disabled={mode === "new"}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }


                                                            </select>

                                                        </div>
                                                    }

                                                    { mode !== "new" && <div className="mb-4">
                                                        <Label htmlFor="name">Reason</Label>
                                                        <Input
                                                            type="textarea"
                                                            className="form-control"
                                                            name="reason"
                                                            placeholder="Enter Reason"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.reason || ""}
                                                            invalid={
                                                                validation.touched.reason &&
                                                                    validation.errors.reason
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.reason &&
                                                            validation.errors.reason ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.reason}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                        }
                                                    <div className="mb-4">
                                                        <Label htmlFor="name">Remark</Label>
                                                        <Input
                                                            type="textarea"
                                                            name="description"
                                                            className="form-control"
                                                            placeholder="Enter Description"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.description || ""}
                                                            invalid={
                                                                validation.touched.description &&
                                                                    validation.errors.description
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.description &&
                                                            validation.errors.description ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.description}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary w-lg"
                                                        >
                                                            {mode === "edit" ? "Update" : "Submit"}
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </OffcanvasBody>
                                </Offcanvas>
                            </CardBody>
                            <CardBody>
                                {
                                    customerList && cntrList.length > 0 &&
                                    <TableContainer
                                        columns={columns}
                                        data={cntrList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        customPageSize={10}
                                        customPageSizeOptions={false}
                                        isJobListGlobalFilter={false}
                                        className="custom-header-css"
                                    />
                                }

                            </CardBody>
                        </Card>
                    </Row>

                    <Modal
                        isOpen={modal_backdrop}
                        toggle={() => {
                            tog_backdrop();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Delete Contract</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setmodal_backdrop(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure, you want to delete this contract?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => {
                                setmodal_backdrop(false);
                            }}>Close</button>
                            <button type="button" className="btn btn-danger" onClick={() => {
                                removeContract()
                            }}>Yes, delete it</button>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={publishModal}
                        toggle={() => {
                            tog_publishModal();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop1"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Publish Contract</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setPublishModal(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure, you want to publish this contract?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => {
                                setPublishModal(false);
                            }}>Close</button>
                            <button type="button" className="btn btn-success" onClick={() => {
                                publishContract()
                            }}>Yes, publish it</button>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
};

//export default Contracts;

export default withRouter(Contracts);

Contracts.propTypes = {
    history: PropTypes.object,
};

