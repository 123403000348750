import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback
} from "reactstrap";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { fetchCustomers, createCustomer, updateMasterCustomer, deleteMasterCustomers } from "../../../toolkitStore/manageCustomerSlice"
import { getConfig } from "../../../toolkitStore/authenticateSlice"
import { resetLocationList } from "../../../toolkitStore/manageLocationSlice"
import { resetContactList } from "../../../toolkitStore/manageCContactSlice"
import { resetAssetList } from "../../../toolkitStore/manageCAssetSlice"
import { resetEsculationList } from "../../../toolkitStore/manageEsculationSlice"

import AddCustomerForm from "./forms/addCustomer"
import AddPrimaryContactForm from "./forms/addPrimaryContact";
import AddLocationForm from "./forms/addLocation";
import AddAssets from "./forms/addAssets";
import AddContacts from "./forms/addContacts";
import AddEsculations from "./forms/addEsculation";
import ListOfLocations from "./forms/listOfLocations";
import ContactList from "./forms/contactList";


const Customers = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { customerList, isSuccessfullyAdded } = useSelector(state => state.manageCustomerReducer)


    const [userInformation, setInfo] = useState("")
    const [cmpConfig, setCmpConfig] = useState("")
    const [cstmrList, setCstmrList] = useState([])
    const [selectedData, setSelectedData] = useState("")
    const [customerType, setCustomerType] = useState("0")
    const [type, setType] = useState("0")
    const [selectedID, setSelectedID] = useState("")
    const [mode, setMode] = useState("new")
    const [formFor, setFormFor] = useState("")
    const [canvasWidth, setCanvasWidth] = useState(500)

    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [open, setOpen] = useState(false);
    const [isRight, setIsRight] = useState(false);

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setInfo(obj)
        const cConfig = JSON.parse(localStorage.getItem("company-config"));
        setCmpConfig(cConfig)
    }, [])


    useEffect(() => {

        if (customerList === null && userInformation) {
            dispatch(fetchCustomers(userInformation.m_company_id))
        }
        else if (userInformation) {
            setCstmrList([...customerList])
        }
    }, [userInformation, customerList])


    useEffect(() => {
        if (isSuccessfullyAdded) {
            dispatch(fetchCustomers(userInformation.m_company_id))
        }
    }, [isSuccessfullyAdded])


    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const toggleRightCanvas = () => {
        isRight && setFormFor("")
        isRight && setSelectedData("")
        isRight && setMode("new")
        isRight && setCustomerType("0")
        isRight && setType("0")
        dispatch(resetLocationList())
        dispatch(resetContactList())
        dispatch(resetAssetList())
        dispatch(resetEsculationList())
        setIsRight(!isRight);
    };

    const deleteData = (data) => {
        setSelectedID(data._id)
        tog_backdrop()
    }
    const onConfirmDelete = () => {
        dispatch(deleteMasterCustomers(selectedID))
        setmodal_backdrop(false);
    }

    // ==============================================

    const editData = (data) => {

        setMode("edit")

        var getCustomerIndex = _.findIndex(cmpConfig.customer_type, { id: data.customer_type })
        if (getCustomerIndex !== -1) {
            var customer_id = cmpConfig.customer_type[getCustomerIndex].id
        }
        setCustomerType(customer_id)
        setSelectedData(data)
        setFormFor("customer")
    }

    const handleCustomerForm = () => {
        setFormFor("customer");
    }

    const handleLocationForm = (data) => {
        setSelectedData(data)
        setFormFor("location");
    }

    const showLocations = (data) => {
        setSelectedData(data)
        setFormFor("listoflocations");
    }

    const showContacs = (data) => {
        setSelectedData(data)
        setFormFor("contacts");
    }

    const showAssets = (data) => {
        setSelectedData(data)
        setFormFor("assets");
    }

    const showEsculation = (data) => {
        setSelectedData(data)
        setFormFor("esculation");
    }



    const closeOffCanvas = () => {
        toggleRightCanvas();
        setCanvasWidth(500)
        dispatch(fetchCustomers(userInformation.m_company_id))
    }

    useEffect(() => {
        formFor !== "" && !isRight && toggleRightCanvas();
    }, [formFor])


    const expandCanvas = () => {
        setCanvasWidth(canvasWidth + 500)
    }

    const resetCanvas = () => {
        setCanvasWidth(500)
    }

    // ==============================================


    const columns = useMemo(
        () => [
            {
                Header: 'Customer Name',
                accessor: 'customer_name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <Col>
                            <div className="text-primary">{cellProps.row.original.customer_name}</div>
                            <div className="font-size-10">{cellProps.row.original.customer_type}</div>
                            <div className="font-size-10">{cellProps.row.original.customer_id}</div>
                        </Col>
                    )
                }
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableFilters: true,
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                disableFilters: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-outline-success"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        showLocations(data);
                                    }}
                                > Location
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-outline-warning"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        showContacs(data);
                                    }}
                                > Contact
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        showAssets(data);
                                    }}
                                > Asset
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        showEsculation(data);
                                    }}
                                > Escalation
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-info"
                                    onClick={(e) => {

                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        editData(data);
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        deleteData(data);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-success"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const SData = cellProps.row.original;
                                        navigate("/support-dashboard", {
                                            state:{
                                              "info":SData
                                            }
                                          })
                                        return false
                                    }}
                                >
                                    <i className="mdi mdi-eye" id="edittooltip" />
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },

        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Utility" breadcrumbItem="" /> */}

                    <Row
                        className="justify-content-center"
                    >
                        <Card>
                            <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Customer</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="#!" onClick={() => {
                                            handleCustomerForm()
                                        }} className="btn btn-primary me-1">Add New Customer</Link>
                                        <Link to="#!" onClick={() => {
                                            dispatch(fetchCustomers(userInformation.m_company_id))
                                        }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                    </div>
                                </div>
                                <Offcanvas
                                    style={{ width: 600, transition: "width .35s ease-in-out", }}
                                    isOpen={isRight}
                                    backdrop={'static'}
                                    direction="end"
                                    toggle={() => { toggleRightCanvas() }}>
                                    <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>
                                        {
                                            formFor === "customer" ? "Add / Edit Customer" :
                                                formFor === "location" ? "Add / Edit Location" :
                                                    formFor === "listoflocations" ? "Customer Locations" :
                                                        formFor === "contacts" ? "Contacts" :
                                                            formFor === "assets" ? "Assets" :
                                                                formFor === "esculation" ? "Escalation" : ""
                                        }
                                    </OffcanvasHeader>
                                    <OffcanvasBody>
                                        {
                                            formFor === "customer" ?
                                                <AddCustomerForm
                                                    selectedData={selectedData}
                                                    customerType={customerType}
                                                    closeOffCanvas={() => { closeOffCanvas() }}
                                                    cmpConfig={cmpConfig}
                                                    userInformation={userInformation}
                                                    mode={mode}
                                                />
                                                : formFor === "location" ?
                                                    <AddLocationForm
                                                        selectedData={selectedData}
                                                        type={type}
                                                        closeOffCanvas={() => { closeOffCanvas() }}
                                                        cmpConfig={cmpConfig}
                                                        userInformation={userInformation}
                                                        mode={mode}
                                                    />
                                                    : formFor === "listoflocations" ?
                                                        <ListOfLocations
                                                            selectedData={selectedData}
                                                            type={type}
                                                            closeOffCanvas={() => { closeOffCanvas() }}
                                                            cmpConfig={cmpConfig}
                                                            userInformation={userInformation}
                                                            mode={mode}
                                                        />
                                                        : formFor === "contacts" ?
                                                            <AddContacts
                                                                selectedData={selectedData}
                                                                customerType={customerType}
                                                                cmpConfig={cmpConfig}
                                                                userInformation={userInformation}
                                                                mode={mode}
                                                                expandCanvas={() => {
                                                                    expandCanvas()
                                                                }}
                                                                resetCanvas={() => {
                                                                    resetCanvas()
                                                                }}
                                                            />
                                                            : formFor === "assets" ?
                                                                <AddAssets
                                                                    selectedData={selectedData}
                                                                    customerType={customerType}
                                                                    closeOffCanvas={() => { closeOffCanvas() }}
                                                                    cmpConfig={cmpConfig}
                                                                    userInformation={userInformation}
                                                                    mode={mode}
                                                                    expandCanvas={() => {
                                                                        expandCanvas()
                                                                    }}
                                                                    resetCanvas={() => {
                                                                        resetCanvas()
                                                                    }}
                                                                />
                                                                : formFor === "esculation" ?
                                                                    <AddEsculations
                                                                        selectedData={selectedData}
                                                                        type={type}
                                                                        closeOffCanvas={() => { closeOffCanvas() }}
                                                                        cmpConfig={cmpConfig}
                                                                        userInformation={userInformation}
                                                                        mode={mode}
                                                                    /> : null
                                        }


                                    </OffcanvasBody>
                                </Offcanvas>

                            </CardBody>
                            <CardBody>
                                {
                                    cstmrList.length > 0 &&
                                    <TableContainer
                                        columns={columns}
                                        data={cstmrList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        customPageSize={10}
                                        customPageSizeOptions={false}
                                        isJobListGlobalFilter={false}
                                        className="custom-header-css"
                                    />
                                }

                            </CardBody>
                        </Card>
                    </Row>

                    <Modal
                        isOpen={modal_backdrop}
                        toggle={() => {
                            tog_backdrop();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Delete Customer</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setmodal_backdrop(false);
                                }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure, you want to delete this customer?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => {
                                setmodal_backdrop(false);
                            }}>Close</button>
                            <button type="button" className="btn btn-danger" onClick={() => {
                                onConfirmDelete()
                            }}>Yes, delete it</button>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Customers;
