import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
  CardText,
  CardTitle, Modal,
  Collapse, Table, Offcanvas, OffcanvasHeader, OffcanvasBody

} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

import { useDispatch, useSelector } from "react-redux";

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";

import { useLocation } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getCryptoOrders } from "store/crypto/actions";


import AddAssets from "./customers/forms/addAssets";
import AddContacts from "./customers/forms/addContacts";
import ListOfLocations from "./customers/forms/listOfLocations";

import moment from "moment";

import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"



import { getConfig } from "../../toolkitStore/authenticateSlice"
import { getCustomerInfo, resetCustomerData, getMasterServices, getServiceContracts, resetMasterServices, resetServiceContractList } from "../../toolkitStore/dashboardSlice"

const SupportDashboard = (props) => {
  //meta title
  document.title = "Support360 HQ | Pentabrains"

  const { userInfo, companyConfig } = useSelector(state => state.authReducer)
  const { customerInfo, isSuccessfullyAdded, customerData, masterServices, serviceContractList } = useSelector(state => state.dashboardReducer)
  const { customerList, } = useSelector(state => state.manageCustomerReducer)

  const dispatch = useDispatch()

  const [cstrInfo, setCstrInfo] = useState('')

  const [selectedData, setSelectedData] = useState("")
  const [customerType, setCustomerType] = useState("0")
  const [type, setType] = useState("0")
  const [selectedID, setSelectedID] = useState("")
  const [mode, setMode] = useState("new")

  const { orders, onGetOrders } = props;
  const [userInformation, setInfo] = useState("")
  const [cmpConfig, setCmpConfig] = useState("")
  const [activeTab, setactiveTab] = useState("1");
  const [activeTab1, setactiveTab1] = useState("5");
  const [col8, setcol8] = useState(true);
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  const [isOpen, setIsOpen] = useState(true);

  const [isOpenD, setIsOpenD] = useState(true);


  const [showAll, setShowAll] = useState(true);
  const [modal_scroll, setmodal_scroll] = useState(false);


  const [expanded, setExpanded] = useState(0)

  const [isRight, setIsRight] = useState(false);
  const [listFor, setListFor] = useState("");
  const [formFor, setFormFor] = useState("")
  const [actionData, setActionData] = useState("")
  const [deliverableName, setDeliverableName] = useState("")

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [filteredAssets, setFilteredAssets] = useState([])
  const [selectedAssetType, setSelectedAssetType] = useState(null)

  const [isCardListOpen, setIsCardListOpen] = useState(false);
  const [listData, setListData] = useState("");

  const toggle = () => {
    setIsOpen(!isOpen)
  };

  const toggleD = () => {
    setIsOpenD(!isOpenD)
    setIsCardListOpen(!isOpenD) 

  };

  const toggleShowAll = () => { setIsOpen(!showAll); setIsOpenD(!showAll); setShowAll(!showAll); setIsCardListOpen(!showAll) };



  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };



  const toggleTab = tab => {
    setactiveTab(tab)
  }


  const location = useLocation()

  useEffect(() => {
    if (companyConfig) {
      const cConfig = JSON.parse(localStorage.getItem("company-config"));
      setCmpConfig(cConfig)
    }
    else {
      dispatch(getConfig())
    }

  }, [companyConfig])


  useEffect(() => {
    if (!userInformation) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setInfo(obj)
      dispatch(getConfig())
      dispatch(getMasterServices(obj.m_company_id))
    }
  }, [userInformation])

  useEffect(() => {
    console.log("masterService", location, customerData)
    if (location && customerData === null) {
      dispatch(resetMasterServices())
      dispatch(resetServiceContractList())
      dispatch(getCustomerInfo(location.state.info))
      setIsOpen(true)
      setIsOpenD(true)
    }
    else {
      setCstrInfo(customerData)
    }

  }, [location, customerData])

  useEffect(() => {
    if (userInformation && masterServices === null) {
      dispatch(getMasterServices(userInformation.m_company_id))
    }
  }, [userInformation, masterServices])

  useEffect(() => {
    console.log("masterServices", masterServices)
    if (masterServices && masterServices.length > 0 && cstrInfo) {
      dispatch(resetServiceContractList())
      loadServiceContracts(masterServices[0], cstrInfo)
    }
  }, [masterServices, cstrInfo])

  //console.log("customer info", location.state.info)

  // let userId = location.state.info;


  const loadServiceContracts = (data, cstrInfo) => {
    console.log("data---", data, cstrInfo)
    dispatch(getServiceContracts(data, cstrInfo))
    setExpanded(0)
  }

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Orders",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: "$ 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Average Price",
      value: "$ 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ]

  const showData = (data) => {
    setListFor(data)
    toggleRightCanvas()
  }


  const showLocations = (data) => {
    setSelectedData(data)
    setFormFor("listoflocations");
  }

  const showContacs = (data) => {
    // setSelectedData(data)
    setFormFor("contacts");
    toggleRightCanvas()
  }

  const showAssets = (data) => {
    setSelectedData(data)
    setFormFor("assets");
  }

  // const filterServiceAssets = (assetType) => {
  //   var filteredAssets = _.filter(contractServiceAssetList, { asset_type: assetType })
  //   setFilteredAssets(assetType === "All" ? [...contractServiceAssetList] : [...filteredAssets])
  // }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Support Dashboard" breadcrumbItem="" /> */}

          <Row>
            <Col lg="12">
              <Card >
                <CardBody style={{ padding: "10px 15px" }}>
                  <Row>
                    <Col className="d-flex flex-column justify-content-center">
                      <div className="row">
                        <div
                          className="col "
                        >
                          <div
                            className="text-primary"

                          ><span style={{ fontSize: 18 }}>{cstrInfo.customer_name}{" "} |</span>  <span className="text-danger" style={{ fontSize: 12 }}>{cstrInfo.customer_id}</span></div>
                        </div>
                      </div>


                    </Col>
                    <Col className="d-flex flex-row justify-content-end ">
                      <div style={{ marginTop: 5 }}>
                        <button className={!showAll ? "btn btn-sm btn-info me-2" : "btn btn-sm btn-secondary me-2"} onClick={() => {
                          toggleShowAll()
                        }}>{showAll ? "Hide All" : "Show All"}</button>
                        <button className={!isOpenD ? "btn btn-sm btn-info me-2" : "btn btn-sm btn-secondary me-2"} onClick={() => {
                          toggleD()
                        }}>{isOpenD ? "Hide Information" : "Show Information"}</button>
                        <button className={!isOpen ? "btn btn-sm btn-info" : "btn btn-sm btn-secondary"} onClick={() => {
                          toggle() 
                        }}>{isOpen ? "Hide Esculation" : "Show Esculation"}</button>
                      </div>

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Collapse isOpen={isOpenD} id="collapseExample">
            <Row style={{ marginBottom: 4 }}>
              {/* welcome card */}
              {
                cstrInfo &&
                <CardWelcome cstrInfo={cstrInfo} />
              }


              <Col xl="8">
                <Row>
                  {/*mimi widgets */}
                  {
                    cstrInfo &&
                    <MiniWidget
                      cstrInfo={cstrInfo}
                      showList={(data)=> {
                        setListData(data)
                        if(isCardListOpen === false )
                        {
                          setIsCardListOpen(true)
                        }
                        else
                        {
                          if(data === listData)
                          {
                            setIsCardListOpen(false)
                          }
                        }
                        
                      }}
                    />
                  }

                </Row>
              </Col>
            </Row>
          </Collapse>

          <Collapse isOpen={isCardListOpen} id="collapseList">
            <Row>
              <Col lg={"12"}>
                <Card style={{ 
                  height: 170, 
                  borderTop:
                  listData === "locations"?"10px solid #34c38f": 
                  listData === "contacts" ? "10px solid #f1b44c" : 
                  listData === "assets" ? "10px solid #74788d" : 
                  "10px solid #556ee6", 
                  overflow:"auto", 
                  }}>
                  <CardBody>
                    {
                      listData === "locations" && cstrInfo ?
                      <div className="table-responsive">
                      <Table className="table table-sm mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Timezone</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            cstrInfo.locations.map((item, idx) => {
                                return (
                                  <tr key={"cstrlevl" + idx}>
                                    <th>{idx + 1}</th>
                                    <td>{item.location_type}</td>
                                    <td >
                                      {item.location_name}
                                    </td>
                                    <td>{item.address1} {item.address2} {item.city} {item.state} {item.country} {item.pincode}</td>
                                    <td>{item.timezone.label}</td>
                                  </tr>

                                )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                      : listData === "contacts" && cstrInfo ?
                      <div className="table-responsive">
                      <Table className="table table-sm mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Department</th>
                            <th>Email</th>
                            <th>Mobile</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            cstrInfo.contacts.map((item, idx) => {
                                return (
                                  <tr key={"contcts" + idx}>
                                    <th>{idx + 1}</th>
                                    <td>{item.first_name} {item.last_name}</td>
                                    <td>{item.designation}</td>
                                    <td >{item.department}</td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile}</td>
                                  </tr>

                                )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>: listData === "assets" && cstrInfo ?
                      <div className="table-responsive">
                      <Table className="table table-sm mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Serial #</th>
                            <th>Type</th>
                            <th>Unique id</th>
                            <th>Environment</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            cstrInfo.assets.map((item, idx) => {
                                return (
                                  <tr key={"contcts" + idx}>
                                    <th>{idx + 1}</th>
                                    <td>{item.asset_name}</td>
                                    <td>{item.asset_serial_no}</td>
                                    <td >{item.asset_type}</td>
                                    <td>{item.auid}</td>
                                    <td>{item.asset_env}</td>
                                    <td>{item.remarks}</td>
                                  </tr>

                                )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>:
                    listData === "contracts" && cstrInfo ?
                    <div className="table-responsive">
                    <Table className="table table-sm mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Contract ID</th>
                          <th>Name</th>
                          <th>Start date</th>
                          <th>End date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          cstrInfo.contracts.map((item, idx) => {
                              return (
                                <tr key={"cntrcts" + idx}>
                                  <th>{idx + 1}</th>
                                  <td>{item.contract_id}</td>
                                  <td>{item.contract_name}</td>
                                  <td >{moment(item.start_date).format("DD/MM/YYYY")}</td>
                                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                                  <td><Badge className={item.state_id === "1" ? "badge-soft-secondary me-1" : item.state_id === "2" ? "badge-soft-warning me-1" : "badge-soft-success me-1"}>
                                {item.state}
                            </Badge></td>
                                </tr>

                              )
                          })
                        }
                      </tbody>
                    </Table>
                  </div>:null
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Collapse>

          <Collapse isOpen={isOpen} id="collapseExample">
            <Row>
              <Col lg={"6"}>
                <Card style={{ minHeight: 170, }}>
                  <CardBody>
                    <div className="table-responsive">
                      <div className="font-size-13 fw-bold bg-primary bg-gradient text-white d-inline-block px-4" style={{ paddingTop: 2, paddingBottom: 2, borderRadius: 15, }}>Customer Escalation</div>
                      <Table className="table table-sm mb-0">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            cstrInfo && cstrInfo.esculations.map((item, idx) => {
                              if (item.from === "customer") {
                                return (
                                  <tr key={"cstrlevl" + idx}>
                                    <th scope="row">
                                      {item.esculation_name}
                                    </th>
                                    <td>{item.user_name}</td>
                                    <td>{item.user_mobile}</td>
                                    <td>{item.user_email}</td>
                                  </tr>

                                )
                              }
                            })
                          }
                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>

              <Col lg={"6"}>
                <Card style={{ minHeight: 170, marginBottom: 4 }}>
                  <CardBody>
                    <div className="table-responsive">
                      <div className="font-size-13 fw-bold bg-danger bg-gradient text-white d-inline-block px-4" style={{ paddingTop: 2, paddingBottom: 2, borderRadius: 15, }}>Internal Escalation</div>
                      <Table className="table table-sm mb-0">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            cstrInfo && cstrInfo.esculations.map((item, idx) => {
                              if (item.from === "company") {
                                return (
                                  <tr key={"cstrlevlc" + idx}>
                                    <th scope="row">
                                      {item.esculation_name}
                                    </th>
                                    <td>{item.user_name}</td>
                                    <td>{item.user_mobile}</td>
                                    <td>{item.user_email}</td>
                                  </tr>

                                )
                              }
                            })
                          }
                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>

            </Row>

          </Collapse>



          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom justify-content-center" role="tablist" >
                    {
                      masterServices && masterServices.map((item, idx) => {
                        return (
                          <NavItem key={"navitem" + idx}>
                            <NavLink
                              className={classnames({
                                active: activeTab === String(idx + 1),
                              })}
                              onClick={() => {
                                loadServiceContracts(item, cstrInfo)
                                toggleTab(String(idx + 1));
                              }}
                            >
                              {item.name}
                            </NavLink>
                          </NavItem>

                        )
                      })
                    }
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">

                    {
                      masterServices && masterServices.map((elem, index) => {
                        return (
                          <TabPane tabId={String(index + 1)} id={index} key={"tbp" + index}>
                            <div className="mt-4">
                              <div
                                className="accordion accordion-flush"
                                id={"accordionFlushExample"}
                              >
                                {
                                  serviceContractList &&
                                  serviceContractList.length > 0 &&
                                  serviceContractList.map((item, idx) => {
                                    if(item.contracts.state === "Published")
                                    {
                                    var assetTypeItems = selectedAssetType && selectedAssetType !== "All" ? _.filter(item.asset_list, {asset_type:selectedAssetType}) : item.asset_list
                                    return (
                                      <div className="accordion-item" key={"acdn" + idx}>
                                        <h2 className="accordion-header" id={"headingFlushOne" + idx}>
                                          <button
                                            // className={classnames(
                                            //   "accordion-button",
                                            //   "fw-medium",
                                            //    { collapsed: expanded !== idx }
                                            // )}
                                            className={expanded !== idx ? "accordion-button bg-secondary bg-opacity-10 text-dark fw-medium" : "accordion-button bg-info text-white fw-medium "}
                                            type="button"
                                            onClick={() => {
                                              if (expanded === idx) {
                                                setExpanded(-1)
                                              }
                                              else {
                                                setExpanded(idx)
                                              }

                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {item.contracts.contract_id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contract Period: {moment(item.contracts.start_date).format("DD-MM-YYYY")} to {moment(item.contracts.end_date).format("DD-MM-YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current State: {item.contracts.publish_state}
                                          </button>
                                        </h2>

                                        <Collapse
                                          isOpen={expanded === idx}
                                          className="accordion-collapse"
                                        >
                                          <div className="accordion-body">

                                          <Row className="mb-3">
                                              <Col lg={"4"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-warning mb-2" ><span className="px-3 py-2 bg-warning text-white">Inscope</span></div>
                                                <div className="text-muted">
                                                  {ReactHtmlParser(item.in_scope)}
                                                </div>
                                              </Col>
                                              <Col lg={"4"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-warning mb-2" ><span className="px-3 py-2 bg-warning text-white">Outscope</span></div>
                                                <div className="text-muted">
                                                  {ReactHtmlParser(item.out_scope)}
                                                </div>
                                              </Col>
                                              <Col lg={"4"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-warning mb-2" ><span className="px-3 py-2 bg-warning text-white">Prerequisite</span></div>
                                                <div className="text-muted">
                                                  {ReactHtmlParser(item.prerequisite)}
                                                </div>
                                              </Col>
                                            </Row>


                                            <Row className="mb-3" >
                                              <Col lg={"12"}>
                                              <div className="mb-2 py-2 fw-bold border-bottom border-primary mb-2" ><span className="px-3 py-2 bg-primary text-white">Asset Inscope</span></div>
                                                <div className="row">

                                                  <Col lg="3">
                                                    <div className="row mb-4 ">


                                                      <Row style={{ padding: 10 }}>
                                                        {
                                                          item.summary.map((smry, idx) => {
                                                            return <Col lg={"12"} key={"smryy" + idx} style={{ marginBottom: 7, cursor: "pointer" }} onClick={() => {
                                                               setSelectedAssetType(smry.name)
                                                              // filterServiceAssets(smry.name)
                                                               setSelectedIndex(idx)
                                                            }}>
                                                              <Row >
                                                                <Col lg={"9"}>
                                                                  <div className={selectedIndex === idx ? "bg-primary text-white" : smry.count > 0 ? "bg-success text-white" : "bg-secondary bg-opacity-75 text-white"} style={{ paddingTop: 2, paddingBottom: 2, borderRadius: 10, paddingLeft: 20 }}>{idx + 1} : {smry.name}</div>
                                                                </Col>
                                                                <Col lg={"2"} >
                                                                  <div className={selectedIndex === idx ? "bg-primary text-white" : smry.count > 0 ? "bg-success text-white" : "bg-secondary bg-opacity-75 text-white"} style={{ backgroundColor: "#f0f0f0", textAlign: "center", paddingTop: 2, paddingBottom: 2, borderRadius: 10, }}>
                                                                    {smry.count}
                                                                  </div></Col>
                                                              </Row>
                                                            </Col>
                                                          })
                                                        }
                                                      </Row>
                                                    </div>
                                                  </Col>
                                                  <Col lg={"9"}>
                                                    <div className="row mb-4" style={{ height: 280, overflow: "auto" }}>
                                                      <div className="table-responsive">
                                                        <Table className="table table-sm mb-0">
                                                          <thead>
                                                            <tr>
                                                              <th>#</th>
                                                              <th>Type</th>
                                                              <th>Serial#</th>
                                                              <th >Name</th>
                                                              <th style={{ width: 250 }}>Configuration</th>
                                                              <th>Unique Id</th>
                                                              <th>Environment</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {
                                                              
                                                              assetTypeItems.map((item, idx) => {
                                                                return (

                                                                  <tr key={"ctrAstlvl" + idx}>
                                                                    <th>{idx + 1}</th>
                                                                    <td>{item.asset_type}</td>
                                                                    <td>{item.asset_serial_no}</td>
                                                                    <td>{item.asset_name}</td>
                                                                    <td>{item.asset_config}</td>
                                                                    <td>{item.auid}</td>
                                                                    <td>{item.asset_env}</td>
                                                                  </tr>


                                                                )
                                                              })
                                                            }
                                                          </tbody>
                                                        </Table>
                                                      </div>

                                                    </div>

                                                  </Col>

                                                </div>
                                              </Col>
                                            </Row>


                                            <Row className="mb-3">
                                              <Col lg={"12"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-primary mb-2" ><span className="px-3 py-2 bg-primary text-white">Service Window</span></div>
                                                <div className="table-responsive">
                                                  <Table className="table table-sm mb-0">
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Work Hours</th>
                                                        <th>Description</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        item.servicewindow.map((child, indx) => {
                                                          return (
                                                            <>
                                                              <tr key={"ctrAstlvl" + indx}>
                                                                <th>{indx + 1}</th>
                                                                <td>{child.name}</td>
                                                                <td>{child.hours_in_day} / {child.day_in_week}</td>
                                                                <td>{child.description}</td>


                                                              </tr>

                                                            </>
                                                          )
                                                        })
                                                      }
                                                    </tbody>
                                                  </Table>
                                                </div>

                                              </Col>
                                            </Row>

                                            <Row className="mb-3">
                                              <Col lg={"12"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-primary mb-2" ><span className="px-3 py-2 bg-primary text-white">Deliverables</span></div>
                                                <div className="table-responsive">
                                                  <Table className="table table-sm mb-0">
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>Type</th>
                                                        <th>Frequency</th>
                                                        <th>Name</th>
                                                        <th>Action</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        item.deliverables.map((child, idx) => {
                                                          return (
                                                            <>
                                                              <tr key={"delvr" + idx}>
                                                                <th>{idx + 1}</th>
                                                                <td>{child.type}</td>
                                                                <td>{child.frequency}</td>
                                                                <td>{child.name}</td>
                                                                <td></td>
                                                                <td>
                                                                  <ul className="list-unstyled hstack gap-1 mb-0 ">
                                                                    {
                                                                      <li>
                                                                        <Link
                                                                          to="#"
                                                                          className="btn btn-sm btn-success"
                                                                          onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setDeliverableName(child.name)
                                                                            setActionData(child.action)
                                                                            tog_scroll()
                                                                            console.log("am in")
                                                                          }}
                                                                        >
                                                                          <i className="mdi mdi-eye" id="deletetooltip" />
                                                                        </Link>
                                                                      </li>
                                                                    }
                                                                  </ul>

                                                                </td>

                                                              </tr>

                                                            </>
                                                          )
                                                        })
                                                      }
                                                    </tbody>
                                                  </Table>
                                                </div>

                                              </Col>
                                              </Row>

                                            <Row className="mb-3">
                                              <Col lg={"12"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-primary mb-2" ><span className="px-3 py-2 bg-primary text-white">Resources</span></div>
                                              <div className="table-responsive">
                                                  <Table className="table table-sm mb-0">
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>Resource Level</th>
                                                        <th>Type</th>
                                                        <th>Quantity</th>
                                                        <th>Remarks</th>
                                                        
                                                      </tr> 
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        item.resources.map((child, indx) => {
                                                          return (
                                                            <>
                                                              <tr key={"ctrAstlvl" + indx}>
                                                                <th>{indx + 1}</th>
                                                                <td>{child.resource_level}</td>
                                                                <td>{child.resource_type} </td>
                                                                <td>{child.quantity}</td>
                                                                <td>{child.remarks}</td>

                                                              </tr>

                                                            </>
                                                          )
                                                        })
                                                      }
                                                    </tbody>
                                                  </Table>
                                                </div>

                                              </Col>
                                            </Row>

                                            <Row className="mb-3">
                                              <Col lg={"12"} className="p-2">
                                              <div className="mb-2 py-2 fw-bold border-bottom border-primary mb-2" ><span className="px-3 py-2 bg-primary text-white">Service Level</span></div>
                                              <div className="table-responsive">
                                                  <Table className="table table-sm mb-0">
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>Impact Type</th>
                                                        <th>Name</th>
                                                        <th>Definition</th>
                                                        <th>Response time</th>
                                                        <th>Resolution time</th>
                                                        
                                                      </tr> 
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        item.sla.map((child, indx) => {
                                                          return (
                                                            <>
                                                              <tr key={"ctrAstlvl" + indx}>
                                                                <th>{indx + 1}</th>
                                                                <td>{child.impact_type}</td>
                                                                <td>{child.impact_name} </td>
                                                                <td>{child.definition}</td>
                                                                <td>{child.response_time}</td>
                                                                <td>{child.resolution_time}</td>
                                                                

                                                              </tr>

                                                            </>
                                                          )
                                                        })
                                                      }
                                                    </tbody>
                                                  </Table>
                                                </div>

                                              </Col>
                                            </Row>

                                          </div>
                                        </Collapse>
                                      </div>
                                    )
                                                    }
                                  })

                                }
                              </div>
                            </div>
                          </TabPane>
                        )
                      })
                    }

                  </TabContent>

                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal
            isOpen={modal_scroll}
            toggle={() => {
              tog_scroll();
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                Deliverable Action
              </h5>
              <button
                type="button"
                onClick={() =>
                  setmodal_scroll(false)
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <p>
                {
                  ReactHtmlParser(actionData)
                }
              </p>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    setmodal_scroll(false)
                  }
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>


        </Container>
      </div>
    </React.Fragment>

  );
};

SupportDashboard.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
});

const mapDispatchToProps = (dispatch) => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SupportDashboard));
