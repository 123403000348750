import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  Table,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  fetchConsumptionsAsync,
  createConsumptionAsync,
  updateConsumptionAsync
} from '../../../toolkitStore/cloudConsumptionSlice'; // Update the path accordingly

import {
  fetchCustomers
} from "../../../toolkitStore/manageCustomerSlice";


import {
  fetchVendors
} from '../../../toolkitStore/manageVendorSlice';

import AddVendorForm from './forms/addVendor';

const CloudConsumption = () => {
  const dispatch = useDispatch();
  const { consumptions } = useSelector(state => state.cloudConsumptionReducer);
  const { customerList, isSuccessfullyAdded } = useSelector(state => state.manageCustomerReducer);
  const { vendorList, isSuccessfullyAdded: isVendorAPISuccessfull } = useSelector(state => state.manageVendorReducer);


  const [userInformation, setInfo] = useState("");
  const [cmpConfig, setCmpConfig] = useState("");
  const [cstmrList, setCstmrList] = useState([]);

  const [editIndex, setEditIndex] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');
  const [percentValue, setPercentValue] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [invoice, setInvoice] = useState('');
  const [billDate, setBillDate] = useState('');
  const [cost, setCost] = useState('');
  const [currencyType, setCurrencyType] = useState('USD');
  const [percentAmount, setPercentAmount] = useState('');
  const [isRight, setIsRight] = useState(false);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setInfo(obj);
    const cConfig = JSON.parse(localStorage.getItem("company-config"));
    setCmpConfig(cConfig);
  }, []);

  useEffect(() => {
    if(isVendorAPISuccessfull) {
      setIsRight(false);
      dispatch(fetchVendors());
    }
  }, [isVendorAPISuccessfull])

  useEffect(() => {
    if (customerList === null && userInformation) {
      dispatch(fetchCustomers(userInformation.m_company_id));
    } else if (userInformation) {
      setCstmrList([...customerList]);
    }
  }, [userInformation, customerList]);

  useEffect(() => {
    dispatch(fetchConsumptionsAsync());
    dispatch(fetchVendors());
    console.log("consumptions", consumptions);
  }, [dispatch]);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
};

  const handleInputChange = (e) => {
    setCurrencyValue(e.target.value);
  };

  const handleCustomerNameChange = (e) => {
    setCustomerName(e.target.value);
  };

  const handleVendorNameChange = (e) => {
    setVendorName(e.target.value);
  };

  const handleVendorForm = () => {
    event.preventDefault()
    setIsRight(true)
  }

  const handleInvoiceChange = (e) => {
    setInvoice(e.target.value);
  };

  const handleBillDateChange = (e) => {
    setBillDate(e.target.value);
  };

  const handleCostChange = (e) => {
    setCost(parseFloat(e.target.value));
  };

  const handlePercentValueChange = (e) => {
    setPercentValue(e.target.value);
  };

  const handleCurrencyTypeChange = (type) => {
    setCurrencyType(type);
  };

  const calculateDifference = () => {
    const parsedCurrencyValue = parseFloat(currencyValue);
    const parsedCost = parseFloat(cost);
    return isNaN(parsedCurrencyValue) || isNaN(parsedCost) ? NaN : parsedCurrencyValue - parsedCost;
  };

  const handleSubmit = () => {
    const difference = calculateDifference();

    if (editIndex !== null) {
      const consumptionData = {
        _id: consumptions[editIndex]._id,
        customerName,
        currencyValue,
        percentValue,
        percentAmount,
        vendorName,
        invoice,
        bill_date: billDate,
        cost,
        currencyType,
        difference
      };
      dispatch(updateConsumptionAsync(consumptionData))
        .then(() => setEditIndex(null))
        .catch(error => console.error("Update failed: ", error));
    } else {
      const consumptionData = {
        customerName,
        currencyValue,
        percentValue,
        percentAmount,
        vendorName,
        invoice,
        bill_date: billDate,
        cost,
        currencyType,
        difference
      };
      dispatch(createConsumptionAsync(consumptionData));
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    const consumption = consumptions[index];
    setCustomerName(consumption.customerName);
    setCurrencyValue(consumption.currencyValue);
    setPercentValue(consumption.percentValue);
    setPercentAmount(consumption.percentAmount);
    setVendorName(consumption.vendorName);
    setInvoice(consumption.invoice);
    setBillDate(consumption.bill_date);
    setCost(consumption.cost);
    setCurrencyType(consumption.currencyType);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Forms" breadcrumbItem="Form Layouts" /> */}
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">USD to INR</CardTitle>
                  <Form>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="customerName">Customer Name</Label>
                          <Input
                            type="select"
                            className="form-control"
                            id="customerName"
                            value={customerName}
                            onChange={handleCustomerNameChange}
                          >
                            <option>Choose...</option>
                            {customerList &&
                              customerList.map((item, idx) => (
                                <option key={"opt" + idx} value={item.customer_name}>
                                  {item.customer_name}
                                </option>
                              ))}
                          </Input>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label htmlFor="currencyType">Provider</Label>
                          <Input
                            type="select"
                            className="form-control"
                            id="currencyType"
                            value={currencyType}
                            onChange={(e) => handleCurrencyTypeChange(e.target.value)}
                          >
                            <option value="USD">AWS</option>
                            <option value="INR">AZURE</option>
                          </Input>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label htmlFor="amount">Enter Amount</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="amount"
                            value={currencyValue}
                            placeholder="Enter Amount"
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3">
                          <Label htmlFor="percentValue">Percent Value</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="percentValue"
                            placeholder="Enter Percent Value"
                            value={percentValue}
                            onChange={handlePercentValueChange}
                          />
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mb-3" style={{ paddingTop: "20px" }}>
                          <Button
                            type="button"
                            color={editIndex !== null ? "warning" : "primary"}
                            className="w-md mt-2"
                            onClick={handleSubmit}
                          >
                            {editIndex !== null ? "Update" : "Create"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {editIndex !== null && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Edit Consumption</CardTitle>
                    <Form>
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="vendorName">Vendor Name</Label>
                            <Input
                              type="select"
                              className="form-control"
                              id="vendorName"
                              value={vendorName}
                              onChange={handleVendorNameChange}
                            >
                              <option>Choose...</option>
                              {vendorList &&
                                vendorList.map((item, idx) => (
                                  <option key={"opt" + idx} value={item.vendor_name}>
                                    {item.vendor_name}
                                  </option>
                                ))}
                            </Input>
                          </div>
                        </Col>
                        <Col>
                          <div className="flex-shrink-0" style={{ paddingTop: "28px" }}>
                            <button onClick={(event) => handleVendorForm(event)} className="btn btn-primary me-1">Add Vendor</button>

                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="editInvoice">Invoice</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="editInvoice"
                              value={invoice || ''}
                              onChange={handleInvoiceChange}
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="editBillDate">Bill Date</Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="editBillDate"
                              value={billDate || ''}
                              onChange={handleBillDateChange}
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="editCost">Cost</Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="editCost"
                              value={cost || ''}
                              onChange={handleCostChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Consumption Data</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer Name</th>
                          <th>Date</th>
                          <th>Exchange Rate</th>
                          <th>USD</th>
                          <th>INR</th>
                          <th>Percent Value</th>
                          <th>Percent Amount</th>
                          <th>Vendor Name</th>
                          <th>Invoice</th>
                          <th>Bill Date</th>
                          <th>Cost</th>
                          <th>Margin</th>
                          <th>Difference</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {consumptions?.map((consumption, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{consumption.customerName}</td>
                            <td>{new Date(consumption.createdAt).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</td>
                            <td>{consumption.exchangeRate}</td>
                            <td>{consumption.usd}</td>
                            <td>{consumption.inr}</td>
                            <td>{consumption.percentValue}</td>
                            <td>{consumption.percentAmount}</td>
                            <td>{consumption.vendorName}</td>
                            <td>{consumption.invoice}</td>
                            <td>{new Date(consumption.bill_date).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</td>
                            <td>{consumption.cost}</td>
                            <td>{consumption.margin}</td>
                            <td>{consumption.difference}</td>
                            <td>
                              <Button color="info" onClick={() => handleEdit(index)}>
                                Edit
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Offcanvas style={{ width: 600, transition: "width .35s ease-in-out", }}
            isOpen={isRight}
            backdrop={true}
            direction="end"
            toggle={() => { toggleRightCanvas() }}>
              <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>Add Vendor</OffcanvasHeader>
              <OffcanvasBody>
                  <AddVendorForm selectedData={{}} vendorType={""} />
              </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>

  );
};

export default CloudConsumption;
