import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    serviceList: [],
    subServiceList:null,
    isSuccessfullyAdded:false,
};


const manageServiceSlice = createSlice({
    name: "manageService",
    initialState: initialRegState,
    reducers: {

        setServiceList: (state, action) => {
            state.serviceList = action.payload
        },
        setSubServiceList: (state, action) => {
            state.subServiceList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        resetSubServiceList: (state, action) => {
            state.subServiceList = null
        },
        setServiceSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = true
        },
        resetServiceSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = false
        },
        
    }

})

export const createService = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetServiceSuccessfullyAdded())
            const responseData = await post("/manage_services/add-service", {
                "serviceInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setServiceSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateService = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetServiceSuccessfullyAdded())
            const responseData = await post("/manage_services/update-service", {
                "serviceInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setServiceSuccessfullyAdded())
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchServices = (company_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_services/get-services", {
                "company_id":company_id
            })
            console.log("get users", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setServiceList(responseData.data))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchSubServices = (serviceInfo) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubServiceList())
            const responseData = await post("/manage_services/get-sub-services", {
                "serviceInfo":serviceInfo
            })
            console.log("get subservices", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubServiceList(responseData.data))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterService = (sid) => {
    return async (dispatch) => {
        try {
            dispatch(resetServiceSuccessfullyAdded())
            const responseData = await post("/manage_services/delete-service", {
                "SID":sid
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setServiceSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setServiceList, 
    setServiceSuccessfullyAdded, 
    resetServiceSuccessfullyAdded,
    setSubServiceList,
    resetSubServiceList
} = manageServiceSlice.actions;

export default manageServiceSlice.reducer;
