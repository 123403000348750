import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback,
} from "reactstrap";


import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { getConfig } from "toolkitStore/authenticateSlice"


import {
    fetchCustomers
} from "toolkitStore/manageCustomerSlice";

import {
    createVendor, fetchVendors
} from "toolkitStore/manageVendorSlice"
import {
    createCustomerSubscription,
    fetchCompanySubscriptions,
    updateCustomerSubscription,
    resetSubscriptionSuccessfullyAdded,
    updateCustomerConsumptions,
    resetConsumptionUpdation,
    updateConsumptionFiles,
    resetConsumptionFileUpdated
} from "toolkitStore/manageSubscriptionSlice";
import { fetchServices, createService, updateService, deleteMasterService } from "toolkitStore/manageServiceSlice";
import moment from "moment";

import FileUpload from "./FileUpload";
import { LabelList } from "recharts";



const CRUDConsumption = (props) => {


    const dispatch = useDispatch()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { customerList } = useSelector(state => state.manageCustomerReducer);
    const { vendorList } = useSelector((state) => state.manageVendorReducer);
    const { serviceList, isSuccessfullyAdded } = useSelector(state => state.manageServiceReducer)
    const { companySubscriptionList, isSubscriptionRequestSuccess, updateConsumptionSuccessfully, consumptionData, updateConsumptionFileSuccessfully } = useSelector(state => state.manageSubscriptionReducer)

    const [userInformation, setInfo] = useState(props.userInformation);
    const [cmpConfig, setCmpConfig] = useState(props.cmpConfig);

    const [consumptionRow, setConsumptionRow] = useState(props.selectedConsumption)


    const [actualCost, setActualCost] = useState(0)
    const [purchaseCost, setPurchaseCost] = useState(0)
    const [customerCost, setCustomerCost] = useState(0)
    const [expectedMargin, setExpectedMargin] = useState(0)

    const [derivedMargin, setDerivedMargin] = useState(0)
    const [derivedDiscount, setDerivedDiscount] = useState(0)
    const [customerAttachments, setCustomerAttachments] = useState(props.selectedConsumption ? props.selectedConsumption.customer_attachments : [])

    const [UCost, setUCost] = useState(0)
    const [rRebate, setRRebate] = useState(0)
    const [rdiscount, setRDiscount] = useState(0)
    const [rQuantity, setRQuantity] = useState(0)

    const [filteredServices, setFilteredServices] = useState(null)

    const [selectedService, setSelectedService] = useState(props.selectedSubscription ? props.selectedSubscription.service_name : null)
    const [serviceInfo, setServiceInfo] = useState(null)
    const [ssList, setSSList] = useState([])
    const [selectedSPlan, setSPlan] = useState(null)
    const [sStartDate, setSStartDate] = useState("")
    const [sEndDate, setSEndDate] = useState("")
    const [isApiRequested, setIsApiRequested] = useState(false)
    const [saveMode, setSaveMode] = useState("draft")

    const [vendorName, setVendorName] = useState("")
    const [vendorNameError, setVendorNameError] = useState(null)
    const [showEntry, setShowEntry] = useState(false)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    useEffect(() => {
        dispatch(fetchVendors(userInformation.m_company_id))
        dispatch(fetchServices(userInformation.m_company_id))
        dispatch(fetchCustomers(userInformation.m_company_id))
    }, []);


    useEffect(() => {
        if (serviceList.length > 0) {
            var getPrimaryData = _.filter(serviceList, { "service_type_id": "2", "service_level_id": "2" })
            setFilteredServices(getPrimaryData)
        }

    }, [serviceList])

    useEffect(() => {
        if (updateConsumptionSuccessfully) {
            dispatch(resetConsumptionUpdation())
            props.closeOffCanvas()
        }
        else {
            setIsApiRequested(false)
        }
    }, [updateConsumptionSuccessfully])

    useEffect(() => {
        // console.log("consumptionData.customer_attachments", consumptionData.customer_attachments)
        if (updateConsumptionFileSuccessfully) {
            let consumption = { ...consumptionRow }
            consumption.customer_attachments = consumptionData.customer_attachments
            setConsumptionRow({...consumption})

            setCustomerAttachments(consumptionData.customer_attachments)
            dispatch(resetConsumptionFileUpdated())
        }

    }, [updateConsumptionFileSuccessfully, consumptionData])


    useEffect(() => {
        if (selectedService && filteredServices) {
            var selectedData = _.filter(filteredServices, { "name": selectedService })
            var subscriptionInfo = {
                company_id: userInformation.m_company_id,
                sub_service_id: selectedData[0]._id
            }
            setServiceInfo(selectedData[0])
            dispatch(fetchCompanySubscriptions(subscriptionInfo))
        }

    }, [selectedService, filteredServices])

    useEffect(() => {
        if (companySubscriptionList) {
            setSSList([...companySubscriptionList])
        }
        else {
            setSSList([])
        }
    }, [companySubscriptionList])


    useEffect(() => {
        setIsApiRequested(isSubscriptionRequestSuccess)
        if (isSubscriptionRequestSuccess) {
            dispatch(resetSubscriptionSuccessfullyAdded())
            props.closeOffCanvas()

        }

    }, [isSubscriptionRequestSuccess])

    // useEffect(() => {
    //     if (consumptionRow && ssList.length > 0) {
    //         var selectedPlan = _.filter(ssList, { subscription_name: props.selectedSubscription.subscription_plan_name })[0]
    //         setSPlan(selectedPlan)

    //         // setSStartDate(moment(props.selectedSubscription.subscription_customer_start_date).format("yyyy-DD-MM"))
    //         // setSEndDate(moment(props.selectedSubscription.subscription_customer_end_date).format("yyyy-DD-MM"))
    //     }
    // }, [consumptionRow, ssList])

    useEffect(() => {
        if (consumptionRow) {

            var unitCost = consumptionRow.subscription_unit_cost

            setActualCost(unitCost * consumptionRow.subscription_customer_quantity)

            // (Unit cost - (Unit cost X Rebate %)) X Quantity
            var totalPurchaseCost = (unitCost - (unitCost * (consumptionRow.cs_rebate / 100))) * consumptionRow.cs_quantity
            setPurchaseCost((totalPurchaseCost).toFixed(2))

            // (Unit Cost - (unit cost X discount %)) x Quantity
            var totalCustomerCost = (unitCost - (unitCost * (consumptionRow.cs_customer_discount / 100))) * consumptionRow.cs_quantity
            setCustomerCost((totalCustomerCost).toFixed(2))

            var expectedMargin = (totalCustomerCost - totalPurchaseCost).toFixed(2)
            setExpectedMargin(expectedMargin)

            console.log("output --", totalPurchaseCost, totalCustomerCost, expectedMargin)
        }
    }, [consumptionRow])


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            consumption_start_date: consumptionRow && moment(consumptionRow.consumption_start_date).format("YYYY-MM-DD") || "",
            consumption_end_date: consumptionRow && moment(consumptionRow.consumption_end_date).format("YYYY-MM-DD") || "",
            cs_rebate: (consumptionRow && consumptionRow.cs_rebate) || "",
            cs_customer_discount: consumptionRow && consumptionRow.cs_customer_discount || "",

            consumption_value: consumptionRow && consumptionRow.consumption_value || "",
            consumption_margin: consumptionRow && consumptionRow.consumption_margin || "",



        },
        validationSchema: Yup.object()
            .shape({
                consumption_start_date: Yup.date()
                    .typeError("please enter date")
                    .required("please enter date"),
                consumption_end_date: Yup.date().required("Enter End Date")
                    .typeError("please enter date")
                    .required("please enter date"),
                consumption_value: Yup.string().required("Enter Consumption Value"),
            }),
        onSubmit: (values, { resetForm }) => {

            values["consumption_margin"] = derivedMargin
            values["derived_discount"] = derivedDiscount
            values["customer_attachments"] = [...customerAttachments]

            if (values["cs_customer_discount"] === "") {
                console.log("cs_customer_discount", values["cs_customer_discount"])
                values["cs_customer_discount"] = "0"
            }

            console.log("values", values)

            setIsApiRequested(true)

            values["_id"] = consumptionRow._id
            dispatch(updateCustomerConsumptions(values))

        }
    });

    useEffect(() => {

        var dMargin = validation.values.consumption_value * ((validation.values.cs_rebate) / 100);
        setDerivedMargin(dMargin.toFixed(2))

        var dDiscount = validation.values.consumption_value * ((validation.values.cs_customer_discount) / 100);
        setDerivedDiscount(dDiscount.toFixed(2))

        // - validation.values.cs_customer_discount

    }, [validation.values.consumption_value, validation.values.cs_rebate, validation.values.cs_customer_discount, validation.values.subscription_customer_quantity])

    const removeCustomerFile = (selectedConsumption, file) => {
        var findIndex = _.findIndex(selectedConsumption.customer_attachments, { "fileName": file.fileName })
        if (findIndex !== -1) {
            let consumption = { ...selectedConsumption }
            let newArr = [...selectedConsumption.customer_attachments]
            newArr.splice(findIndex, 1)
            consumption.customer_attachments = newArr
            dispatch(updateConsumptionFiles(consumption))
        }
    }

    const updateCustomerAttachments = (selectedConsumption, newArr) => {
        let consumption = { ...selectedConsumption }
        consumption.customer_attachments = newArr
        dispatch(updateConsumptionFiles(consumption))
    }




    return (

        <Form
            className="form-horizontal"
            onSubmit={(e) => {
                e.preventDefault();

                return false;
            }}
        >
            <Row className="mb-3 pb-4" style={{ borderBottom: "1px solid #dedede" }}>
                <Label className="my-1 text-primary">Usage Period</Label>
                <Col lg={4}>
                    <Label htmlFor="consumption_start_date">Start From</Label>
                    <Input
                        id="consumption_start_date"
                        name="consumption_start_date"
                        placeholder="date placeholder"
                        type="date"
                        value={validation.values.consumption_start_date || ""}
                        required
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                            validation.touched.consumption_start_date && validation.errors.consumption_start_date ? true : false
                        }
                    />
                    {validation.touched.consumption_start_date && validation.errors.consumption_start_date ? (
                        <FormFeedback type="invalid">{validation.errors.consumption_start_date}</FormFeedback>
                    ) : null}

                </Col>
                <Col lg={4}>
                    <Label>Upto</Label>
                    <Input
                        id="consumption_end_date"
                        name="consumption_end_date"
                        placeholder="date placeholder"
                        type="date"
                        required
                        value={validation.values.consumption_end_date}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                            validation.touched.consumption_end_date && validation.errors.consumption_end_date ? true : false
                        }
                    />
                    {validation.touched.consumption_end_date && validation.errors.consumption_end_date ? (
                        <FormFeedback type="invalid">{validation.errors.consumption_end_date}</FormFeedback>
                    ) : null}
                </Col>
            </Row>

            <Row className="mb-3">
                <Col className="col-4">
                    <div >
                        <Label htmlFor="cs_rebate">Vendor Rebate</Label>
                        <Input
                            name="cs_rebate"
                            className="form-control"
                            placeholder="Vendor Rebate"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cs_rebate || ""}
                            invalid={
                                validation.touched.cs_rebate && validation.errors.cs_rebate ? true : false
                            }
                        />
                        {validation.touched.cs_rebate && validation.errors.cs_rebate ? (
                            <FormFeedback type="invalid">{validation.errors.cs_rebate}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col className="col-4">
                    <div >
                        <Label htmlFor="cs_customer_discount">Customer Discount</Label>
                        <Input
                            name="cs_customer_discount"
                            className="form-control"
                            placeholder="Customer discount"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cs_customer_discount || ""}
                            invalid={
                                validation.touched.cs_customer_discount && validation.errors.cs_customer_discount ? true : false
                            }
                        />
                        {validation.touched.cs_customer_discount && validation.errors.cs_customer_discount ? (
                            <FormFeedback type="invalid">{validation.errors.cs_customer_discount}</FormFeedback>
                        ) : null}
                    </div>
                </Col>

                <Col className="col-4">
                    <div >
                        <Label htmlFor="consumption_value">Consumption Value</Label>
                        <Input
                            name="consumption_value"
                            className="form-control mb-1"
                            placeholder="Enter consumption value"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.consumption_value || ""}
                            required
                            invalid={
                                validation.touched.consumption_value && validation.errors.consumption_value ? true : false
                            }
                        />
                        {props.selectedSubscription && <Label className="font-size-13 text-secondary">Estimated Value : {props.selectedSubscription.customer_cost}</Label>}
                        {validation.touched.consumption_value && validation.errors.consumption_value ? (
                            <FormFeedback type="invalid">{validation.errors.consumption_value}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
            </Row>

            <Row className="mb-3 pb-3" style={{ borderBottom: "1px solid #dedede" }}>

                <Col className="col-4">
                    <div >
                        <Label htmlFor="consumption_margin" className="mb-0 pb-0">Total Quantity</Label>
                        <div >
                            <div style={{ fontSize: 20 }}>{consumptionRow && consumptionRow.cs_quantity}</div>
                        </div>
                        {consumptionRow && <Label className="font-size-13 text-secondary">Unit cost : {consumptionRow.subscription_unit_cost}</Label>}
                        {validation.touched.consumption_margin && validation.errors.consumption_margin ? (
                            <FormFeedback type="invalid">{validation.errors.consumption_margin}</FormFeedback>
                        ) : null}
                    </div>
                </Col>

                <Col className="col-4">
                    <div >
                        <Label htmlFor="consumption_margin" className="mb-0 pb-0">Dervied Margin</Label>
                        <div >
                            <div style={{ fontSize: 20 }}>{derivedMargin}</div>
                        </div>

                        {consumptionRow && <Label className="font-size-13 text-secondary">Estimated Margin : {consumptionRow.cs_expected_margin}</Label>}
                    </div>
                </Col>

                <Col className="col-4">
                    <div >
                        <Label htmlFor="consumption_margin" className="mb-0 pb-0">Dervied Discount</Label>
                        <div >
                            <div style={{ fontSize: 20 }}>{derivedDiscount}</div>
                        </div>

                        {consumptionRow && <Label className="font-size-13 text-secondary">Customer Discount @ {validation.values.cs_customer_discount}%</Label>}
                    </div>
                </Col>

            </Row>

            {/* <Row className="mb-3">
                        <FileUpload
                            fileToBeAttach={"Customer Consumption"}
                            filesAttached={customerAttachments}
                            removeFile = {(data) => {
                                removeCustomerFile(consumptionRow, data)
                            }}
                            uploadedFiles={(data) => {
                                let newArr = [...customerAttachments]
                                newArr.push(data)
                                setCustomerAttachments([...newArr])
                            }}
                        />
                    </Row> */}

            {/* <Row className="mb-3 pb-4" style={{ borderBottom: "1px solid #dedede" }}>
                <Col className="col-6">
                    <div >
                        <Label htmlFor="customer_invoice_no">Customer Invoice No.</Label>
                        <Input
                            name="customer_invoice_no"
                            className="form-control"
                            placeholder="Enter invoice no."
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.customer_invoice_no || ""}
                            invalid={
                                validation.touched.customer_invoice_no && validation.errors.customer_invoice_no ? true : false
                            }
                        />
                        {validation.touched.customer_invoice_no && validation.errors.customer_invoice_no ? (
                            <FormFeedback type="invalid">{validation.errors.customer_invoice_no}</FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <Label>Customer Invoice Date</Label>
                    <InputGroup>
                        <Input
                            id="customer_invoice_date"
                            name="customer_invoice_date"
                            placeholder="date placeholder"
                            type="date"
                            value={validation.values.customer_invoice_date}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />

                    </InputGroup>
                    {validation.touched.customer_invoice_date && validation.errors.customer_invoice_date ? (
                        <FormFeedback type="invalid">{validation.errors.customer_invoice_date}</FormFeedback>
                    ) : null}
                </Col>

            </Row> */}

            <Row className="mb-3">
                <FileUpload
                    fileToBeAttach={"Consumption Report"}
                    filesAttached={_.filter(customerAttachments, {"related_to":"Consumption Report"})}
                    removeFile={(data) => {
                        removeCustomerFile(consumptionRow, data)
                    }}
                    uploadedFiles={(data) => {
                        let newArr = [...customerAttachments]
                        newArr.push(data)
                        //setCustomerAttachments([...newArr])
                        updateCustomerAttachments(consumptionRow, newArr)

                    }}
                />
            </Row>



            <div className="d-flex flex-row">
                {/* {responseCustomerError && <label className="font-size-10 text-danger">{responseCustomerError}</label>} */}
                <button type="submit"
                    onClick={(event) => {
                        setSaveMode("draft")
                        validation.handleSubmit(event)
                    }}
                    className="btn btn-primary w-md me-2" disabled={isApiRequested} >
                    {"Submit"}
                </button>
            </div>
        </Form>


    );
};

export default CRUDConsumption;

