import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback
} from "reactstrap";

import TableContainer from '../components/TableContainer';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import VendorForm from  './Component/vendorForm'
import {
    fetchVendors,
} from 'toolkitStore/manageVendorSlice';


const Vendors = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { userInfo, companyConfig } = useSelector(state => state.authReducer)
    const { vendorList, isSuccessfullyAdded, responseVendorError } = useSelector(state => state.manageVendorReducer)

    const [userInformation, setInfo] = useState("")
    const [cmpConfig, setCmpConfig] = useState("")
    // const [vendorList, setVendorList] = useState([])
    const [selectedData, setSelectedData] = useState("")
    const [mode, setMode] = useState("new")


    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [isRight, setIsRight] = useState(false);

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setInfo(obj)
        const cConfig = JSON.parse(localStorage.getItem("company-config"));
        setCmpConfig(cConfig)
    }, [])

    useEffect(() => {

        console.log("vendorList", vendorList, userInformation)

        if (!vendorList && userInformation) {
            dispatch(fetchVendors(userInformation.m_company_id))
        }

    }, [vendorList, userInformation])



    const toggleRightCanvas = () => {
        setIsRight(!isRight);
        setSelectedData("")
    };

    const closeOffCanvas = () => {
        toggleRightCanvas();
        //setCanvasWidth(500)
        setSelectedData("")
        dispatch(fetchVendors(userInformation.m_company_id))
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Vendor Name',
                accessor: 'vendor_name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <Col>
                            <div className="text-primary">{cellProps.row.original.vendor_name}</div>
                        </Col>
                    )
                }
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableFilters: true,
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                disableFilters: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-info"
                                    onClick={(e) => {

                                        e.preventDefault()
                                        const data = cellProps.row.original;
                                        setSelectedData(data)
                                        setMode("edit")
                                        setIsRight(!isRight);
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#!"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        // deleteData(data);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                           
                        </ul>
                    );
                }
            },

        ],
        []
    );



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row className="justify-content-center">
                        <Card>
                            <CardBody className="border-bottom" style={{ padding: "0.7rem" }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">Vendors</h5>
                                    <div className="flex-shrink-0">
                                        <Link to="#!" onClick={() => {
                                            toggleRightCanvas()
                                        }} className="btn btn-primary me-1">Add New Vendor</Link>
                                        <Link to="#!" onClick={() => {
                                            dispatch(fetchCustomers(userInformation.m_company_id))
                                        }} className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                    </div>
                                </div>
                            </CardBody>
                            <CardBody>
                                {
                                    vendorList && vendorList.length > 0 &&
                                    <TableContainer
                                        columns={columns}
                                        data={vendorList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        customPageSize={10}
                                        customPageSizeOptions={false}
                                        isJobListGlobalFilter={false}
                                        className="custom-header-css"
                                    />
                                }

                            </CardBody>
                        </Card>
                    </Row>

                    <Offcanvas
                        style={{ width: 600, transition: "width .35s ease-in-out", }}
                        isOpen={isRight}
                        backdrop={'static'}
                        direction="end"
                        toggle={() => { toggleRightCanvas() }}>
                        <OffcanvasHeader toggle={() => { toggleRightCanvas() }} style={{ borderBottom: "1px solid #dedede" }}>
                            {"Add / Edit Vendor"}
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <VendorForm 
                            selectedData={selectedData}
                            closeOffCanvas={() => { closeOffCanvas() }}
                            cmpConfig={cmpConfig}
                            userInformation={userInformation}
                            mode={mode}
                            />
                        </OffcanvasBody>
                    </Offcanvas>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Vendors