import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card,
    CardBody,
    CardHeader,
    CardText,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    UncontrolledTooltip,
    Modal, FormFeedback, Collapse
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import TimezoneSelect from 'react-timezone-select'

import { getConfig } from "../../../../toolkitStore/authenticateSlice"
import { resetLocationSuccessfullyAdded, fetchLocations, createLocation, updateMasterLocation, deleteMasterLocation } from "../../../../toolkitStore/manageLocationSlice"
import { resetContactSuccessfullyAdded, fetchContacts, createContact, updateMasterContact, deleteMasterContact } from "../../../../toolkitStore/manageCContactSlice"
import { resetAssetSuccessfullyAdded, fetchAssets, createAsset, updateMasterAsset, deleteMasterAsset, mapLocationtoAsset, removeMapLocationtoAsset, getMapLocationtoAsset } from "../../../../toolkitStore/manageCAssetSlice"


const AddAssets = (props) => {

    const { locationList, isSuccessfullyAdded } = useSelector(state => state.manageLocationReducer)
    const { assetList, assetLocationList, isAssetSuccessfullyAdded } = useSelector(state => state.manageCAssetReducer)

    const dispatch = useDispatch()

    const [astList, setAstList] = useState([])
    const [selectedData, setSelectedData] = useState(props.selectedData)
    const [showEditor, setEditor] = useState(false)

    const [locList, setLocList] = useState([])
    const [modalAsset_backdrop, setmodalAsset_backdrop] = useState(false);
    const [selectedAssetData, setSelectedAssetData] = useState("")
    const [selectedAssetLocationData, setSelectedAssetLocationData] = useState("")
    const [assetType, setAssetType] = useState("0")
    const [assetEnv, setAssetEnv] = useState("0")
    const [tglLcn, setToggleLocation] = useState(false)
    const [selectedAstForLocation, setSelectAstForLocation] = useState("")

    const [ColToggle, setColToggle] = useState(false)



    useEffect(() => {
        if (assetList === null) {
            dispatch(fetchAssets(props.userInformation.m_company_id, props.selectedData._id))
            setSelectedAssetData("")
        }
        else {
            setAstList([...assetList])
            setSelectedAssetData("")
        }

    }, [assetList])

    useEffect(() => {
        if (locationList === null) {
            dispatch(fetchLocations(props.userInformation.m_company_id, selectedData._id))
        }
        else {
            setLocList([...locationList])
        }

    }, [locationList])

    useEffect(() => {
        if (isAssetSuccessfullyAdded) {
            dispatch(fetchAssets(props.userInformation.m_company_id, props.selectedData._id))
            setSelectedAssetData("")
        }
    }, [isAssetSuccessfullyAdded])




    const toggleShowEditor = () => {
        var tempLoc = []
        _.each(astList, item => {
            var loc = { ...item }
            loc["edit"] = false
            tempLoc.push(loc)
        })
        setAssetType("0")
        setSelectedAssetData("")
        setAstList([...tempLoc])
        setEditor(!showEditor)
    }



    const closeEditor = () => {
        setSelectedAssetData("")
        setEditor(!showEditor)
    }

    const closeAssetEditor = () => {
        var tempLoc = []
        _.each(astList, item => {
            var loc = { ...item }
            loc["edit"] = false
            tempLoc.push(loc)
        })
        setAssetType("0")
        setAstList([...tempLoc])

    }

    const toggleEditor = (data) => {
        console.log("data", data)
        var tempLoc = []
        _.each(astList, item => {
            var loc = { ...item }
            if (loc._id === data._id) {
                loc["edit"] = !loc.edit
                setSelectedAssetData(loc)
            }
            else {
                loc["edit"] = false
            }
            loc["locationEditor"] = false
            tempLoc.push(loc)
        })
        setAssetType(data.asset_type_id)
        setAstList([...tempLoc])
        setEditor(false)

    }



    const toggleLocation = (data) => {
        setSelectAstForLocation(data)
        dispatch(getMapLocationtoAsset(data._id))
    }

    const closeEditors = () => {
        var tempLoc = []
        _.each(astList, item => {
            var loc = { ...item }
            loc["edit"] = false
            loc["locationEditor"] = false
            tempLoc.push(loc)
        })
        setAstList([...tempLoc])
        setEditor(false)
    }

    useEffect(() => {

        function fetchData() {

        if (assetLocationList === null) {
            dispatch(getMapLocationtoAsset(selectedAstForLocation._id))
        }
        else {
            var tempLoc = []
            var getLocations = []
            _.each(astList, item => {
                var loc = { ...item }

                if (loc._id === selectedAstForLocation._id) {
                    loc["locationEditor"] = !loc.locationEditor
                    setSelectedAssetData(loc)
                }
                else {
                    loc["locationEditor"] = false
                }
                loc["edit"] = false
                tempLoc.push(loc)
            })

            if (!selectedAstForLocation.locationEditor) {

                var getLocations = []
                _.each(locList, item => {
                    var getItem = _.filter(assetLocationList, { location_id: item._id, asset_id: selectedAstForLocation._id })[0]
                    var loc = { ...item }
                    if (getItem !== undefined && getItem.location_id === loc._id) {

                        loc["editLocation"] = true
                    }
                    else {
                        loc["editLocation"] = false

                    }
                    getLocations.push(loc)
                })
                setLocList([...getLocations])
            }
            else {
                setLocList([...locationList])
            }

            setAstList([...tempLoc])
        }
    }

    fetchData()

    }, [assetLocationList])




    const handleDeleteAsset = (data) => {
        dispatch(deleteMasterAsset(data._id))
    }





    const assetValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            asset_name: selectedAssetData.asset_name || "",
            asset_config: selectedAssetData.asset_config || "",
            auid: selectedAssetData.auid || "",
            asset_serial_no: selectedAssetData.asset_serial_no || "",
            remarks: selectedAssetData.remarks || "",
        },
        validationSchema: Yup.object({
            //customer_name: Yup.string().required("Please Enter Your impact name"),
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values, { resetForm }) => {

            console.log("values", values)

            var getIndex = _.findIndex(props.cmpConfig.asset_type, { id: assetType })
            if (getIndex !== -1) {
                var id = props.cmpConfig.asset_type[getIndex].id
                var name = props.cmpConfig.asset_type[getIndex].name
                var icon = props.cmpConfig.asset_type[getIndex].icon
            }

            var getEnvIndex = _.findIndex(props.cmpConfig.asset_environment, { id: assetEnv })
            if (getEnvIndex !== -1) {
                var env_id = props.cmpConfig.asset_environment[getEnvIndex].id
                var env_name = props.cmpConfig.asset_environment[getEnvIndex].name
            }


            values["asset_type_id"] = id
            values["asset_type"] = name
            values["asset_icon"] = icon
            values["asset_env_id"] = env_id
            values["asset_env"] = env_name
            values["customer_id"] = props.selectedData._id
            values["m_company_id"] = props.userInformation.m_company_id
            values["m_b_unit_id"] = props.userInformation.m_b_unit_id
            values["created_by"] = props.userInformation._id

            if (selectedAssetData !== "") {
                values["_id"] = selectedAssetData._id
                dispatch(updateMasterAsset(values))
                setSelectedAssetData("")
                if (selectedAssetData.edit) {
                    closeAssetEditor()
                }
                else {
                    closeEditor()
                }

                resetForm({ values: "" })
            }
            else {
                dispatch(createAsset(values))
                setSelectedAssetData("")
                if (selectedAssetData.edit) {
                    closeAssetEditor()
                }
                else {
                    closeEditor()
                }
                resetForm({ values: "" })
            }

        }
    });

    const assetMapLocation = (location, asset) => {

        console.log("location check", location)

        var tempLoc = []
        _.each(locList, item => {
            var loc = { ...item }
            console.log("lc", loc._id, location._id)
            if (loc._id === location._id) {
                loc["editLocation"] = !loc.editLocation

                var data = {
                    "asset_name": asset.asset_name,
                    "asset_id": asset._id,
                    "location_id": location._id,
                    "customer_id": props.selectedData._id,
                    "m_company_id": props.userInformation.m_company_id,
                    "m_b_unit_id": props.userInformation.m_b_unit_id,
                    "created_by": props.userInformation._id,
                }
                if (loc.editLocation === true) {
                    dispatch(mapLocationtoAsset(data))
                }
                else {
                    var data = {
                        "asset_id": asset._id,
                        "location_id": location._id,
                        "customer_id": props.selectedData._id,
                        "m_company_id": props.userInformation.m_company_id,
                    }
                    dispatch(removeMapLocationtoAsset(data))
                }
                // 
            }

            tempLoc.push(loc)
        })

        setLocList([...tempLoc])



    }



    // ============================




    return (
        <div className="p-3">
            <Row style={{ borderBottom: "1px solid #ededed", paddingBottom: 10 }}>
                <Col lg={9} >
                    <div className="row">
                        <div className="font-size-10 text-secondary text-opacity-70">Customer</div>
                        <div className="text-primary font-size-15">{selectedData.customer_name}</div>
                    </div>
                </Col>
                <Col lg={3} className="d-flex flex-row justify-content-end">
                    <button type="submit" className="btn btn-secondary w-md" onClick={() => {
                        toggleShowEditor()
                    }}>
                        Add Asset <i className={showEditor ? "mdi mdi-close" : "mdi mdi-pencil-outline"} id="edittooltip" />
                    </button>
                </Col>

            </Row>
            <Row>
                <Col lg={12} >

                    {
                        showEditor &&
                        <div style={{ borderBottom: "1px solid #ededed", paddingBottom: 10, paddingTop: 10 }}>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    assetValidation.handleSubmit();
                                    return false;
                                }}
                            >

                                <Row>
                                    {
                                        props.cmpConfig &&
                                        <Col md={12}>
                                            <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="autoSizingSelect">Asset type</label>
                                                <select className="form-select" onChange={(e) => {
                                                    console.log("event.taget.value", e.target.value)
                                                    setAssetType(e.target.value)
                                                }}
                                                    defaultValue={assetType}
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        props.cmpConfig.asset_type.map((item, idx) => {
                                                            return (
                                                                <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        </Col>
                                    }

                                </Row>

                                <Row>
                                    <Col lg={12} >
                                        <div className="mb-3">
                                            <Label htmlFor="asset_name">Asset Name</Label>
                                            <Input
                                                name="asset_name"
                                                className="form-control"
                                                placeholder="Enter Asset name"
                                                type="text"
                                                onChange={assetValidation.handleChange}
                                                onBlur={assetValidation.handleBlur}
                                                value={assetValidation.values.asset_name}
                                                required
                                                invalid={
                                                    assetValidation.touched.asset_name && assetValidation.errors.asset_name ? true : false
                                                }
                                            />
                                            {assetValidation.touched.asset_name && assetValidation.errors.asset_name ? (
                                                <FormFeedback type="invalid">{assetValidation.errors.asset_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="asset_config">Asset Configuration</Label>
                                            <Input
                                                name="asset_config"
                                                className="form-control"
                                                placeholder="Enter Configuration"
                                                type="textarea"
                                                onChange={assetValidation.handleChange}
                                                onBlur={assetValidation.handleBlur}
                                                value={assetValidation.values.asset_config || ""}
                                                required
                                                invalid={
                                                    assetValidation.touched.asset_config && assetValidation.errors.asset_config ? true : false
                                                }
                                            />
                                            {assetValidation.touched.asset_config && assetValidation.errors.asset_config ? (
                                                <FormFeedback type="invalid">{assetValidation.errors.asset_config}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>

                                <div className="mb-3">
                                    <Label htmlFor="auid">Asset Unique Identifier</Label>
                                    <Input
                                        name="auid"
                                        className="form-control"
                                        placeholder="Enter Ip address / Host name / License number"
                                        type="text"
                                        onChange={assetValidation.handleChange}
                                        onBlur={assetValidation.handleBlur}
                                        value={assetValidation.values.auid}
                                        required
                                        invalid={
                                            assetValidation.touched.auid && assetValidation.errors.auid ? true : false
                                        }
                                    />
                                    {assetValidation.touched.auid && assetValidation.errors.auid ? (
                                        <FormFeedback type="invalid">{assetValidation.errors.auid}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="asset_serial_no">Asset Serial Number</Label>
                                    <Input
                                        name="asset_serial_no"
                                        className="form-control"
                                        placeholder="Enter Serial Number"
                                        type="text"
                                        onChange={assetValidation.handleChange}
                                        onBlur={assetValidation.handleBlur}
                                        value={assetValidation.values.asset_serial_no}
                                        invalid={
                                            assetValidation.touched.asset_serial_no && assetValidation.errors.asset_serial_no ? true : false
                                        }
                                    />
                                    {assetValidation.touched.asset_serial_no && assetValidation.errors.asset_serial_no ? (
                                        <FormFeedback type="invalid">{assetValidation.errors.asset_serial_no}</FormFeedback>
                                    ) : null}
                                </div>

                                <div className="col-sm-auto mb-3">
                                                <label className="" htmlFor="autoSizingSelect">Environment</label>
                                                <select className="form-select" onChange={(e) => {
                                                    console.log("event.taget.value", e.target.value)
                                                    setAssetEnv(e.target.value)
                                                }}
                                                    defaultValue={assetEnv}
                                                >
                                                    <option value="0" disabled>Choose...</option>
                                                    {
                                                        props.cmpConfig.asset_environment.map((item, idx) => {
                                                            return (
                                                                <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>

                                <div className="mb-3">
                                    <Label htmlFor="remarks">Remarks</Label>
                                    <Input
                                        name="remarks"
                                        className="form-control"
                                        placeholder="Enter Mobile number"
                                        type="textarea"
                                        onChange={assetValidation.handleChange}
                                        onBlur={assetValidation.handleBlur}
                                        value={assetValidation.values.remarks || ""}
                                        required
                                        invalid={
                                            assetValidation.touched.remarks && assetValidation.errors.remarks ? true : false
                                        }
                                    />
                                    {assetValidation.touched.remarks && assetValidation.errors.remarks ? (
                                        <FormFeedback type="invalid">{assetValidation.errors.remarks}</FormFeedback>
                                    ) : null}
                                </div>




                                <div>
                                    <button type="submit" className="btn btn-primary w-md me-2" >
                                        {selectedAssetData !== "" ? "update" : "Submit"}
                                    </button>

                                    <button type="submit" className="btn btn-danger w-md" onClick={() => {
                                        toggleShowEditor()
                                    }}>
                                        {"cancel"}
                                    </button>
                                </div>
                            </Form>

                        </div>
                    }

                    {

                        astList.length > 0 &&
                        <div className="d-flex flex-column mt-2 mb-2">
                            <div className="d-flex flex-column  mt-2">
                                <div className="text-secondary">Assets</div>
                                {
                                    astList.map((elem, idx) => {
                                        return (
                                            <div key={"ast" + idx} className="row py-2 w-100" style={{ borderBottom: "1px solid #e0e0e0" }}>
                                                <div className="col-9 d-flex flex-row align-items-center my-2" >
                                                    <div className="">
                                                        <div className="">{elem.asset_name}</div>
                                                        <div className="font-size-12 text-secondary" >{elem.asset_type}</div>
                                                        <div className="font-size-12 text-secondary" >AUID - {elem.auid}, Serial No - {elem.asset_serial_no}</div>
                                                    </div>
                                                </div>
                                            <div className="col-3 d-flex flex-row justify-content-end align-items-center my-2">
                                                <div>
                                                    <Link
                                                        to="#!"
                                                        className="btn btn-sm btn-soft-success me-1"
                                                        onClick={(e) => {

                                                            e.preventDefault()
                                                            setColToggle(-1)
                                                            const data = elem;
                                                            toggleLocation(elem)

                                                        }}
                                                    >
                                                        <i className={elem.locationEditor ? "mdi mdi-close font-size-15" : "mdi mdi-google-maps font-size-15"} id="edittooltip" />
                                                    </Link>
                                                    <Link
                                                        to="#!"
                                                        className="btn btn-sm btn-soft-secondary me-1"
                                                        onClick={(e) => {

                                                            e.preventDefault()
                                                            setColToggle(-1)
                                                            const data = elem;
                                                            toggleEditor(elem)
                                                        }}
                                                    >
                                                        <i className={elem.edit ? "mdi mdi-close font-size-15" : "mdi mdi-pencil-outline font-size-15"} id="edittooltip" />
                                                    </Link>
                                                    <Link
                                                        to="#!"
                                                        className="btn btn-sm btn-soft-danger"
                                                        onClick={(e) => {

                                                            e.preventDefault()
                                                            const data = elem;
                                                            closeEditors()
                                                            ColToggle !== idx ? setColToggle(idx) : setColToggle(-1)
                                                        }}
                                                    >
                                                        <i className={ColToggle === idx ? "mdi mdi-close font-size-15" : "mdi mdi-delete-outline font-size-15"} />
                                                    </Link>
                                                </div>
                                            </div>

                                                {
                                                    <Collapse isOpen={elem.edit}>
                                                        <div className="row mb-3">
                                                            <div className="font-size-13 text-secondary bg-secondary bg-opacity-10 py-2 ps-2">Edit Asset</div>
                                                        </div>
                                                        <Form
                                                            className="form-horizontal"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                assetValidation.handleSubmit();
                                                                return false;
                                                            }}
                                                        >

                                                            <Row>
                                                                {
                                                                    props.cmpConfig &&
                                                                    <Col md={12}>
                                                                        <div className="col-sm-auto mb-3">
                                                                            <label className="" htmlFor="autoSizingSelect">Asset type</label>
                                                                            <select className="form-select" onChange={(e) => {
                                                                                console.log("event.taget.value", e.target.value)
                                                                                setAssetType(e.target.value)
                                                                            }}
                                                                                defaultValue={assetType}
                                                                            >
                                                                                <option value="0" disabled>Choose...</option>
                                                                                {
                                                                                    props.cmpConfig.asset_type.map((item, idx) => {
                                                                                        return (
                                                                                            <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </select>
                                                                        </div>
                                                                    </Col>
                                                                }

                                                            </Row>

                                                            <Row>
                                                                <Col lg={12} >
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="asset_name">Asset Name</Label>
                                                                        <Input
                                                                            name="asset_name"
                                                                            className="form-control"
                                                                            placeholder="Enter Asset name"
                                                                            type="text"
                                                                            onChange={assetValidation.handleChange}
                                                                            onBlur={assetValidation.handleBlur}
                                                                            value={assetValidation.values.asset_name}
                                                                            required
                                                                            invalid={
                                                                                assetValidation.touched.asset_name && assetValidation.errors.asset_name ? true : false
                                                                            }
                                                                        />
                                                                        {assetValidation.touched.asset_name && assetValidation.errors.asset_name ? (
                                                                            <FormFeedback type="invalid">{assetValidation.errors.asset_name}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="asset_config">Asset Configuration</Label>
                                                                        <Input
                                                                            name="asset_config"
                                                                            className="form-control"
                                                                            placeholder="Enter Configuration"
                                                                            type="textarea"
                                                                            onChange={assetValidation.handleChange}
                                                                            onBlur={assetValidation.handleBlur}
                                                                            value={assetValidation.values.asset_config || ""}
                                                                            required
                                                                            invalid={
                                                                                assetValidation.touched.asset_config && assetValidation.errors.asset_config ? true : false
                                                                            }
                                                                        />
                                                                        {assetValidation.touched.asset_config && assetValidation.errors.asset_config ? (
                                                                            <FormFeedback type="invalid">{assetValidation.errors.asset_config}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <div className="mb-3">
                                                                <Label htmlFor="auid">Asset Unique Identifier</Label>
                                                                <Input
                                                                    name="auid"
                                                                    className="form-control"
                                                                    placeholder="Enter Ip address / Host name / License number"
                                                                    type="text"
                                                                    onChange={assetValidation.handleChange}
                                                                    onBlur={assetValidation.handleBlur}
                                                                    value={assetValidation.values.auid}
                                                                    required
                                                                    invalid={
                                                                        assetValidation.touched.auid && assetValidation.errors.auid ? true : false
                                                                    }
                                                                />
                                                                {assetValidation.touched.auid && assetValidation.errors.auid ? (
                                                                    <FormFeedback type="invalid">{assetValidation.errors.auid}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label htmlFor="asset_serial_no">Asset Serial Number</Label>
                                                                <Input
                                                                    name="asset_serial_no"
                                                                    className="form-control"
                                                                    placeholder="Enter Serial Number"
                                                                    type="text"
                                                                    onChange={assetValidation.handleChange}
                                                                    onBlur={assetValidation.handleBlur}
                                                                    value={assetValidation.values.asset_serial_no}
                                                                    invalid={
                                                                        assetValidation.touched.asset_serial_no && assetValidation.errors.asset_serial_no ? true : false
                                                                    }
                                                                />
                                                                {assetValidation.touched.asset_serial_no && assetValidation.errors.asset_serial_no ? (
                                                                    <FormFeedback type="invalid">{assetValidation.errors.asset_serial_no}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="col-sm-auto mb-3">
                                                                <label className="" htmlFor="autoSizingSelect">Environment</label>
                                                                <select className="form-select" onChange={(e) => {
                                                                    console.log("event.taget.value", e.target.value)
                                                                    setAssetEnv(e.target.value)
                                                                }}
                                                                    defaultValue={assetEnv}
                                                                >
                                                                    <option value="0" disabled>Choose...</option>
                                                                    {
                                                                        props.cmpConfig.asset_environment.map((item, idx) => {
                                                                            return (
                                                                                <option key={"impt" + idx} value={item.id}>{item.name}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label htmlFor="remarks">Remarks</Label>
                                                                <Input
                                                                    name="remarks"
                                                                    className="form-control"
                                                                    placeholder="Enter Mobile number"
                                                                    type="textarea"
                                                                    onChange={assetValidation.handleChange}
                                                                    onBlur={assetValidation.handleBlur}
                                                                    value={assetValidation.values.remarks || ""}
                                                                    required
                                                                    invalid={
                                                                        assetValidation.touched.remarks && assetValidation.errors.remarks ? true : false
                                                                    }
                                                                />
                                                                {assetValidation.touched.remarks && assetValidation.errors.remarks ? (
                                                                    <FormFeedback type="invalid">{assetValidation.errors.remarks}</FormFeedback>
                                                                ) : null}
                                                            </div>




                                                            <div>
                                                                <button type="submit" className="btn btn-primary w-md me-2" >
                                                                    {selectedAssetData !== "" ? "update" : "Submit"}
                                                                </button>
                                                                <button type="submit" className="btn btn-danger w-md" onClick={() => {
                                                                    closeAssetEditor()
                                                                }}>
                                                                    {"Cancel"}
                                                                </button>
                                                            </div>
                                                        </Form>

                                                    </Collapse>
                                                }
                                                {
                                                    <Collapse isOpen={elem.locationEditor} >
                                                    <div className="row mb-3">
                                                        <div className="font-size-13 text-success bg-success bg-opacity-10 py-2 ps-2">Locations mapped</div>
                                                    </div>
                                                        {
                                                            locList && locList.map((item, idx) => {
                                                                return (
                                                                    <div className="row " key={"ln" + idx} style={{ borderBottom: idx !== locList.length - 1 && "1px solid #ededed", paddingBottom: 5, paddingTop: 5 }}>
                                                                    <div className="col-12">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <div style={{ width: "5%", textAlign:"center"}}>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        value={item.editLocation}
                                                                                        id={"pmsn" + idx}
                                                                                        onChange={(e) => { assetMapLocation(item, selectedAstForLocation) }}
                                                                                        checked={item.editLocation}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: "95%" }}>
                                                                                <div className="text-success font-size-14">{item.location_name}</div>
                                                                                <div className="font-size-11">{item.location_type}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                   
                                                                )
                                                            })
                                                        }
                                                    </Collapse>
                                                }
                                                {
                                                <Collapse isOpen={ColToggle === idx}>
                                                    <div className="row mb-3">
                                                        <div className="font-size-13 text-danger bg-danger bg-opacity-10 py-2 ps-2">Delete Item</div>
                                                    </div>

                                                    <div >
                                                        <div className="py-2 ps-3" >
                                                            <CardHeader className="bg-transparent mb-3">
                                                                <h7 className="my-0 text-danger">
                                                                    <i className="mdi mdi-block-helper me-3" />Are your sure, you want to delete this item?
                                                                </h7>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <CardText>
                                                                    <div>
                                                                        <button className="btn btn-sm btn-success me-2" onClick={() => { setColToggle(-1) }}>Cancel</button>
                                                                        <button className="btn btn-sm btn-danger" onClick={() => { setColToggle(-1); handleDeleteAsset(elem) }}>Delete</button>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </div>

                                                    </div>
                                                </Collapse>
                                            }

                                            </div>
                                            // <div key={"ast" + idx} className="btn btn-outline-secondary p-2 me-2" onClick={() => {
                                            //     handleEditAsset(elem, item)
                                            // }}>
                                            //     <div className="col-auto" >
                                            //         <div className="d-flex flex-column justify-content-start ">
                                            //             <div className="me-2"><i className={elem.asset_icon} style={{ fontSize: 25 }} id="edittooltip" /> </div>
                                            //             <div style={{ lineHeight: 1 }}>{elem.asset_name}</div>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        )
                                    })

                                }
                            </div>
                        </div>
                    }
                </Col>

            </Row>
        </div>
    );
};

export default AddAssets;
