import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";
import { set } from "lodash";

const initialRegState = {
    companyInfo: null,
    isSubscriptionRequestSuccess:false,
    responseSubscriptionError:"",
    companySubscriptionList: null,
    customerSubscriptionList: null,
    isAddonAdded:false,
    addonList:null,
    customerConsumptions:null,
    updateConsumptionSuccessfully:false,
    customerConsumptionMonthlyList:null,
    updateConsumptionFileSuccessfully:false,
    consumptionData:null

};


const manageSubscriptionSlice = createSlice({
    name: "manageSubscription",
    initialState: initialRegState,
    reducers: {

        setCompanySubscriptionList: (state, action) => {
            state.companySubscriptionList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },
        resetCompanySubscriptionList: (state, action) => {
            state.companySubscriptionList = null
        },

        setCustomerSubscriptionList: (state, action) => {
            state.customerSubscriptionList = _.each(action.payload, item => {
                item["edit"] = false
            })
        },

        resetCustomerSubscriptionList: (state, action) => {
            state.customerSubscriptionList = null
        },

        setSubscriptionSuccessfullyAdded:(state, action) => {
            state.isSubscriptionRequestSuccess = true
        },
        resetSubscriptionSuccessfullyAdded:(state, action) => {
            state.isSubscriptionRequestSuccess = false
        },
        setSubResponseError:(state, action) => {
            state.responseSubscriptionError = action.payload
        },
        resetSubResponseError:(state, action) => {
            state.responseSubscriptionError = null
        },
        addonAddedSuccessfully:(state, action) => {
            state.isAddonAdded = true
        },
        resetAddonAddedSuccessfully:(state, action) => {
            state.isAddonAdded = true
        },
        resetAddonAdded:(state, action) => {
            state.isAddonAdded = false
        },
        setAddon:(state, action) => {
            state.addonList = action.payload
        },
        setCustomerConsumptions:(state, action) => {
            state.customerConsumptions = action.payload
        },
        resetCustomerConsumptions:(state, action) => {
            state.customerConsumptions = null
        },
        consumptionUpdated:(state, action) => {
            state.updateConsumptionSuccessfully = true
        },
        resetConsumptionUpdation:(state, action) => {
            state.updateConsumptionSuccessfully = false
        },
        setConsumptionMonthlyData:(state, action) => {
            state.customerConsumptionMonthlyList = action.payload
        },
        consumptionUpdatedData:(state, action) => {
            state.consumptionData = action.payload
            state.updateConsumptionFileSuccessfully = true
        },
        consumptionFileUpdated:(state, action) => {
            state.updateConsumptionFileSuccessfully = true
        },
        resetConsumptionFileUpdated:(state, action) => {
            state.updateConsumptionFileSuccessfully = false
        },
        ccExpandCollapse:(state, action) => {
            state.customerConsumptions = state.customerConsumptions.map(item => {
                 if(action.payload._id === item._id){
                    return  {...item , "is_expand" : !item.is_expand}
                 }else{
                     return item
                }
            })
        },
        dataExpandCollapse:(state, action) => {
            state.customerConsumptionMonthlyList = state.customerConsumptionMonthlyList.map(item => {
                 if(action.payload._id === item._id){
                    return  {...item , "is_expand" : !item.is_expand}
                 }else{
                     return item
                }
            })
        }
        
    }

})

export const createCompanySubscription = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/add-company-subscription", {
                "subscriptionInfo": values
            })
            console.log("log --", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubscriptionSuccessfullyAdded())
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateCompanySubscription = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/update-company-subscription", {
                "subscriptionInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubscriptionSuccessfullyAdded())
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchCompanySubscriptions = (subscriptionInfo) => {
    return async (dispatch) => {
        try {
            console.log("am woring", subscriptionInfo)
            const responseData = await post("/manage_subscription/fetch-company-subscription", {
                "subscriptionInfo":subscriptionInfo
            })
            console.log("response scri" , responseData)
            if(responseData.response_code === 500)
            {
                
                if(responseData.data.length > 0)
                {
                    dispatch(setCompanySubscriptionList(responseData.data))
                }
                else
                {
                    dispatch(resetCompanySubscriptionList())
                }
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteCompanySubscriptions = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/delete-company-subscription", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubscriptionSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const createCustomerSubscription = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/add-customer-subscription", {
                "subscriptionInfo": values
            })
            console.log("log --", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubscriptionSuccessfullyAdded())
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateCustomerSubscription = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/update-customer-subscription", {
                "subscriptionInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubscriptionSuccessfullyAdded())
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchCustomerSubscriptions = (company_id) => {
    return async (dispatch) => {
        try {
            const responseData = await get("/manage_subscription/fetch-customer-subscription")
            if(responseData.response_code === 500)
            {
                
                if(responseData.data.length > 0)
                {
                    dispatch(setCustomerSubscriptionList(responseData.data))
                }
                else
                {
                    dispatch(resetCustomerSubscriptionList())
                }
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteCustomerSubscriptions = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/delete-customer-subscription", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setSubscriptionSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchSubscriptionAddon = (subscription_id) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_subscription/fetch-customer-subscription", {subscription_id})
            if(responseData.response_code === 500)
            {
                
                if(responseData.data.length > 0)
                {
                    dispatch(setAddon(responseData.data))
                }
                else
                {
                    dispatch(resetAddonAdded())
                }
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const createSubscriptionAddon = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetSubscriptionSuccessfullyAdded())
            const responseData = await post("/manage_subscription/add-customer-subscription-addon", {
                "addonInfo": values
            })
            console.log("log --", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(addonAddedSuccessfully())
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchCustomerConsumptions= (subscriptionInfo) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_subscription/fetch-customer-consumptions", {subscriptionInfo})
            if(responseData.response_code === 500)
            {
                
                if(responseData.data.length > 0)
                {
                    dispatch(setCustomerConsumptions(responseData.data))
                }
                else
                {
                    dispatch(resetCustomerConsumptions())
                }
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateCustomerConsumptions = (values) => {
    return async (dispatch) => {
        try {
            const responseData = await post("/manage_subscription/update-customer-consumption", {
                "consumptionInfo": values
            })
            console.log("update consumption", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(consumptionUpdated())
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateConsumptionFiles = (values) => {
    return async (dispatch) => {
        console.log("am in")
        try {
            const responseData = await post("/manage_subscription/update-consumption-files", {
                "consumptionInfo": values
            })
            console.log("update consumption", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(consumptionUpdatedData(responseData.data))
            }
            else
            {
                dispatch(setSubResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchCustomerMonthlyConsumption = (values) => {
    return async (dispatch) => {
        try{
            let responseData = await post('/manage_subscription/fetch-customer-monthly-consumption', {
                "consumptionInfo": values
            })
            console.log('data', responseData )
            if(responseData.response_code === 500) dispatch(setConsumptionMonthlyData(responseData.data));
            
        }catch(err){
            console.log('error in getting user monthly usage: ', err)
        }
    }
}


export const { 
    setCompanySubscriptionList, 
    setCustomerSubscriptionList, 
    setSubscriptionSuccessfullyAdded, 
    resetSubscriptionSuccessfullyAdded,
    resetCompanySubscriptionList,
    resetCustomerSubscriptionList,
    setSubResponseError,
    resetSubResponseError,
    setAddon,
    resetAddonAdded,
    addonAddedSuccessfully,
    resetAddonAddedSuccessfully,
    setCustomerConsumptions,
    resetCustomerConsumptions,
    consumptionUpdated,
    resetConsumptionUpdation,
    setConsumptionMonthlyData,
    consumptionFileUpdated,
    resetConsumptionFileUpdated,
    consumptionUpdatedData,
    dataExpandCollapse,
    ccExpandCollapse
} = manageSubscriptionSlice.actions;

export default manageSubscriptionSlice.reducer;
