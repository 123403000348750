import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { del, get, post, put } from "../helpers/QP_API_ACCESS/apiAccess";

const initialRegState = {
    companyInfo: null,
    locationList: null,
    isSuccessfullyAdded:false,
    responseLocationError:""
};


const manageLocationSlice = createSlice({
    name: "manageLocation",
    initialState: initialRegState,
    reducers: {

        setLocationList: (state, action) => {
            state.locationList = _.each(action.payload, item => {
                item["editLocation"] = false
            })
        },
        setLocationSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = true
        },
        resetLocationSuccessfullyAdded:(state, action) => {
            state.isSuccessfullyAdded = false
        },
        resetLocationList:(state, action) => {
            state.locationList = null
        },
        setLocResponseError:(state, action) => {
            state.responseLocationError = action.payload
        },
        resetLocCusResponseError:(state, action) => {
            state.responseLocationError = null
        }
        
    }

})

export const createLocation = (values) => {
    return async (dispatch) => {
        console.log("values", values)
        try {
            dispatch(resetLocationSuccessfullyAdded())
            const responseData = await post("/manage_location/add-location", {
                "locationInfo": values
            })
            console.log("create user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setLocationSuccessfullyAdded())
            }
            else
            {
                dispatch(setLocResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const updateMasterLocation = (values) => {
    return async (dispatch) => {
        try {
            dispatch(resetLocationSuccessfullyAdded())
            const responseData = await post("/manage_location/update-location", {
                "locationInfo": values
            })
            console.log("update user", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setLocationSuccessfullyAdded())
            }
            else
            {
                dispatch(setLocResponseError(responseData.message))
            }
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const fetchLocations = (company_id, customer_id) => {
    return async (dispatch) => {
        try {

            const responseData = await post("/manage_location/get-locations", {
                "company_id":company_id,
                "customer_id":customer_id,
            })
            console.log("get locations", responseData)
            if(responseData.response_code === 500)
            {
                if(responseData.data.length > 0)
                {
                    dispatch(setLocationList(responseData.data))
                }
                else
                {
                    dispatch(resetLocationList())
                }
                
            }
            else
            {
                dispatch(setLocResponseError(responseData.message))
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}

export const deleteMasterLocation = (id) => {
    return async (dispatch) => {
        try {
            dispatch(resetLocationSuccessfullyAdded())
            const responseData = await post("/manage_location/delete-location", {
                "ID":id
            })
            console.log("delete units", responseData)
            if(responseData.response_code === 500)
            {
                dispatch(setLocationSuccessfullyAdded())
            }
            
        } catch (error) {
            console.log("try error", error)
        }
    }
}


export const { 
    setLocationList, 
    resetLocationList, 
    setLocationSuccessfullyAdded, 
    resetLocationSuccessfullyAdded,
    setLocResponseError,
    resetLocResponseError 
} = manageLocationSlice.actions;

export default manageLocationSlice.reducer;
